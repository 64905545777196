import * as React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";

export default function MockInterview() {
  return (
    <Container
      maxWidth={false}
      className="landing-container"
      sx={{
        marginTop: "2.5rem",
        width: "100%",
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // add position property to create a stacking context
      }}
    >
      <Box
        sx={{
          position: "absolute", // position the pseudo-element
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url('MockInterviewBGFinal.png')`, // add your image path here
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1, // adjust the opacity as needed
        }}
        component="div"
        className="background"
      />
      <Container
        className="landing-container"
        sx={{
          width: "100%",
          marginTop: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            position: "relative",
          }}
        >
          <Typography align="left" fontSize="18px" color={Color.white}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
          <Typography
            align="left"
            fontSize="52px"
            color={Color.white}
            fontWeight="400px"
          >
            Mock Interview
          </Typography>
          <Typography align="left" fontSize="16px" color={Color.white}>
            The Mock Interview is one of the most important placement
            preparedness assessment tools for you. It plays a very critical role
            of identifying areas of improvement. In a 1x1 interaction, you will
            meet a relevant industry mentor to simulate an actual job interview
            on campus. You will get feedback on how to do better in both
            technical and behavioral areas in the context of the JD that you
            have chosen
          </Typography>
          <Button
            variant="contained"
            // color="warning"
            size="large"
            sx={{
              float: "left",
              marginTop: "40px",
              backgroundColor: Color.primary1,
            }}
          >
            Buy Now
          </Button>
        </Container>
        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        />
      </Container>
    </Container>
  );
}
