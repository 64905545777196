import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
// const TAX_RATE = 0.07;
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
// import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import CCAppBar from "../../components/AppBar/AppBar";
import ServiceCM from "../../services/cartmoduleServices";
import Service from "../../services/httpService";
// import "react-phone-input-2/lib/material.css";
import "../Pricing/OurPackages/OurPackages.css";
export default function CartScreen({ props }) {
  const navigate = useNavigate();

  const location = useLocation();
  const [tempQuantity, setTempQuantity] = useState({});
  const serviceCM = new ServiceCM();
  const services = new Service();
  const [phone, setPhone] = useState("");
  console.log("phone", phone);

  const tempBundleInfo = location.state && location.state.temp_quantity;
  console.log("temp quantoity in chat screen", tempBundleInfo);
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [errMsg, setErrMsg] = useState("");
  console.log(errMsg);
  const heading = [
    {
      tableHeader: "Item",
    },
    {
      tableHeader: "Price",
    },
    {
      tableHeader: "Quantity",
    },
    {
      tableHeader: "Total",
    },
  ];

  const bottomBillingSections = [
    {
      title: "Subtotal",
    },
    {
      title: "Taxes",
    },
    {
      title: "Costs",
    },
    {
      title: "Grand Total",
    },
  ];

  // get bundle and sku data
  const [skuData, setSkuData] = useState([]);
  const [bundleData, setBundleData] = useState([]);

  const [cartBundleData, setCartBundleData] = useState([]);
  const [cartSKUData, setCartSKUData] = useState([]);

  const [subTotal, setSubTotal] = useState(0);
  // const [disableCheckOut, setDisableCheckOut] = useState(true);
  // const [subTotal, setSubTotal] = useState(0);
  // useEffect for pricing
  useEffect(() => {
    const handleEffect = async () => {
      const storedTempQuantity = JSON.parse(
        localStorage.getItem("temp_quantity")
      );
      console.log("storedTempQuantity", storedTempQuantity);
      if (storedTempQuantity) {
        setTempQuantity(storedTempQuantity);
      }

      let bundle = await bundleGetter();
      bundle?.forEach(element => {
        const storedItem = storedTempQuantity.find(
          item => item.id === element.id && item.search_tbl === "bundle"
        );
        if (storedItem) {
          element.quantity = storedItem.quantity;
          element.total_price = storedItem.total_price;
        }
      });
      let sku = await SKUGetter();
      sku?.forEach(element => {
        const storedItem = storedTempQuantity.find(
          item => item.id === element.id && item.search_tbl === "sku"
        );
        if (storedItem) {
          element.quantity = storedItem.quantity;
          element.total_price = storedItem.total_price;
        }
      });
      // bundle info
      let temp_cart_bundle = bundle?.map(testing => ({
        id: testing.id,
        name: testing.name,
        price: testing.actual_price,
        quantity: testing.quantity,
        total_price: testing.total_price,
      }));
      console.log("local storage temp bundle", temp_cart_bundle);
      let temp_cart_filtering = temp_cart_bundle.filter((item, i) =>
        storedTempQuantity.some(
          sq => sq.id === item.id && sq.search_tbl === "bundle"
        )
      );
      setCartBundleData(temp_cart_filtering);

      // sku info
      let temp_cart_skus = sku.map(testing => ({
        id: testing.id,
        name: testing.name,
        price: testing.price,
        quantity: testing.quantity,
        total_price: testing.total_price,
      }));
      console.log("local storage temp bundle", temp_cart_bundle);
      let temp_sku_cart_filtering = temp_cart_skus.filter((item, i) =>
        storedTempQuantity.some(
          sq => sq.id === item.id && sq.search_tbl === "sku"
        )
      );
      setCartSKUData(temp_sku_cart_filtering);
      console.log("local storage temp bundle", temp_cart_filtering);
      console.log("local storage temp sku", temp_sku_cart_filtering);
      console.log("odonil", { bundle }, { sku });
      let subTotal = 0;
      bundle.forEach(item => {
        if (typeof item.total_price === "number") {
          subTotal += item.total_price;
        }
      });
      sku.forEach(item => {
        if (typeof item.total_price === "number") {
          subTotal += item.total_price;
        }
      });
      setSubTotal(subTotal);
      data_manupulator_bundle(bundle);
    };
    // subtotal

    handleEffect();
  }, []);
  console.log("temp in chat screen", tempQuantity);
  console.log("local storage", cartBundleData);
  console.log("local storage", cartSKUData);
  console.log("subtotal", subTotal);

  const bundleGetter = async () => {
    try {
      const res = await serviceCM.get("/bundle");
      console.log("Duration RESPONSE:", res.data);
      setBundleData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const SKUGetter = async () => {
    try {
      const res = await serviceCM.get("/sku");
      console.log("Duration RESPONSE:", res.data);
      setSkuData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  console.log("sku data", skuData);
  console.log("bundle data", bundleData);

  const data_manupulator_bundle = bundle => {
    console.log("SKU data:", bundle);
    let temp_rowsData = JSON.parse(JSON.stringify(bundle));
    temp_rowsData = temp_rowsData.map(row => {
      delete row.is_deleted;
      delete row.skus;
      delete row.footer_desc;
      delete row.header_desc;
      return row;
    });
    console.log("temp rows:", temp_rowsData);
  };

  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormInputs({ ...formInputs, [name]: value });
    if (value === "") {
      setFormInputs({ ...formInputs, [name]: null });
    } else if (value === "+91") {
      setFormInputs({ ...formInputs, [name]: null });
    }
    setFormErrors({});
    setErrMsg("");
    console.log("first name", formInputs.first_name);
    console.log("last name", formInputs.last_name);
    console.log("email", formInputs.email);
    console.log("phone no", formInputs.phone);
  };
  console.log("form inputs", formInputs);
  const validation = values => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const name = /^[A-Za-z]+$/;
    const errors = {};
    if (!name.test(String(values.first_name).toLowerCase())) {
      errors.first_name = "Enter a valid name";
    }
    if (
      !name.test(String(values.last_name).toLowerCase()) &&
      values.last_name !== ""
    ) {
      errors.last_name = "Enter a valid name";
    }

    if (!re.test(String(values.email).toLowerCase())) {
      errors.email = "Email is Invalid";
    }
    if (
      (values.phone.substring(0, 3) === "+91" && values.phone.length !== 13) ||
      (values.phone !== "" &&
        (values.phone.length <= 10 || values.phone.length >= 18))
    ) {
      errors.phone = "Enter Correct Phone Number";
      console.log("Phone Number cannot be blank");
    }

    // if(values.phone.trim())
    return errors;
  };

  const handleSubmit = async () => {
    let formError = { ...formErrors };
    formError = validation(formInputs);

    setFormErrors(formError);
    console.log("Form errors length:", formError);
    console.log({ formInputs });
    // if (Object.keys(formError).length === 0) {
    // setDisableCheckOut(true);
    try {
      const userDetailsPost = await services.post(
        `/purchase/preLogin/fetchUser`,
        {
          email: formInputs.email,
          first_name: formInputs.first_name,
          last_name: formInputs.last_name === "" ? null : formInputs.last_name,
          ph_no: "+" + phone,
          signup_platform: "CareerCarve",
          device_type: "Web",
        }
      );
      if (userDetailsPost.status === 200) {
        const user_id = userDetailsPost.data.user_id;
        console.log("user_id", user_id);
        const items = tempQuantity
          .filter(item => item.id !== null)
          .map(item => ({
            item_id: item.id,
            search_table: item.search_tbl,
            quantity: item.quantity,
          }));
        try {
          const cartOrdering = await services.post(
            `/purchase/preLogin/purchase`,
            {
              user_id: user_id,
              items: JSON.stringify({ items }),
            }
          );
          if (cartOrdering.status === 200) {
            navigate("/cartOrderConfirmation");
          }

          console.log(cartOrdering);
        } catch (err) {
          console.log(err);
          console.log(err?.response.data);

          setErrMsg(err?.response.data.message);
        }
      }

      console.log(userDetailsPost);
    } catch (err) {
      console.log(err);
      console.log(err?.response.data);

      setErrMsg(err?.response.data.message);
    }
    // }
  };

  // handle bundle increment and decrement
  const handleBundleIncrement = (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(bundleData));
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity + 1;
    setBundleData(temp_bundle_info);
    setTempQuantity(
      tempQuantity?.map(item => {
        if (item.id === key && item.search_tbl === "bundle") {
          return {
            ...item,
            quantity: temp_bundle_info[index].quantity,
            total_price: price * temp_bundle_info[index].quantity,
          };
        }
        return item;
      })
    );
    // localStorage.setItem("temp_quantity", JSON.stringify(tempQuantity));
  };

  const handleBundleDecrement = (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(bundleData));
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity - 1;
    setBundleData(temp_bundle_info);
    setTempQuantity(
      tempQuantity?.map(item => {
        if (item.id === key && item.search_tbl === "bundle") {
          return {
            ...item,
            quantity: temp_bundle_info[index].quantity,
            total_price: price * temp_bundle_info[index].quantity,
          };
        }
        return item;
      })
    );
  };

  return (
    <div className="login-Container" style={{ width: "max(100%, 1200px)" }}>
      <CCAppBar />
      <div className="login-Container" style={{ padding: "2rem" }}>
        <p className="billingCheckout">Checkout</p>
        <div className="login-Resumes_Image">
          <div className="" style={{ paddingRight: "2rem" }}>
            <form action="" class="login-form-billing">
              <p className="billingCheckout" style={{ marginTop: ".5rem" }}>
                Billing Details
              </p>
              <div className="outlined-basic">
                <div
                  className="firstNameAndLastName"
                  style={{ justifyContent: "space-between" }}
                >
                  <TextField
                    placeholder="Enter your first name"
                    onChange={handleChange}
                    value={formInputs.first_name}
                    name="first_name"
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    sx={{ mr: 2.5 }}
                    InputProps={{ sx: { height: 50.4 } }}
                  />
                  <TextField
                    placeholder="Enter your last name"
                    onChange={handleChange}
                    value={formInputs.last_name}
                    name="last_name"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    //   sx={{ mr: 3 }}
                    InputProps={{ sx: { height: 50.4 } }}
                  />
                </div>
                <TextField
                  placeholder="Enter your email id"
                  className=""
                  onChange={handleChange}
                  value={formInputs.email}
                  name="email"
                  id="outlined-basic"
                  label="Email "
                  variant="outlined"
                  sx={{ width: "100%", mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                />
                <br />

                <div className="signup-form__div">
                  <PhoneInput
                    className="signup-div__input"
                    onEnterKeyPress={handleSubmit}
                    countryCodeEditable={false}
                    specialLabel="Mobile Number"
                    value={phone}
                    placeholder=" "
                    country="in"
                    name="phoneno"
                    countrySelectProps={{ unicodeFlags: true }}
                    onChange={val => setPhone(val)}
                  />
                  <p className="alert-errMsg-input">{formErrors.phone}</p>
                </div>

                {/* <button
                type="button"
                className="Login-button"
                style={{
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  width: "400px",
                }}
                onClick={e => handleSubmit(e)}
              >
                Signupfbundlr
              </button> */}
              </div>
            </form>
          </div>
        </div>
        <div className="login-Main-content">
          <TableContainer className="login-form-billing">
            <Table
              sx={{ minWidth: 600, margin: "0 auto", width: "50%" }}
              aria-label="spanning table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="billingCheckout"
                    align="center"
                    colSpan={5}
                    style={{ fontSize: "24px", justifyContent: "center" }}
                  >
                    Your Shopping bag
                  </TableCell>
                </TableRow>
                <TableRow>
                  {heading.map(names => (
                    <TableCell>{names.tableHeader}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cartBundleData.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ fontSize: "18px" }}>
                      {row.name}
                    </TableCell>
                    <TableCell>&#x20b9; {row.price}</TableCell>
                    <TableCell>
                      {/* {row.quantity} */}

                      <ButtonGroup
                        className="pricingSingleACtivityOrder"
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            handleBundleIncrement(row?.id, i, row.actual_price)
                          }
                        >
                          +
                        </Button>

                        <Button disabled>{row.quantity}</Button>

                        <Button
                          onClick={() =>
                            handleBundleDecrement(row?.id, i, row.actual_price)
                          }
                        >
                          -
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell>&#x20b9; {row.price * row.quantity}</TableCell>
                  </TableRow>
                ))}
                {cartSKUData.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ fontSize: "18px" }}>
                      {row.name}
                    </TableCell>
                    <TableCell>&#x20b9; {row.price}</TableCell>
                    <TableCell>
                      {/* {row.quantity} */}
                      <ButtonGroup
                        className="pricingSingleACtivityOrder"
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                        // onClick={() =>
                        //   handleBundleIncrement(row?.id, i, row.price)
                        // }
                        >
                          +
                        </Button>

                        <Button disabled>{row.quantity}</Button>

                        <Button
                        // onClick={() =>
                        //   handleBundleDecrement(row?.id, i, row.price)
                        // }
                        >
                          -
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell>&#x20b9; {row.total_price}</TableCell>
                  </TableRow>
                ))}

                {bottomBillingSections.map((names, i) => (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} />
                      <TableCell>{names.title}</TableCell>
                      <TableCell>&#x20b9; {subTotal}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
            <button
              // disabled={disableCheckOut}
              type="button"
              className="Login-button"
              style={{
                textAlign: "center",
                marginBottom: "1.5rem",
                marginTop: "1.5rem",
                width: "400px",
                float: "right",
              }}
              onClick={e => handleSubmit(e)}
            >
              Check out
            </button>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
