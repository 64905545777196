import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  user_type: null,
  user_id: null,
  companyName: "",
  email: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.user_type = action.payload.userType;
      state.user_id = action.payload.user_id;
      state.email = action.payload.email;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
