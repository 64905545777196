import React, { Fragment } from "react";
import { useAVToggle } from "@100mslive/react-sdk";
import {
  MicOffIcon,
  MicOnIcon,
  VideoOffIcon,
  VideoOnIcon,
} from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/react-ui";
import IconButton from "../IconButton";
import { isMacOS } from "../common/constants";
import { Color } from "../../GlobalStyles";

export const AudioVideoToggle = () => {
  const { isLocalVideoEnabled, isLocalAudioEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  return (
    <Fragment>
      {toggleAudio ? (
        <Tooltip
          title={`Turn ${isLocalAudioEnabled ? "off" : "on"} audio (${isMacOS ? "⌘" : "ctrl"
            } + d)`}
        >
          <IconButton
            active={isLocalAudioEnabled}
            onClick={toggleAudio}
            key="toggleAudio"
            data-testid="audio_btn" style={{ color: "white", backgroundColor: isLocalAudioEnabled ? Color.darkslategray : Color.meetRed, borderRadius: "50px" }}
          >
            {!isLocalAudioEnabled ? (
              <MicOffIcon data-testid="audio_off_btn" />
            ) : (
              <MicOnIcon data-testid="audio_on_btn" />
            )}
          </IconButton>
        </Tooltip>
      ) : null
      }
      {
        toggleVideo ? (
          <Tooltip
            title={`Turn ${isLocalVideoEnabled ? "off" : "on"} video (${isMacOS ? "⌘" : "ctrl"
              } + e)`}
          >
            <IconButton
              key="toggleVideo"
              active={isLocalVideoEnabled}
              onClick={toggleVideo}
              data-testid="video_btn" style={{
                color: "white", backgroundColor: isLocalVideoEnabled ? Color.darkslategray : Color.meetRed,
                borderRadius: "50px"
              }}
            >
              {!isLocalVideoEnabled ? (
                <VideoOffIcon data-testid="video_off_btn" />
              ) : (
                <VideoOnIcon data-testid="video_on_btn" />
              )}
            </IconButton>
          </Tooltip>
        ) : null
      }
    </Fragment >
  );
};
