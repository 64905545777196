import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import io from "socket.io-client";
import Alert from "../../components/Alert/Alert";
// import { CountdownTimer } from "../../components/Timer/Timer";
import { StartSessionCountdownTimer } from "../../components/Timer/StartSessionTimer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { Color } from "../../../GlobalStyles";
import { SlotsContext } from "../../AppContext";
// import SessionNotStarted from "../Students/SessionNotStarted/SessionNotStarted";
import {
  checkDate,
  formatConvertermonthfirst,
} from "../../services/dateFunctions";
import TimeService from "../../services/epochTime";
import Service from "../../services/httpService";
import {
  calculateEpochTimeInLocalTime,
  extractDateFromDateString,
} from "../../services/specialCharReplace";
import "../Mentors/UpcomingBooking/UpcomingBooking.css";
const UpcomingBookingCard = props => {
  const time = new TimeService();
  const services = new Service();
  //location data
  const { slot, user_type, recent_activity } = props;
  // constants
  const { setSlotsContextData } = useContext(SlotsContext);
  // console.log("slotsContextData", slotsContextData);
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  //socket
  // const socket = io("localhost:3001");
  // const socketRef = useRef();
  // const socket = io("https://socket.careercarve.in/");
  const [is_time_ended, set_is_time_ended] = useState(false);
  //variables
  const [conditionals, setConditionals] = useState({
    join_session: true,
    start_session: false,
  });

  //flags json
  const [flag, setFlag] = useState({ time_over: false });
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  // const [buttonDisabled, setButtonDisabled] = useState(true);

  // bhoomika
  // const [sessionStarted, setSessionStarted] = useState(false);
  // console.log("SLOT", slot);
  // const handleStartSession = () => {
  //   setSessionStarted(true);
  // };

  // const handleTimerFinish = () => {
  //   console.log("Timer finished");
  //   setSessionStarted(false);
  // };
  // define default props

  const defaultProps = {
    slot: {
      act_type_id: null,
      act_type_name: "",
      college_name: null,
      duration: "",
      end_time: null,
      file_url: null,
      first_name: "",
      id: null,
      image_url: "",
      aws_url: "",
      jd_id: null,
      last_name: "",
      mentor_id: null,
      mentor_joined: 0,
      mentor_material: { pdf: [], audio: [], video: [] },
      student_material: { pdf: [], audio: [], video: [] },
      other_docs: { files: [] },
      resume: { url: "", name: "" },
      role_id: null,
      roomId: "",
      role_name: "",
      slot_date: "",
      slot_name: "",
      specialization_name: "",
      start_time: null,
      user_id: null,
      student_join_time: null,
    },
    user_type: "",
    recent_activity: false,
  };
  props = { ...defaultProps, ...props };

  //enable start session
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date().getTime();
      // console.log(
      //   `present date: ${timeService.getUTCPresentDateAndTimeInMS()}, currentDate: ${currentDate}`
      // );

      const currentTimeMS = currentDate;
      set_is_time_ended(currentDate > slot.end_time);
      if (recent_activity === true) {
        if (
          slot.student_feedback_submitted === 1 &&
          (slot.order_flag === "Feedback" || slot.order_flag === "Done")
        )
          setConditionals({
            ...conditionals,
            join_session: true,
            start_session: true,
          });
        else if (slot.order_flag === "Scheduled") {
          setConditionals({
            ...conditionals,
            join_session: true,
            start_session: false,
          });
          if (currentTimeMS >= slot.end_time + 900000) {
            setFlag(prev => ({ ...prev, time_over: true }));
          }
        } else
          setConditionals({
            ...conditionals,
            start_session: true,
            join_session: true,
          });
      }

      //15 min before time but should end on time
      if (recent_activity !== true) {
        if (user_type !== "mentor") {
          if (slot.order_flag === "In process" && slot.mentor_joined === 1)
            setConditionals({ ...conditionals, join_session: true });
          else if (
            slot.student_feedback_submitted === 1 &&
            (slot.order_flag === "Feedback" || slot.order_flag === "Done")
          ) {
            setConditionals({ ...conditionals, join_session: true });
          }
        }
        if (slot.order_flag === "Scheduled") {
          // console.log(
          //   "timer value",
          //   currentTimeMS >= slot.start_time - 900000 &&
          //     currentTimeMS <= slot.end_time
          // );
          // console.log("slot.start_time:", slot.start_time);
          // console.log("currentTimeMS:", currentTimeMS);

          const remainingTimeMS = slot.start_time - currentTimeMS;
          // console.log("present time", time.getPresentTimeInMS());
          // console.log("present UTC time", time.getUTCPresentTimeInMS());
          // console.log(
          //   `remainingTimeMinutes :${remainingTimeMinutes} & remainingTimeMS:${remainingTimeMS}`
          // );
          setRemainingSeconds(Math.ceil(remainingTimeMS / 1000));
          // const remaining_Seconds = remainingTimeMinutes * 60;
          // setRemainingSeconds(remaining_Seconds);
          // console.log("remainingTimeMS", remainingTimeMS);
          // if (remainingMinutes > 0 && remainingMinutes < 15) {
          //   setConditionals(prevState => ({
          //     ...prevState,
          //     start_session: true,
          //   }));
          // }
          // console.log(
          //   "currentTimeMS >= (slot.end_time + 900000):",
          //   currentTimeMS,
          //   slot.end_time,
          //   currentTimeMS >= slot.end_time + 900000
          // );
          if (currentTimeMS >= slot.end_time + 900000) {
            setFlag(prev => ({ ...prev, time_over: true }));
          }
          if (
            currentTimeMS >= slot.start_time &&
            currentTimeMS <= slot.end_time + 900000
          ) {
            setConditionals(prevState => ({
              ...prevState,
              start_session: true,
            }));
            clearInterval(intervalId);
          } //to be enabled to set timer value
          else
            setConditionals(prevState => ({
              ...prevState,
              start_session: false,
            }));
        } else {
          setConditionals(prevState => ({
            ...prevState,
            start_session: true,
          })); //to be enabled to set timer value
        }

        // if (
        //   slot.order_flag === "Done" &&
        //   slot.student_feedback_submitted === 1
        // ) {
        //   console.log("cadburry");
        //   setConditionals(prevState => ({ ...prevState, join_session: false }));
        // }

        if (props.settingType === "upcoming") {
          props.setIsLoadingUpcoming(false);
        } else {
          props.setIsLoadingRecent(false);
        }
      }
    }, 1000); // check every second

    return () => clearInterval(intervalId); // clean up on unmount
  }, []); // run only once on mount

  //socket student useEffect
  // useEffect(() => {
  //   const handleEffect = () => {
  //     // sessionStorage.clear();
  //     const currentDate = timeService.getPresentTimeInMS();
  //     socketRef.current = io("https://socketdev.careercarve.in");
  //     console.log("present date", currentDate, slot.end_time);
  //     set_is_time_ended(currentDate > slot.end_time);
  //     if (user_type !== "mentor") {
  //       socketRef.current.emit(
  //         "findOrder",
  //         {
  //           order_id: slot.id,
  //           user_id: slot.user_id,
  //         },
  //         (error, response) => {
  //           if (error) {
  //             console.error("errorrrrr ~ student", error);
  //           } else {
  //             console.log("responseeee ~ student", response);
  //           }
  //         }
  //       );
  //     }
  //   };
  //   if (slot.order_flag === "Scheduled" || slot.order_flag === "In process") {
  //     handleEffect();
  //     // Cleanup function to close socket connection
  //     return () => {
  //       socketRef.current.close();
  //     };
  //   }
  // }, []);

  //socket student useEffect
  // useEffect(() => {
  //   const handleSocketEffect = () => {
  //     socketRef.current.on("mentor_joined", mentor_joined => {
  //       console.log({ "has mentor joined yet": mentor_joined });
  //       if (slot.mentor_joined === 1)
  //         setConditionals({ ...conditionals, join_session: true });
  //     });
  //     return () => {
  //       socketRef.current.off("mentor_joined");
  //     };
  //   };
  //   if (slot.order_flag === "Scheduled" || slot.order_flag === "In process")
  //     handleSocketEffect();
  // }, [socketRef.current]);

  const handleStartSession = async () => {
    //backend call
    window.sessionStorage.setItem("slot", JSON.stringify(slot));
    navigate(`/preview/${slot.room_id}/mentor`);
  };
  const handleMarkAbsent = async () => {
    let body = {
      order_id: slot.id,
      is_absent: 1,
    };
    // console.log("here here", body);
    try {
      await services.put(`/scheduling/session/endSession`, body);
      //socketRef.current.close();
      navigate(`/`);
      // window.location.reload();
    } catch (err) {
      console.log(err.message || err.response.data.message);
    }
  };

  const responseAlert = data => {
    // bhoomika changes
    if (data === "Yes")
      slot.mentor_joined === 1 &&
      (slot.order_flag === "In process" ||
        slot.order_flag === "Feedback" ||
        slot.order_flag === "Done") && //----------------------------->> when its "Comments" and not the other Tabs
      // is_time_ended &&
      user_type === "mentor"
        ? handleMarkAbsent()
        : handleStartSession();
    else setAlert(false);
  };

  const handleSessionClick = async () => {
    if (slot.mentor_joined !== 1) {
      window.sessionStorage.setItem(
        "joiningTime",
        time.getUTCPresentTimeInMS()
      );
    }

    // console.log("session clicked");
    // console.log("session clicked user_type", user_type);
    window.sessionStorage.setItem("slot", JSON.stringify(slot));

    if (user_type === "mentor") {
      setSlotsContextData(slot);
      // console.log("session clicked mentor");
      if (slot.mentor_joined === 0) {
        setAlertMessage(
          "Once you start the session, a notification will be sent to the student to join the session immediately. Kindly take this action as close as possible to the scheduled time. Do you want to start the activity?"
        );
        setAlert(true);
      } else {
        //navigation ~ mentor
        //socketRef.current.close();
        // console.log("is it navigating");
        navigate(`/preview/${slot.room_id}/mentor`);
      }
    } else if (user_type === "student") {
      // console.log("session clicked student");
      const { data } = await services.get(
        `/superadmin/order?order_id=${slot.id}`
      );
      if (data[0].mentor_joined === 0) {
        navigate("/SessionNotStarted");
      } else if (data[0].mentor_joined === 1) {
        window.sessionStorage.setItem(
          "mentor_join_time",
          data[0].mentor_join_time
        );
        setSlotsContextData(data[0]);
        navigate(`/preview/${slot.room_id}/student`);
      }
    }

    // Save the student_join_time for the mentor in sessionStorage
  };

  const handleFeedbackOverview = slot => {
    window.sessionStorage.setItem("slot", JSON.stringify(slot));
    //socketRef.current.close();
    setSlotsContextData(slot);
    navigate(`/FeedbackOverview?order_id:${slot.id}`, { state: slot });
    // navigate(`/FeedbackReview`, { state: slot });
  };

  const handleFeedbackReview = slot => {
    setSlotsContextData(slot);
    navigate(`/FeedbackReview?order_id=${slot.id}`, { state: slot });
  };
  const handleFeedbackFromStudent = slot => {
    window.sessionStorage.setItem("slot", JSON.stringify(slot));
    //socketRef.current.close();
    setSlotsContextData(slot);
    navigate(`/FeedbackFromStudent`, { state: slot });
    // navigate("/StudentNotes", { state: slot });
  };
  // const handleEndSession = async () => {
  //   let body = {
  //     order_id: slot.id,
  //     is_absent: 0,
  //   };
  //   try {
  //     // hmsActions.endRoom(true, "End Room");
  //     await services.put(`/scheduling/session/endSession`, body);

  //     navigate(`/`);
  //     // sessionStorage.removeItem("mark_absent");
  //   } catch (err) {
  //     // setLoading(false);
  //     window.alert(err.response.data.message);
  //   }
  // };

  const handleAlert = is_alert => {
    if (is_alert === true) {
      setAlertMessage("Mark this student as Absent?");
      setAlert(true);
    }
    // else if (is_alert === false) {
    //   // setAlertMessage("Mark this student as Absent?")
    //   setAlert(false);
    // }
  };

  // const handleGiveFeedback = slot => {
  //   window.sessionStorage.setItem("slot", JSON.stringify(slot));
  //   //socketRef.current.close();
  //   navigate(`/FeedbackOverview`, { state: slot });
  // // };

  // console.log("conditionals", conditionals);
  // console.log("remainingMinutes", remainingMinutes);

  const handleCountDownFinish = () => {
    setConditionals(prevState => ({
      ...prevState,
      start_session: true,
    }));
  };

  return (
    <div
      className="upcoming"
      style={{
        marginBottom: "40px",
      }}
    >
      {/* <p>remainingMinutes: {remainingMinutes}</p> */}
      <Card
        sx={{
          minWidth: 275,
          background:
            flag.time_over ||
            slot.is_absent === 1 ||
            slot.order_flag === "On hold" ||
            slot.order_flag === "Missed"
              ? Color.neutralLightGrey
              : Color.white,
          border: "1px solid #E6E6E6",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
          borderRadius: "40px",
        }}
      >
        <CardContent>
          <div
            className="upcomingBookingContent"
            style={{
              height:
                checkDate(extractDateFromDateString(slot.start_time)) ===
                "today"
                  ? "230px"
                  : "250px",
            }}
          >
            <div className="upcomingBookingDescription">
              <Typography variant="h5">{slot.sku_name}</Typography>
              <Typography
                sx={{ fontSize: 18, marginTop: "1%", mb: 0 }}
                color="#000000"
                gutterBottom
              >
                {/* {console.log({ start: conditionals.start_session })} */}
                {formatConvertermonthfirst(
                  extractDateFromDateString(slot.start_time)
                )}
                {!["invalid", "day before yesterday"].includes(
                  checkDate(extractDateFromDateString(slot.start_time))
                ) ? (
                  " (" +
                  (checkDate(extractDateFromDateString(slot.start_time)) ===
                  "today"
                    ? "Today"
                    : checkDate(extractDateFromDateString(slot.start_time)) ===
                      "tomorrow"
                    ? "Tomorrow"
                    : checkDate(extractDateFromDateString(slot.start_time)) ===
                      "yesterday"
                    ? "Yesterday"
                    : "") +
                  ")"
                ) : (
                  <></>
                )}
                , {calculateEpochTimeInLocalTime(slot.start_time)} -{" "}
                {calculateEpochTimeInLocalTime(slot.end_time)}
              </Typography>
              <Typography sx={{ mb: 2, mt: 0 }} color="color: #000000;">
                with{" "}
                {user_type === "mentor" ? slot?.user_name : slot?.mentor_name}
              </Typography>
              {user_type !== "mentor" && recent_activity !== true ? (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ color: Color.green }}
                >
                  CONFIRMED
                </Typography>
              ) : slot.mentor_joined === 1 &&
                slot.order_flag === "In process" &&
                is_time_ended &&
                user_type === "mentor" ? (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ color: Color.green }}
                >
                  ONGOING
                </Typography>
              ) : null}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: user_type !== "mentor" ? 1 : 2 }}
              >
                {slot.duration} for {slot.role_name} <br />
                in {slot.specialization_name}
                <br />
              </Typography>
              {/* {console.log({ joins_session: conditionals.join_session })} */}
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* slot.order_flag === "Done" &&
                  slot.student_feedback_submitted === 1  */}
                {slot.order_flag === "Missed" ? (
                  <Typography variant="body2" color={Color.red} sx={{ mb: 2 }}>
                    {user_type === "mentor"
                      ? "You were absent for this session"
                      : "Mentor missed the session"}
                    <br />
                  </Typography>
                ) : slot.order_flag === "On hold" ? (
                  <Typography variant="body2" color={Color.red} sx={{ mb: 2 }}>
                    {user_type === "mentor" ? "On Hold" : "On Hold"}
                    <br />
                  </Typography>
                ) : slot.is_absent === 1 ? (
                  user_type === "mentor" ? (
                    <Typography
                      variant="body2"
                      color={Color.red}
                      sx={{ mb: 2 }}
                    >
                      Student has been marked absent
                      <br />
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color={Color.red}
                      sx={{ mb: 2 }}
                    >
                      Student has been marked absent
                      <br />
                    </Typography>
                  )
                ) : (
                  <>
                    {slot.order_flag === "Feedback" &&
                    slot.student_join_time === null &&
                    slot.is_absent === 0 ? (
                      <></>
                    ) : (
                      <>
                        <button
                          sx={{ alignContent: "flex-end" }}
                          disabled={
                            user_type !== "mentor"
                              ? conditionals.join_session === false
                                ? true
                                : false
                              : conditionals.start_session === false
                              ? true
                              : false
                          }
                          className={
                            recent_activity === true ||
                            slot.order_flag === "Feedback" ||
                            slot.order_flag === "Done"
                              ? "StartSEssionbutton-outline"
                              : "StartSEssionbutton"
                          }
                          size="small"
                          style={{
                            width:
                              user_type === "mentor" &&
                              ((slot.order_flag === "Feedback" &&
                                slot.mentor_feedback_submitted === 0) ||
                                (slot.order_flag === "Done" &&
                                  slot.mentor_feedback_submitted === 0))
                                ? "40%"
                                : "80%",
                            marginTop:
                              user_type !== "mentor"
                                ? "0px"
                                : user_type === "mentor" &&
                                  ((slot.order_flag === "Feedback" &&
                                    slot.mentor_feedback_submitted === 0) ||
                                    (slot.order_flag === "Done" &&
                                      slot.mentor_feedback_submitted === 0))
                                ? "-3px"
                                : slot.order_flag === "Scheduled" ||
                                  slot.order_flag === "In process"
                                ? "0px"
                                : "16px",
                          }}
                          onClick={
                            () =>
                              user_type === "mentor"
                                ? slot.order_flag === "Scheduled"
                                  ? handleSessionClick() //start session
                                  : slot.order_flag === "In process"
                                  ? handleSessionClick() //join session
                                  : slot.order_flag === "Feedback"
                                  ? slot.mentor_feedback_submitted === 1
                                    ? handleFeedbackOverview(slot)
                                    : handleFeedbackOverview(slot) //provide feedback
                                  : slot.order_flag === "Done"
                                  ? recent_activity === true &&
                                    slot.mentor_feedback_submitted === 0
                                    ? handleFeedbackReview(slot)
                                    : handleFeedbackReview(slot) //View details
                                  : ""
                                : //student condition
                                slot.order_flag === "Feedback" ||
                                  slot.order_flag === "Done" //after student has submitted notes
                                ? slot.student_feedback_submitted === 1
                                  ? "" //Feedback Given
                                  : handleFeedbackFromStudent(slot) // "Give Feedback"
                                : handleSessionClick() // "Join Session"
                          }
                        >
                          {}
                          {
                            user_type === "mentor" ? (
                              slot.order_flag === "Scheduled" &&
                              remainingSeconds > 0 &&
                              remainingSeconds <= 900 ? (
                                <StartSessionCountdownTimer
                                  onFinish={() => handleCountDownFinish()}
                                  second={remainingSeconds}
                                />
                              ) : slot.order_flag === "Scheduled" ? (
                                "Start Session"
                              ) : slot.order_flag === "Scheduled" &&
                                remainingSeconds < 0 ? (
                                "Start Session"
                              ) : slot.order_flag === "In process" ? (
                                "Join Session"
                              ) : slot.order_flag === "Feedback" ? (
                                slot.mentor_feedback_submitted === 1 ? (
                                  "View Details"
                                ) : (
                                  "Provide Feedback"
                                )
                              ) : slot.order_flag === "Done" ? (
                                slot.mentor_feedback_submitted === 0 ? (
                                  "Provide Feedback"
                                ) : (
                                  "View Details"
                                )
                              ) : (
                                "Invalid"
                              )
                            ) : //student condition
                            slot.order_flag === "Feedback" ||
                              slot.order_flag === "Done" ? ( //after student has submitted notes
                              slot.student_feedback_submitted === 1 ? (
                                "Feedback Given" //after student has submitted feedback
                              ) : (
                                "Give Feedback"
                              ) // not submitted feedback
                            ) : (
                              "Join Session"
                            ) //not submitted notes
                          }
                        </button>
                      </>
                    )}
                  </>
                )}

                {/* 2nd button */}
                {user_type === "mentor" &&
                slot.order_flag === "Feedback" &&
                slot.student_join_time === null &&
                slot.is_absent !== 1 ? (
                  // ||
                  // (slot.order_flag === "Done" &&
                  //   slot.mentor_feedback_submitted === 0 &&
                  //   slot.is_absent !== 1)
                  <>
                    <button
                      disabled={
                        user_type !== "mentor"
                          ? conditionals.join_session === false
                            ? true
                            : false
                          : conditionals.start_session === false
                          ? true
                          : false
                      }
                      className="StartSEssionbutton-outline"
                      size="small"
                      style={{
                        marginTop:
                          checkDate(
                            extractDateFromDateString(slot.start_time)
                          ) === "today"
                            ? "28.px"
                            : "24px",
                        width: "40%",
                        marginLeft:
                          slot.order_flag === "Feedback" &&
                          slot.student_join_time === null &&
                          slot.is_absent === 0
                            ? "0%"
                            : "5%",
                        marginTop: "-0.2rem",
                      }}
                      onClick={() => {
                        handleAlert(true);
                      }}
                    >
                      Mark Absent
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {}
              {user_type === "mentor" && slot.order_flag === "Scheduled" ? (
                <>
                  {!flag.time_over && (
                    <Typography
                      style={{ fontSize: "12px", textAlign: "center" }}
                    >
                      You will be able to start the session at the scheduled
                      time
                    </Typography>
                  )}
                </>
              ) : user_type === "mentor" &&
                slot.order_flag === "Scheduled" &&
                remainingSeconds > 0 &&
                remainingSeconds <= 900 ? (
                <>
                  <Typography style={{ fontSize: "12px", textAlign: "center" }}>
                    15 minutes to start the session
                  </Typography>
                </>
              ) : user_type === "mentor" &&
                slot.order_flag === "Scheduled" &&
                remainingSeconds < 0 ? (
                <>
                  <Typography style={{ fontSize: "12px", textAlign: "center" }}>
                    Once you start the session, the student will receive a
                    notification mentioning that the session has started
                  </Typography>
                </>
              ) : (
                <>
                  <Typography style={{ fontSize: "12px", textAlign: "center" }}>
                    {/* 15 minutes to start the session */}
                  </Typography>
                </>
              )}
            </div>
            <div
              className="upcomingBookingDocuments"
              id="upcomingBookingDocuments"
            >
              <Typography
                className="relatedDocuments"
                id="relatedDocuments"
                sx={{ fontSize: 16 }}
                style={{
                  marginBottom: "1rem",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 540,
                }}
              >
                Related Documents
              </Typography>
              <div className="iconAndName2">
                <PictureAsPdfIcon
                  style={{ color: "#1C8EA8", marginRight: "5%" }}
                />
                {/* <p
                  style={{ color: "#1C8EA8" }}
                  className="upcomingBookingFileName"
                >
                  {slot.resume.name}
                </p> */}
                <button
                  style={{ color: "#1C8EA8" }}
                  className="upcomingBookingFileName"
                  onClick={() => window.open(slot.resume.url || "")}
                >
                  {/* cell.substring(0, 30).concat(cell.length > 30 ? "..." : "") : cell */}
                  {slot.resume.name
                    .substring(0, 25)
                    .concat(slot.resume.name.length > 25 ? "..." : "") ||
                    "No resume attached"}
                </button>
              </div>
              {slot.file_url ? (
                <div className="iconAndName2">
                  <PictureAsPdfIcon
                    style={{ color: "#1C8EA8", marginRight: "5%" }}
                  />
                  <button
                    style={{ color: "#1C8EA8" }}
                    className="upcomingBookingFileName"
                    onClick={() => window.open(slot.file_url || "")}
                  >
                    {slot.file_url ? (
                      slot.file_url
                        .split("/")
                        .pop()
                        .replace(/^Job\+Description\//, "")
                        .substring(0, 25)
                        .concat(
                          slot.file_url
                            .split("/")
                            .pop()
                            .replace(/^Job\+Description\//, "").length > 25
                            ? "..."
                            : ""
                        )
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              ) : (
                <></>
              )}

              {slot.other_docs.files.length > 0 ? (
                slot.other_docs.files.map(file => (
                  <div className="iconAndName2">
                    <PictureAsPdfIcon
                      style={{
                        color: "#1C8EA8",
                        float: "left",
                        marginRight: "5%",
                      }}
                    />
                    <button
                      className="feedbackFileName"
                      onClick={() => window.open(file.url)}
                    >
                      <p
                        style={{ color: "#1C8EA8" }}
                        className="upcomingBookingFileName"
                      >
                        {file.name || "No file attached"}
                      </p>
                    </button>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="upcomingBookingVideo">
              <Typography className="upcomingBookingRecommended">
                Recommended Video
              </Typography>
              {user_type === "mentor" ? (
                <div>
                  {/* <p>{JSON.stringify(slot.mentor_material)}</p> */}
                  {slot.aws !== null ? (
                    <VideoPlayer url={slot.aws_url} />
                  ) : (
                    <img
                      style={{
                        borderRadius: "11px",
                        height:
                          checkDate(
                            extractDateFromDateString(slot.start_time)
                          ) === "today"
                            ? "168px"
                            : "180px",
                        width: "320px",
                        objectFit: "cover",
                      }}
                      src={slot.image_url}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {/* <p>{JSON.stringify(slot.mentor_material)}</p> */}
                  {slot?.student_material?.video?.path !== undefined ? (
                    <VideoPlayer url={slot?.student_material?.video?.path} />
                  ) : (
                    <img
                      style={{
                        borderRadius: "11px",
                        height:
                          checkDate(
                            extractDateFromDateString(slot.start_time)
                          ) === "today"
                            ? "168px"
                            : "180px",
                        width: "320px",
                        objectFit: "cover",
                      }}
                      src={slot.image_url}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </CardContent>

        <CardActions />
      </Card>
      <Alert
        heading="Alert"
        body={alertMessage}
        alert={alert}
        setAlert={setAlert}
        responseAlert={responseAlert}
      />
    </div>
  );
};

export default UpcomingBookingCard;
