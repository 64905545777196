import React from "react";
import { Button } from "@mui/material";
import { Color } from "../../../GlobalStyles";

function FileDownloader() {
  const handleDownload = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const fileUrl = urlParams.get("url");

    if (!fileUrl) {
      console.error("File URL not found in params");
      return;
    }

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a temporary anchor element
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "file"; // You can set the filename here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => {
        console.error("Error downloading file: ", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button
        className="Rephrasebutton"
        // variant="contained"
        sx={{
          // width: "45%",
          color: "white",
          backgroundColor: Color.primary1,
          border: "none",
        }}
        onClick={handleDownload}
      >
        Download File
      </Button>
    </div>
  );
}

export default FileDownloader;
