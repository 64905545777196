import React, { useRef, useState } from "react";
// import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Alert from "../../components/Modal/Alert";
import { Color } from "../../../GlobalStyles";
import { useTimer } from "../ResendButton/ResendButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Service from "../../services/httpService";
import "react-phone-input-2/lib/material.css";

const GetYourFreeAccount = () => {
  const navigate = useNavigate();
  const services = new Service();
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  console.log("phone", phone);
  const Mobile = useMediaQuery("(max-width:800px)");

  const alertResponse = () => {
    navigate("/SignUp");
  };
  const [changeTemplate, setChangeTemplate] = useState(false);
  const [condition, setCondition] = useState("");

  const firebaseConfig = {
    apiKey: "AIzaSyBbrNdmcQY1vAhXy3Zg0gHUJ3zWJCuAbRI",
    authDomain: "careercarvelogin.firebaseapp.com",
    projectId: "careercarvelogin",
    storageBucket: "careercarvelogin.appspot.com",
    messagingSenderId: "151843323088",
    appId: "1:151843323088:web:000a09c7a3d8067ae072d5",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  async function handleSignInClick() {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(
        auth,
        new OAuthProvider("apple.com")
      );
      // The signed-in user info.
      const user = result?.user;
      console.log("apple user deatils", user);
      // Apple credential
      const credential = OAuthProvider?.credentialFromResult(result);
      const accessToken = credential.accessToken;
      console.log("apple accessToken deatils", accessToken);
      console.log("user name", result.user.displayName);
      console.log("user email", result.user.email);

      const idToken = credential.idToken;
      console.log("apple idToken deatils", idToken);
      if (idToken !== null && idToken !== undefined) {
        if (result.user.email !== null && result.user.email !== undefined) {
          const res = await services.post(`/appleAuth`, {
            id_token: idToken,
            signup_platform: "CareerCarve Web App",
          });
          if (res.status === 200) {
            console.log("login with apple successful");
            // navigate("/UpcomingBooking");
            Cookies.set("token", res.headers.jwt, { expires: 182.5 });
          }
          console.log(res);
        } else {
          if (result.user.email === null || result.user.email === undefined) {
            toast.error("Email ID is required ");
          }
        }
      }
    } catch (err) {
      if (
        err?.response.status === 400 &&
        err?.response.data.message === "Unverified account"
      ) {
        setErrMsg("This email is Unverified.");
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message ===
          "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
      ) {
        setChangeTemplate(true);
        setErrMsg(
          "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
        );
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message === "Alternate email already existing."
      ) {
        setErrMsg("Alternate email already existing.");
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message === "Email already exists as alternate."
      ) {
        setErrMsg(
          "This email already exists as an secondary email. Please login with your primary email."
        );
      } else if (
        err?.response.status === 500 &&
        err?.response.data.message === "Alternate email entry doesn't exists"
      ) {
        setErrMsg("Alternate email entry doesn't exists.");
      }
      console.log("apple sign in error", err);
      const errorCode = err.code;
      console.log("apple sign in error", errorCode);

      const errorMessage = err.message;
      console.log("apple sign in error", errorMessage);

      const email = err.customData.email;
      console.log("apple sign in email", email);

      const credential = OAuthProvider.credentialFromError(err);
      console.log("apple sign in credential", credential);
    } finally {
      setIsLoading(false);
    }
  }
  const errRef = useRef();

  const clientId =
    "331878265619-n1b1ia44qjlp90amsdg2trohjqt9m51o.apps.googleusercontent.com";

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({});
  console.log("isSubmit", formErrors);
  const [errMsg, setErrMsg] = useState("");
  console.log("isSubmit", errMsg);
  const [resendTime, setResendTime] = useTimer({
    multiplier: 1,
  });
  console.log(resendTime);

  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormInputs({ ...formInputs, [name]: value });
    if (value === "") {
      setFormInputs({ ...formInputs, [name]: null });
    } else if (value === "+91") {
      setFormInputs({ ...formInputs, [name]: null });
    }
    setFormErrors({});
    setErrMsg("");
    console.log("first name", formInputs.first_name);
    console.log("last name", formInputs.last_name);
    console.log("email", formInputs.email);
    console.log("phone no", formInputs.phone);
  };

  //for validation
  const validation = values => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const name = /^[A-Za-z]+$/;
    const errors = {};
    if (!name.test(String(values.first_name).toLowerCase())) {
      errors.first_name = "Enter a valid name";
    }
    if (
      !name.test(String(values.last_name).toLowerCase()) &&
      values.last_name !== ""
    ) {
      errors.last_name = "Enter a valid name";
    }

    if (!re.test(String(values.email).toLowerCase())) {
      errors.email = "Email is Invalid";
    }
    if (
      (values.phone.substring(0, 3) === "+91" && values.phone.length !== 13) ||
      (values.phone !== "" &&
        (values.phone.length <= 10 || values.phone.length >= 18))
    ) {
      errors.phone = "Enter Correct Phone Number";
      console.log("Phone Number cannot be blank");
    }

    // if(values.phone.trim())
    return errors;
  };

  const handleResend = async e => {
    e.preventDefault();
    setResendTime(10);
    await services
      .post(`/resendVerify`, { email: formInputs.email })
      .then(res => {
        if (res.status === 200) {
          setErrMsg(
            <p>
              Link successfully resent to {formInputs.email}
              <br />
              Didn't get the link?
              <button
                className="signup-button"
                type="button"
                onClick={e => handleResend(e)}
              >
                Resend
              </button>
            </p>
          );
        }
      });
  };

  const handleActivationLink = e => {
    setCondition("Activation");
    navigate("/ForgotPassword", { state: { condition: "Activation" } });
  };
  console.log(condition);

  const handleSubmit = async () => {
    let formError = { ...formErrors };
    formError = validation(formInputs);

    setFormErrors(formError);
    console.log("Form errors length:", formError);
    console.log({ formInputs });
    // if (Object.keys(formError).length === 0) {
    try {
      const userDetailsPost = await services.post(`/signup`, {
        email: formInputs.email,
        first_name: formInputs.first_name,
        last_name: formInputs.last_name === "" ? null : formInputs.last_name,
        ph_no: "+" + phone,
        signup_platform: "CareerCarve",
        device_type: "App",
      });
      if (
        userDetailsPost.status === 200 &&
        userDetailsPost.data.message === "Verification email sent"
      ) {
        setErrMsg(
          <div>
            <p className="alert-errMsg-button">
              We have sent you an activation link on {formInputs.email}. Check
              your email to complete the activation.
              <br />
              Didn't receive email?
              <button className="signup-button" onClick={handleResend}>
                Resend
              </button>
            </p>
          </div>
        );
      }

      console.log(userDetailsPost);
    } catch (err) {
      console.log(err);
      console.log(err?.response.data);
      if (err?.response.data.message === "Mail already sent") {
        setErrMsg(
          <p className="alert-errMsg-button">
            You had already signed up using this email but it looks like you
            have not activated your account. Check your email to complete
            activation. <br /> Can't find activation link?
            <button className="signup-button" onClick={handleResend}>
              Resend
            </button>
          </p>
        );
      } else if (err?.response.data.message === "User exists") {
        setErrMsg(
          <p className="alert-errMsg-button">
            You have already activated your account
            <button className="signup-button" onClick={() => navigate("/")}>
              Login
            </button>
          </p>
        );
      } else {
        setErrMsg(err?.response.data.message);
      }

      //
    }
    // }
  };

  const text_alignment = "center";
  const googleButtonWidth = 465;
  return (
    <div
      className="login-Container"
      style={{
        width: Mobile ? "100%" : "max(100%,1200px)",
        ...(Mobile && {
          overflow: "hidden",
          width: "100%",
          maxWidth: "100%",
          overflowY: "auto",
        }),
      }}
    >
      {!Mobile && (
        <div className="login-Resumes_Image">
          <img
            className="imageFlexible"
            src="backgroundImage.png"
            alt="background"
            style={{
              backgroundRepeat: "none",
              float: "left",
              margin: "0",
              width: "100%",
              height: "100vh",
              position: "static",
              flex: "1 1 auto",
            }}
          />
          <img
            className="imageFlexible"
            src="logo_welcome.png"
            alt="background"
            style={{
              position: "absolute",
              height: "150px",
              width: "150px",
              top: "max(30%,200px)",
              left: "20%",
            }}
          />
          <img
            src="CareerCarve.png"
            alt="background"
            style={{
              position: "absolute",
              top: "max(52%,350px)",
              left: "13%",
            }}
          />
          <p
            style={{
              position: "absolute",
              top: "max(62%, 430px)",
              left: "13.5%",
              fontSize: "20px",
            }}
          >
            {" "}
            Placement | Preparedness | Perfected
          </p>
        </div>
      )}

      <div
        className="login-Main-content"
        style={{ width: Mobile ? "100%" : "50%" }}
      >
        {Mobile && (
          <img
            src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/career+carve+logo+vector+small.png"
            style={{ margin: "0 auto", width: "200px", marginTop: "1rem" }}
            alt="Career Carve Logo"
          />
        )}
        <p
          className="login-name"
          style={{
            whiteSpace: "nowrap",
            ...(Mobile && {
              paddingLeft: "1rem",
              fontSize: "28px",
              textAlign: "left",
              marginTop: "1rem",
            }),
          }}
        >
          Get your free account
        </p>
        <div className="login-l-form">
          <form
            action=""
            class="login-form"
            style={{
              ...(Mobile && {
                paddingTop: "1rem",
                paddingRight: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
                width: "100%",
              }),
            }}
          >
            <div
              className="outlined-basic"
              style={{ width: Mobile ? "100%" : "400px" }}
            >
              <div className="firstNameAndLastName">
                <TextField
                  onChange={handleChange}
                  value={formInputs.first_name}
                  name="first_name"
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  sx={{ mr: 2.5 }}
                  InputProps={{ sx: { height: 50.4 } }}
                />
                <TextField
                  onChange={handleChange}
                  value={formInputs.last_name}
                  name="last_name"
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  //   sx={{ mr: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                />
              </div>
              <TextField
                className=""
                onChange={handleChange}
                value={formInputs.email}
                name="email"
                id="outlined-basic"
                label="Email "
                variant="outlined"
                sx={{ width: Mobile ? "100%" : 400, mb: Mobile ? 4 : 3 }}
                InputProps={{ sx: { height: 50.4 } }}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              />
              <br />

              <div className="signup-form__div">
                <PhoneInput
                  className="signup-div__input"
                  style={{ marginBottom: ".3rem" }}
                  onEnterKeyPress={handleSubmit}
                  countryCodeEditable={false}
                  specialLabel="Mobile Number"
                  value={phone}
                  placeholder=" "
                  country="in"
                  name="phoneno"
                  countrySelectProps={{ unicodeFlags: true }}
                  onChange={val => setPhone(val)}
                />
                <p className="alert-errMsg-input">{formErrors.phone}</p>
              </div>
              <div className="signup-form__div">
                {/* <p
                  style={{
                    whiteSpace: "nowrap",
                    color: Color.neutralMidGrey,
                  }}
                >
                  <Checkbox
                    checked={privacyPolicyChecked}
                    style={{ textAlign: "left" }}
                    onChange={e => setPrivacyPolicyChecked(e.target.checked)}
                  />
                  I agree to the{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navigate("/TermsOfUse")}
                  >
                    Terms of Use{" "}
                  </span>
                  and{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navigate("/PrivacyPolicy")}
                  >
                    Privacy Statement
                  </span>
                </p> */}
                <p
                  style={{
                    whiteSpace: "nowrap",
                    color: Color.neutralMidGrey,
                    margin: 0, // Remove default margin
                    padding: 0, // Remove default padding
                    marginLeft: "-20px",
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: "1rem",
                  }}
                >
                  <Checkbox
                    checked={privacyPolicyChecked}
                    style={{ textAlign: "left" }}
                    onChange={e => setPrivacyPolicyChecked(e.target.checked)}
                  />
                  I agree to the{" "}
                  <a
                    href="/TermsOfUse"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    target="_blank"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="/PrivacyPolicy"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    target="_blank"
                  >
                    Privacy Statement
                  </a>
                </p>
              </div>
              <button
                type="button"
                className="Login-button"
                style={{
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  width: Mobile ? "100%" : "400px",
                }}
                disabled={!privacyPolicyChecked}
                onClick={e => handleSubmit(e)}
              >
                Sign up as student
              </button>
              <div style={{ width: "100%" }}>
                <p
                  ref={errRef}
                  className="alert-errMsg-button"
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </div>
              <div>
                <p style={{ color: "#545454", fontSize: "16px" }}>
                  Can't find activation link?
                  <br />
                  <button
                    className="signup-button"
                    type="button"
                    onClick={() => handleActivationLink()}
                  >
                    Resend
                  </button>
                </p>
              </div>

              <div className="loginOrlogin">
                <div className="line-login"></div>
                <p
                  style={{
                    marginTop: "0",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  or
                </p>
                <div className="line-login"></div>
              </div>
              <div>
                <div className="signInButton" id="signInGoogleButton">
                  <GoogleOAuthProvider
                    width={googleButtonWidth}
                    clientId={clientId}
                    text="Login with Google"
                  >
                    <GoogleLogin
                      width={googleButtonWidth}
                      text="Login with Google"
                      logo_alignment={text_alignment}
                      style={{ border: "1px solid #545454" }}
                      onSuccess={async credentialResponse => {
                        console.log("Google Auth Sucess.");
                        console.log(
                          "token that is sent to bcknd:",
                          credentialResponse.credential
                        );
                        console.log(
                          "token that is sent to bcknd:",
                          jwt_decode(credentialResponse.credential)
                        );
                        try {
                          const response = await services.post(`/googleAuth`, {
                            id_token: credentialResponse.credential,
                            signup_platform: "CareerCarve Web App",
                          });
                          console.log({ response });
                          if (response.status === 200) {
                            Cookies.set("token", response.headers.jwt, {
                              expires: 182.5,
                            });
                            // navigate("/UpcomingBooking", {
                            //   state: { formInputs: formInputs },
                            // });
                            console.log("UpcomingBooking");
                            // setErrMsg("Login successful.");
                          }
                        } catch (err) {
                          console.log(err);
                          if (
                            err?.response.status === 400 &&
                            err?.response.data.message === "Unverified account"
                          ) {
                            setErrMsg("This email is Unverified.");
                          } else if (
                            err?.response.status === 400 &&
                            err?.response.data.message ===
                              "Alternate email already existing."
                          ) {
                            setErrMsg("Alternate email already existing.");
                          } else if (
                            err?.response.status === 400 &&
                            err?.response.data.message ===
                              "Email already exists as alternate."
                          ) {
                            setErrMsg(
                              "This email already exists as an secondary email. Please login with your primary email."
                            );
                          } else if (
                            err?.response.status === 400 &&
                            err?.response.data.message ===
                              "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
                          ) {
                            setChangeTemplate(true);
                            setErrMsg(
                              "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
                            );

                            console.log("sdfghjkl", changeTemplate);
                          } else if (
                            err?.response.status === 500 &&
                            err?.response.data.message ===
                              "Alternate email entry doesn't exists"
                          ) {
                            setErrMsg("Alternate email entry doesn't exists.");
                          }
                          errRef.current.focus();
                        }
                      }}
                      onError={() => {
                        window.alert("Login Failed");
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>
              <div
                style={{
                  marginTop: "2rem",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <button
                  className="apple-sign-up-button"
                  onClick={handleSignInClick}
                  disabled={isLoading}
                  style={{ ...(Mobile && { width: "100%" }) }}
                >
                  <img className="appleIcon" src="apple.svg" />
                  Sign in with Apple
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          style={{
            bottom: 0,
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            // marginTop: "2rem",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>Already have an account?</p>
          <button
            className="LoginNowBUtton"
            onClick={() => navigate("/")}
            style={{
              margin: 0,
              marginLeft: "1rem",
            }}
          >
            Login
          </button>
        </div>
        <div
          style={{
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>Not a student?</p>{" "}
          <button
            className="LoginNowBUtton"
            onClick={() => navigate("/LoginAsMentor")}
            style={{
              margin: 0,
              marginLeft: "1rem",
            }}
          >
            Login as mentor
          </button>
        </div>
      </div>
      <Alert
        show={changeTemplate}
        title="Alert"
        body="You are not a registered user with us. Please sign up on the CareerCarve App to continue."
        yesButton="Signup"
        alertResponse={alertResponse}
        onHide={() => setChangeTemplate(false)}
      />
    </div>
  );
};

export default GetYourFreeAccount;
