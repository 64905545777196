import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Color } from "../../../GlobalStyles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "481px",
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 4,
  textAlign: "center",
  alignItems: "center",
};

export default function Alert(props) {
  const {
    heading,
    body,
    alert,
    setAlert,
    responseAlert,
    origin,
    image,
    container,
    yesButton,
    noButton,
  } = props;

  const [loadingYes, setLoadingYes] = React.useState(false);
  const [loadingNo, setLoadingNo] = React.useState(false);

  const handleClose = () => setAlert(false);

  const handleResponse = (response, setLoading) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await responseAlert(response);
      } finally {
        setLoading(false);
      }
    }, 1000); // 5-second delay
  };

  return (
    <div>
      <Modal
        open={alert}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backdropFilter: "blur(1px)",
          backgroundColor: "rgba(16, 16, 16, 0.5)",
        }}
      >
        <Box sx={style}>
          {origin === "extendedTime" && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: Color.blueBlack,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          {origin === "delete" ? (
            <Box sx={{ width: "100%", margin: "0 auto" }}>
              <img
                src={image}
                style={{ width: "200px", height: "160px", margin: "auto" }}
              />
            </Box>
          ) : (
            <>
              {heading && (
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    ...(origin === "extendedTime" && {
                      color: Color.meetRed,
                    }),
                  }}
                >
                  <p>{heading}</p>
                </Typography>
              )}
            </>
          )}
          <Typography sx={{ mt: 2, fontWeight: "medium", fontSize: "16px" }}>
            {body}
          </Typography>
          {origin === "delete" ? (
            <>
              {container === "Got it" ? (
                <Container
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="Rephrasebutton"
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor: Color.primary1,
                      color: "white",
                      borderRadius: "10px",
                      "&:disabled": {
                        width: "45%",
                      },
                    }}
                    onClick={() => handleResponse("No", setLoadingNo)}
                    disabled={loadingNo}
                  >
                    {loadingNo ? <CircularProgress size={24} /> : "Got it"}
                  </Button>
                </Container>
              ) : (
                <Container
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="RephrasebuttonNoBackground"
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor: Color.primary1,
                      color: Color.primary1,
                      borderRadius: "10px",
                      "&:disabled": {
                        width: "45%",
                      },
                    }}
                    onClick={() => handleResponse("Yes", setLoadingYes)}
                    disabled={loadingYes}
                  >
                    {loadingYes ? <CircularProgress size={24} /> : "Yes"}
                  </Button>
                  <Button
                    className="Rephrasebutton"
                    sx={{
                      width: "45%",
                      color: "white",
                      backgroundColor: Color.primary1,
                      border: "none",
                      borderRadius: "10px",
                      "&:disabled": {
                        width: "45%",
                      },
                    }}
                    onClick={() => handleResponse("No", setLoadingNo)}
                    disabled={loadingNo}
                  >
                    {loadingNo ? <CircularProgress size={24} /> : "No"}
                  </Button>
                </Container>
              )}
            </>
          ) : (
            <>
              {origin !== "extendedTime" && (
                <Container
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="RephrasebuttonNoBackground"
                    variant="outlined"
                    sx={{
                      width: "45%",
                      borderColor: Color.primary1,
                      color: Color.primary1,
                      fontSize: noButton === "Wait a bit" ? "10px" : "16px",
                      "&:disabled": {
                        width: "45%",
                        fontSize: noButton === "Wait a bit" ? "10px" : "16px",
                      },
                    }}
                    onClick={() => handleResponse("No", setLoadingNo)}
                    disabled={loadingNo}
                  >
                    {loadingNo ? (
                      <CircularProgress size={24} />
                    ) : (
                      noButton || "No"
                    )}
                  </Button>
                  <Button
                    className="Rephrasebutton"
                    variant="outlined"
                    sx={{
                      width: "45%",
                      color: "white",
                      backgroundColor: Color.primary1,
                      border: "none",
                      fontSize:
                        yesButton === "Mark Student Absent" ? "10px" : "16px",
                      "&:disabled": {
                        width: "45%",
                        fontSize:
                          yesButton === "Mark Student Absent" ? "10px" : "16px",
                      },
                    }}
                    onClick={() => handleResponse("Yes", setLoadingYes)}
                    disabled={loadingYes}
                  >
                    {loadingYes ? (
                      <CircularProgress size={24} />
                    ) : (
                      yesButton || "Yes"
                    )}
                  </Button>
                </Container>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
