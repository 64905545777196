import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";
import { Flex } from "@100mslive/react-ui";
import { AppContext, SlotsContext } from "../../AppContext";
import { ChatBody } from "./ChatBody";
import { ChatFooter } from "./ChatFooter";
import { ChatHeader } from "./ChatHeader";

export const Chat = () => {
  const socketRef = useRef();
  const listRef = useRef(null);
  const { slotsContextData } = useContext(SlotsContext);
  const formInputs = useContext(AppContext);
  const [messages, setMessages] = useState([]);
  async function fetchData() {
    try {
      if (slotsContextData.id !== null) {
        socketRef.current.emit(
          "findRoom",
          {
            order_id: parseInt(slotsContextData.id),
            req_raised_by: slotsContextData.user_id,
          },
          async (error, response) => {
            if (error) {
              console.error("findRoom errorrrrr", error, {
                order_id: parseInt(slotsContextData.id),
                req_raised_by: formInputs.user_id,
              });
            } else {
              console.log("responseeee", response);
            }
          }
        );
        socketRef.current.on("foundRoom", roomChats => {
          console.log("ROOMCHATS", JSON.stringify(roomChats));
          const chatData = JSON.parse(roomChats.chat);
          setMessages(chatData.messages);
        });
      }
    } catch (err) {
      console.log("err", err.response);
    }
  }
  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      transports: ["websocket"],
    });
    fetchData();

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    socketRef.current.on("roomMessage", messages => {
      // console.log("roomMessage", messages.at(-1), user_id);
      setMessages(messages);
    });
    return () => {
      socketRef.current.off();
    };
  }, [messages]);

  const scrollToBottom = useCallback(
    (unreadCount = 0) => {
      if (listRef.current && listRef.current.scrollToItem && unreadCount > 0) {
        listRef.current?.scrollToItem(messages.length, "end");
        requestAnimationFrame(() => {
          listRef.current?.scrollToItem(messages.length, "end");
        });
      }
    },
    [messages]
  );

  return (
    <Flex direction="column" css={{ size: "100%", zIndex: 100 }}>
      <ChatHeader />
      {/* <PinnedMessage clearPinnedMessage={setPinnedMessage} /> */}

      <ChatBody
        // role={chatOptions.role}
        // peerId={chatOptions.peerId}
        ref={listRef}
        messages={messages}
        scrollToBottom={scrollToBottom}
      />
      <ChatFooter
        ref={socketRef}
        // role={chatOptions.role}
        // peerId={chatOptions.peerId}
        // onSend={() => }
      />
    </Flex>
  );
};
