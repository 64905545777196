import React, { useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
// import { BorderBottom } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Color, feedbackbackground } from "../../../GlobalStyles";
import { SlotsContext } from "../../AppContext";
export default function CameraAndAudio(props) {
  const { slotsContextData } = useContext(SlotsContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    border: "none",
    borderRadius: "16px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
    p: 4,
    bgcolor: "background.paper",
    minWidth: "300px",
    zIndex: 2,
  };
  console.log("studentJoined", props.studentJoined);
  return (
    <Box sx={{}}>
      <Modal
        sx={{
          ...feedbackbackground,
          top:
            props.studentJoined || slotsContextData.student_join_time !== null
              ? "max(6%, 50px)"
              : "13.6%",
          ...(window.innerWidth > 1500 && {
            left: props.checkUser === "mentor" ? "2.5%" : "2.3%",
            width: "max(65.6%, 780px)",
          }),
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: ".6rem",
            }}
          >
            {props.image}
            <Typography
              id="modal-modal-title"
              fontSize={20}
              component="h2"
              style={{ paddingTop: ".3rem" }}
            >
              {props.header}
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="Rephrasebutton"
              onClick={props.takeCameraAndMicrophoneAction}
              style={{
                textTransform: "none",
                width: "85%",
                color: "#fff",
                borderRadius: "10px",
                marginBottom: ".6rem",
                marginTop: ".5rem",
                height: "40px",
                fontSize: "16px",
                marginLeft: "auto",
                marginRight: "auto",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {props.buttonText}
            </button>
            <button
              onClick={props.handleWhyThisClick}
              style={{
                textTransform: "none",
                marginBottom: ".6rem",
                marginTop: ".5rem",
                textAlign: "left",
                width: "80%",
                color: "#1c8ea8",
                background: "transparent",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {props.WhyThis}
            </button>
            <Box
              sx={{
                width: "350px",
                textAlign: "left",
                fontSize: "14px",
              }}
              color={Color.neutralMidGrey}
            >
              {props.answerOpen ? (
                <>
                  <Box>{props.Answer}</Box>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export function FeedbackModal(props) {
  const { slotsContextData } = useContext(SlotsContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    borderTop: "rgba(0, 0, 0, 0.08)",
    borderBottom: "rgba(0, 0, 0, 0.08)",
    borderRadius: "30px",
    // boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
    p: 4,
    bgcolor: "background.paper",
    minWidth: "300px",
    zIndex: 2,
  };

  console.log("props answer", props.Answer);

  return (
    <div>
      <Modal
        sx={{
          ...feedbackbackground,
          top:
            props.studentJoined || slotsContextData.student_join_time !== null
              ? "max(6%, 50px)"
              : "13.6%",
          ...(window.innerWidth > 1500 && {
            left: "2.5%",
            width: "max(65.6%, 780px)",
          }),
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: Color.blueBlack,
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={props.image}
            style={{ width: "auto", height: "140px" }}
            alt="icon"
          />
          <Box
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            <Typography
              id="modal-modal-title"
              fontSize={20}
              component="h2"
              style={{ marginTop: ".3rem" }}
            >
              {props.header}
            </Typography>
            <Button
              className="Rephrasebutton"
              onClick={props.handleClose}
              style={{
                textTransform: "none",
                width: "40%",
                color: "#fff",
                borderRadius: "10px",
                marginBottom: ".6rem",
                marginTop: ".6rem",
                marginLeft: "auto",
                marginRight: "auto",
                height: "40px",
                fontSize: "16px",
              }}
            >
              {props.buttonText}
            </Button>
            <button
              onClick={props.handleWhyThisClick}
              style={{
                textTransform: "none",
                width: "80%",
                alignItems: "left",
                marginBottom: ".6rem",
                textAlign: "left",
                color: "#1c8ea8",
                justifyContent: "flex-start",
                background: "transparent",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {props.WhyThis}
            </button>
            <Box
              sx={{
                width: "max(25%,400px)",
                textAlign: "left",
                margin: "0 auto",
                fontSize: "14px",
              }}
              color={Color.neutralMidGrey}
            >
              {/* {props.answerOpen ? (
                <>
                  <Box>{props.Answer}</Box>
                </>
              ) : (
                <></>
              )} */}
              {props.answerOpen ? (
                <>
                  <Box>
                    {isExpanded
                      ? props.Answer
                      : `${props.Answer?.slice(0, 316)}...`}
                  </Box>
                  {props.Answer?.length > 316 && (
                    <div
                      onClick={handleReadMoreClick}
                      style={{
                        textTransform: "none",
                        color: "#1c8ea8",
                        fontSize: "14px",
                        width: "100%",
                        textAlign: "right",
                        marginLeft: "auto",
                      }}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
