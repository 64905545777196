import React from "react";
import Cropper from "react-easy-crop";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, IconButton, Modal } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { ActivityLoader } from "../../components/Loader/Loader";
import { Color } from "../../../GlobalStyles";
// import { getCroppedImg } from "./utils/cropImage";
// import Loading from "../../Reusable_functions/LoadingComponent/LoadingComponent";
import getCroppedImg from "./cropImage";
import "./profilePictureSelection.css";
export default function ProfilePictureSelection(props) {
  const [image, setImage] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  // const [move, setMove] = React.useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [croppedImage, setCroppedImage] = React.useState(null);

  const [userPicture, setUserPicture] = React.useState({});

  // const marginLeft = props.marginLeft;
  //to prevent mui modal from setting body to overflow: hidden
  document.body.style.overflow = "auto";

  React.useEffect(() => {
    const handelEffect = async () => {
      setCroppedImage(
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
      );
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      };
      console.log(props.show_user);
      // console.log("Margin Left through Props:", props.marginLeft)
      if (props.show_user === 1) {
        try {
          const imageGet = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/picture`,
            { headers }
          );
          console.log(imageGet.data.url);
          const imageUploaded = imageGet.data.url;
          setCroppedImage(imageUploaded);
          console.log(imageUploaded);
          props.getProfileImage(imageUploaded);
        } catch (error) {
          console.log(error);
          setCroppedImage(
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
          );
        }
      } else if (props.show_user !== 1) {
        console.log(props.picture);
        props.getProfileImage(props.picture);
      }
    };
    handelEffect();
  }, []);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log(croppedAreaPixels);
  };

  const onSelectFile = event => {
    props.move_picture(true);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024; // Size in MB
      if (fileSize > 10) {
        // alert("Image size exceeds 10 MB");
        props.setImageErrorModal(true);
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  const showCroppedImage = async () => {
    if (!image) return;
    console.log(image);
    const multiReturn = await getCroppedImg(image, croppedAreaPixels);
    // setLoader(false);
    const userCurrentPicture = multiReturn["file"];
    const croppedImage = multiReturn["url"];
    console.log("donee", { userCurrentPicture });
    try {
      await uploadImage(userCurrentPicture);
      setUserPicture(userCurrentPicture);
      console.log({ userPicture, userCurrentPicture });
      setCroppedImage(croppedImage);
      props.getProfileImage(croppedImage);
      console.log({ croppedImage });
      setZoom(1);
      setImage(null);
      props.move_picture(false);
    } catch (e) {
      console.log(e);
    }
  };

  const uploadImage = async userPicture => {
    try {
      props.setPictureLoading(true);
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      };
      const imageRes = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/picture`,
        { userPicture },
        { headers }
      );
      console.log(imageRes);
      props.setPictureLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onCrossClick = () => {
    setImage(null);
    props.move_picture(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 920,
    height: 600,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      {/* <div style={{height:'%'}}><button style={{float:"right"}} >X</button></div> */}
      <>
        <Modal open={image !== null} onClose={() => setImage(null)}>
          {/* {console.log('Margin Left through Props:', `${props.marginLeft}`)} */}
          <Box sx={style}>
            <div
              className="pp-container"
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontSize: "24px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "44px",
                  color: "#000",
                }}
              >
                <p>Select Profile Picture</p>
                <IconButton
                  sx={{
                    fontSize: "14px",
                    background: "transparent",
                    color: "#000",
                    padding: "0px",
                  }}
                  onClick={e => setImage(null)}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </IconButton>
              </div>
              {props.pictureLoading ? (
                <ActivityLoader show={props.pictureLoading} />
              ) : (
                <>
                  <div
                    className="pp-container-cropper"
                    style={{
                      marginLeft: `${props.marginLeft}`,
                      width: "450px",
                      padding: "12px",
                      height: "450px",
                    }}
                  >
                    <div
                      style={{
                        height: "12%",
                        marginRight: "0%",
                        marginLeft: "0",
                        backgroundColor: "#000000",
                        marginTop: "%",
                      }}
                    >
                      <button
                        type="button"
                        onClick={e => {
                          showCroppedImage(e);
                        }}
                        style={{
                          backgroundColor: "Transparent",
                          border: "none",
                          padding: "2% 0% 0% 2%",
                          float: "left",
                        }}
                      >
                        <DoneIcon
                          //   class="fa-solid fa-check"
                          style={{ fontSize: "1.5em", color: "#FFFFFF" }}
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => onCrossClick()}
                        style={{
                          backgroundColor: "Transparent",
                          border: "none",
                          float: "right",
                          padding: "2% 2% 0% 0%",
                        }}
                      >
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.5em",
                            color: "#FFFFFF",
                            float: "right",
                            margin: "3% 3% 0% 0%",
                          }}
                        ></i>
                      </button>
                    </div>
                    <div className="pp-cropper">
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        cropShape="round"
                        showGrid={false}
                        minZoom={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>

                    <div
                      style={{
                        backgroundColor: "#000000",
                        display: "flex",
                        justifyContent: "Center",
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setZoom(zoom + 0.1);
                        }}
                        style={{
                          backgroundColor: "Transparent",
                          border: "none",
                          margin: "1% 2% 0 0%",
                        }}
                      >
                        <i
                          class="fa fa-plus"
                          style={{ fontSize: "1.5em", color: "#FFFFFF" }}
                        ></i>
                      </button>
                      <button
                        type="button"
                        style={{
                          cursor: "default",
                          backgroundColor: "Transparent",
                          border: "none",
                          color: "#FFFFFF",
                          fontWeight: "50%",
                        }}
                      >
                        Zoom
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setZoom(zoom - 0.1 <= 1 ? 1 : zoom - 0.1);
                        }}
                        style={{
                          backgroundColor: "Transparent",
                          border: "none",
                          float: "right",
                          margin: "0% 0 0 2%",
                        }}
                      >
                        <i
                          class="fa fa-minus"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.5em",
                            color: "#FFFFFF",
                            float: "right",
                          }}
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        background: Color.primary_gradient,
                        width: "277px",
                        height: "64px",
                        color: "#FFFFFF",
                        borderRadius: "10px",
                      }}
                      onClick={e => {
                        showCroppedImage(e);
                      }}
                    >
                      Set as Profile Picture
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Box>
        </Modal>
      </>
      {image === null && (
        <>
          <div
            className="editprofile-img-holder"
            style={{ marginBottom: "2%" }}
          >
            <div className="editprofile-profileimage">
              {props.editable ? (
                <input
                  type="file"
                  classname="inputfile"
                  accept="image/*"
                  name="image-upload"
                  id="input"
                  onChange={onSelectFile}
                  style={{ display: "none" }}
                  //  disabled={disableU}
                />
              ) : (
                <></>
              )}
              <label className="image-upload" htmlFor="input">
                <img
                  src={croppedImage}
                  alt=""
                  id="img"
                  className="editprofile-img"
                />
                {/* <i id="editprofile-image-edit" class="fa-solid fa-edit edit-icon" style={{ padding: '15px' }}></i> */}
                {props.editable ? (
                  <EditOutlinedIcon
                    id="editprofile-image-edit"
                    class="fa-solid fa-pen"
                    style={{ padding: "15px", fontSize: "18px" }}
                  />
                ) : (
                  <></>
                )}
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
