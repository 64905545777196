import React, { useEffect, useState } from "react";

export function StartSessionCountdownTimer({
  onFinish,
  second,
  backgroundColor,
  fontSize,
  borderRadius,
  padding,
}) {
  console.log("second", second);
  let s = second < 0 ? 0 : second;
  const [secondsLeft, setSecondsLeft] = useState(s); // 900 seconds = 15 minutes

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft => secondsLeft - 1);
      } else {
        onFinish();
      }
    }, 1000);

    // Clear the interval when the component unmounts or when secondsLeft reaches zero
    return () => {
      clearInterval(intervalId);
    };
  }, [secondsLeft]);

  // Calculate minutes and seconds from the remaining seconds
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <div
      style={{
        color: window.location.pathname === "/Feedback" ? "black" : "white",
        textAlign: "center",
      }}
    >
      <span
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "transparent",
          fontSize: fontSize ? fontSize : "16px",
          borderRadius: borderRadius ? borderRadius : "0px",
          padding: padding ? padding : "0px",
        }}
      >
        {minutes.toString().padStart(2, "0")}
      </span>
      <span style={{ fontSize: fontSize ? fontSize : "16px" }}>:</span>
      <span
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "transparent",
          fontSize: fontSize ? fontSize : "16px",
          borderRadius: borderRadius ? borderRadius : "0px",
          padding: padding ? padding : "0px",
        }}
      >
        {seconds.toString().padStart(2, "0")}
      </span>
    </div>
  );
}
