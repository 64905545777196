import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Circle, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
// import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useHMSActions } from "@100mslive/react-sdk";
import Alert from "../../../components/Alert/Alert";
import { FeedbackModal } from "../../../components/Modal/CameraAndAudioModal";
import {
  Color,
  DivCenter,
  feedbackbackground,
  styles,
} from "../../../../GlobalStyles";
import { SlotsContext } from "../../../AppContext";
import { PlaceholderModal } from "../../PlaceholderModal/PlaceholderModal";
// import { MCQModal } from "../MentorModals";
import { DialogueBox } from "../../../services/DialogueBox";
import Service from "../../../services/httpService";
import { sort_by } from "../../../services/sortJSONinArray";
import { send_specialCharReplace } from "../../../services/specialCharReplace";

export default function Feedback(props) {
  const services = new Service();
  // const [feedbackLoading, setFeedbackLoading] = useState({ useEffect: false });
  const [feedbackFocused, setFeedbackFocused] = useState(false);
  const { slotsContextData } = useContext(SlotsContext);
  const [alert, setAlert] = useState(false);
  const hmsActions = useHMSActions();

  //Data Variables
  const { studentJoined, checkUser } = props;
  const [pageState, setPageState] = useState({
    is_edit: false,
    is_feedback_posted: false,
  });
  // const [loading, setLoading] = useState({
  //   action_button: false,
  //   modal: false,
  // });
  console.log("ProvideFeedbackComponent ~ pageState:", feedbackFocused);
  console.log("pageState", pageState);
  const [spokenText, setSpokenText] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  //Main data Variables
  const [mcqData, setMcqData] = useState([]);
  const [feedbackFields, setFeedbackFields] = useState([]);
  const [savedFeedbackFields, setSavedFeedbackFields] = useState({ All: 0 });
  const [savedFeedbackBullets, setSavedFeedbackBullets] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBullet, setCurrentBullet] = useState(null);
  const [currentBulletIndex, setCurrentBulletIndex] = useState(null);
  // const [endSession, setMarkAbsentModal] = useState(false);

  const navigate = useNavigate();
  console.log(
    "ProvideFeedbackComponent ~ savedFeedbackBullets:",
    savedFeedbackBullets,
    selectedTab
  );
  console.log(
    "savedFeedbackFields",
    savedFeedbackFields,
    Object.keys(savedFeedbackFields).length
  );
  const [tabData, setTabData] = useState([
    {
      header: "",
      subheader: "",
      placeholder: "",
      description: "",
      sequence: null,
      rating: 0,
      n_a: [],
    },
  ]);

  const [lastDescriptionChange, setLastDescriptionChange] = useState(
    Date.now()
  );

  const [tabDataIndex, setTabDataIndex] = useState(0);

  //MODALs
  const [mcqModal, setMcqModal] = useState({
    open: false,
    onClose: () => setMcqModal(prev => ({ ...prev, open: false })),
    mcqs: [],
    extraData: {},
  });
  const [dialogue, setDialogue] = useState({
    open: false,
    onClose: () => {
      setDialogue(prev => ({ ...prev, open: false }));
    },
    content: "",
    actions: {
      label: "OK",
      handler: () => {},
      color: Color.primary1,
      variant: "contained",
    },
  });
  const handleEndSessionModal = data => {
    if (data === "Yes") handleEndSessionYes();
    else setAlert(false);
  };

  const handleEndSessionYes = async () => {
    setLoading(true);
    let body = {
      order_id: slotsContextData.id,
      is_absent: 0,
    };
    try {
      hmsActions.endRoom(false, "End Room");
      if (checkUser === "mentor") {
        await services.put(`/scheduling/session/endSession`, body);
      }
      sessionStorage.clear();
      window.sessionStorage.removeItem("tabData");
      window.sessionStorage.removeItem("CommentData");
      window.sessionStorage.removeItem("session_order_id");
      navigate(`/EndSession`, { state: { user_type: "mentor" } });
      // window.location.reload();
      // sessionStorage.removeItem("mark_absent");
    } catch (err) {
      setLoading(false);
      window.alert(err.response.data.message);
    }
  };

  console.log("Banana:", {
    slotsContextData,
    mcqData,
    feedbackFields,
    tabData,
    lastDescriptionChange,
    tabDataIndex,
  });

  // const {
  //   transcript,
  //   listening,
  //   resetTranscript,
  //   // browserSupportsSpeechRecognition,
  //   // isMicrophoneAvailable,
  // } = useSpeechRecognition();

  //Functions
  useEffect(() => {
    handleMainPFeedbackEffect();
  }, []);

  const handleMainPFeedbackEffect = async () => {
    // setLoading(prev => ({ ...prev, page: true }));
    // setFeedbackLoading(prev => ({ ...prev, useEffect: true }));

    //[GET] feedback fields
    let temp_feedback_fields = await fetchFeedbackFields(slotsContextData);
    temp_feedback_fields.forEach(data => {
      return (data.description = ""), (data.rating = 0), (data.n_a = []);
    });

    let temp_feedback_mcq = await fetchFeedbackMCQ(slotsContextData);
    temp_feedback_mcq.forEach(
      data => (
        (data.options = JSON.parse(data.options).options), (data.ans = "")
      )
    );
    setMcqData(temp_feedback_mcq.sort(sort_by("sequence", false, parseInt)));
    setFeedbackFields(
      temp_feedback_fields.sort(sort_by("sequence", false, parseInt))
    );

    console.log(" temp_feedback_fields ", temp_feedback_fields);

    if (temp_feedback_fields.length > 0) {
      //feedbacks
      const session_tabData = JSON.parse(
        window.sessionStorage.getItem("tabData")
      );
      const session_order_id = parseInt(
        window.sessionStorage.getItem("session_order_id")
      );
      if (session_tabData && session_order_id === slotsContextData.id) {
        console.log("abra");
        setTabData(session_tabData);
        setLastDescriptionChange(Date.now());
        setFeedbackFields(session_tabData);
      } else {
        console.log("joti");
        setTabData(temp_feedback_fields);
        setLastDescriptionChange(Date.now());
      }
      setTabDataIndex(0);
    } else {
      //comments
      const session_commentData = JSON.parse(
        window.sessionStorage.getItem("CommentData")
      );
      const session_order_id = parseInt(
        window.sessionStorage.getItem("session_order_id")
      );

      if (session_commentData && session_order_id === slotsContextData.id) {
        console.log("ashihs");
        // setNoteData(session_commentData);
        setTabData(temp_feedback_fields);
        setLastDescriptionChange(Date.now());
        setFeedbackFields(temp_feedback_fields);
      } else {
        console.log("anand");
        setTabData(temp_feedback_fields);
        setLastDescriptionChange(Date.now());
        setFeedbackFields(temp_feedback_fields);
      }
    }
    let feedback_responses = await fetchFeedbackFieldsAccordingToOrderId();
    console.log("what is here", feedback_responses);
    // setFeedbackLoading(prev => ({ ...prev, useEffect: false }));
  };

  const fetchFeedbackFieldsAccordingToOrderId = async slotsContextData => {
    try {
      const res = await services.get(
        `mentorModule/mentorFeedback?order_id=${slotsContextData?.id}`
      );
      return res.data;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };

  const fetchFeedbackFields = async slotsContextData => {
    try {
      const res = await services.get(
        `mentorModule/mentorFeedbackFields?act_type_id=${
          slotsContextData?.act_type_id ?? null
        }`
      );
      return res.data;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };
  const fetchFeedbackMCQ = async slotsContextData => {
    try {
      const res = await services.get(
        `mentorModule/mentorFeedbackMcq?act_type_id=${
          slotsContextData?.act_type_id ?? null
        }`
      );
      return res.data;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };

  const handleChangeSpokenText = ({ target }) => {
    const { value } = target;
    setSpokenText(value);
    if (!value || !spokenText) setLastDescriptionChange(Date.now());
    setPageState(prev => ({ ...prev, is_edit: true }));
  };

  const handleSaveFeedback = field => {
    if (spokenText) {
      let temp_bullets = [...savedFeedbackBullets];
      temp_bullets.push({
        field_name: field.field_name,
        rating: 0,
        description: spokenText,
        is_edit: false,
      });
      setSavedFeedbackBullets(temp_bullets);

      let sumAll = 0;
      let temp_fields = { ...savedFeedbackFields };
      temp_fields[field.field_name] = (temp_fields[field.field_name] ?? 0) + 1;
      Object.keys(temp_fields)
        .filter(field => field !== "All")
        .forEach(fieldValue => {
          sumAll = sumAll + temp_fields[fieldValue];
        });
      temp_fields["All"] = sumAll;
      console.log(
        "temp_fields[All]:",
        temp_fields,
        Object.values(temp_fields).forEach(field => {
          sumAll = sumAll + field;
        })
      );
      setSavedFeedbackFields(temp_fields);

      setPageState(prev => ({ ...prev, is_edit: false }));
      setSpokenText("");
    }
  };

  //clicking on the tab
  const handleClickTab = field => {
    console.log("handleClickTab ~ field:", field);
    setSelectedTab(field);
  };

  const handleClickEditBullet = (event, bullet, bullet_index) => {
    event.stopPropagation();
    setFeedbackFocused(false);
    setCurrentBullet(bullet);
    setCurrentBulletIndex(bullet_index);
    let temp_savedBullets = [...savedFeedbackBullets];
    temp_savedBullets.forEach(bullet => {
      bullet.is_edit = false;
    });
    setSavedFeedbackBullets(temp_savedBullets);
    setSelectedTab("");
    setPageState(prev => ({ ...prev, is_edit: false }));
    setIsModalOpen(true);
  };

  //open Questions Modal
  const handleOpenMCQModal = event => {
    event.stopPropagation();
    const disable = handleDisableNextButton();
    if (!disable) {
      // handleCloseClick();
      setFeedbackFocused(false);
      setMcqModal(prev => ({
        ...prev,
        open: true,
        mcqs: mcqData,
        setMcqData: setMcqData,
        extraData: {
          savedFeedbackBullets,
          setSavedFeedbackBullets,
          slotsContextData,
          setPageState,
          dialogue,
          setDialogue,
        },
      }));
    } else {
      setDialogue(prev => ({
        ...prev,
        open: true,
        actions: {
          label: "OK",
          handler: () => {
            dialogue.onClose();
          },
          color: Color.primary1,
          variant: "contained",
        },
        title: "Disabled",
        content: "There should be atleast 15 bullets",
      }));
    }
  };

  //solely for disabling Next button in case the validation doesnt pass
  const handleDisableNextButton = () => {
    let disable = false;
    if (savedFeedbackBullets.length < 15) disable = true;
    console.log("handleDisableNextButton ~ disable:", disable);
    return disable;
  };
  const [loading, setLoading] = useState({
    action_button: false,
    modal: false,
  });
  //OTHER FUNCTIONS
  const [feedbackCompleteRequestModal, setFeedbackCompleteRequestModal] =
    useState(false);
  const [header, setHeader] = useState("");
  const [image, setImage] = useState("");
  const [WhyThis, setWhyThis] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [Answer, setAnswer] = useState("");
  const feedbackModal = true;
  const [feedbackPlaceholderModalOpen, setFeedbackPlaceholderModalOpen] =
    useState(false);
  const recordFeedbackPopupTime = () => {
    const currentTime = Date.now();
    sessionStorage.setItem(
      "time_json",
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem("time_json")),
        feedback_pop_up_time: currentTime,
      })
    );
  };
  const handleOpenFeedbackModal = () => setFeedbackCompleteRequestModal(true);
  const handleOpenFeedbackPlaceholderModal = () =>
    setFeedbackPlaceholderModalOpen(true);
  const handleCloseFeedbackModal = () => {
    setFeedbackCompleteRequestModal(false);
    recordFeedbackPopupTime();
  };

  const [checked, setChecked] = React.useState(false);
  const [answerOpen, setAnswerOpen] = React.useState(false);
  const handleChangeFeedbackModal = () => {
    setChecked(prev => !prev);
  };
  const handleWhyThisClick = () => {
    setAnswerOpen(!answerOpen); // Toggle the checked state
  };

  const handleCloseFeedbackPlaceholderModal = () => {
    setFeedbackPlaceholderModalOpen(false);
  };

  useEffect(() => {
    console.log("gang", {
      studentJoined,
      student_join_time: slotsContextData.student_join_time,
      features: slotsContextData?.features?.features,
    });
    if (studentJoined || slotsContextData?.student_join_time !== null) {
      console.log("inside feedback modal useeffect");
      const interval = setInterval(() => {
        checkDescriptionChanges();
      }, 3 * 60 * 1000); // Check every 3 minutes

      return () => clearInterval(interval);
    }
  }, [studentJoined, tabData, lastDescriptionChange]);

  const checkDescriptionChanges = () => {
    const currentTime = Date.now();
    const threeMinutes = 3 * 60 * 1000;
    // const fiveseconds = 5 * 1000;

    if (currentTime - lastDescriptionChange > threeMinutes) {
      console.log("inside feedback modal condition");
      setFeedbackCompleteRequestModal(true);
      setHeader(
        "Please keep typing feedback during the session to keep it detailed"
      );
      setImage(
        "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/OBJECTS.jpg"
      );
      setButtonText("Provide Feedback");
      setWhyThis("Why am I seeing this?");
      setAnswer(
        <>
          For detailed actionable feedback, it is important to capture granular
          details of the session. Feedback (say in the case of a Mock Interview)
          should contain all the questions for which you did not get
          satisfactory answers (incomplete or wrong or no answer). This would
          work as a very good starting point for students. <br /> When we notice
          the mentor not typing feedback for a long duration during the
          interview, the reminder pops up. We understand that you may get
          engrossed in the session so a quick reminder would help us to also
          capture detailed feedback
        </>
      );
    }
  };

  const handleFeedbackClick = () => {
    setFeedbackFocused(true);
  };

  const handleBackgroundClick = () => {
    setFeedbackFocused(false);
    setSelectedTab("");
    setPageState(prev => ({ ...prev, is_edit: false }));
  };

  const handleCloseClick = event => {
    event.stopPropagation();
    setFeedbackFocused(false);
    setSelectedTab("");
    setPageState(prev => ({ ...prev, is_edit: false }));
  };

  const handleEndSessionClick = event => {
    event.stopPropagation();
    setFeedbackFocused(false);
    setAlert(true);
  };

  const ModalComponent = ({
    bullet,
    bulletIndex,
    feedbackFields,
    isModalOpen,
  }) => {
    // const [selectedField, setSelectedField] = useState(bullet.field_name);
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      border: "none",
      borderRadius: "16px",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
      p: 4,
      bgcolor: "background.paper",
      minWidth: "300px",
      zIndex: 1, // Ensure it's above other content
    };
    const [description, setDescription] = useState(bullet.description);
    const handleSaveBullet = (index, newFieldName) => {
      let temp_savedBullets = [...savedFeedbackBullets];
      temp_savedBullets[index].description = description;
      let temp_fields = { ...savedFeedbackFields };
      const bullet = temp_savedBullets[index];

      if (bullet.field_name !== newFieldName) {
        temp_fields[bullet.field_name]--;
        if (temp_fields[bullet.field_name] === 0) {
          delete temp_fields[bullet.field_name];
        }
        bullet.field_name = newFieldName;
        temp_fields[bullet.field_name] =
          (temp_fields[bullet.field_name] ?? 0) + 1;
      }

      bullet.is_edit = false;

      let sumAll = 0;
      Object.keys(temp_fields)
        .filter(field => field !== "All")
        .forEach(fieldValue => {
          sumAll = sumAll + temp_fields[fieldValue];
        });
      temp_fields["All"] = sumAll;

      setSavedFeedbackBullets(temp_savedBullets);
      setSavedFeedbackFields(temp_fields);
      setIsModalOpen(false);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setCurrentBullet(null);
      setCurrentBulletIndex(null);
    };

    return (
      <Modal
        sx={{
          ...feedbackbackground,
          top:
            props.studentJoined || slotsContextData.student_join_time !== null
              ? "6%"
              : "13%",
          // ...(window.location.pathname === "/FeedbackOverview" && {
          left: "10px",
          // }),
        }}
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                color:
                  window.location.pathname === "/FeedbackOverview" &&
                  !feedbackFocused
                    ? Color.blueBlack
                    : Color.white,
                textAlign: "left",
                width: "100%",
                fontSize: "20px",
              }}
            >
              Edit Feedback{" "}
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography>{bullet.field_name}</Typography>
          <TextField
            multiline
            variant="standard"
            value={description}
            onChange={e => setDescription(e.target.value)}
            fullWidth
            sx={{
              width: "100%",
              borderRadius: "8px",
              border: "1px solid #ccc",
              mb: "16px",
              height: "auto",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Circle
                    sx={{
                      fontSize: "8px",
                      color: Color.neutralMidGrey,
                    }}
                  />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "8px",
                height: "auto",
              },
              disableUnderline: true,
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: Color.accent,
              marginBottom: "8px",
            }}
          >
            Select the relevant tag to save the feedback
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              mb: "16px",
            }}
          >
            {feedbackFields.map(field => (
              <ButtonBase key={field.field_name}>
                <Box
                  // onClick={() => setSelectedField(field.field_name)}
                  onClick={() =>
                    handleSaveBullet(bulletIndex, field.field_name)
                  }
                  sx={{
                    ...DivCenter,
                    borderRadius: "8px",
                    background:
                      bullet.field_name === field.field_name
                        ? "transparent"
                        : Color.primary1,
                    color:
                      bullet.field_name === field.field_name
                        ? Color.primary1
                        : Color.white,
                    border: `1px solid ${Color.primary1}`,
                    fontSize: "14px",
                    height: "32px",
                    width: "auto",
                    minWidth: "112px",
                    p: "10px",
                  }}
                >
                  {field.field_name}
                </Box>
              </ButtonBase>
            ))}
          </Box>
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleSaveBullet(bulletIndex, selectedField)}
            sx={{
              ...styles.button_styles.contained,
              width: "45%",
              height: "34px",
            }}
          >
            Save
          </Button>
          <Button
            onClick={handleCloseModal}
            sx={{
              ...styles.button_styles.contained,
              width: "45%",
              height: "34px",
              bgcolor: "red", // Change color as needed
            }}
          >
            Cancel
          </Button>
        </Box> */}
        </Box>
      </Modal>
    );
  };

  const MCQModal = ({ open, onClose, mcqData, setMcqData, extraData }) => {
    console.log("MCQModal ~ mcqs:", mcqData);
    // const [newMCQ, setNewMCQ] = useState([...mcqs]);
    console.log("MCQModal ~ newMCQ:", mcqData);

    const handleChange = (e, mcq_index) => {
      let temp_newMcq = [...mcqData];
      const { value } = e.target;
      temp_newMcq[mcq_index].ans = value;
      setMcqData(temp_newMcq);
    };

    function transformFeedback(fieldsFeedback) {
      const groupedFeedback = fieldsFeedback.reduce(
        (acc, { field_name, description }) => {
          if (!acc[field_name]) {
            acc[field_name] = [];
          }
          acc[field_name].push(description);
          return acc;
        },
        {}
      );

      const transformedFeedback = {
        responses: Object.entries(groupedFeedback).map(
          ([field_name, descriptions]) => ({
            field_name,
            description: {
              bullets: descriptions.map(desc => send_specialCharReplace(desc)),
            },
          })
        ),
      };

      return transformedFeedback;
    }

    const handleSubmitFeedbackAndMCQ = async () => {
      setLoading(prev => ({ ...prev, action_button: true }));
      let tabData_body = JSON.parse(
        JSON.stringify(extraData.savedFeedbackBullets)
      );
      console.log("tabData_body ~ before:", tabData_body);
      const transformedFeedback = transformFeedback(tabData_body);
      console.log(
        "transformedFeedback",
        JSON.stringify(transformedFeedback, null, 2)
      );

      let mcqData_body = JSON.parse(JSON.stringify(mcqData));

      let body = {};
      if (tabData_body.length > 0) {
        body = {
          order_id: extraData.slotsContextData.id,
          fields_feedback: {
            responses: transformedFeedback.responses.map(data => ({
              field_name: data.field_name,
              description: data.description,
            })),
          },
          mcq_feedback: {
            responses: mcqData_body.map(data => ({
              question: data.question,
              ans: data.ans,
              id: data.id,
            })),
          },
        };
      } else {
        console.error("Data sent is empty!");
      }

      console.log("sending data:", body);

      try {
        await services.post(`/mentorModule/mentorFeedback`, body);
        setPageState(true);
        setLoading(prev => ({ ...prev, action_button: false }));
        // setDialogue(prev => ({
        //   ...prev,
        //   open: true,
        //   actions: [
        //     {
        //       label: "Edit Feedback",
        //       handler: () => {
        //         dialogue.onClose();
        //       },
        //       color: Color.primary1,
        //       variant: "outlined",
        //     },
        //     {
        //       label: "End Session",
        //       handler: () => {
        //         handleEndSession();
        //       },
        //       color: Color.white,
        //       background: Color.primary1,
        //       variant: "contained",
        //     },
        //   ],
        //   title: "End Session?",
        //   content:
        //     "Once you end the session, the video will end and this page will close. Are you sure you would like to end the session?",
        // }));
      } catch (err) {
        console.error("Error posting feedback and mcq data:", err);
        setLoading(prev => ({ ...prev, action_button: false }));
      }

      onClose();
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            ...feedbackbackground,
            height: "460px",
            top: "max(6%, 50px)",
            ...DivCenter,
            justifyContent: "flex-start",
            ...(window.location.pathname === "/FeedbackOverview" && {
              left: "10px",
            }),
          }}
        >
          <Box
            sx={{
              ...DivCenter,
              justifyContent: "space-between",
              width: "100%",
              height: "20%",
              marginLeft: "1rem",
            }}
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "medium",
                color: Color.white,
              }}
            >
              Evaluate Student Progress
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              overflowY: "auto",
              flexDirection: "column",
              marginLeft: "1rem",
            }}
          >
            {mcqData?.map((mcq, mcq_index) => (
              <Box
                key={mcq_index}
                sx={{
                  ...DivCenter,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "16px",
                    color: Color.white,
                  }}
                >
                  {mcq.sequence}. {mcq.question}
                </Typography>
                <Box
                  sx={{
                    ...DivCenter,
                    width: "100%",
                    justifyContent: "left",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name={`controlled-radio-buttons-group-${mcq_index}`}
                    value={mcq.ans}
                    onChange={e => handleChange(e, mcq_index)}
                    sx={{
                      ...DivCenter,
                      width: "100%",
                      justifyContent: "left",
                      color: Color.white,
                    }}
                  >
                    {mcq.options.map((option, option_index) => (
                      <Box
                        key={option_index}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <FormControlLabel
                          value={option}
                          control={<Radio />}
                          label={option}
                          labelPlacement="bottom"
                        />
                        {option === "1" && (
                          <Typography
                            style={{
                              textAlign: "center",
                              color: Color.white,
                              fontSize: "16px",
                            }}
                          >
                            Not Likely
                          </Typography>
                        )}
                        {option === "5" && (
                          <Typography
                            style={{
                              textAlign: "center",
                              color: Color.white,
                              fontSize: "16px",
                            }}
                          >
                            Likely
                          </Typography>
                        )}
                        {!["1", "5"].includes(option) && (
                          <Typography
                            style={{
                              color: "transparent",
                              fontSize: "16px",
                              userSelect: "none",
                            }}
                          >
                            a
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ ...DivCenter, width: "100%", height: "10%", gap: "16px" }}>
            <Button
              variant="contained"
              disabled={mcqData.some(mcq => !mcq.ans)}
              onClick={handleSubmitFeedbackAndMCQ}
              sx={{ ...styles.button_styles.contained, width: "50%" }}
            >
              {loading.action_button ? (
                <CircularProgress size={24} sx={{ color: Color.white }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  console.log(
    "Object.keys(savedFeedbackFields)",
    Object.keys(savedFeedbackFields)
  );

  return (
    <Box sx={{ width: "100%", marginBottom: "2rem", marginTop: ".5rem" }}>
      <Box
        sx={{ width: "100%", zIndex: feedbackFocused ? 10 : 1 }} // Adjust zIndex based on focus
        onClick={handleFeedbackClick} // Handle click event
        className={`feedback-wrapper ${feedbackFocused ? "focused" : ""}`} // Apply focused class
      >
        <Box
          sx={{
            ...DivCenter,
            flexDirection: "column",
            width: "100%",
            paddingLeft: "0px",
            justifyContent: "flex-start",
            gap:
              studentJoined || slotsContextData.student_join_time !== null
                ? "10px"
                : "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: feedbackFocused
                ? "rgba(66, 66, 66, 0.1)"
                : "transparent",
              padding: feedbackFocused ? "20px" : "0px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color:
                    window.location.pathname === "/FeedbackOverview" &&
                    !feedbackFocused
                      ? Color.blueBlack
                      : Color.white,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "20px",
                }}
              >
                Provide Feedback{" "}
                {!feedbackFocused && (
                  <span style={{ color: Color.accent, fontSize: "16px" }}>
                    (Provide at least 15 specific areas for the student's
                    enhancement)
                  </span>
                )}
              </Typography>
              {feedbackFocused && (
                <IconButton onClick={handleCloseClick}>
                  <Close />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                ...DivCenter,
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {savedFeedbackFields.All > 0 &&
                Object.keys(savedFeedbackFields).map(field => (
                  <Typography
                    onClick={() => {
                      handleClickTab(field);
                    }}
                    sx={{
                      fontSize: "16px",
                      color: Color.primary1,
                      textDecoration:
                        selectedTab === field ? "underline" : "none",
                      cursor: "pointer",
                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    {field}
                    {savedFeedbackFields[field] ? (
                      <>
                        (
                        <span style={{ textDecoration: "none" }}>
                          {savedFeedbackFields[field]}
                        </span>
                        )
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                ))}
            </Box>
          </Box>
          {selectedTab ? (
            <>
              <Box
                sx={{
                  ...DivCenter,
                  width: "100%",

                  gap: "16px",

                  backgroundColor: feedbackFocused
                    ? "rgba(66, 66, 66, 0.1)"
                    : "pink",
                  top: "8%",
                  // overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    ...DivCenter,
                    flexDirection: "column",
                    height: selectedTab === "All" ? "400px" : "auto",
                    overflowY: selectedTab === "All" ? "auto" : "visible",
                    justifyContent: "flex-start",
                    background: "rgba(244, 244, 244, 1)",
                    width: "100%",

                    minHeight: "51px",
                    borderRadius: "12px",
                    p: "20px 16px 20px 16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: "16px",
                      height: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: ".5rem",
                      }}
                    >
                      {/* <Typography
                        style={{
                          fontSize: "20px",
                          cursor: "default",
                          width: "100%",
                        }}
                      >
                        Your Feedback
                      </Typography> */}
                      <Typography
                        style={{
                          fontSize: "14px",
                          cursor: "default",
                          color: Color.accent,
                          margin: "auto 0",
                        }}
                      >
                        (Hover on a specific feedback to edit)
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        let temp_savedBullets = [...savedFeedbackBullets];
                        temp_savedBullets.forEach(bullet => {
                          bullet.is_edit = false;
                        });
                        setSavedFeedbackBullets(temp_savedBullets);
                        setSelectedTab("");
                        setPageState(prev => ({ ...prev, is_edit: false }));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  {/* the modal view will be here */}
                  <Box
                    sx={{
                      ...DivCenter,
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "left",
                      padding: "8px",
                      borderRadius: "10px",
                      boxShadow:
                        selectedTab !== "All"
                          ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                          : "none",
                      background: "rgba(255, 255, 255, 1)",
                    }}
                  >
                    {selectedTab !== "All" && (
                      <Typography
                        sx={{
                          textAlign: "left",
                          width: "100%",
                          fontWeight: "medium",
                          fontSize: "16px",
                        }}
                      >
                        {selectedTab}
                      </Typography>
                    )}

                    {savedFeedbackBullets.map((bullet, bullet_index) => {
                      if (bullet.field_name === selectedTab)
                        return (
                          <>
                            <List
                              component="ul"
                              sx={{
                                ...DivCenter,
                                justifyContent: "flex-start",
                                width: "100%",
                                pt: "0",
                                pb: "0",
                              }}
                            >
                              <ListItem
                                onMouseEnter={() =>
                                  setHoveredIndex(bullet_index)
                                }
                                onMouseLeave={() => setHoveredIndex(null)}
                                onClick={e =>
                                  handleClickEditBullet(e, bullet, bullet_index)
                                }
                                sx={{
                                  pt: "0",
                                  pb: "0",
                                  backgroundColor:
                                    hoveredIndex === bullet_index
                                      ? "#f0f0f0"
                                      : "inherit", // Grey background on hover
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s",
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: "16px" }}>
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: Color.neutralMidGrey,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={bullet.description}
                                  sx={{ fontSize: "14px" }}
                                />
                                {hoveredIndex === bullet_index && (
                                  <IconButton
                                    onClick={e =>
                                      handleClickEditBullet(
                                        e,
                                        bullet,
                                        bullet_index
                                      )
                                    }
                                  >
                                    <button
                                      style={{
                                        ...DivCenter,
                                        borderRadius: "8px",
                                        background: "transparent",
                                        color: Color.primary1,
                                        border: `1px solid ${Color.primary1}`,
                                        fontSize: "14px",
                                        height: "30px",
                                        width: "auto",
                                        minWidth: "70px",
                                        textTransform: "none",
                                        boxShadow: "none",
                                        p: "10px",
                                      }}
                                      onMouseEnter={e =>
                                        (e.target.style.boxShadow = "none")
                                      }
                                      onMouseLeave={e =>
                                        (e.target.style.boxShadow = "")
                                      }
                                    >
                                      Edit
                                    </button>
                                  </IconButton>
                                )}
                              </ListItem>
                            </List>
                          </>
                        );
                    })}
                    {selectedTab === "All" &&
                      feedbackFields.map((field, fieldIndex) => (
                        <Box
                          key={fieldIndex}
                          sx={{
                            width: "100%",
                            boxShadow:
                              field.field_name in savedFeedbackFields &&
                              savedFeedbackFields[field.field_name] > 0
                                ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                : "none",
                            padding:
                              field.field_name in savedFeedbackFields &&
                              savedFeedbackFields[field.field_name] > 0
                                ? "8px"
                                : "0px",
                            borderRadius: "10px",
                            mb:
                              field.field_name in savedFeedbackFields &&
                              savedFeedbackFields[field.field_name] > 0
                                ? 2
                                : 0,
                            background: "rgba(255, 255, 255, 1)",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "left",
                              width: "100%",
                              fontWeight: "medium",
                              fontSize: "16px",
                            }}
                          >
                            {field.field_name in savedFeedbackFields &&
                            savedFeedbackFields[field.field_name] > 0
                              ? field.field_name
                              : ""}
                          </Typography>

                          {savedFeedbackBullets.map((bullet, bullet_index) => {
                            if (bullet.field_name === field.field_name) {
                              return (
                                <>
                                  <List
                                    component="ul"
                                    sx={{
                                      ...DivCenter,
                                      justifyContent: "flex-start",
                                      width: "100%",
                                      pt: "0",
                                      pb: "0",
                                    }}
                                  >
                                    <ListItem
                                      key={bullet_index}
                                      onMouseEnter={() =>
                                        setHoveredIndex(bullet_index)
                                      }
                                      onMouseLeave={() => setHoveredIndex(null)}
                                      onClick={e =>
                                        handleClickEditBullet(
                                          e,
                                          bullet,
                                          bullet_index
                                        )
                                      }
                                      sx={{
                                        pt: "0",
                                        pb: "0",
                                        backgroundColor:
                                          hoveredIndex === bullet_index
                                            ? "#f0f0f0"
                                            : "inherit",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: "16px" }}>
                                        <Circle
                                          sx={{
                                            fontSize: "8px",
                                            color: Color.neutralMidGrey,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        sx={{ fontSize: "14px" }}
                                        primary={bullet.description}
                                      />
                                      {hoveredIndex === bullet_index && (
                                        <IconButton
                                          onClick={e =>
                                            handleClickEditBullet(
                                              e,
                                              bullet,
                                              bullet_index
                                            )
                                          }
                                        >
                                          <button
                                            style={{
                                              ...DivCenter,
                                              borderRadius: "8px",
                                              background: "transparent",
                                              color: Color.primary1,
                                              border: `1px solid ${Color.primary1}`,
                                              fontSize: "14px",
                                              height: "30px",
                                              width: "auto",
                                              minWidth: "70px",
                                              textTransform: "none",
                                              boxShadow: "none",
                                              p: "10px",
                                            }}
                                            onMouseEnter={e =>
                                              (e.target.style.boxShadow =
                                                "none")
                                            }
                                            onMouseLeave={e =>
                                              (e.target.style.boxShadow = "")
                                            }
                                          >
                                            Edit
                                          </button>
                                        </IconButton>
                                      )}
                                    </ListItem>
                                    <Divider />
                                  </List>
                                </>
                              );
                            }
                          })}
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  ...DivCenter,
                  width: "100%",
                  height: "auto",
                  gap: "16px",
                  justifyContent: "left",
                }}
              >
                {/* WHITE - TEXTBOX */}

                <Box
                  // onClick={() =>
                  //   setPageState(prev => ({ ...prev, is_edit: true }))
                  // }
                  sx={{
                    ...DivCenter,
                    justifyContent: "flex-start",
                    width: pageState.is_edit ? "100%" : "100%",
                    height: "auto",
                    border:
                      window.location.pathname === "/FeedbackOverview" &&
                      !feedbackFocused
                        ? `1px solid ${Color.blueBlack}`
                        : window.location.pathname === "/FeedbackOverview" &&
                          feedbackFocused
                        ? "none"
                        : `1px solid ${Color.white}`,
                    minHeight: "51px",
                    borderRadius: "12px",
                    p: "10px",
                    background: feedbackFocused ? Color.white : "transparent",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "left",
                      width: "100%",
                      gap: "16px",
                      height: "auto",
                      justifyContent: "left",
                    }}
                  >
                    <TextField
                      multiline
                      placeholder="Click to type your feedback"
                      variant="standard"
                      value={spokenText}
                      onChange={e => handleChangeSpokenText(e)}
                      style={{
                        color:
                          window.location.path === "/FeedbackOverview"
                            ? Color.blueBlack
                            : "#fff",
                        border: "none",
                        width: "100%",
                        padding: 0,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: Color.blueBlack,
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {feedbackFocused &&
                (pageState.is_edit ? (
                  <Box
                    sx={{
                      width: "100%",
                      mt: "8px",
                      backgroundColor: "rgba(66, 66, 66, 0.1)",
                      padding: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: Color.accent,
                        marginBottom: "8px",
                      }}
                    >
                      Select the relevant tag to save the feedback
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      {feedbackFields.map(field => (
                        <ButtonBase key={field.field_name}>
                          <Box
                            onClick={() => handleSaveFeedback(field)}
                            sx={{
                              ...DivCenter,
                              borderRadius: "8px",
                              background: Color.primary1,
                              color: Color.white,
                              fontSize: "14px",
                              height: "32px",
                              width: "auto",
                              minWidth: "112px",
                              p: "10px",
                            }}
                          >
                            {field.field_name}
                          </Box>
                        </ButtonBase>
                      ))}
                    </Box>
                  </Box>
                ) : null)}

              {!feedbackFocused ? (
                mcqData.some(mcq => mcq.ans) ? (
                  <Box sx={{ ...DivCenter, width: "100%" }}>
                    <Button
                      variant="contained"
                      disabled={!savedFeedbackBullets.length}
                      onClick={e => handleEndSessionClick(e)}
                      sx={{
                        ...styles.button_styles.contained,
                        fontSize: "20px",
                        width: "25%",
                        background: Color.meetRed,
                        height: "40px",
                        textTransform: "capitalize",
                        "&:disabled": {
                          background: Color.meetRed,
                          color: Color.white,
                          boxShadow: "none",
                        },
                        "&:hover": {
                          background: Color.meetRed,
                          color: Color.white,
                          boxShadow: "none",
                        },
                      }}
                    >
                      End Session
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ ...DivCenter, width: "100%" }}>
                    <Button
                      variant="contained"
                      disabled={!savedFeedbackBullets.length}
                      onClick={e => {
                        setPageState(prev => ({ ...prev, is_edit: false }));
                        handleOpenMCQModal(e);
                      }}
                      sx={{
                        ...styles.button_styles.contained,
                        fontSize: "20px",
                        width: "25%",
                        height: "40px",
                        textTransform: "capitalize",
                        "&:disabled": {
                          background: Color.neutralLightGrey,
                          color: Color.neutralMidGrey,
                          boxShadow: "none",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                )
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Box>
      {isModalOpen && (
        <ModalComponent
          bullet={currentBullet}
          bulletIndex={currentBulletIndex}
          feedbackFields={feedbackFields}
          isModalOpen={isModalOpen}
        />
      )}
      {mcqModal.open && (
        <MCQModal
          open={mcqModal.open}
          onClose={mcqModal.onClose}
          mcqData={mcqData}
          setMcqData={setMcqData}
          extraData={mcqModal.extraData}
        />
      )}
      {dialogue.open && (
        <DialogueBox
          open={dialogue.open}
          onClose={dialogue.onClose}
          title={dialogue.title}
          actions={dialogue.actions}
          content={
            <>
              <p>{dialogue.content}</p>
            </>
          }
        />
      )}
      <PlaceholderModal
        open={feedbackPlaceholderModalOpen}
        handleClose={handleCloseFeedbackPlaceholderModal}
        handleOpen={handleOpenFeedbackPlaceholderModal}
        setOpen={setFeedbackPlaceholderModalOpen}
      />
      {window.location.pathname !== "/FeedbackOverview" && (
        <FeedbackModal
          studentJoined={studentJoined}
          checked={checked}
          header={header}
          answerOpen={answerOpen}
          setAnswerOpen={setAnswerOpen}
          handleWhyThisClick={handleWhyThisClick}
          setChecked={setChecked}
          open={feedbackCompleteRequestModal}
          setOpen={setFeedbackCompleteRequestModal}
          image={image}
          WhyThis={WhyThis}
          buttonText={buttonText}
          Answer={Answer}
          handleChange={handleChangeFeedbackModal}
          handleOpen={handleOpenFeedbackModal}
          handleClose={handleCloseFeedbackModal}
          feedbackModal={feedbackModal}
        />
      )}
      <Alert
        alert={alert}
        setAlert={setAlert}
        heading="Confirmation"
        body={
          <p>
            Once you end the session, the video call will end and this page will
            close. Are you sure you would like to end the session?
          </p>
        }
        responseAlert={handleEndSessionModal}
      />
      {feedbackFocused && (
        <Box
          className="feedback-overlay"
          sx={{
            top:
              studentJoined || slotsContextData.student_join_time !== null
                ? "6%"
                : "13%",
            // ...(window.location.pathname === "/FeedbackOverview" && {
            left: "10px",
            // }),
          }}
          onClick={handleBackgroundClick}
        />
      )}
    </Box>
  );
}
