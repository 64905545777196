import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

function Instructions() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location", location);
  const selectedTest = location.state.selectedTest;
  const testType = location.state.testType;
  // const handleStartTest = () => {
  //   const windowFeature = `fullscreen=1,status=no,height=${window.screen.height},width=${window.screen.width},status=0,titlebar=0`;
  //   const newWindow = window.open(
  //     `/Questions/${selectedTest.test_id}`,
  //     "_blank",
  //     windowFeature
  //   );

  //   newWindow.focus();
  // };

  const handleStartTest = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    navigate(`/Questions/${selectedTest.test_id}`, {
      state: { testType: testType },
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        padding: "9rem 5rem",
      }}
    >
      <Typography sx={{ fontSize: "32px" }}>Instructions</Typography>
      <Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
              Test name:
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ display: "flex" }}>
              {selectedTest.test_name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
              Duration
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ display: "flex" }}>
              {selectedTest.duration}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
              Number of Questions:
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ display: "flex" }}>
              {selectedTest.total_no_questions}
            </Typography>
          </Box>
        </Box>
      </Box>

      <button
        style={{
          background: "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
          padding: "12px 50px",
          fontSize: "16px",
          width: "fit-content",
          borderRadius: "10px",
          color: "#fff",
        }}
        onClick={() => handleStartTest()}
      >
        Start Test
      </button>
    </Box>
  );
}

export default Instructions;
