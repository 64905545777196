import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Color } from "../../../../GlobalStyles";
const EditModal = ({ open, handleClose, mcq, handleSave }) => {
  const [selectedOption, setSelectedOption] = useState(mcq.ans);
  const [stackOptions, setStackOptions] = useState([
    { option: "Poor", color: "#FF4C4C" },
    { option: "Fair", color: "#FF944C" },
    { option: "Average", color: "#FFC94C" },
    { option: "Good", color: "#D4FF4C  " },
    { option: "Excellent", color: "#4CFF4C" },
  ]);
  console.log("setStackOptions", setStackOptions);
  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleSaveClick = () => {
    handleSave({ ...mcq, ans: selectedOption });
    handleClose();
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    ...theme.typography.body2,
    color: Color.blueBlack,
    textAlign: "center",
    boxShadow: "none",
    margin: "auto 0",
  }));

  const FilledBox = ({ color }) => (
    <Box
      sx={{
        width: 20,
        height: 20,
        backgroundColor: color,
        borderRadius: "3px",
      }}
    />
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 2,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          maxWidth: 500,
          margin: "0 auto",
          mt: "10%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">{mcq.question}</Typography>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RadioGroup
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "row" }}
          >
            {mcq.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={
                  <Radio
                    sx={{
                      color: Color.neutralMidGrey,
                      "&.Mui-checked": {
                        color:
                          option === "Yes" || option === "5"
                            ? "#4CFF4C"
                            : option === "No" || option === "1"
                            ? "#FF4C4C"
                            : option === "2"
                            ? "#FF944C"
                            : option === "3"
                            ? "#FFC94C"
                            : option === "4"
                            ? "#D4FF4C"
                            : Color.accent,
                      },
                      "&:hover": {
                        color:
                          option === "Yes" || option === "5"
                            ? "#4CFF4C"
                            : option === "No" || option === "1"
                            ? "#FF4C4C"
                            : option === "2"
                            ? "#FF944C"
                            : option === "3"
                            ? "#FFC94C"
                            : option === "4"
                            ? "#D4FF4C"
                            : Color.accent,
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                }
                label={option}
                sx={{ display: "flex", flexDirection: "column" }}
              />
            ))}
          </RadioGroup>
          <Box>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{}}>
              {stackOptions.map(item => {
                return (
                  <Box
                    sx={{
                      margin: "auto 0",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Checkbox
                      checked
                      sx={{
                        color: item.color,
                        "&.Mui-checked": {
                          color: item.color,
                        },
                      }}
                      icon={<FilledBox color={item.color} />}
                      checkedIcon={<FilledBox color={item.color} />}
                    />
                    <Item>{item.option}</Item>
                  </Box>
                );
              })}
            </Stack>
          </Box>
          <button
            onClick={handleSaveClick}
            className="Rephrasebutton"
            size="small"
            style={{
              width: "150px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              marginTop: "1rem",
              margin: "0 auto",
            }}
          >
            Save
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditModal;
