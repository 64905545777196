import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
// firebase apple login
// import * as firebase from "firebase/app";
import { AppContext } from "../../AppContext";
// import { useHistory } from "react-router-dom";
// import Service from "../../services/httpService";
// import UpcomingBooking from "../../screens/Mentors/UpcomingBooking/UpcomingBooking";
// import Validations from "../../screens/Validations/Validations";
import "../Login/Login.css";
import "react-toastify/dist/ReactToastify.css";
const ApplyNow = () => {
  const navigate = useNavigate();
  const slot = JSON.parse(window.sessionStorage.getItem("slot"));
  console.log("slot", slot);
  // const [pathName, setPathName] = useState("");
  // const location = useLocation();
  const formInputs = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  console.log("formInputs in apply now", formInputs);
  console.log("applynow");
  useEffect(() => {
    // console.log("Running useEffect");
    // console.log("location", location.pathname);
    // const firstSegment = location.pathname.split("/")[1];
    // console.log("firstSegment", firstSegment);
    // setPathName(firstSegment);
    // let temp_data = ["streaming", "preview", "meeting", "leave"];
    // if (temp_data.includes(firstSegment)) {
    //   if (formInputs.userType === "mentor") {
    //     console.log("Navigating mentor");
    //     navigate(`/preview/${slot.room_id}/mentor`);
    //   } else if (
    //     formInputs.userType === "student b2c" ||
    //     formInputs.userType === "student b2b"
    //   ) {
    //     console.log("Navigating student");
    //     navigate(`/preview/${slot.room_id}/student`);
    //   }
    // }
    setLoading(false);
  }, []);

  // console.log("pathname now", pathName);
  console.log("userType", formInputs);

  return (
    <>
      {loading ? (
        <Loader show={loading} />
      ) : (
        <div className="login-Container">
          <div className="login-Resumes_Image">
            <img
              className="imageFlexible"
              src="backgroundImage.png"
              alt="background"
              style={{
                backgroundRepeat: "none",
                float: "left",
                margin: "0",
                width: "100%",
                height: "100%",
                position: "static",
              }}
            />
            <img
              className="imageFlexible"
              src="logo_welcome.png"
              alt="background"
              style={{
                position: "absolute",
                height: "150px",
                width: "150px",
                top: "max(30%,200px)",
                left: "20%",
              }}
            />
            <img
              src="CareerCarve.png"
              alt="background"
              style={{
                position: "absolute",
                top: "max(52%,350px)",
                left: "13%",
              }}
            />
            <p
              style={{
                position: "absolute",
                top: "max(62%, 430px)",
                left: "13.5%",
                fontSize: "20px",
              }}
            >
              {" "}
              Placement | Preparedness | Perfected
            </p>
          </div>

          <div className="login-Main-content">
            <p className="login-name">Complete</p>
            <p className="signUpProcessText">
              this step on CareerCarve mobile app
            </p>
            <p className="signUpDownloadText">Download Now</p>
            {/* <div className="signUpBorderLine"></div> */}
            <div className="googleAndAppleStoreButton">
              <button className="googlePlayBUtton">
                <a href="https://play.google.com/store/apps/details?id=com.careercarve">
                  <div>
                    <div className="signUpFlexButton">
                      <img
                        className="imageFlexible"
                        src="google-play.svg"
                        alt="background"
                        style={{
                          backgroundRepeat: "none",
                          height: "40px",
                          width: "40px",
                          marginRight: ".5rem",
                        }}
                      />
                      <div>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "14px",
                            color: "#545454",
                          }}
                        >
                          Get it on
                        </p>
                        <p className="AppStoreText">Google Play</p>
                      </div>
                    </div>
                  </div>
                </a>
              </button>
              <button className="appStoreBUtton">
                <a href="https://apps.apple.com/in/app/careercarve/id6447052699">
                  <div
                    className="signUpFlexButton"
                    style={{ paddingLeft: ".6rem" }}
                  >
                    <img
                      className="imageFlexible"
                      src="App_Store_(iOS).svg"
                      alt="background"
                      style={{
                        backgroundRepeat: "none",
                        height: "40px",
                        width: "40px",
                        marginRight: ".5rem",
                      }}
                    />
                    <div style={{ paddingLeft: ".2rem" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          color: "#545454",
                        }}
                      >
                        Download from
                      </p>
                      <p className="AppStoreText">App Store</p>
                    </div>
                  </div>
                </a>
              </button>
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                <img src="QR_code_sign_up.jpg" />
              </div>
            </div>
            <div style={{ textAlign: "center", width: "100%" }}>
              <button
                className="LoginNowBUtton"
                onClick={() => navigate("/")}
                style={{ marginTop: "2rem" }}
              >
                Login Now
              </button>
            </div>
            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyNow;
