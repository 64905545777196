import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
export function SkeletonLoader() {
  return (
    <Box
      //   class="upcomingBookingMain"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        marginTop: "1rem",
        margin: "0 auto",
        border: "1px solid #E6E6E6",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
        borderRadius: "40px",
        padding: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0 auto",
        }}
      >
        <Box
          variant="rounded"
          width="30%"
          height="150px"
          sx={{ borderRadius: "5px" }}
        >
          <Skeleton
            variant="rounded"
            height="30px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />
          <Skeleton
            variant="rounded"
            width="80%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="30%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="30%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1.5rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="100%"
            height="35px"
            sx={{ borderRadius: "10px" }}
            animation="wave"
          />{" "}
        </Box>
        <Skeleton
          variant="rounded"
          width="30%"
          height="210px"
          sx={{ borderRadius: "8px", margin: "auto", marginTop: ".5rem" }}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width="30%"
          height="210px"
          sx={{ borderRadius: "8px", margin: "auto", marginTop: ".5rem" }}
          animation="wave"
        />
      </Box>
      {/* <Skeleton
        variant="rounded"
        height="35px"
        sx={{ borderRadius: "5px", marginTop: "1rem" }}
        animation="wave"
      /> */}
    </Box>
  );
}

export function PastBookingsSkeletonLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        marginTop: "1rem",
        width: "90%",
        alignItems: "left",
        borderTop: "1px solid #E6E6E6",
        borderBottom: "1px solid #E6E6E6",
        padding: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0 auto",
        }}
      >
        {/* 1st */}
        <Box
          variant="rounded"
          width="30%"
          height="70px"
          justifyContent="center"
          alignItems="center"
          sx={{ borderRadius: "5px", marginTop: "auto", marginBottom: "auto" }}
        >
          <Skeleton
            variant="rounded"
            width="80%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
        </Box>
        {/* 2nd */}
        <Box
          variant="rounded"
          width="30%"
          height="70px"
          sx={{ borderRadius: "5px" }}
        >
          <Skeleton
            variant="rounded"
            width="80%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
        </Box>
        {/* 3rd */}
        <Box
          variant="rounded"
          width="30%"
          height="70px"
          sx={{ borderRadius: "5px" }}
        >
          <Skeleton
            variant="rounded"
            width="80%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
        </Box>
        {/* 4th */}
        <Box
          variant="rounded"
          width="30%"
          height="70px"
          sx={{ borderRadius: "5px" }}
        >
          <Skeleton
            variant="rounded"
            width="80%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
          <Skeleton
            variant="rounded"
            width="50%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />{" "}
        </Box>
        {/* 5th */}
        <Box
          variant="rounded"
          width="30%"
          height="70px"
          sx={{ borderRadius: "5px" }}
          margin="auto 0"
        >
          <Skeleton
            variant="rounded"
            width="60%"
            height="35px"
            sx={{ borderRadius: "5px", marginBottom: "1.5rem" }}
            animation="wave"
          />{" "}
        </Box>
      </Box>
      {/* <Skeleton
        variant="rounded"
        height="35px"
        sx={{ borderRadius: "5px", marginTop: "1rem" }}
        animation="wave"
      /> */}
    </Box>
  );
}

export function MentorAnalyticsSkeletonLoader() {
  return (
    <Box>
      <Card
        sx={{
          width: 350,
          height: 605,
          marginRight: "1rem",
          marginTop: "1rem",
          marginLeft: ".2rem",
          marginBottom: ".2rem",
          background: "var(--Neutral-White, #FFF)",
          boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.08)",
          borderRadius: "15px",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CardContent class="sessionsMainCardContent">
          <Skeleton
            variant="rounded"
            width="30%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />
          <Skeleton variant="circular" width={150} height={150} />
          <Box class="cardsFlexed">
            <Box>
              <Skeleton
                variant="rounded"
                width="30%"
                height="20px"
                sx={{ borderRadius: "5px", marginBottom: "1rem" }}
                animation="wave"
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width="40%"
                height="20px"
                sx={{ borderRadius: "5px", marginBottom: "1rem" }}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width="40%"
                height="20px"
                sx={{ borderRadius: "5px", marginBottom: "1rem" }}
                animation="wave"
              />
            </Box>
          </Box>
          <Box class="cardsFlexed">
            <Box>
              <Skeleton
                variant="rounded"
                width="40%"
                height="20px"
                sx={{ borderRadius: "5px", marginBottom: "1rem" }}
                animation="wave"
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width="30%"
                height="20px"
                sx={{ borderRadius: "5px", marginBottom: "1rem" }}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width="30%"
                height="20px"
                sx={{ borderRadius: "5px", marginBottom: "1rem" }}
                animation="wave"
              />
            </Box>
          </Box>
          <Skeleton
            variant="rounded"
            width="40%"
            height="20px"
            sx={{ borderRadius: "5px", marginBottom: "1rem" }}
            animation="wave"
          />
        </CardContent>
      </Card>
    </Box>
  );
}
