import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import "./SessionNotStarted.css";

const SessionNotStarted = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_type = location.state?.user_type;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Container maxWidth="sm">
          <p className="sessionNotStartedText">
            Oops! <br /> Looks like the session hasn't started yet
          </p>
          <p className="sessionNotStartedPTag">
            Once the mentor starts the session, you shall receive a notification
            as well as an email. You can then click on the join now button to
            join the session.
            <br /> See you in sometime!
          </p>
          <button
            onClick={() =>
              navigate("/StudentActivity", { state: { user_type: user_type } })
            }
            className="Rephrasebutton"
            style={{ width: "100%", height: "45px" }}
          >
            Back to Home Page
          </button>
        </Container>
      </Grid>
    </Grid>
    // <div className="sessionNotStartedFullContainer">

    // </div>
  );
};

export default SessionNotStarted;
