/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { DivCenter } from "../../../GlobalStyles";
import "../DocumentsAndFeedback/DocumentsAndFeedback.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
//PDFjs worker from an external cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({ url }) {
  const [numPages, setNumPages] = useState(null);
  console.log("url", url);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    url && (
      <div style={{ width: "100%", height: "100%" }}>
        {/* <Document
        file={url}
        // options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            height={400}
            scale={1.5}
          />
        ))}
      </Document> */}
        <Viewer fileUrl={url} />
      </div>
    )
  );
}
