import * as React from "react";
//icons
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

// const pages = ["Pricing"];
export default function CCAppBar() {
  return (
    <AppBar position="fixed" color="inherit">
      <Container
        maxWidth="xl"
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "2rem",
        }}
      >
        <Toolbar disableGutters>
          <a href="/">
            <img
              src="Career_Carve_Logo__1_-removebg-preview 2.png"
              style={{ height: "40px" }}
            />
          </a>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/Pricing"
            sx={{
              ml: 2,
              mt: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              //   fontWeight: 700,
              //   letterSpacing: ".3rem",
              color: "#8B8B8B",
              textDecoration: "none",
            }}
          >
            Pricing
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Divider />

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              mt: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              //   fontWeight: 700,
              //   letterSpacing: ".3rem",
              color: "#8B8B8B",
              textDecoration: "none",
            }}
          >
            Login
          </Typography>
          <ShoppingBagIcon />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
