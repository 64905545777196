import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Color } from "../../../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./MeetOurStars.css";

const FADE_INTERVAL_MS = 3000;
const WORD_CHANGE_INTERVAL_MS = 1000;
const WORDS_TO_ANIMATE = [
  "MeetOurStarsBLUEDIAG.png",
  "MeetOurStarsBEIGEDIAG.png",
];
const SMALL_IMAGES_TO_ANIMATE = [
  "GraduatedFromTopCollegesDIAG.png",
  "WorkingAtTopCompanies.png",
];

export default function MeetOurStars() {
  // const [showFirstImage, setShowFirstImage] = useState(true);
  const Mobile = useMediaQuery("(min-width:800px)");

  //changing state at an interval ~ for animating the meet our stars diagrams
  const [fadeProp, setFadeProp] = useState({ fade: "fade-in" });
  const [slideProp, setSlideProp] = useState({ slide: "slide-in" });
  const [wordOrder, setWordOrder] = useState(0);

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === "fade-in"
        ? setFadeProp({ fade: "fade-out" })
        : setFadeProp({ fade: "fade-in" });
    }, FADE_INTERVAL_MS);

    return () => clearInterval(fadeTimeout);
  }, [fadeProp]);

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      slideProp.slide === "slide-in"
        ? setSlideProp({ slide: "slide-out" })
        : setSlideProp({ slide: "slide-in" });
    }, FADE_INTERVAL_MS);

    return () => clearInterval(fadeTimeout);
  }, [slideProp]);

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder(
        prevWordOrder => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length
      );
    }, WORD_CHANGE_INTERVAL_MS);
    return () => clearInterval(wordTimeout);
  }, []);
  // <span className={fadeProp.fade}>{WORDS_TO_ANIMATE[wordOrder]}</span>, I'm Julia.
  return (
    <div style={{ marginTop: "161px" }}>
      <Container
        className="landing-container"
        sx={{
          width: Mobile ? "Max(100%, 800px)" : "80%",
          display: "flex",
          flexDirection: Mobile ? "row" : "column",
          justifyContent: "center", // center horizontally
          alignItems: "center", // center vertically
        }}
      >
        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            fontSize="57px"
            fontWeight="400px"
            align="left"
            color={Color.primary1}
          >
            Meet Our Stars
          </Typography>
          <Container
            disableGutters
            sx={{ alignItems: "left", marginTop: "14px" }}
          >
            <Container
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                marginBottom: "8px",
              }}
            >
              <Typography align="left" sx={{ display: "flex" }}>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5006 34.8294C16.3166 34.8294 14.2868 34.4376 12.4111 33.6539C10.5354 32.8702 8.90378 31.7846 7.51628 30.3971C6.12878 29.0096 5.04319 27.378 4.25951 25.5023C3.47582 23.6266 3.08398 21.5968 3.08398 19.4128C3.08398 17.2544 3.47582 15.2374 4.25951 13.3617C5.04319 11.486 6.12878 9.85443 7.51628 8.46693C8.90378 7.07943 10.5354 5.98741 12.4111 5.19089C14.2868 4.39436 16.3166 3.99609 18.5006 3.99609C20.4277 3.99609 22.2263 4.30443 23.8965 4.92109C25.5666 5.53776 27.0697 6.38568 28.4059 7.46484L26.7486 9.12213C25.618 8.22283 24.359 7.52908 22.9715 7.04089C21.584 6.55269 20.0937 6.30859 18.5006 6.30859C14.775 6.30859 11.6595 7.5612 9.1543 10.0664C6.64909 12.5716 5.39648 15.6871 5.39648 19.4128C5.39648 23.1385 6.64909 26.2539 9.1543 28.7591C11.6595 31.2643 14.775 32.5169 18.5006 32.5169C22.2263 32.5169 25.3418 31.2643 27.847 28.7591C30.3522 26.2539 31.6048 23.1385 31.6048 19.4128C31.6048 18.6419 31.547 17.8904 31.4314 17.1581C31.3158 16.4258 31.1423 15.7128 30.9111 15.019L32.684 13.2461C33.0951 14.1968 33.4034 15.186 33.609 16.2138C33.8145 17.2416 33.9173 18.3079 33.9173 19.4128C33.9173 21.5968 33.5191 23.6266 32.7225 25.5023C31.926 27.378 30.834 29.0096 29.4465 30.3971C28.059 31.7846 26.4274 32.8702 24.5517 33.6539C22.676 34.4376 20.659 34.8294 18.5006 34.8294ZM16.2267 26.4273L9.86732 20.0294L11.6017 18.2951L16.2267 22.9201L32.1829 6.9638L33.9559 8.69818L16.2267 26.4273Z"
                    fill="#1C8EA8"
                  />
                </svg>
              </Typography>
              <Typography fontSize="20px" marginLeft="9px">
                600+ Mentors
              </Typography>
            </Container>

            <Container
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Typography align="left" sx={{ display: "flex" }}>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5006 34.8294C16.3166 34.8294 14.2868 34.4376 12.4111 33.6539C10.5354 32.8702 8.90378 31.7846 7.51628 30.3971C6.12878 29.0096 5.04319 27.378 4.25951 25.5023C3.47582 23.6266 3.08398 21.5968 3.08398 19.4128C3.08398 17.2544 3.47582 15.2374 4.25951 13.3617C5.04319 11.486 6.12878 9.85443 7.51628 8.46693C8.90378 7.07943 10.5354 5.98741 12.4111 5.19089C14.2868 4.39436 16.3166 3.99609 18.5006 3.99609C20.4277 3.99609 22.2263 4.30443 23.8965 4.92109C25.5666 5.53776 27.0697 6.38568 28.4059 7.46484L26.7486 9.12213C25.618 8.22283 24.359 7.52908 22.9715 7.04089C21.584 6.55269 20.0937 6.30859 18.5006 6.30859C14.775 6.30859 11.6595 7.5612 9.1543 10.0664C6.64909 12.5716 5.39648 15.6871 5.39648 19.4128C5.39648 23.1385 6.64909 26.2539 9.1543 28.7591C11.6595 31.2643 14.775 32.5169 18.5006 32.5169C22.2263 32.5169 25.3418 31.2643 27.847 28.7591C30.3522 26.2539 31.6048 23.1385 31.6048 19.4128C31.6048 18.6419 31.547 17.8904 31.4314 17.1581C31.3158 16.4258 31.1423 15.7128 30.9111 15.019L32.684 13.2461C33.0951 14.1968 33.4034 15.186 33.609 16.2138C33.8145 17.2416 33.9173 18.3079 33.9173 19.4128C33.9173 21.5968 33.5191 23.6266 32.7225 25.5023C31.926 27.378 30.834 29.0096 29.4465 30.3971C28.059 31.7846 26.4274 32.8702 24.5517 33.6539C22.676 34.4376 20.659 34.8294 18.5006 34.8294ZM16.2267 26.4273L9.86732 20.0294L11.6017 18.2951L16.2267 22.9201L32.1829 6.9638L33.9559 8.69818L16.2267 26.4273Z"
                    fill="#1C8EA8"
                  />
                </svg>
              </Typography>
              <Typography fontSize="20px" marginLeft="9px">
                Highly qualified from top institutions
              </Typography>
            </Container>
            <Container
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Typography align="left" sx={{ display: "flex" }}>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5006 34.8294C16.3166 34.8294 14.2868 34.4376 12.4111 33.6539C10.5354 32.8702 8.90378 31.7846 7.51628 30.3971C6.12878 29.0096 5.04319 27.378 4.25951 25.5023C3.47582 23.6266 3.08398 21.5968 3.08398 19.4128C3.08398 17.2544 3.47582 15.2374 4.25951 13.3617C5.04319 11.486 6.12878 9.85443 7.51628 8.46693C8.90378 7.07943 10.5354 5.98741 12.4111 5.19089C14.2868 4.39436 16.3166 3.99609 18.5006 3.99609C20.4277 3.99609 22.2263 4.30443 23.8965 4.92109C25.5666 5.53776 27.0697 6.38568 28.4059 7.46484L26.7486 9.12213C25.618 8.22283 24.359 7.52908 22.9715 7.04089C21.584 6.55269 20.0937 6.30859 18.5006 6.30859C14.775 6.30859 11.6595 7.5612 9.1543 10.0664C6.64909 12.5716 5.39648 15.6871 5.39648 19.4128C5.39648 23.1385 6.64909 26.2539 9.1543 28.7591C11.6595 31.2643 14.775 32.5169 18.5006 32.5169C22.2263 32.5169 25.3418 31.2643 27.847 28.7591C30.3522 26.2539 31.6048 23.1385 31.6048 19.4128C31.6048 18.6419 31.547 17.8904 31.4314 17.1581C31.3158 16.4258 31.1423 15.7128 30.9111 15.019L32.684 13.2461C33.0951 14.1968 33.4034 15.186 33.609 16.2138C33.8145 17.2416 33.9173 18.3079 33.9173 19.4128C33.9173 21.5968 33.5191 23.6266 32.7225 25.5023C31.926 27.378 30.834 29.0096 29.4465 30.3971C28.059 31.7846 26.4274 32.8702 24.5517 33.6539C22.676 34.4376 20.659 34.8294 18.5006 34.8294ZM16.2267 26.4273L9.86732 20.0294L11.6017 18.2951L16.2267 22.9201L32.1829 6.9638L33.9559 8.69818L16.2267 26.4273Z"
                    fill="#1C8EA8"
                  />
                </svg>
              </Typography>
              <Typography fontSize="20px" marginLeft="9px">
                Experience in top industries
              </Typography>
            </Container>
            <Container
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Typography align="left" sx={{ display: "flex" }}>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5006 34.8294C16.3166 34.8294 14.2868 34.4376 12.4111 33.6539C10.5354 32.8702 8.90378 31.7846 7.51628 30.3971C6.12878 29.0096 5.04319 27.378 4.25951 25.5023C3.47582 23.6266 3.08398 21.5968 3.08398 19.4128C3.08398 17.2544 3.47582 15.2374 4.25951 13.3617C5.04319 11.486 6.12878 9.85443 7.51628 8.46693C8.90378 7.07943 10.5354 5.98741 12.4111 5.19089C14.2868 4.39436 16.3166 3.99609 18.5006 3.99609C20.4277 3.99609 22.2263 4.30443 23.8965 4.92109C25.5666 5.53776 27.0697 6.38568 28.4059 7.46484L26.7486 9.12213C25.618 8.22283 24.359 7.52908 22.9715 7.04089C21.584 6.55269 20.0937 6.30859 18.5006 6.30859C14.775 6.30859 11.6595 7.5612 9.1543 10.0664C6.64909 12.5716 5.39648 15.6871 5.39648 19.4128C5.39648 23.1385 6.64909 26.2539 9.1543 28.7591C11.6595 31.2643 14.775 32.5169 18.5006 32.5169C22.2263 32.5169 25.3418 31.2643 27.847 28.7591C30.3522 26.2539 31.6048 23.1385 31.6048 19.4128C31.6048 18.6419 31.547 17.8904 31.4314 17.1581C31.3158 16.4258 31.1423 15.7128 30.9111 15.019L32.684 13.2461C33.0951 14.1968 33.4034 15.186 33.609 16.2138C33.8145 17.2416 33.9173 18.3079 33.9173 19.4128C33.9173 21.5968 33.5191 23.6266 32.7225 25.5023C31.926 27.378 30.834 29.0096 29.4465 30.3971C28.059 31.7846 26.4274 32.8702 24.5517 33.6539C22.676 34.4376 20.659 34.8294 18.5006 34.8294ZM16.2267 26.4273L9.86732 20.0294L11.6017 18.2951L16.2267 22.9201L32.1829 6.9638L33.9559 8.69818L16.2267 26.4273Z"
                    fill="#1C8EA8"
                  />
                </svg>
              </Typography>
              <Typography fontSize="20px" marginLeft="9px">
                Average of 11+ years experience
              </Typography>
            </Container>
          </Container>
        </Container>

        {/* <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        > */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          />
          <img
            src={WORDS_TO_ANIMATE[wordOrder]}
            style={{
              position: "absolute",
              marginTop: Mobile ? "200px" : "141px",
              marginLeft: Mobile ? "0px" : "100px",
            }}
            className={fadeProp.fade}
          />
          <img
            src={SMALL_IMAGES_TO_ANIMATE[wordOrder]}
            style={{
              position: "absolute",
              marginTop: Mobile ? "200px" : "141px",
              marginLeft: "100px",
            }}
            className={fadeProp.fade}
          />
          {/*<img
            src="MeetOurStarsBEIGEDIAG.png"
            style={{ zIndex: 1, position: "absolute" }}
            className="slide-in"
          />
          <img
            src="WorkingAtTopCompanies.png"
            style={{ zIndex: 1, position: "absolute" }}
            className="slide-in"
          /> */}
        </div>
        {/* </Container> */}
      </Container>
    </div>
  );
}
