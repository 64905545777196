import * as React from "react";
import { Container, Typography } from "@mui/material";
import { wrap } from "framer-motion";
import { Color } from "../../../../GlobalStyles";
// import ImageGrid from "../ImagesInfiniteScroll/ImagesInfiniteScroll";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function OurPrograms() {
  const Mobile = useMediaQuery("(min-width:800px)");

  const cardData = [
    {
      id: 1,
      image_url: "conversation.png",
      title: "1X1 Sessions",
      subtitle: "Focused outcome driven interaction with industry mentors",
      link: "Learn More",
    },
    {
      id: 2,
      image_url: "conversation.png",
      title: "Group Sessions",
      subtitle: "Common learnings by an expert of the area",
      link: "Learn More",
    },
    {
      id: 3,
      image_url: "conversation.png",
      title: "Subscriptions",
      subtitle: "Learnings on the go AI resume builder",
      link: "Learn More",
    },
    {
      id: 4,
      image_url: "conversation.png",
      title: "Webinars",
      subtitle: "High Impact deep dive sessions ",
      link: "Learn More",
    },
  ];

  return (
    <div style={{ marginTop: "241px" }}>
      <Typography
        fontSize="24px"
        fontWeight={400}
        align="center"
        sx={{
          paddingTop: "50px",
          position: "relative",
          "&::after": {
            content: "''",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "-10%",
            width: "64px",
            borderBottom: "2px solid #1C8EA8",
            paddingBottom: "50px",
          },
          paddingBottom: "9px",
        }}
      >
        Our Programs
      </Typography>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: "flex",
          flexDirection: Mobile ? "row" : "column",
          gap: Mobile ? "5" : "8.13px",
          flexWrap: wrap,
          marginTop: "50px",
          width: "100%",
        }}
      >
        {cardData.map(card => (
          <Container
            disableGutters
            key={card.id}
            className="landing-container"
            sx={{
              width: "335px",
              height: "310px",
              justifyContent: "center", // center horizontally
              alignItems: "center", // center vertically
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.14)",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container sx={{ display: "flex", justifyContent: "center" }}>
              <img src={card.image_url} />
            </Container>
            <Container sx={{ color: Color.primary1, textAlign: "center" }}>
              {card.title}
            </Container>
            <Container sx={{ textAlign: "center" }}>{card.subtitle}</Container>
            <Container sx={{ color: Color.primary1, textAlign: "center" }}>
              {card.link}
            </Container>
          </Container>
        ))}
      </Container>
    </div>
  );
}
