import * as React from "react";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import { Container, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./Landing.css";
export default function Landing() {
  const APKUrl =
    "https://play.google.com/store/apps/details?id=host.exp.exponent";
  const iOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";
  const Mobile = useMediaQuery("(min-width:800px)");
  // const Desktop = useMediaQuery("(min-width:1200px)");
  return (
    <div style={{ marginTop: "141px" }}>
      <Container
        className="landing-container"
        sx={{
          paddingLeft: 0,
          width: Mobile ? "100%" : "80%",
          display: "flex",
          flexDirection: Mobile ? "row" : "column",
          justifyContent: "center", // center horizontally
          alignItems: "center", // center vertically
        }}
      >
        <Container
          sx={{
            // width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography fontSize="57px" fontWeight="400px" align="left">
            Let's Get
          </Typography>
          <Typography
            fontSize="57px"
            fontWeight="400px"
            align="left"
            color={Color.primary1}
          >
            Placement Ready!
          </Typography>
          <Typography align="left" fontSize="16px" fontWeight="400px">
            Get prepared with the best & the oldest Placement Training <br />{" "}
            Agency with an experience in training over 45,000 students <br />
            from over 30 Institutes
          </Typography>
          <Container
            disableGutters
            sx={{
              display: "flex",
              padding: 0,
              marginTop: "1rem",
              justifyContent: "space-between",
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <GooglePlayButton
              url={APKUrl}
              theme="light"
              className="download-buttons"
              width="200px"
              height="68px"
            />
            <AppStoreButton
              url={iOSUrl}
              theme="light"
              className="download-buttons"
              width="200px"
              height="68px"
            />
            <Typography />
            <Typography />
            <Typography />
            <Typography />
            <Typography />
            <Typography />
          </Container>
        </Container>
        {/* <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        > */}
        <img src="iPhoneViewofCC.png" style={{ zIndex: 1 }} />
        <img
          src="WavyPatternsDIAG2.png"
          style={{
            position: "absolute",
            zIndex: 0,
            marginLeft: "35%",
            marginTop: "8%",
          }}
        />
        {/* </Container> */}
      </Container>
    </div>
  );
}
