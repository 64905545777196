import * as React from "react";
import { Container } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
// import ImageGrid from "../ImagesInfiniteScroll/ImagesInfiniteScroll";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function WhyChooseUs2() {
  const Mobile = useMediaQuery("(min-width:800px)");

  const cardData = [
    {
      id: 1,
      image_url: "Flawless Scheduling DIAG.png",
      title: "Flawless Scheduling",
      subtitle: "Matches availability of students and mentors",
    },
    {
      id: 2,
      image_url: "Dedicated Ops Team DIAG.png",
      title: "Dedicated Ops Team",
      subtitle: "To handle exceptions which tech can’t",
    },
    {
      id: 3,
      image_url: "In-App issue Resolution DIAG.png",
      title: "In-App issue Resolution",
      subtitle: "Chat & support ticket-based resolution to reduce hassles",
    },
    {
      id: 4,
      image_url: "Mobile App Redesigned DIAG.png",
      title: "Mobile App Redesigned",
      subtitle: "Complete all actions on tap",
    },
    {
      id: 5,
      image_url: "Podcasts DIAG.png",
      title: "Podcasts",
      subtitle: "Learning continues on the treadmill",
    },
    {
      id: 6,
      image_url: "MBA Focused DIAG.png",
      title: "MBA Focused",
      subtitle: "Our single Focus",
    },
  ];

  return (
    <div style={{ marginTop: "64px" }}>
      <Container
        sx={{
          display: "flex",
          gap: Mobile ? "90px" : "96px",
          marginTop: "24px",
          width: "80%",
          flexWrap: "wrap",
        }}
      >
        {cardData.map(card => (
          <Container
            key={card.id}
            className="landing-container"
            sx={{
              width: "311px",
              height: "219.92px",
              justifyContent: "center", // center horizontally
              alignItems: "center", // center vertically
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container sx={{ display: "flex", justifyContent: "center" }}>
              <img src={card.image_url} />
            </Container>
            <Container sx={{ textAlign: "center", marginTop: "10px" }}>
              <p
                style={{
                  fontWeight: "530",
                  fontSize: "22px",
                  color: Color.neutralBlack,
                }}
              >
                {card.title}
              </p>
            </Container>
            <Container sx={{ textAlign: "center" }}>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: Color.neutralBlack,
                }}
              >
                {card.subtitle}
              </p>
            </Container>
          </Container>
        ))}
      </Container>
    </div>
  );
}
