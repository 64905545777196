import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Cookies from "js-cookie";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTimer } from "../ResendButton/ResendButton";
// import { handleSubmit } from "../Validations/Validations";
import "../Login/Login.css";
import "./DeleteAccount.css";
import "react-toastify/dist/ReactToastify.css";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const errRef = useRef();
  const Mobile = useMediaQuery("(max-width:800px)");
  console.log("mobile", Mobile);
  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  console.log("isSubmit", formErrors);
  const [errMsg, setErrMsg] = useState("");
  //for validation
  console.log("isSubmit", errMsg);

  const [errorMessage, SeterrorMessage] = useState(""); //error message for the request
  console.log(errorMessage);
  const [Confirmation_mgs, setConfirmation_mgs] = useState(""); //error mesage for the text input

  const handleSubmit = async e => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (formValues.email === "") {
      SeterrorMessage("Email cannot be blank");
      console.log("Email is Is blank");
    }
    if (!re.test(String(formValues.email).toLowerCase())) {
      SeterrorMessage("Email is Invalid");
      console.log("Email is Invalid");
    } else {
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      axios
        .post(
          `https://backendstg.careercarve.in/api/delete/generateOTP`,
          {
            email_id: formValues.email,
          },
          { headers }
        )
        .then(res => {
          console.log("Response from backend is", res?.content);
          console.log("Response from backend is", JSON.stringify(res, null, 2));
          res.data.success
            ? console.log("Success in Delete Account")
            : console.log("Failed in Delete Account, Email doesn't exist");
          toast.success(`OTP sent to your mail ${formValues.email}`, {
            onClose: () => {
              // Navigate to another page after the toast is closed
              navigate("/DeleteAccountOTP", {
                state: { email_id: formValues.email },
              });
            },
          });
        })
        .catch(err => {
          setConfirmation_mgs(err.response.data.message);
          console.log("Error Msg: ", err.response);
          console.log("failed To Send request");
        });

      // navigate("/DeleteAccountOTP", { state: { email_id: formValues.email } });
    }
  };

  useEffect(() => {
    const handleEffect = () => {
      const user_type = Cookies.get("user_type");
      const token = Cookies.get("token");
      if (
        token !== null &&
        token !== undefined &&
        token !== "null" &&
        token !== "undefined"
      ) {
        console.log("token not null", token);
        if (
          user_type !== null &&
          user_type !== undefined &&
          user_type !== "null" &&
          user_type !== "undefined"
        ) {
          console.log("user_type not null", user_type);
        }
      }
    };
    handleEffect();
  });

  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
    setErrMsg("");
    console.log("email", formValues.email);
    console.log("password", formValues.password);
  };

  return (
    <>
      {Mobile ? (
        <>
          <div
            className="mobileViewDeleteAccount"
            style={{ marginTop: "2rem" }}
          >
            <ToastContainer position="top-center" />

            <div className="mobileViewDeleteAccount">
              <div
                className="mobileViewDeleteAccount"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="mobileViewDeleteAccount"
                  src="logo_welcome.png"
                  alt="background"
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                />
                <img
                  className="mobileViewDeleteAccount"
                  src="CareerCarve.png"
                  alt="background"
                  style={{
                    height: "auto",
                    width: "200px",
                  }}
                />
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Inter",
                  }}
                  className="mobileViewDeleteAccount"
                >
                  {" "}
                  Placement | Preparedness | Perfected
                </p>
              </div>
              <div className="login-l-form">
                <form action="" class="login-form">
                  <div
                    className="outlined-basic"
                    style={{ top: "50%", bottom: "0" }}
                  >
                    <p
                      className="login-name"
                      style={{
                        fontSize: "24px",
                        marginBottom: "1rem",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      Delete your account
                    </p>
                    <TextField
                      className=""
                      onChange={handleChange}
                      value={formValues.email}
                      name="email"
                      id="outlined-basic"
                      label="Email "
                      variant="outlined"
                      sx={{ width: "80%", mb: 3 }}
                      InputProps={{ sx: { height: 50.4 } }}
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        borderRadius: "5px",
                      }}
                    />

                    <br />
                    <button
                      type="button"
                      className="Login-button"
                      style={{
                        textAlign: "center",
                        marginBottom: "1.5rem",
                        width: "80%",
                        margin: "0 auto",
                        justifyContent: "center",
                        fontFamily: "Inter",
                      }}
                      onClick={handleSubmit}
                    >
                      Verify Email
                    </button>
                    <div>
                      <p
                        ref={errRef}
                        className="alert-errMsg-button"
                        aria-live="assertive"
                      >
                        {Confirmation_mgs}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="login-Container">
            <ToastContainer position="top-center" limit={1} />

            <div className="login-Resumes_Image">
              <img
                className="imageFlexible"
                src="backgroundImage.png"
                alt="background"
                style={{
                  backgroundRepeat: "none",
                  float: "left",
                  margin: "0",
                  width: "100%",
                  height: "100%",
                  position: "static",
                }}
              />
              <img
                className="imageFlexible"
                src="logo_welcome.png"
                alt="background"
                style={{
                  position: "absolute",
                  height: "150px",
                  width: "150px",
                  top: "max(30%,200px)",
                  left: "20%",
                }}
              />
              <img
                src="CareerCarve.png"
                alt="background"
                style={{
                  position: "absolute",
                  top: "max(52%,350px)",
                  left: "13%",
                }}
              />
              <p
                style={{
                  position: "absolute",
                  top: "max(62%, 430px)",
                  left: "13.5%",
                  fontSize: "20px",
                }}
              >
                {" "}
                Placement | Preparedness | Perfected
              </p>
            </div>
            <div className="login-Main-content">
              <div className="fprgotPasswordCenter">
                <p className="login-name">Delete your account</p>
                <div className="login-l-form">
                  <form action="" class="login-form">
                    <div className="outlined-basic">
                      <TextField
                        className=""
                        onChange={handleChange}
                        value={formValues.email}
                        name="email"
                        id="outlined-basic"
                        label="Email "
                        variant="outlined"
                        sx={{ width: 400, mb: 3 }}
                        InputProps={{ sx: { height: 50.4 } }}
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                      />

                      <br />
                      <button
                        type="button"
                        className="Login-button"
                        style={{
                          textAlign: "center",
                          marginBottom: "1.5rem",
                          width: "400px",
                        }}
                        onClick={handleSubmit}
                      >
                        Verify Email
                      </button>
                      <div>
                        <p
                          ref={errRef}
                          className="alert-errMsg-button"
                          aria-live="assertive"
                        >
                          {Confirmation_mgs}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeleteAccount;
