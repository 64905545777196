import { ChatIcon } from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/react-ui";
import { useContext } from "react";
import { Color } from "../../../GlobalStyles";
import { SlotsContext } from "../../AppContext";
import IconButton from "../../IconButton";
import { ChatBubble, Circle } from "@mui/icons-material";

export const ChatToggle = ({ checkUser }) => {
  // const countUnreadMessages = useHMSStore(selectUnreadHMSMessagesCount);
  // const isChatOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.CHAT);
  // const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);
  const { slotsContextData, setSlotsContextData } = useContext(SlotsContext)
  return (
    <Tooltip key="chat" title={`${slotsContextData?.isChatOpen ? "Close" : "Open"} chat`}>
      <IconButton
        onClick={() => setSlotsContextData({ ...slotsContextData, isChatOpen: !slotsContextData.isChatOpen })}
        active={!slotsContextData?.isChatOpen}
        data-testid="chat_btn"
        style={{ color: Color.primary1, borderRadius: "50px", borderColor: "transparent" }}

      >
        <ChatBubble/>
      </IconButton>
    </Tooltip>
  );
};
