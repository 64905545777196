import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useHMSActions } from "@100mslive/react-sdk";
import { EmojiIcon, SendIcon } from "@100mslive/react-icons";
import { Box, Flex, IconButton, Popover, styled } from "@100mslive/react-ui";
import { ToastManager } from "../Toast/ToastManager";
import { useChatDraftMessage } from "../AppData/useChatState";
import { useEmojiPickerStyles } from "./useEmojiPickerStyles";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { SlotsContext } from "../../AppContext";
import { send_specialCharReplace } from "../../services/specialCharReplace";

const TextArea = styled("textarea", {
  width: "100%",
  bg: "transparent",
  color: "$textPrimary",
  resize: "none",
  lineHeight: "1rem",
  position: "relative",
  top: "$3",
  "&:focus": {
    boxShadow: "none",
    outline: "none",
  },
});

export const ChatFooter = React.forwardRef(
  ({ role, peerId, onSend, children }, ref) => {
    const hmsActions = useHMSActions();
    const inputRef = useRef(null);
    const [draftMessage, setDraftMessage] = useChatDraftMessage();
    const jwt = jwtDecode(Cookies.get("token"))
    const { slotsContextData } = useContext(SlotsContext);

    const sendMessage = useCallback(async () => {
      const message = inputRef.current.value;
      if (!message || !message.trim().length) {
        return;
      }
      try {
        const temp_message = { text: send_specialCharReplace(message).replaceAll("'", "’").replaceAll('"', '”').trim(), triggered_activity: "" }
        let temp_message_data = { message: temp_message, user_id: jwt.sub, time: new Date().getTime(), order_id: slotsContextData.id }
        console.log("MESSAGER", temp_message_data)
        ref.current.emit("sendMessage", temp_message_data, (error, response) => {
          if (error) {
            ToastManager.addToast({ title: error.message });
            console.error("errorrrrr", error);
          } else {
            console.log("responseeee", response);
            inputRef.current.value = ""
          }
        });
      } catch (error) {
        ToastManager.addToast({ title: error.message });
      }
    }, [role, peerId, hmsActions, onSend]);

    useEffect(() => {
      const messageElement = inputRef.current;
      if (messageElement) {
        messageElement.value = draftMessage;
      }
    }, [draftMessage]);

    useEffect(() => {
      const messageElement = inputRef.current;
      return () => {
        setDraftMessage(messageElement?.value || "");
      };
    }, [setDraftMessage]);

    return (
      <Flex
        align="center"
        css={{
          bg: "$surfaceLight",
          minHeight: "$16",
          maxHeight: "$24",
          position: "relative",
          py: "$6",
          pl: "$8",
          r: "$1",
        }}
      >
        {children}
        <TextArea
          placeholder="Write something here"
          ref={inputRef}
          autoFocus
          onKeyPress={async event => {
            if (event.key === "Enter") {
              if (!event.shiftKey) {
                event.preventDefault();
                await sendMessage();
              }
            }
          }}
          autoComplete="off"
          aria-autocomplete="none"
          onPaste={e => e.stopPropagation()}
          onCut={e => e.stopPropagation()}
          onCopy={e => e.stopPropagation()}
        />

        <IconButton
          onClick={sendMessage}
          css={{ ml: "auto", height: "max-content", mr: "$4" }}
          data-testid="send_msg_btn"
        >
          <SendIcon />
        </IconButton>
      </Flex>
    );
  });