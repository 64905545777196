import * as React from "react";
import { Box, TextField } from "@mui/material";
import {
  DatePicker,
  // MobileDatePicker,
  // StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import dayjs from "dayjs";
import { DivCenter } from "../../../GlobalStyles";

function Examples() {
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = event => {
    // setSelectedDate(event.format("YYYY-MM-DD"));
    setSelectedDate(event);
  };

  return (
    <Box sx={{ ...DivCenter, width: "100vw", height: "100vh", gap: "24px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <h1>MUI React Date Picker Demo</h1>
        <div>
          {/* <h2>Desktop DatePicker</h2> */}
          <DatePicker
            label="Select date"
            value={selectedDate}
            views={["year", "month", "day"]}
            // views={["year"]}
            onChange={handleDateChange}
            renderInput={params => <TextField {...params} />}
          />
          <DatePicker
            label="Select date"
            value={selectedDate}
            // views={["year", "month", "day"]}
            views={["year"]}
            onChange={handleDateChange}
            renderInput={params => <TextField {...params} />}
          />
        </div>
      </LocalizationProvider>
    </Box>
  );
}

export default Examples;
