import React from "react";
import { TimerOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Flex } from "@100mslive/react-ui";
import { Color } from "../../../GlobalStyles";

function TimeSection({ checkUser, timeRemaining, timeRemainingStudent }) {
  console.log(
    "what am i getting",
    checkUser,
    timeRemaining,
    timeRemainingStudent
  );

  const formatTime = milliseconds => {
    if (milliseconds < 0) {
      return "00:00"; // or any default value you prefer when the time is negative
    }
    const minutes = Math.floor(milliseconds / 60000); // Convert milliseconds to minutes
    const seconds = Math.floor((milliseconds % 60000) / 1000); // Remaining seconds
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <Flex align="center">
      <TimerOutlined style={{ color: Color.white }} sx={{ mr: 1 }} />
      <Typography variant="h6" color={Color.white} sx={{ mr: 1 }}>
        Time left :
      </Typography>
      <Typography variant="h6" color={Color.green}>
        {checkUser === "mentor"
          ? formatTime(timeRemaining)
          : formatTime(timeRemainingStudent)}
      </Typography>
    </Flex>
  );
}

export default TimeSection;
