import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DivCenter } from "../../../GlobalStyles";
import { SlotsContext } from "../../AppContext";
import FileCardView from "./FileCardView";
import PdfViewer from "./PdfViewer";
const UserInformationComponent = ({
  showPdfUrl,
  setShowPdfUrl,
  checkUser,
  feedbackFocused,
  studentData,
}) => {
  const { slotsContextData } = useContext(SlotsContext);
  console.log("slotsContextData", slotsContextData);

  const [files, setFiles] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const handlePdfClick = pdfUrl => {
    console.log("updates", pdfUrl);
    setShowPdfUrl(pdfUrl);
  };
  const slot = JSON.parse(window.sessionStorage.getItem("slot"));
  console.log("slot", slot);
  console.log(
    "type of things",
    typeof slotsContextData?.resume,
    typeof slotsContextData?.other_docs.files
  );
  useEffect(() => {
    if (slotsContextData) {
      let session_guidelines;
      const parsedResume =
        typeof slotsContextData?.resume !== "string"
          ? slotsContextData?.resume
          : JSON.parse(slotsContextData?.resume);

      if (
        window.location.pathname === "/FeedbackOverview/offline" ||
        checkUser !== "mentor"
      ) {
        setShowPdfUrl(parsedResume);
        setSelectedFileName(parsedResume?.name);
      } else {
        session_guidelines = {
          url: slotsContextData?.session_guidelines,
          name: "Session Guidelines",
        };
        if (
          slotsContextData?.session_guidelines &&
          (slotsContextData?.session_guidelines?.url !== null ||
            slotsContextData?.session_guidelines?.url !== undefined)
        ) {
          setShowPdfUrl(session_guidelines);
          setSelectedFileName(session_guidelines?.name);
        } else {
          setShowPdfUrl(parsedResume);
          setSelectedFileName(parsedResume?.name);
        }
      }

      const parsedFiles =
        typeof slotsContextData?.other_docs !== "string"
          ? slotsContextData?.other_docs.files
          : JSON.parse(slotsContextData?.other_docs).files;

      const otherDocuments = {
        url: slotsContextData?.file_url,
        name: slotsContextData?.file_url
          ? slotsContextData.file_url.split("/").pop().split(".")[0]
          : "",
      };
      console.log("session_guidelines", session_guidelines);
      const allFiles = [
        ...(session_guidelines &&
        session_guidelines?.url &&
        checkUser === "mentor"
          ? [session_guidelines]
          : []),
        parsedResume,
        ...(parsedFiles ?? []),
        ...(otherDocuments && otherDocuments.url ? [otherDocuments] : []),
      ].filter(file => file && file.url);

      setFiles(allFiles);
    }
  }, []);

  console.log("files", files);
  return (
    <>
      <Box
        sx={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: ["/FeedbackOverview", "/FeedbackOverview/offline"].includes(
            window.location.pathname
          )
            ? "400px"
            : "max(25%,430px)",
          overflowY: "auto", // Changed to 'auto' to allow scrolling
          background: "#fff",
          zIndex: 2,
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for Chrome, Safari, Edge
          },
          scrollbarWidth: "none",
          marginBottom: ".5rem",
          boxShadow: [
            "/FeedbackOverview",
            "/FeedbackOverview/offline",
          ].includes(window.location.pathname)
            ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            : "none",
          borderBottomRightRadius: "6px",
          borderBottomLeftRadius: "6px",
        }}
      >
        <PdfViewer
          url={typeof showPdfUrl === "string" ? showPdfUrl : showPdfUrl?.url}
        />
      </Box>
      {!feedbackFocused && (
        <Box sx={{ width: "100%", marginLeft: "0px" }}>
          <FileCardView
            files={files}
            handlePdfClick={handlePdfClick}
            selectedFileName={selectedFileName}
            setSelectedFileName={setSelectedFileName}
          />
        </Box>
      )}
    </>
  );
};

export default UserInformationComponent;
