import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import debounce from "lodash.debounce";
import Navbar from "../../../components/AppBar/Navbar";
import { Color } from "../../../../GlobalStyles";
// import { AppContext } from "../../../AppContext";
// import "../OngoingEvents/OngoingEvents.css";
import SideNavBarStudent from "../../SideNavBar/SideNavBarStudent";
import ServiceCM from "../../../services/cartmoduleServices";
// import { Color } from "../../../GlobalStyles";
import Service from "../../../services/httpService";
import {
  calculateEpochTimeInLocalTime,
  extractDateFromDateString,
  get_specialCharReplace,
} from "../../../services/specialCharReplace";
import "../../Mentors/UpcomingBooking/UpcomingBooking.css";
export default function UpcomingPrograms(props) {
  const navigate = useNavigate();
  const services = new Service();
  const [groupSessionDetails, setGroupSessionDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [responseDataEmpty, setResponseDataEmpty] = useState(false);
  const cartService = new ServiceCM();
  // const formInputs = useContext(AppContext);
  const [activityTypeData, setActivityTypeData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const isUpcomingFetchingRef = useRef(isFetching);
  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      const groupSession = await groupSessionDataGetter(1);
      const activityType = await activityTypeGetter();
      console.log("groupSession", groupSession, activityType);
    };

    handleEffect();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isFetching) {
        await groupSessionDataGetter(page + 1);
        setIsFetching(false);
      }
    };

    fetchData();
  }, [isFetching, page]);

  const handleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (
      !responseDataEmpty &&
      windowHeight + scrollTop + 200 >= documentHeight &&
      !isUpcomingFetchingRef.current
    ) {
      setIsFetching(true);
      isUpcomingFetchingRef.current = true;
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching]);

  const groupSessionDataGetter = async page => {
    const headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const res = await services.get(
        `/superAdmin/groupSession/student?limit=4&page=${page}`,
        {
          headers,
        }
      );
      let temp_change_short_list = res.data;
      if (temp_change_short_list.length) {
        setGroupSessionDetails(prev => [...prev, ...temp_change_short_list]);
        setPage(page);
        isUpcomingFetchingRef.current = false;
      } else {
        setResponseDataEmpty(true);
      }
      return temp_change_short_list;
    } catch (err) {
      console.log(err);
    }
  };

  console.log("groupSessionDetails", groupSessionDetails);

  const activityTypeGetter = async () => {
    try {
      const res = await cartService.get("/sku?type=Group");
      console.log("Response from backend for activity name:", res.data);
      setActivityTypeData(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }

    console.log("Response activity type data", activityTypeData);
  };

  // function formatTime12Hour(time) {
  //   const date = new Date(time);
  //   const hours = date.getUTCHours();
  //   const minutes = date.getUTCMinutes();
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   const formattedHours = hours % 12 || 12; // Convert midnight (0) to 12 AM
  //   const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  //   return `${formattedHours}:${formattedMinutes} ${ampm}`;
  // }

  // const currentMillis = new Date().getTime(); // Get the current time in milliseconds

  // const filteredSessions = groupSessionDetails.filter(item => {
  //   const sessionStartDateMillis = new Date(item.date).getTime(); // Calculate session start date in milliseconds
  //   const sessionStartTimeMillis = item.time; // Start time is already in milliseconds
  //   const sessionDurationMillis = item.duration_value * 60 * 1000; // Duration in milliseconds

  //   const sessionStartTimeMillisWithDate =
  //     sessionStartDateMillis + sessionStartTimeMillis;
  //   let sessionEndTimeMillis =
  //     sessionStartTimeMillisWithDate + sessionDurationMillis;

  //   // Remove 5 hours and 30 minutes (5 * 60 * 60 * 1000 + 30 * 60 * 1000) from sessionEndTimeMillis
  //   sessionEndTimeMillis -= 5 * 60 * 60 * 1000 + 30 * 60 * 1000;

  //   // Add 20 minutes (20 * 60 * 1000) to sessionEndTimeMillis
  //   sessionEndTimeMillis += 20 * 60 * 1000;

  //   console.log(
  //     "Current Time:",
  //     currentMillis,
  //     sessionEndTimeMillis,
  //     item.date,
  //     item.time,
  //     currentMillis > sessionEndTimeMillis
  //   );

  //   // Exclude sessions whose end time is before the current time
  //   return currentMillis < sessionEndTimeMillis;
  // });

  // console.log("ongoing sessions", filteredSessions);

  return (
    <div
      className="OngoingEventsCard"
      style={{ marginTop: "2rem", marginLeft: "10rem" }}
    >
      <SideNavBarStudent />
      <Box sx={{ paddingRight: "7.5rem" }}>
        <Navbar title=" Group Sessions" />
      </Box>

      <div className="cardsInsideFlex">
        {groupSessionDetails?.length === 0 ? (
          <>
            <div
              className="noUpcomingBookings"
              style={{
                width: "80%",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                marginTop: "1.5rem",
              }}
            >
              <div className="noUpcomingBookingsIcon">
                <EventBusyIcon
                  sx={{
                    color: "#1C8EA8",
                    width: "50px",
                    height: "50px",
                  }}
                />
              </div>
              <div className="noUpcomingBookingsTexts">
                <p className="noUpcomingBookingsText">No Group Sessions</p>
              </div>
            </div>
          </>
        ) : (
          <>
            {groupSessionDetails.map((batchInfo, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    width: "80%",
                    marginRight: "1rem",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
                    borderRadius: "10px",
                    border: `1px solid ${Color.primary1}`,
                  }}
                >
                  <CardContent className="cardContainer" disableGutters>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5rem",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontSize: 22 }}
                        color={Color.primary1}
                        gutterBottom
                      >
                        {batchInfo?.group_session_name}
                      </Typography>
                    </div>
                    <Typography
                      component="div"
                      sx={{ color: Color.neutralMidGrey, fontSize: "12px" }}
                    >
                      {get_specialCharReplace(batchInfo.description)}
                    </Typography>
                    <div className="group-container">
                      <Typography
                        variant="body2"
                        className="group-providingSpace1"
                      >
                        Session:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="group-providingSpaceForSubDetailsBlack"
                      >
                        {batchInfo?.sku_name}
                      </Typography>
                    </div>

                    <div className="group-container">
                      <Typography
                        variant="body2"
                        className="group-providingSpace1"
                      >
                        Date & Time:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="group-providingSpaceForSubDetailsBlack"
                      >{`${extractDateFromDateString(
                        batchInfo.date_time
                      )} | ${calculateEpochTimeInLocalTime(
                        batchInfo.date_time
                      )}`}</Typography>
                    </div>
                    <div className="group-container">
                      <Typography
                        variant="body2"
                        className="group-providingSpace1"
                      >
                        Duration:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="group-providingSpaceForSubDetailsBlack"
                      >
                        {batchInfo?.duration_name}
                      </Typography>
                    </div>
                    <div className="group-container">
                      <Typography
                        variant="body2"
                        className="group-providingSpace1"
                      >
                        Mentor:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="group-providingSpaceForSubDetails"
                      >
                        {batchInfo?.mentor_name}
                      </Typography>
                    </div>
                    <div className="group-container">
                      <Typography
                        variant="body2"
                        className="group-providingSpace1"
                      >
                        Session venue:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="group-providingSpaceForSubDetails"
                        sx={{
                          color:
                            batchInfo?.link.startsWith("http://") ||
                            batchInfo?.link.startsWith("https://")
                              ? Color.primary1
                              : Color.blueBlack,
                          cursor:
                            batchInfo?.link.startsWith("http://") ||
                            batchInfo?.link.startsWith("https://")
                              ? "pointer"
                              : "none",
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          const mentorLinkedInLink = batchInfo.link;
                          if (
                            mentorLinkedInLink.startsWith("http://") ||
                            mentorLinkedInLink.startsWith("https://")
                          ) {
                            window.open(mentorLinkedInLink);
                          } else {
                            // window.open("https://" + mentorLinkedInLink);
                          }
                        }}
                      >
                        {batchInfo?.link}
                      </Typography>
                    </div>
                  </CardContent>
                </Box>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
