import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../GlobalStyles";
import "../PrivacyPolicy/PrivacyPolicy.css";
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleEmailButtonClick = () => {
    const emailAddress = "support@careercarve.com";
    const mailServiceUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}`;
    window.open(mailServiceUrl, "_blank");
  };

  return (
    <div className="privacyPolicymainConatiner">
      <div className="privacyPolicymainConatiner1">
        <div>
          <p className="privacyPolicyHeader">Terms of Use</p>
          <p className="normalprivacyPolicy">
            This document is an electronic record in terms of Information
            Technology Act of 2000 and the Information Technology
            (Intermediaries guidelines) Rules, 2011 thereunder as applicable and
            the amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act of 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
            <br />
            <br />
            This document is published in accordance with the provisions of Rule
            3(1) of the Information Technology (Intermediaries Guidelines) Rules
            2011 that require publishing the rules and regulations, privacy
            policy and Terms of Use for access or usage of the{" "}
            <b>“careercarve.in/ careercarve.com/resumes.digital”</b> website and
            the <b>CareerCarve Mobile Application</b> (“Application”).
            <br />
            <br />
            Welcome to CareerCarve.in/ careercarve.com/ resumes.digital
            (hereinafter referred to as “Website or Site”), the domain name,
            which is owned by Parivartana Consulting Private Limited, a company
            incorporated under the Companies Act 2013 and having its registered
            office at [55, 6th Main, Gauravnagar, JP Nagar 7th Phase, Bangalore
            - 560078] (hereinafter referred to as “CareerCarve” or “Company.”)
            <br />
            <br />
            The terms and conditions contained herein along with the Privacy
            Policy regulates the use of Website or use of the software
            application called <b>“[CareerCarve]”</b> for the mobile devices
            that was created by CareerCarve. By accessing or using the Website
            and procuring the services of CareerCarve (hereinafter collectively
            referred to as “Services”), however accessed you agree to be
            governed by the following terms and conditions (“Terms of Use” or
            “Agreement”) as applicable to the Services including the applicable
            policies which are incorporated herein by way of reference. If you
            transact/register on the Website, you shall be subject to the
            policies that are applicable to the Services for such transaction.
            By mere use of the Services, you shall be contracting with
            CareerCarve and these terms and conditions including the policies
            constitute your binding obligations with CareerCarve. These Terms of
            Use affect your legal rights and obligations. You are advised to
            read this Agreement carefully. If you are not agreeable to any terms
            and conditions contained in this Terms of Use or Privacy Policy, you
            shall refrain from access or use of the Service.
            <br />
            <br />
            For the purpose of these Terms of Use, wherever the context so
            requires ‘You’ or “User” shall mean any natural or legal person who
            has agreed to become a member of the Services by providing
            Registration Data or Log In Data while registering on the Services
            as Registered User using the computer systems or mobile phones.
            CareerCarve does not allow the User to surf the Services or making
            purchases without registering on the Services. The term “we”, “us”,
            “our” shall mean Parivartana Consulting Private Limited. <br />
            <br />
            <b>
              ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR
              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF
              USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
            </b>{" "}
            By impliedly or expressly accepting these Terms, you also accept and
            agree to be bound by the CareerCarve’s Privacy Policy. All
            products/services and information displayed on Site or Application
            shall constitute an "invitation to offer". Your order for purchase
            constitutes your "offer" which shall be subject to the terms and
            conditions as listed below. CareerCarve reserves the right to accept
            or reject your offer. If you have supplied us with your email
            address, we will notify you by email as soon as possible to confirm
            receipt of your order and email you again to confirm details and
            therefore process the order. Our acceptance of your order will take
            place upon dispatch of the product(s) ordered or the Services
            rendered. No act or omission of the Company prior to the actual
            dispatch of the product(s) ordered or services rendered will
            constitute acceptance of your offer.
          </p>
          <p className="privacyPolicySubHeading">Membership Eligibility</p>
          <p className="normalprivacyPolicy">
            Use of Services available in the Site or the Application is
            available only to persons who can form legally binding contracts
            under Indian Contracts Act, 1872. Persons who are "incompetent to
            contract" within the meaning of the Indian Contract Act, 1872
            including un-discharged insolvents, minors etc. are not eligible to
            use or access the Site or the Application. In the event a minor
            utilizes the Application/Site/Services, it is assumed that he/she
            has obtained the consent of the legal guardian or parents and such
            use is made available by the legal guardian or parents. CareerCarve
            will not be responsible for any consequence that arises as a result
            of misuse of any kind of the Application or any of Services that may
            occur by virtue of any person including a minor registering for the
            Services/products provided. By using the products or Services You
            warrant that all the data provided by You is accurate and complete
            and that student using the Application has obtained the consent of
            parent/legal guardian (in case of minors). CareerCarve reserves the
            right to terminate Your subscription and / or refuse to provide You
            with access to the products or Services if it is discovered that You
            are under the age of 18 (eighteen) years and the consent to use the
            products or Services is not made by Your parent/legal guardian or
            any information provided by You is inaccurate. You acknowledge that
            CareerCarve does not have the responsibility to ensure that You
            conform to the aforesaid eligibility criteria. It shall be Your sole
            responsibility to ensure that You meet the required qualification.
            Any persons under the age of 18 (eighteen) should seek the consent
            of their parents/legal guardians before providing any Information
            about themselves or their parents and other family members on the
            Application. If your age is below that of 18 years your parents or
            legal guardians can transact on behalf of you if they are registered
            users. The Site and the Application are not available to persons
            whose membership has been suspended or terminated by CareerCarve.in
            for any reason whatsoever. If you are registering as a business
            entity, you represent that you have the authority to bind the
            entity, on behalf of which you are registering to this Terms of Use.
            Unless otherwise specified, the materials on this Website and the
            Application are directed solely at those who consume our Services.
            Those who choose to access this Site or the Application from outside
            India are responsible for compliance with local laws if and to the
            extent local laws are applicable. CareerCarve undertakes to provide
            the services only within India and shall not be liable for any
            claims relating to any products or services ordered from outside
            India.
            <br />
            <br />
            Except where additional terms and conditions are provided which are
            product or service specific, these terms and conditions supersede
            all previous representations, understandings, or agreements and
            shall prevail notwithstanding any variance with any other terms of
            any order submitted. By using the Services of CareerCarve and
            accessing the Website you agree to be bound by this Terms of Use.
            And in cases where there is conflict between the product/service
            specific terms and conditions and this Terms of Use, such product /
            service specific terms and conditions shall override this Terms of
            Use.
          </p>
          <p className="privacyPolicySubHeading">
            Governing Law and Jurisdiction
          </p>
          <p className="normalprivacyPolicy">
            This Terms of Use shall be governed and construed in accordance with
            the applicable laws of India. The Courts at Bengaluru shall have
            exclusive jurisdiction in any proceedings or disputes arising out of
            this Terms of Use.
          </p>
          <p className="privacyPolicySubHeading">
            Account and Registration Obligations
          </p>
          <p className="normalprivacyPolicy">
            "Your Information" shall mean any information, including your name,
            address details, phone number you provide to us at the time of
            registration for access of the Services available on the Website or
            the Application, buying process, in the feedback area or through any
            e-mail feature. Your Information or any other information / data
            made available through you by accessing or using the Website or the
            Application shall remain confidential and privacy of the same shall
            be governed by the Privacy Policy (available at{" "}
            <button
              onClick={() => navigate("/PrivacyPolicy")}
              className="signup-button"
            >
              Privacy Policy
            </button>
            ). If you use the Services or access the Site or the Application,
            you shall be responsible for maintaining the confidentiality of Your
            Information which may include your Account Login information and
            Password and for restricting access to your computer or mobile
            devices., You hereby agree to be responsible for the activities in
            your account or any liability incurred because of misuse of Your
            Information including your account id or Password. CareerCarve shall
            not be liable to any user for any loss or damage which may arise as
            a result of any failure by you to protect your password or account.
            If you know or suspect that someone else has access to Your
            Information you should notify us by contacting us immediately
            through the address provided below and change your account
            information including your password. If CareerCarve has reason to
            believe that there is likely to be a breach of security or misuse of
            the CareerCarve.in Site or the Application, we may require you to
            change your password or we may suspend your account without any
            liability to CareerCarve.in.
            <br />
            <br />
            You hereby agree that you shall:
            <br />
            1. Provide true, accurate, current and complete information about
            yourself as prompted by <i>CareerCarve.</i> in’s registration form
            (such information being the "Registration Information and Profile
            Information" or any “Access Credentials”)
            <br />
            <br />
            2. Maintain and promptly update the Access Credentials to keep it
            true, accurate, current and complete. If you provide any information
            that is untrue, inaccurate, incomplete, or not current or if
            CareerCarve.in has reasonable grounds to suspect that such
            information is untrue, inaccurate, not current or not in accordance
            with the User Agreement, CareerCarve.in has the right to
            indefinitely suspend or terminate your membership and refuse to
            provide you with access to the Site.
            <br />
            <br />
            3. You are responsible for any activity that occurs under your
            Access Credentials and you agree you will not sell, transfer,
            license or assign your account or any account rights.
            <br />
            <br />
            4. When you use the Services or send emails or other data or
            information or communications to CareerCarve, you agree and
            understand that you are communicating with CareerCarve through
            electronic records and you consent to receive communications via
            electronic records from the Company periodically and as and when
            required by CareerCarve.
          </p>

          <p className="privacyPolicySubHeading">Pricing Information</p>
          <p className="normalprivacyPolicy">
            Our Site provides users with access to compiled training plans and
            related information to facilitate placement preparedness for its
            users. Our Site/Application offers a unique way of career guidance,
            counseling & mentoring and is not limited to psychometric
            assessments, career counselling and guidance, interactive career
            focused activities and career progression or tracking mechanisms
            etc., as may be provided time to time for help and guidance of
            user/s. While CareerCarve strives to provide accurate pricing
            information, pricing errors or typographical errors may occur.
            CareerCarve.in cannot confirm the price of a product/service until
            after you order is placed by you on the Website and confirmed. In
            the event that a product or service is listed at an incorrect price
            or with incorrect information due to an error in pricing or product
            information, CareerCarve.in shall have the right, at our sole
            discretion, to refuse or cancel any orders placed for that product
            or service. In the event that a product/service carries an incorrect
            price, CareerCarve.in may, at its discretion, either contact you for
            instructions or cancel your order and notify you of such
            cancellation. Unless the product or service ordered by you has been
            dispatched or delivered, your offer will not be deemed
            accepted/confirmed and CareerCarve.in will have the right to modify
            the price of the product and contact you for further instructions
            using the e-mail address or contact number provided by you during
            the time of registration or cancel the order and notify you of such
            cancellation. In the event that CareerCarve.in accepts your order
            the same shall be debited to your credit card account or debit card
            account or your designated bank account and duly notified to you by
            email or phone that the payment has been processed. The payment may
            be processed prior to CareerCarve.in dispatch of the product or
            delivery of the service that you have ordered on the
            Site/Application. If we have to cancel the order after we have
            processed the payment, the said amount will not be reversed back to
            you.
          </p>
          <p className="privacyPolicySubHeading">Colors and textures</p>
          <p className="normalprivacyPolicy">
            We have made every effort to display the colors and textures of our
            products & services that appear on the Site and the Application as
            accurately as possible. However, as the actual colors you see will
            depend on your monitor, we cannot guarantee that your monitor's
            display of any color/texture will be accurate. CareerCarve shall not
            be liable for any perceived inaccuracy of the color and texture of
            the products or any designs or blueprints that are available on the
            Website or the Application. The product or the service is displayed
            on as is basis on the Website or the Application and the User or any
            prospective customer understands that the same is subject to
            differences that are beyond the control of CareerCarve.
          </p>
          <p className="privacyPolicySubHeading">
            Modification of Terms and conditions of Service
          </p>
          <p className="normalprivacyPolicy">
            CareerCarve may at any time modify or revise or amened this Terms of
            Use or Privacy Policy or any other policy that governs the use of
            this Website or Application without any prior notification to you.
            Subsequent to any modification of the User Agreement, we will inform
            you of the modifications in the User Agreement via e-mail at the
            address provided by you while registering on CareerCarve.in. You can
            access the latest version of the User Agreement at any given time on
            CareerCarve.in. You should regularly review the User Agreement on
            CareerCarve.in. In the event the modified User Agreement is not
            acceptable to you, you should discontinue using the service and
            delete the account on the Website. However, if you continue to use
            the service you shall be deemed to have agreed to and abide by the
            modified or amended User Agreement.
          </p>
          <p className="privacyPolicySubHeading">
            Reviews, Feedback, Submissions
          </p>
          <p className="normalprivacyPolicy">
            All reviews, comments, feedback, postcards, suggestions, ideas,
            improvements and other submissions disclosed, submitted or offered
            to CareerCarve on or by this Site or the Application or otherwise
            disclosed, submitted or offered in connection with your use of this
            Site or the Application (collectively, the "Comments") shall be and
            remain exclusively the property of CareerCarve. You understand that
            such information or ideas or other submission shared in the Website
            or the Application would constitute Proprietary Information of
            CareerCarve. Further, any such disclosure, submission or offer of
            any Comments made by you shall constitute an assignment to
            CareerCarve of all worldwide rights, titles and interests in all
            copyrights and other intellectual properties in the Comments. Thus,
            CareerCarve owns exclusively all such rights, titles and interests
            and shall not be limited in any way in its use, commercial or
            otherwise, of any Comments that are made by you while using or
            accessing the Website or the Application. CareerCarve will be
            entitled to use, reproduce, disclose, modify, adapt, create
            derivative works from, publish, display and distribute any Comments
            you submit for any purpose whatsoever, without restriction and
            without compensating you in any way. CareerCarve is and shall be
            under no obligation (1) to maintain any Comments in confidence; (2)
            to pay you any compensation for any Comments; or (3) to respond to
            any Comments. You agree that any Comments submitted by you to the
            Site or the Application will not violate this policy or any right of
            any third party, including copyright, trademark, privacy or other
            personal or proprietary right(s), and will not cause injury to any
            person or entity. You further agree that no Comments submitted by
            you to the Site or the Application will be or contain libelous or
            otherwise unlawful, threatening, abusive or obscene material, or
            contain software viruses, political campaigning, commercial
            solicitation, chain letters, mass mailings or any form of spam.
            <br />
            CareerCarve does not regularly review posted Comments but does
            reserve the right (but not the obligation) to monitor and edit or
            remove any Comments submitted to the Site or the Application. You
            grant CareerCarve the right to use the name that you submit in
            connection with any Comments. You agree not to use a false email
            address, impersonate any person or entity, or otherwise mislead as
            to the origin of any Comments you submit. You are and shall remain
            solely responsible for the content of any Comments you make and you
            agree to bear all claims made against CareerCarve resulting from any
            Comments you submit. CareerCarve and its affiliates take no
            responsibility and assume no liability for any Comments submitted by
            you or any third party. You hereby agree to indemnify and hold
            harmless CareerCarve and its affiliates for any loss or damage,
            including any loss of reputation or good will, incurred by it,
            because of your infringement of copyright or any other intellectual
            property rights of third party in the Comments shared by you in the
            Website or the Application. Any claims initiated by any third party
            against CareerCarve because of such breach of law committed by you
            shall be indemnified by you.
          </p>

          <p className="privacyPolicySubHeading">Copyright & Trademark</p>

          <p className="normalprivacyPolicy">
            CareerCarve and its suppliers and licensors expressly reserve all
            intellectual property rights in all text, programs, products,
            processes, technology, content and other materials, which appear on
            this Site or the Application. CareerCarve claims exclusive ownership
            or license to the materials or products available in the Website or
            the Application. While CareerCarve grants you a non-exclusive, fully
            paid and royalty-free, transferable, sub-licensable, worldwide
            license to access and browse through the product and services that
            are available on the Website or Application, access to this Site or
            the Application does not confer and shall not be considered as
            conferring upon anyone any license to the intellectual property
            rights over the products available on the Website. All rights,
            including copyright, in this Website and the Application are owned
            by or licensed to CareerCarve. Any use of this Website or the
            Application or its contents, including copying or storing it or them
            in whole or part, other than for your own personal, non-commercial
            use is prohibited without the permission of CareerCarve. You may not
            modify, distribute or re-post anything on this website or the
            Application for any purpose.
            <br /> <br />
            CareerCarve’s names and logos and all related product and service
            names, design marks and slogans are registered trademarks or service
            marks of Parivartana Consulting Private Limited. All other marks are
            the property of their respective companies or third-party vendors or
            suppliers. No trademark or service mark license is granted in
            connection with the materials contained on this Site or the
            Application. Access to this Site or the Application does not
            authorize anyone to use any trade name, logo or mark in any manner.
            <br />
            <br />
            References on this Site or the Application to any names, marks,
            products or services of third parties or hypertext links to third
            party sites or information are provided solely as a convenience to
            you and do not in any way constitute or imply CareerCarve
            endorsement, sponsorship or recommendation of the third party,
            information, product or service.
            <br />
            <br />
            CareerCarve is not responsible for the content of any third-party
            sites and does not make any representations regarding the content or
            accuracy of material on such sites. If you decide to link to any
            such third-party websites, you do so entirely at your own risk.
            <br />
            <br />
            All materials, including images, text, illustrations, designs,
            icons, photographs, programs, music clips or downloads, video clips
            and written and other materials that are part of this Site or the
            Application (collectively, the "Contents") are intended solely for
            personal, non-commercial use. Upon acquiring prior consent of
            CareerCarve, You may download or copy the Contents and other
            downloadable materials displayed on the Site or the Application for
            your personal use only. No right, title or interest in any
            downloaded materials or software is transferred to you as a result
            of any such downloading or copying. You may not reproduce (except as
            noted above), publish, transmit, distribute, display, modify, create
            derivative works from, sell or participate in any sale of or exploit
            in any way, in whole or in part, any of the Contents, the Site or
            the Application or any related software. All software used on this
            Site and the Application are the property of CareerCarve or its
            suppliers and protected by Indian and international copyright laws.
            The Contents and software on this Site and the Application may be
            used only as a shopping resource. Any other use, including the
            reproduction, modification, distribution, transmission,
            republication, display, or performance, of the Contents on this Site
            or the Application is strictly prohibited. Unless otherwise noted,
            all Contents are copyrights, trademarks, trade dress and/or other
            intellectual property owned, controlled or licensed by CareerCarve,
            one of its affiliates or by third parties who have licensed their
            materials to CareerCarve and are protected by Indian and
            international copyright laws. The compilation (meaning the
            collection, arrangement, and assembly) of all Contents on this Site
            and the Application is the exclusive property of CareerCarve and is
            also protected by relevant Indian and international copyright laws.
          </p>
          <p className="privacyPolicySubHeading">Objectionable Material</p>
          <p className="normalprivacyPolicy">
            You understand that by using or accessing this Site or the
            Application or any services provided on them, you may encounter
            Content that may be deemed by some to be offensive, indecent, or
            objectionable, which Content may or may not be identified as such.
            You agree to use the Site and the Application and any service at
            your sole risk and that to the fullest extent permitted under
            applicable law, CareerCarve and its affiliates shall have no
            liability to you for Content that may be deemed offensive, indecent,
            or objectionable to you.
          </p>

          <p className="privacyPolicySubHeading">Indemnity</p>
          <p className="normalprivacyPolicy">
            You (and also any third party for whom you operate an account or
            activity on the Services) agree to defend, indemnify and hold
            harmless CareerCarve, its owner, licensee, affiliates, subsidiaries,
            group companies (as applicable) and its employees, directors,
            officers, agents and their successors and assigns from and against
            any and all claims, liabilities, damages, losses, costs and
            expenses, including without limitation attorney's fees, caused by or
            arising out of claims based upon the following (a) your access to
            the Website or the Application (b) your breach or alleged breach of
            any of the provisions of this Terms of Use (c) your actions or
            inactions, which may result in any loss or liability to CareerCarve
            or any third party including but not limited to breach of any
            warranties, representations or undertakings or in relation to the
            non-fulfillment of any of your obligations under this User Agreement
            or arising out of the your violation of any applicable laws,
            regulations including but not limited to Intellectual Property
            Rights, payment of statutory dues and taxes, claim of libel,
            defamation, violation of rights of privacy or publicity, loss of
            service by other subscribers and infringement of intellectual
            property or other rights. You will cooperate as fully required by
            CareerCarve in the defense of any claim. CareerCarve reserves the
            right to assume the exclusive defense and control of any matter
            subject to indemnification by you, and you will not in any event
            settle any claim without the prior written consent of CareerCarve.
            This clause shall survive the expiry or termination of this User
            Agreement.
          </p>
          <p className="privacyPolicySubHeading">Termination</p>
          <p className="normalprivacyPolicy">
            This User Agreement is effective unless and until terminated by
            either you or CareerCarve. You may terminate this User Agreement at
            any time, provided that you discontinue any further use of this Site
            and the Application. CareerCarve may terminate this User Agreement
            at any time and may do so immediately without notice, and
            accordingly deny you access to the Site, such termination will be
            without any liability to CareerCarve. Upon any termination of the
            User Agreement by either you or CareerCarve, you must promptly
            destroy all materials downloaded or otherwise obtained from this
            Site or the Application, as well as all copies of such materials,
            whether made under the User Agreement or otherwise. However, you
            shall have the limited right to retain such materials including the
            concept notes that are exclusively obtained by you after paying the
            appropriate fees to CareerCarve through the Website or the
            Application. CareerCarve’s right to any Comments shall survive any
            termination of this User Agreement. Any such termination of the User
            Agreement shall not cancel your obligation to pay for the product or
            services already ordered from the Site or the Application or affect
            any liability that may have arisen under the User Agreement.
          </p>
          <p className="privacyPolicySubHeading">Disclaimer of Warranties</p>
          <p className="normalprivacyPolicy">
            THE SERVICEs, INCLUDING, WITHOUT LIMITATION, THE WEBSITE, ALL
            MATERIALS AND PRODUCTS (INCLUDING BUT NOT LIMITED TO SOFTWARE) AND
            SERVICES AND CAREERCARVE CONTENT INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THIS SITE, IS PROVIDED ON AN “AS IS”, “AS
            AVAILABLE” AND “WITH ALL FAULTS” BASIS WITHOUT ANY REPRESENTATION OR
            WARRANTIES, EXPRESS OR IMPLIED EXCEPT AS SPECIFIED IN WRITING.{" "}
            <br />
            <br />
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER CAREERCARVE NOR
            ANY OF THEIR EMPLOYEES, MANAGERS, OFFICERS OR AGENTS (COLLECTIVELY,
            THE “CAREERCARVE PARTIES”) MAKE ANY REPRESENTATIONS OR WARRANTIES OR
            ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO: (A)
            THE SERVICES; (B) THE CAREERCARVE CONTENT; (C) USER CONTENT; OR (D)
            SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO
            CAREERCARVE OR VIA THE SERVICE. IN ADDITION, ALL CAREERCARVE PARTIES
            HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET
            ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.
            <br />
            <br />
            THE CAREERCARVE PARTIES DO NOT REPRESENT OR WARRANT THAT THE SERVICE
            WILL BE ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED;
            OR THAT THE SERVICE OR THE SERVER THAT MAKES THE SERVICE AVAILABLE
            IS FREE FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION,
            VIRUSES. THE CAREERCARVE PARTIES DO NOT MAKE ANY REPRESENTATIONS OR
            WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE
            SERVICE IS ACCURATE, COMPLETE, OR USEFUL. YOU ACKNOWLEDGE THAT YOUR
            USE OF THE SERVICE IS AT YOUR SOLE RISK. THE CAREERCARVE PARTIES DO
            NOT WARRANT THAT YOUR USE OF THE SERVICE IS LAWFUL IN ANY PARTICULAR
            JURISDICTION, AND THE CAREERCARVE PARTIES SPECIFICALLY DISCLAIM SUCH
            WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER
            OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY
            TO YOU TO THE EXTENT SUCH JURISDICTION’S LAW IS APPLICABLE TO YOU
            AND THESE TERMS OF USE.
            <br />
            <br />
            BY ACCESSING OR USING THE SERVICE YOU REPRESENT AND WARRANT THAT
            YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR
            USE THE SERVICE.
            <br />
            <br />
            THE CAREERCARVE PARTIES DO NOT ENDORSE CONTENT AND SPECIFICALLY
            DISCLAIM ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR
            ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR
            OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR
            CHARACTER BASED UPON OR RESULTING FROM ANY CONTENT. CAREERCARVE WILL
            NOT BE LIABLE TO YOU IN ANY WAY OR IN RELATION TO THE CONTENT OF, OR
            USE OF, OR OTHERWISE IN CONNECTION WITH, THE SERVICES. CAREERCARVE
            DO NOT WARRANT THAT THIS SITE, INFORMATION, CONTENT, MATERIALS,
            PRODUCT (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE
            MADE AVAILABLE TO YOU THROUGH THE SERVICES, THEIR SERVERS OR
            ELECTRONIC COMMUNICATION SENT FROM ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS.
          </p>
          <p className="privacyPolicySubHeading">
            Limitation of Liability and Disclaimers
          </p>
          <p className="normalprivacyPolicy">
            Under no circumstances and to the fullest extent permitted under
            applicable law, CareerCarve or its affiliates shall not be liable
            for any indirect, incidental, punitive, special, incidental,
            consequential or exemplary damages, including but not limited to,
            damages for loss of profits, goodwill, use, data or other intangible
            losses arising out of or in connection with the Site or the
            Application, its services or this User Agreement.
            <br />
            <br />
            Without prejudice to the generality of the section above, the total
            liability of CareerCarve to you for all liabilities arising out of
            this User Agreement be it in tort or contract is limited to the
            value of the product or services ordered by you.
            <br />
            <br />
            CareerCarve, its associates and technology partners make no
            representations or warranties about the accuracy, reliability,
            completeness, correctness and/or timeliness of any content,
            information, software, text, graphics, links or communications
            provided on or through the use of the Site or the Application or
            that the operation of the Site or the Application will be error free
            and/or uninterrupted. Consequently, CareerCarve assumes no liability
            whatsoever for any monetary or other damage suffered by you on
            account of the delay, failure, interruption, or corruption of any
            data or other information transmitted in connection with use of the
            Site or the Application; and/or any interruption or errors in the
            operation of the Site. In no event will the CareerCarve total
            liability to you for all damages, losses or causes or action shall
            exceed One Hundred Indian Rupees (INR 100.00).
          </p>
          <p className="privacyPolicySubHeading">Site Security</p>
          <p className="normalprivacyPolicy">
            You are prohibited from violating or attempting to violate the
            security of the Site or the Application, including, without
            limitation, (a) accessing data not intended for you or logging onto
            a server or an account which you are not authorized to access; (b)
            attempting to probe, scan or test the vulnerability of a system or
            network or to breach security or authentication measures without
            proper authorization; (c) attempting to interfere with service to
            any other user, host or network, including, without limitation, via
            means of submitting a virus to the Site or the Application,
            “overloading,” "flooding," "spamming," "mailbombing" or "crashing;"
            (d) sending unsolicited email, including promotions and/or
            advertising of products or services; or (e) forging any TCP/IP
            packet header or any part of the header information in any email or
            newsgroup posting. Violations of system or network security may
            result in civil or criminal liability. CareerCarve will investigate
            occurrences that may involve such violations and may involve, and
            cooperate with, law enforcement authorities in prosecuting users who
            are involved in such violations. You agree not to use any device,
            software or routine to interfere or attempt to interfere with the
            proper working of this Site and the Application or any activity
            being conducted on this Site and the Application. You agree,
            further, not to use or attempt to use any engine, software, tool,
            agent or other device or mechanism (including without limitation
            browsers, spiders, robots, avatars or intelligent agents) to
            navigate or search this Site or Application other than the search
            engine and search agents available from CareerCarve on this Site or
            the Application and other than generally available third-party web
            browsers (e.g., Microsoft Edge, Google Chrome, Mozilla Firefox).
          </p>
          <p className="privacyPolicySubHeading">Entire Agreement</p>
          <p className="normalprivacyPolicy">
            If any part of this agreement is determined to be invalid or
            unenforceable pursuant to applicable law including, but not limited
            to, the warranty disclaimers and liability limitations set forth
            above, then the invalid or unenforceable provision will be deemed to
            be superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remainder of
            the agreement shall continue in effect. Unless otherwise specified
            herein, this agreement constitutes the entire agreement between you
            and CareerCarve or the Application with respect to the CareerCarve
            sites/services and it supersedes all prior or contemporaneous
            communications and proposals, whether electronic, oral or written,
            between you and CareerCarve with respect to the CareerCarve
            sites/services. CareerCarve's failure to act with respect to a
            breach by you or others does not waive its right to act with respect
            to subsequent or similar breaches.
          </p>
          <p className="privacyPolicySubHeading">Cancellation/Refund Policy</p>
          <p className="normalprivacyPolicy">
            All sales/purchase of services are final with no refund or exchange
            permitted. However, if in a transaction performed by you on the
            site, money has been charged to your card or bank account without
            the delivery of the services, then you may inform us by sending an
            email to{" "}
            <button
              onClick={handleEmailButtonClick}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: Color.primary1,
              }}
            >
              support@careercarve.com
            </button>{" "}
            or an email address mentioned on the Contact Us page. Parivartana
            Consulting Private Limited shall investigate the incident and if it
            is found that money was indeed charged to your card or bank account
            without delivery of the service, then you will be refunded the money
            within 21 working days from the date of receipt of your email. All
            refunds will be credited back to source of the payment after
            deducting the service charges and taxes(if applicable). It will take
            3-21 days for the money to show in your bank account depending on
            your bank's policy.
          </p>
          <p className="privacyPolicySubHeading">Contact Details: </p>
          <p className="normalprivacyPolicy" style={{ marginBottom: "5rem" }}>
            You may contact us at:
            <br />
            Ph: +91 <b>[9945280336]</b> <br />
            E-mail: <b>[admin@careercarve.com]</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
