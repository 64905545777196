export function checkDate(dateString) {
  const today = new Date();
  const tomorrow = new Date(today);
  const yesterday = new Date(today);
  const dayBeforeYesterday = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  yesterday.setDate(yesterday.getDate() - 1);
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
  const date = new Date(dateString);

  if (date.toDateString() === today.toDateString()) {
    return "today";
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return "tomorrow";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "yesterday";
  } else if (date.toDateString() === dayBeforeYesterday.toDateString()) {
    return "day before yesterday";
  } else {
    return "invalid";
  }
}

export const formatConvertermonthfirst = date => {
  const monthNames = [
    "Januray",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // console.log("what might the date be" + date);
  const d = new Date(date);
  // console.log("what might the date be month:" + monthNames[d.getMonth(date)]);
  return monthNames[d.getMonth(date)] === undefined
    ? " "
    : d.getDate(date) +
        // (d.getDate(date) % 10 === 1
        //   ? " "
        //   : d.getDate(date) % 10 === 2
        //   ? " "
        //   : d.getDate(date) % 10 === 3
        //   ? " "
        //   : " ")
        " " +
        monthNames[d.getMonth(date)] +
        " " +
        d.getFullYear(date);
};

// Utility function to calculate the difference in days between two dates
const calculateDaysDifference = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // Hours * minutes * seconds * milliseconds
  const diffDays = Math.round(Math.abs((date1 - date2) / oneDay));
  return diffDays;
};

// Function to check if it has been 7 days since the session ended
export const hasBeenSevenDaysSinceSessionEnd = sessionEndEpoch => {
  console.log("what is here", sessionEndEpoch);
  const currentDate = new Date();
  const endDate = new Date(sessionEndEpoch);
  const daysDifference = calculateDaysDifference(currentDate, endDate);
  console.log("what is here", daysDifference);
  return daysDifference >= 7;
};
