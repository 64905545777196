import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "../../services/httpService";
import Webcam from "react-webcam";
import * as tf from "@tensorflow/tfjs";
import * as posenet from "@tensorflow-models/posenet";
function EndTest() {
  const navigate = useNavigate();
  const services = new Service();
  const location = useLocation();
  const webcamRef = useRef(null);
  const [tests, setTests] = useState([]);
  const { score, mxm_marks } = location.state.responseData;
  const [selectedTest, setSelectedTest] = useState(null);
  const [modal, setModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalIndex, setModalIndex] = useState(0);
  const [faceError, setFaceError] = useState("");
  const testType = location.state.testType;
  console.log("Location DAta", location.state.responseData, location);
  useEffect(() => {
    const getRecommendedTests = async () => {
      const recommendedTest = await services.get(
        `/tests/getAllTestByTestType?test_type_id=${testType.test_type_id}`
      );
      setTests(
        recommendedTest.pendingTests[0].test_not_attempted.length > 4
          ? recommendedTest.pendingTests[0].test_not_attempted.slice(0, 4)
          : recommendedTest.pendingTests[0].test_not_attempted
      );
    };

    getRecommendedTests();
  }, []);

  useEffect(() => {
    const getCameraAccess = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (stream.active) {
        setModalIndex(1);
      }
    };

    if (modal && modalIndex === 0) {
      getCameraAccess();
    }
  }, [modal, modalIndex]);

  useEffect(() => {
    const runPoseEstimation = async () => {
      if (buttonDisabled) {
        tf.setBackend("webgl");
        const net = await posenet.load({
          architecture: "ResNet50",
          outputStride: 16,
          inputResolution: { width: 100, height: 100 },
          quantBytes: 2,
        });
        const webcam = webcamRef.current.video;
        // const pose = await net.estimateSinglePose(webcam, {
        //   flipHorizontal: true,
        // });

        const multiplePose = await net.estimateMultiplePoses(webcam, {
          flipHorizontal: true,
          outputStride: 16,
          maxDetections: 2,
          scoreThreshold: 0.0,
        });
        console.log("POSE", multiplePose);
        let count = 0;
        multiplePose.map(person => {
          if (
            person.score > 0.35 ||
            (person.keypoints[0].score > 0.5 &&
              person.keypoints[1].score > 0.5 &&
              person.keypoints[2].score > 0.5 &&
              person.score > 0.2)
          ) {
            count = count + 1;
          }
        });

        if (count > 1) {
          setFaceError("Multiples Person is Sitting");
        } else if (count === 0) {
          setFaceError(
            "No face detected. Please ensure your webcam is on and facing you directly"
          );
        } else {
          setButtonDisabled(false);
          setFaceError("");
        }

        // eslint-disable-next-line no-undef
        tf.dispose(pose);
        tf.dispose(net);
        // eslint-disable-next-line no-unused-expressions
        tf.memory().numTensors; // Check the number of tensors (optional)
        tf.memory().dispose();

        // Check if a key body part (e.g., nose, eyes) is within a certain position range
        // if (
        //   pose.keypoints[0].score < 0.7 ||
        //   pose.keypoints[1].score < 0.8 ||
        //   pose.keypoints[2].score < 0.8
        // ) {
        //   // User may not be sitting in front of the camera
        //   console.log("User is not sitting in front of the camera.");
        //   window.alert("User is not sitting in front of the camera.");
        // } else {
        //   // User is sitting in front of the camera
        //   console.log("User is sitting in front of the camera.");
        //   setButtonDisabled(false);
        // }
      }
    };
    if (modal) {
      const intervalId = setInterval(runPoseEstimation, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [modalIndex, buttonDisabled, modal]);

  const handleTestSelection = (item, type) => {
    if (type === "unAttempted") {
      let selected_test = tests.find(
        (test, t_index) => test.test_id === item.test_id
      );

      setSelectedTest(selected_test);
      setModal(true);
    } else {
      let selected_test = onGoingTest.find(
        (test, t_index) => test.test_id === item.test_id
      );
      console.log("SelectedTest", selected_test);
      setSelectedTest(selected_test);
      setModal(true);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "400px",
          marginBottom: "-150px",
          backgroundColor: "#000",
        }}
      />
      <Box
        sx={{
          padding: "9rem",
          overflow: "hidden",
          borderRadius: "50% 50% 0 0 / 150px 150px 0 0",
          backgroundColor: "#fff",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          <img
            src="https://du03hetco8xdw.cloudfront.net/Assessment_test/testcomplete.svg"
            style={{ position: "absolute", left: "", top: "8%" }}
          />
          <Typography sx={{ fontSize: "32px" }}>
            Congrats on <span style={{ color: "#1C8EA8" }}>Completion!</span>
          </Typography>
          <Box
            sx={{
              padding: "24px",
              boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "20px",
            }}
          >
            <Typography sx={{ fontSize: "32px" }}>
              <span style={{ color: "#1C8EA8" }}>{score}</span>/{mxm_marks}
            </Typography>
          </Box>
          <Typography sx={{ color: "#8B8B8B" }}>
            You've successfully completed this test
          </Typography>
          <button
            style={{
              background:
                "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
              padding: "12px 50px",
              marginBlock: "16px",
              fontSize: "16px",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() => navigate("/Assessment")}
          >
            Back to Tests Page
          </button>
        </Box>
        {tests.length > 0 ? (
          <Box>
            <Typography sx={{ fontSize: "24px" }}>Recommended Tests</Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#8B8B8B",
                marginBlock: "8px 32px",
              }}
            >
              Take these tests to improve your score
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              {tests.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "16px",
                    padding: "16px",
                    boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
                    borderRadius: "20px",
                    height: "100%",
                    width: "300px",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "24px" }}>
                      {item.test_name}
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                      Duration: {item.duration} mins
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
                      Number of questions: {item.total_no_questions}
                    </Typography>
                  </Box>
                  <button
                    style={{
                      border: "1px solid #1C8EA8",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    onClick={() => handleTestSelection(item, "unAttempted")}
                  >
                    Start Test
                  </button>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          setButtonDisabled(true);
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            padding: "20px",
            borderRadius: "30px",
            gap: "16px",
          }}
        >
          {modalIndex === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="https://du03hetco8xdw.cloudfront.net/Assessment_test/allowAccess.svg"
                style={{}}
              />
              <Typography
                sx={{
                  fontSize: "32px",
                  color: "#1C8EA8",
                  textAlign: "center",
                }}
              >
                Allow Access
              </Typography>
              <Typography sx={{ fontSize: "16px", textAlign: "center" }}>
                It is necessary to allow access to the your camera and
                microphone to start the test.
                <br /> Click on allow to provide access
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Webcam
                ref={webcamRef}
                style={{ width: "800px", height: "400px", padding: "20px" }}
              />
              <Typography
                sx={{
                  fontSize: "32px",
                  color: "#1C8EA8",
                  textAlign: "center",
                }}
              >
                Face Recognition
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  textAlign: "center",
                  marginBlock: "8px",
                }}
              >
                Kindly center your face in the frame to be accepted
              </Typography>
              <Typography
                style={{
                  color: "red",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {faceError}
              </Typography>

              <button
                disabled={buttonDisabled}
                style={{
                  background: buttonDisabled
                    ? "#8B8B8B"
                    : "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                  padding: "12px 50px",
                  fontSize: "16px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
                onClick={() =>
                  navigate("/Instructions", {
                    state: {
                      selectedTest: selectedTest,
                      testType: testType,
                    },
                  })
                }
              >
                View Instructions
              </button>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default EndTest;
