import * as React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
import FrameComponent from "./FrameComponent";
// import SIPSuccessDIAG from "./SIPSuccessDIAG";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SipSuccess() {
  const Mobile = useMediaQuery("(min-width:800px)");

  return (
    <Container
      maxWidth={false}
      className="landing-container"
      sx={{
        marginTop: "2.5rem",
        width: "100%",
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // add position property to create a stacking context
      }}
    >
      <Box
        sx={{
          position: "absolute", // position the pseudo-element
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url('SIPSuccessBGFinal.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1, // adjust the opacity as needed
        }}
        component="div"
        className="background"
      />
      <Container
        className="landing-container"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: Mobile ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            width: Mobile ? "100%" : "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography align="left" fontSize="18px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
          <Typography fontSize="52px" fontWeight="400px" align="left">
            SIP Success
          </Typography>
          <Typography align="left" fontSize="16px">
            Advice, if not timely, is of no value is the philosophy of SIP
            success. On campus, Summer Internship Program is a career shaping
            experience resulting in a PPO/PPI or a solid project to showcase
            during finals interviews. It is very critical that you get the right
            advice on time to maximize your SIP outcome.
          </Typography>
          <Button
            variant="contained"
            // color="warning"
            size="large"
            sx={{
              float: "left",
              marginTop: "40px",
              backgroundColor: Color.primary1,
            }}
          >
            Buy Now
          </Button>
        </Container>
        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <FrameComponent />
        </Container>
      </Container>
    </Container>
  );
}
