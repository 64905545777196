import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export const DialogueBox = ({
  open,
  onClose,
  title,
  content,
  actions,
  isFullScreen,
  fullWidth,
  maxWidth,
  backgroundColor,
  borderRadius,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isFullScreen ? true : false}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        style: {
          width: "50%",
          borderRadius: borderRadius ? borderRadius : "20px",
          backgroundColor: backgroundColor ? backgroundColor : "#FFF",
        },
      }}
    >
      <DialogTitle
        // sx={{ display: "flex", justifyContent: 'center' }}
        sx={{ padding: "20px" }}
      >
        <Typography style={{ fontSize: "20px", fontWeight: "medium" }}>
          {title}
        </Typography>
        <Button
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
          color="inherit"
          aria-label="close"
        >
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent sx={{ padding: "20px" }}>{content}</DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <Box width="100%" sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            key={actions}
            onClick={actions.handler}
            disabled={actions.disabled ?? false}
            // color={action.color}
            variant={actions.variant}
            sx={{
              width: "30%",
              height: "auto",
              background:
                actions.variant === "contained" ? actions.color : "transparent",
              color: actions.variant === "contained" ? "#FFF" : actions.color,
              borderColor: actions.color,
              borderRadius: "10px",
              padding: "14px",
              ":&hover": {
                background: actions.color,
                color: actions.variant === "contained" ? "#FFF" : actions.color,
              },
            }}
          >
            {actions.label}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
