export function AboutYouValidations(items) {
  let errors = {};
  items.forEach((field, index) => {
    if (
      ![
        "cat_percentile",
        "total_work_ex_in_months",
        "last_drawn_annual_salary_ctc",
        "on_sabbatical",
        "startup_exp_in_months",
      ].includes(field.backend_field_name)
    ) {
      if (
        (field.value === "" || field.value === null) &&
        index !== items.length - 1
      ) {
        errors[field.field_name] = `${field.field_name} cannot be empty`;
      } else if (field?.value?.length > 1000) {
        errors[
          field.field_name
        ] = `${field.field_name} should be less than 1000 characters`;
      }
    }
  });
  return errors;
}
