import * as React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
import ExpertConnectDIAGFinal from "./ExpertConnectDIAGFinal";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ExpertConnect() {
  const Mobile = useMediaQuery("(min-width:800px)");

  return (
    <Container
      maxWidth={false}
      className="landing-container"
      sx={{
        marginTop: "2.5rem",
        width: "100%",
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative", // add position property to create a stacking context
      }}
    >
      <Box
        sx={{
          position: "absolute", // position the pseudo-element
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url('ExpertConnectBGFinal.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1, // adjust the opacity as needed
        }}
        component="div"
        className="background"
      />
      <Container
        className="landing-container"
        sx={{
          width: "100%",
          marginTop: "24px",
          display: "flex",
          flexDirection: Mobile ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            width: Mobile ? "max(100%, 800px)" : "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography align="left" fontSize="18px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
          <Typography fontSize="52px" fontWeight="400px" align="left">
            Expert Connect
          </Typography>
          <Typography align="left" fontSize="16px">
            Expert Connect is designed to address your placement training <br />
            requirements beyond what is already covered in the many 1X1s <br />
            that CareerCarve offers. This could range from understanding a<br />
            Job Description better just before an interview you are going to{" "}
            <br />
            face to an open ended discussion to boost morale midway <br />
            through placement season or from an analysis of role fit & long{" "}
            <br />
            term career prospects to a follow up session with a mentor you{" "}
            <br />
            met earlier for another session
          </Typography>
          <Button
            variant="contained"
            // color="warning"
            size="large"
            sx={{
              float: "left",
              marginTop: "40px",
              backgroundColor: Color.primary1,
            }}
          >
            Buy Now
          </Button>
        </Container>
        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
            // paddingLeft: "100px",
          }}
        >
          {/* <img src="ExpertConnectDIAG.png" /> */}
          <ExpertConnectDIAGFinal />
        </Container>
      </Container>
    </Container>
  );
}
