// A function to replace special characters in a string for safe transmission or storage
export const send_specialCharReplace = text => {
  console.log("text:", text);
  let replacedText;
  if (![null, undefined].includes(text)) {
    if (typeof text === "string") {
      replacedText = text
        .replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
        .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
        .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
        .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
        .replace(/\\/g, "||"); // Replace all backslashes (\) with the string "||"
    } else if (Array.isArray(text)) {
      replacedText = text.map(each => {
        return each
          ?.replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
          .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
          .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
          .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
          .replace(/\\/g, "||"); // Replace all backslashes (\) with the string "||"
      });
    } else if (typeof text === "number") return text;
    console.log("replacedText:", replacedText);
    // Return the modified string or array of strings
    return replacedText;
  } else {
    return text;
  }
};

export const get_specialCharReplace = text => {
  console.log("text:", text);
  if (![null, undefined].includes(text)) {
    let replacedText;
    if (typeof text === "string") {
      replacedText = text
        ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
        .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
        .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
        .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
        .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
    } else if (Array.isArray(text)) {
      replacedText = text.map(each => {
        return each
          ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
          .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
          .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
          .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
          .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
      });
    } else return text;
    console.log("replacedText:", replacedText);
    // Return the modified string or array of strings
    return replacedText;
  } else {
    return text;
  }
};

export function calculateEpochTimeInLocalTime(selectedDate) {
  const date = new Date(selectedDate);
  const selectedTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return selectedTime;
}

// export function extractDateFromDateString(dateString) {
//   console.log("date passed", dateString);
//   const inputDate = new Date(dateString);
//   const year = inputDate.getFullYear();
//   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
//   const day = String(inputDate.getDate()).padStart(2, "0");
//   console.log("what's returning", year, month, day);
//   return `${year}-${month}-${day}`;
// }

export function extractDateFromDateString(dateString) {
  const inputDate = new Date(dateString);

  // Extract month, date, and year components
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const date = String(inputDate.getDate()).padStart(2, "0");
  const year = inputDate.getFullYear();

  // Combine components into mm/dd/yyyy format
  const formattedDate = `${month}/${date}/${year}`;
  return formattedDate;
}
