import React from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import { Box, Typography } from "@mui/material";
import { VerticalDivider } from "@100mslive/react-ui";
import { Color } from "../../../GlobalStyles";
const FileCardView = ({
  files,
  handlePdfClick,
  selectedFileName,
  setSelectedFileName,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const selectedFile = files[newValue];
    handlePdfClick(selectedFile.url);
    setSelectedFileName(selectedFile.name);
  };

  const isLightTheme =
    window.location.pathname === "/FeedbackOverview" ||
    window.location.pathname === "/FeedbackOverview/offline";

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          ...(isLightTheme && { background: "rgba(255, 255, 255, 0.8)" }),
          ...(window.location.pathname === "/FeedbackOverview/offline" && {
            ...(isLightTheme && { background: "rgba(255, 255, 255)" }),
          }),
        }}
      >
        <Tabs
          aria-label="file tabs"
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            bgcolor: "transparent",
            border: isLightTheme
              ? `1px solid ${Color.feedbackColor}`
              : "1px solid rgba(107, 107, 107, 1)",
            borderRadius: "10px",
          }}
          width="auto"
        >
          <TabList
            disableUnderline
            sx={{
              p: 0,
              gap: 0,
              borderRadius: "xl",
              bgcolor: isLightTheme ? "#e0e0e0" : "transparent",
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: "sm",
                bgcolor: isLightTheme ? "#f0f0f0" : "#ffffff",
                color: isLightTheme ? "#202124" : "#202124",
              },
              [`& .${tabClasses.root}:hover`]: {
                bgcolor: isLightTheme ? "#e0e0e0" : "#ffffff",
                color: isLightTheme ? "#202124" : "#202124",
              },
              ...(window.location.pathname === "/FeedbackOverview/offline" && {
                bgcolor: isLightTheme ? "#e0e0e0" : "transparent",
                [`& .${tabClasses.root}[aria-selected="true"]`]: {
                  boxShadow: "sm",
                  bgcolor: isLightTheme ? Color.primary1 : "#ffffff",
                  color: isLightTheme ? "#202124" : "#202124",
                },
                [`& .${tabClasses.root}:hover`]: {
                  bgcolor: isLightTheme ? Color.primary1 : "#ffffff",
                  color: isLightTheme ? "#202124" : "#202124",
                },
              }),
            }}
          >
            {files.map((file, index) => (
              <React.Fragment key={file.id}>
                <Tab
                  key={file.id}
                  disableIndicator
                  sx={{
                    width: "auto",
                    ...styles.tab,
                    color: isLightTheme ? "#202124" : "#ffffff",
                    ...(selectedTab === index && {
                      boxShadow: "sm",
                      bgcolor: isLightTheme ? "#f0f0f0" : "#ffffff",
                      color: isLightTheme ? "#202124" : "#202124",
                      ...(window.location.pathname ===
                        "/FeedbackOverview/offline" && {
                        boxShadow: "sm",
                        bgcolor: Color.primary1,
                        color: Color.white,
                      }),
                    }),
                    "&:hover": {
                      bgcolor: isLightTheme ? "#ffffff" : "#ffffff",
                      color: isLightTheme ? "#202124" : "#202124",
                    },
                    "&:hover .MuiTypography-root": {
                      bgcolor: isLightTheme ? "transparent" : "transparent",
                      color: isLightTheme ? "#202124" : "#202124",
                    },
                    height: "24px",
                    padding: "-1px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // borderRight: `1px solid #e0e0e0`,
                      "&:hover .MuiTypography-root": {
                        bgcolor: isLightTheme ? "transparent" : "transparent",
                        color: isLightTheme ? "#202124" : "#202124",
                      },
                    }}
                  >
                    <InsertDriveFileOutlinedIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: Color.primary3,
                        padding: "0px",
                      }}
                    />
                    <Typography
                      sx={{
                        ...styles.typography,
                        color: isLightTheme ? "#202124" : "#ffffff",
                        ...(selectedTab === index && {
                          boxShadow: "sm",
                          bgcolor: isLightTheme ? "transparent" : "transparent",
                          color: isLightTheme ? "#202124" : "#202124",
                          ...(window.location.pathname ===
                            "/FeedbackOverview/offline" && {
                            boxShadow: "sm",
                            bgcolor: Color.primary1,
                            color: Color.white,
                          }),
                        }),
                        "&:hover": {
                          bgcolor: isLightTheme ? "transparent" : "transparent",
                          color: isLightTheme ? "#202124" : "#202124",
                        },
                        fontSize: "14px",
                      }}
                    >
                      {file.name
                        .substring(0, 25)
                        .concat(file.name.length > 25 ? "..." : "")}
                    </Typography>
                  </Box>
                </Tab>
                {index !== files.length - 1 &&
                index !== selectedTab - 1 &&
                index !== selectedTab ? (
                  <Box
                    sx={{
                      width: "1px",
                      bgcolor:
                        index !== selectedTab - 1 && index !== selectedTab
                          ? isLightTheme
                            ? "#e0e0e0"
                            : "#ffffff"
                          : "transparent",
                    }}
                  >
                    <VerticalDivider css={{ height: "auto" }} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "1px",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </TabList>
        </Tabs>
      </Box>
    </Box>
  );
};

const styles = {
  tab: {
    minWidth: 100,
    cursor: "pointer",
    borderRadius: "10px",
    boxShadow: "none",
    transition: "background-color 0.3s, box-shadow 0.3s, border 0.3s",
  },
  typography: {
    textAlign: "center",
    fontSize: "16px",
    width: "auto",
    marginLeft: "8px",
  },
};

export default FileCardView;
