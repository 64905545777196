import React, { useEffect } from "react";
// import { MentorAnalyticsSkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
// import MentorPastBookings from "../MentorPastBookings/MentorPastBookings";
// import MentorSessionAnalytics from "./screens/Mentors/MentorSessionAnalytics/MentorSessionAnalytics.js";
// import MentorSessionAnalytics from "../MentorSessionAnalytics/MentorSessionAnalytics";
// import Service from "../../../services/httpService";
import { useLocation } from "react-router-dom";
// import OnGoingGroupSessions from "../../Mentors/MentorOnGoingSessions/OnGoingGroupSessions";
import SideNavBar from "../../SideNavBar/SideNavBar";
import SessionsHome from "../OfflineSessions/SessionsHome";

const MentorDashboard = () => {
  // const service = new Service();
  // const [sessionsMissed, setSessionsMissed] = useState({});
  // const [loader, setLoader] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const user_id = queryParams.get("user_id");
  console.log("user_id", queryParams.get("user_id"));
  useEffect(() => {
    const handleEffect = async () => {
      window.scrollTo(0, 0);
      // let sessionsMissedDetails = await sessionsMissedGetter();
      // setSessionsMissed(sessionsMissedDetails);
      // let batchAnalyticsDetails = await batchAnalyticsDataGetter();
      // let batchActivityReportGetter = await batchActivityReportDataGetter();
      // setLoader(false);
    };
    handleEffect();
  }, []);

  // const sessionsMissedGetter = async () => {
  //   try {
  //     const res = await service.get(
  //       `/mentorModule/mentorDashBoard?mentor_id=1830`
  //     );
  //     console.log("session missed response:", res.data);
  //     return res.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <>
      <div
        className="OngoingEventsCard"
        style={{ marginTop: "2rem", marginLeft: "10rem" }}
      >
        <SideNavBar />
        {/* <div>
          <div className="topHeadings" style={{ display: "flex" }}>
            <p style={{ marginBottom: "1rem" }}>Dashboard</p>
          </div>
          {loader ? (
            <>
              <MentorAnalyticsSkeletonLoader />
              <MentorAnalyticsSkeletonLoader />
              <MentorAnalyticsSkeletonLoader />
            </>
          ) : (
            <></>
          )}
          <MentorSessionAnalytics sessionsMissed={sessionsMissed} />
        </div> */}
        {/* <OnGoingGroupSessions user_id={user_id} />*/}
        <SessionsHome />
      </div>
    </>
  );
};

export default MentorDashboard;
