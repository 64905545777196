import React from "react";
import { Modal } from "react-bootstrap";
import { Color } from "../../../GlobalStyles";

export default function Alert(props) {
  const { title, body, alertResponse } = props;
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        border: "none",
        position: "absolute",
        width: props.Mobile ? "90%" : "100%",
        borderRadius:
          window.location.pathname === "/DeleteAccountOTP" ? "20px" : "auto",
        margin: props.Mobile ? "auto" : "auto",
      }}
    >
      <Modal.Body className="ModalBody">
        <p
          className="modalBodyText"
          style={{
            color:
              window.location.pathname === "/DeleteAccountOTP"
                ? Color.red
                : "#000",
            fontSize: "24px",
          }}
        >
          {title}
        </p>
        {console.log("props.yesButton", props.yesButton)}
        {console.log("props.no", props.no)}
        {console.log("props.yes", props.yes)}
        <div>
          <p
            className="modalBodyText"
            style={{
              textAlign:
                window.location.pathname === "/DeleteAccountOTP"
                  ? "left"
                  : "center",
            }}
          >
            {body}
          </p>

          <div className="PreviousAndSubmit">
            {props.no && (
              <button
                name="previous-button"
                style={{ margin: "auto", marginTop: "1rem" }}
                className="Previousbutton"
                onClick={() => props.alertResponse("No")}
              >
                No
              </button>
            )}

            {props.yes && (
              <button
                name="next-button"
                style={{ margin: "auto", marginTop: "1rem" }}
                className="Rephrasebutton"
                onClick={() => alertResponse("Yes")}
              >
                Yes
              </button>
            )}
            {props.yesButton && (
              <button
                type="submit"
                name="next-button"
                style={{ margin: "auto", marginTop: "1rem" }}
                className="Rephrasebutton"
                onClick={() => alertResponse()}
              >
                OK
              </button>
            )}

            {window.location.pathname === "/DeleteAccountOTP" && (
              <>
                <div
                  className="PreviousAndSubmit"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    display: "flex",
                    flexDirection: props.Mobile ? "column" : "row",
                  }}
                >
                  <button
                    name="previous-button"
                    style={{ margin: "auto", marginTop: "1rem" }}
                    className="Rephrasebutton"
                    onClick={() => props.cancelResponse()}
                  >
                    Cancel
                  </button>
                  <button
                    name="next-button"
                    style={{
                      margin: "auto",
                      marginTop: "1rem",
                    }}
                    className="Previousbutton"
                    onClick={() => alertResponse()}
                  >
                    Confirm Delete
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
