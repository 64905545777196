import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// firebase apple login
// import * as firebase from "firebase/app";
import { AppContext } from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useHistory } from "react-router-dom";
// import Service from "../../services/httpService";
// import UpcomingBooking from "../../screens/Mentors/UpcomingBooking/UpcomingBooking";
// import Validations from "../../screens/Validations/Validations";
import "../Login/Login.css";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  // const service = new Service();
  // const rd_service = new Service();
  // const [userType, setUserType] = useState();
  const formInputs = useContext(AppContext);
  const Mobile = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    const handleEffect = async () => {};
    handleEffect();
  }, []);
  console.log("userType", formInputs);

  return (
    <div
      className="login-Container"
      style={{ width: Mobile ? "100%" : "max(100%,1200px)" }}
    >
      {!Mobile && (
        <div className="login-Resumes_Image">
          <img
            className="imageFlexible"
            src="backgroundImage.png"
            alt="background"
            style={{
              backgroundRepeat: "none",
              float: "left",
              margin: "0",
              width: "100%",
              height: "100%",
              position: "static",
            }}
          />
          <img
            className="imageFlexible"
            src="logo_welcome.png"
            alt="background"
            style={{
              position: "absolute",
              height: "150px",
              width: "150px",
              top: "max(30%,200px)",
              left: "20%",
            }}
          />
          <img
            src="CareerCarve.png"
            alt="background"
            style={{
              position: "absolute",
              top: "max(52%,350px)",
              left: "13%",
            }}
          />
          <p
            style={{
              position: "absolute",
              top: "max(62%, 430px)",
              left: "13.5%",
              fontSize: "20px",
            }}
          >
            {" "}
            Placement | Preparedness | Perfected
          </p>
        </div>
      )}

      <div
        className="login-Main-content"
        style={{ width: Mobile ? "100%" : "max(50%,600px)" }}
      >
        <p className="login-name">Signup</p>
        <p className="signUpProcessText">
          To complete the signup process, kindly download the CareerCarve app
        </p>
        <p className="signUpDownloadText">Download Now</p>
        {/* <div className="signUpBorderLine"></div> */}
        <div
          className="googleAndAppleStoreButton"
          style={{
            ...(Mobile && {
              display: "flex",
              flexDirection: "column",
              width: "80%",
              margin: "0 auto",
            }),
          }}
        >
          <button
            className="googlePlayBUtton"
            style={{ ...(Mobile && { width: "100%" }) }}
          >
            <a href="https://play.google.com/store/apps/details?id=com.careercarve">
              <div>
                <div className="signUpFlexButton">
                  <img
                    className="imageFlexible"
                    src="google-play.svg"
                    alt="background"
                    style={{
                      backgroundRepeat: "none",
                      height: "40px",
                      width: "40px",
                      marginRight: ".5rem",
                    }}
                  />
                  <div>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        color: "#545454",
                      }}
                    >
                      Get it on
                    </p>
                    <p className="AppStoreText">Google Play</p>
                  </div>
                </div>
              </div>
            </a>
          </button>
          <button
            className="appStoreBUtton"
            style={{ ...(Mobile && { width: "100%" }) }}
          >
            <a href="https://apps.apple.com/in/app/careercarve/id6447052699">
              <div
                className="signUpFlexButton"
                style={{ paddingLeft: ".6rem" }}
              >
                <img
                  className="imageFlexible"
                  src="App_Store_(iOS).svg"
                  alt="background"
                  style={{
                    backgroundRepeat: "none",
                    height: "40px",
                    width: "40px",
                    marginRight: ".5rem",
                  }}
                />
                <div style={{ paddingLeft: ".2rem" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      color: "#545454",
                    }}
                  >
                    Download from
                  </p>
                  <p className="AppStoreText">App Store</p>
                </div>
              </div>
            </a>
          </button>
          <div
            style={{
              width: "200px",
              height: "200px",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <img src="QR_code_sign_up.jpg" />
          </div>
        </div>
        <div style={{ textAlign: "center", width: "100%" }}>
          <button
            className="LoginNowBUtton"
            onClick={() => navigate("/")}
            style={{ marginTop: "2rem" }}
          >
            Login Now
          </button>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Login;
