import React, { useContext, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { AttachFile, NotesOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Divider, Modal } from "@mui/material";
// import { Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ActivityLoader } from "../../../components/Loader/Loader";
import { Color, PrimaryButton } from "../../../../GlobalStyles";
import { ProvideFeedback, SlotsContext } from "../../../AppContext";
import SideNavBar from "../../SideNavBar/SideNavBar";
import TimeService from "../../../services/epochTime";
import Service from "../../../services/httpService";
import "./OfflineSessions.css";
function SessionSchedule() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const process_id = queryParams.get("process_id");
  const data = location?.state?.data ?? [];
  console.log("data", data);
  const navigate = useNavigate();
  const [dates, setDates] = useState([
    1720763637000, 1720850037000, 1720936437000,
  ]);
  const [showOTP, setShowOTP] = useState({
    open: false,
    onClose: () => setShowOTP({ ...showOTP, open: false }),
    otp: "",
    state: "take_otp", //or confirmation
  });
  const [error, setError] = useState({
    open: false,
    onClose: () => setError({ ...error, open: false }),
    otp: "",
    heading: "",
  });
  const [chosenDate, setChosenDate] = useState(1720763637000);
  const [sessionData, setSessionData] = useState([]);
  const [filtered_sessionData, setFilteredSessionData] = useState([]);
  const { slotsContextData, setSlotsContextData } = useContext(SlotsContext);
  const { provideFeedback, setProvideFeedback } = useContext(ProvideFeedback);
  const [loading, setLoading] = useState(true);
  // const [studentData, setStudentData] = useState({
  //   order_id: "",
  //   student_name: "Bhoomika",
  //   college_name: "",
  //   batch_type_name: "",
  //   other_docs: [],
  //   resume: [],
  //   start_time: "",
  //   end_time: "",
  // });

  const [sessionStudentData, setSessionStudentData] = useState([
    {
      order_id: 43,
      student_name: "",
      start_time: 1716215400000,
      end_time: 1716217200000,
      resume: [],
      other_docs: [],
      user_group_name: "",
      batch_type_name: "",
      college_name: "",
      act_type_id: "",
      act_type_name: "",
      sku_id: 0,
      sku_name: "",
    },
  ]);
  console.log("sessionStudentData:", sessionStudentData);
  const time = new TimeService();
  const services = new Service();
  // const apiRef = useGridApiRef();
  console.log("session data:", sessionData, process_id);
  async function handleProcessData() {
    setLoading(true);
    try {
      const res = await services.get(
        "/os/mentor/allOrders?process_id=" + process_id
      );
      console.log("res:", res);
      // setStudentData(res);
      const date_session = res.session_dates.map((item, index) => {
        return item.session_date;
      });
      console.log("date_session:", date_session);
      filterDates(date_session[0], res.data);
      setDates(date_session);
      setSessionData(res.data);
      setChosenDate(date_session[0]);

      return res.data;
    } catch (err) {
      console.log("error", err);
    }
  }

  const getSessionData = async order_id => {
    try {
      const res = await services.get(
        "/os/mentor/osOrderDetails?order_id=" + order_id
      );
      console.log("res of get order:", res);
      console.log("/os/mentor/osOrderDetails?order_id=");
      setSessionStudentData([...res.data]);

      let tempData = {
        ...res.data[0],
      };
      console.log("CONTEXT temp 1", tempData);
      tempData["other_docs"] = tempData["other_docs"]
        ? JSON.parse(tempData["other_docs"])
        : [];
      tempData["resume"] = JSON.parse(tempData["resume"]);
      tempData["student_availability_proof"] = JSON.parse(
        tempData["student_availability_proof"]
      );
      console.log("CONTEXT temp", tempData);
      setSlotsContextData(tempData);
      return res.data[0];
    } catch (err) {
      console.log("error:", err);
    }
  };

  console.log("CONTEXT", slotsContextData);

  const handleProvideFeedback = async order_id => {
    const res = await getSessionData(order_id);
    setProvideFeedback(true);
    console.log("provide in handle pro:", provideFeedback);
    console.log(
      "inside handleProvideFeedback",
      res,
      JSON.parse(window.sessionStorage.getItem("slot"))
    );
    // window.open(`/FeedbackOverview/offline?id=${order_id}`, "_blank");
    navigate(`/FeedbackOverview/offline?id=${order_id}`, {
      state: {
        studentData: res,
        process_id: process_id,
        provideFeedback: true,
      },
    });
  };
  const putjoinSession = async received_otp => {
    const body = {
      // order_id: 43, // cannot send order_id
      otp: received_otp,
    };
    try {
      const res = await services.put("/os/mentor/joinSession", body);

      console.log("res in putjoinSession", res, res.data[0].order_id);
      window.sessionStorage.setItem(
        "slot",
        JSON.stringify({ ...res.data[0], id: res.data[0].order_id })
      );
      window.sessionStorage.setItem(
        "mentor_join_time",
        res.data[0].mentor_join_time
      );
      setSlotsContextData({
        ...res.data[0],
        id: res.data[0].order_id,
        // sku_name: res.data[0].sku_name,
      });
      setSessionStudentData([...res.data]);
      setShowOTP({ ...showOTP, state: ".confirmation" });
      setProvideFeedback(false);
    } catch (err) {
      setProvideFeedback(false);
      if (received_otp.length < 6) {
        setError({ ...error, open: true, heading: "OTP incomplete" });
      } else {
        setError({ ...error, open: true, heading: err.response.data.message });
      }
      console.log("err in otp:", err);
    }
  };
  const filterDates = (chosen_date, data = sessionData) => {
    console.log("in set filter");

    const filter = data.filter((item, index) => {
      if (time.sameDay(chosen_date, item.end_time) === true) {
        return item;
      }
    });
    console.log("in set filter", filter);
    setFilteredSessionData(filter);
  };

  console.log("setFilteredSessionData", filtered_sessionData);
  useEffect(() => {
    handleProcessData();
    setLoading(false);
    // return () => {
    //   setSlotsContextData(null);
    // };
  }, []);

  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    backdropFilter: "blur(5px)",
  };

  function getStatus(id, order_flag, is_absent) {
    const order_data = filtered_sessionData.filter(
      item => item.order_id === id
    );
    console.log("order_data in get status:", order_data);
    if (is_absent === 1) {
      return (
        <div
          style={{
            padding: "8px 10px",
            backgroundColor: "#E7E7E7",
            color: Color.gainsboro400,
            fontSize: "12px",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>Absent</div>
        </div>
      );
    } else if (order_flag === "Scheduled")
      return (
        <div
          style={{
            padding: "8px 10px",
            backgroundColor: "#E8F3EA",
            color: Color.green,
            fontSize: "12px",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>Scheduled</div>
        </div>
      );
    else if (order_flag === "Done") {
      return (
        <div>
          <div
            onClick={() =>
              navigate(`/FeedbackReview/offline?order_id=${id}`, {
                state: order_data[0],
              })
            }
            style={{
              fontSize: "12px",
              marginBottom: "12px",
              color: Color.green,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Completed
          </div>
          <div
            onClick={() =>
              navigate(`/FeedbackReview/offline?order_id=${id}`, {
                state: order_data[0],
              })
            }
            style={{
              padding: "5px 8px",
              // backgroundColor: "#FFF7EB",
              color: Color.primary1,
              borderColor: Color.primary1,
              borderWidth: "1px",
              borderRadius: "16px",
              fontSize: "12px",
            }}
          >
            <div>
              {" "}
              <RemoveRedEyeIcon sx={{ fontSize: "16px" }} /> View feedback
            </div>
          </div>
        </div>
      );
    } else if (order_flag === "In process") {
      return (
        <div
          style={{
            padding: "8px 10px",
            backgroundColor: "#FFF7EB",
            color: Color.accent,
            borderRadius: "16px",
          }}
        >
          <div>In process</div>
        </div>
      );
    } else if (order_flag === "Feedback") {
      return (
        <div>
          <div
            // onClick={() =>
            //   navigate(`/FeedbackReview?order_id=${id}`, {
            //     state: order_data[0],
            //   })
            // }
            style={{
              fontSize: "12px",
              marginBottom: "12px",
              color: Color.red,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Pending
          </div>
          <div
            onClick={() => handleProvideFeedback(id)}
            style={{
              padding: "5px 8px",
              // backgroundColor: "#FFF7EB",
              color: Color.primary1,
              borderColor: Color.primary1,
              borderWidth: "1px",
              borderRadius: "16px",
              fontSize: "12px",
            }}
          >
            <div style={{ cursor: "pointer" }}>
              {" "}
              <EditIcon sx={{ fontSize: "16px" }} /> Provide Feedback
            </div>
          </div>
        </div>
      );
    }
  }

  console.log("loading:", loading);

  const modalHeader = {
    fontSize: "32px",
    fontWeight: 500,
  };
  return loading ? (
    <ActivityLoader />
  ) : (
    <div
      style={{
        marginLeft: "128px",
      }}
    >
      <SideNavBar />
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            style={{
              color: Color.primary1,
              fontSize: "30px",
              marginRight: "10px",
            }}
            color={Color.primary1}
          />
          <div style={{ fontSize: "32px" }}>Offline Activities</div>{" "}
        </div>

        <div
          style={{
            margin: "24px 50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 20 }}>
            <div style={{ fontWeight: 600 }}>{data.college_name}</div>
            <div>
              {data.name} • {data.user_group_name} • {data.batch_type_name}
            </div>
          </div>
          <PrimaryButton
            variant="outlined"
            // onClick={() => navigate("/FeedbackOverview")}
            onClick={() => setShowOTP({ ...showOTP, open: true })}
            sx={{
              textTransform: "none",
              borderColor: Color.primary1,
              background: Color.primary1,
              color: Color.primary1,
              borderRadius: "8px",
              marginTop: "12px",
              marginLeft: "30px",

              boxShadow: "none",
              width: "200px",
              "&:hover": {
                background: Color.primary1,
                borderColor: Color.primary1,
                color: Color.primary1,
              },
            }}
          >
            Start Session
          </PrimaryButton>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 24,
            margin: "0px 80px",
            marginTop: "80px",
            marginBottom: "32px",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dates.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setChosenDate(item);
                  filterDates(item);
                }}
                style={{
                  cursor: "pointer",
                  fontSize: 24,
                  flex: 0.3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: chosenDate === item ? Color.primary1 : Color.blueBlack,
                  // textDecoration: chosenDate === item ? "underline" : "none",
                  // textDecorationColor: Color.primary1,
                }}
              >
                {time.convertEpochToDate(item, true)}
                {chosenDate === item ? (
                  <Divider
                    sx={{
                      borderWidth: "medium",
                      borderColor: Color.primary3,
                      width: "200px",
                      color: Color.primary1,
                    }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
        <div
          style={{
            margin: "10px 24px 0px 64px",
            width: "90%",
            "&.theme--header": {
              backgroundColor: "rgba(255, 7, 0, 0.55)",
            },
          }}
        >
          <DataGrid
            getRowHeight={() => "auto"}
            autoHeight
            rows={filtered_sessionData.map((item, index) => {
              return {
                id: item.order_id,
                // avatar: spe.mentor,
                Student_name: item.student_name,
                Specialization: item.spe_name,
                Time_slot: `${time.convertEpochToTime(
                  item?.start_time
                )}-${time.convertEpochToTime(item?.end_time)} `,
                Resume: JSON.parse(item.resume).url,
                order_show: item?.order_flag,
                // Documents: "No Documents",
              };
            })}
            columns={[
              {
                field: "Student_name",
                headerName: "Student Details",
                // width: 90,
                headerClassName: "grid-header",
                flex: 0.3,
                height: "110px",
                renderCell: params => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 8,
                    }}
                  >
                    {" "}
                    {params.row.Student_name}
                  </div>
                ),
              },

              {
                field: "Specialization",
                flex: 0.3,
                headerClassName: "grid-header",
                headerAlign: "center",
                height: "110px",
                renderCell: params => (
                  <div
                    style={{
                      padding: 16,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {params.row.Specialization}
                  </div>
                ),
              },
              {
                field: "Time slot",
                headerName: "Time slot",
                headerClassName: "grid-header",
                flex: 0.3,
                headerAlign: "center",
                height: "110px",
                renderCell: params => (
                  <div
                    style={{
                      cursor: "pointer",
                      // fontSize: 18,
                      // color: Color.primary1,
                      padding: 8,
                      marginTop: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {params.row.Time_slot}
                  </div>
                ),
              },

              {
                field: "Resume",
                headerName: "Resume",
                headerClassName: "grid-header",
                flex: 0.3,
                height: "110px",
                renderCell: params => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 8,
                    }}
                  >
                    <a
                      href={params.row.Resume}
                      target="_blank"
                      rel="noreferrer"
                      // style={{ textDecoration: "underline" }}
                    >
                      <NotesOutlined />
                    </a>
                  </div>
                ),
              },

              {
                field: "Documents",
                headerName: "Documents",
                headerClassName: "grid-header",
                flex: 0.3,
                height: "110px",
                renderCell: params => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 8,
                    }}
                  >
                    <a
                      // href={params.row.}
                      target="_blank"
                      rel="noreferrer"
                      // style={{ textDecoration: "underline" }}
                    >
                      <AttachFile />
                    </a>
                  </div>
                ),
              },
              {
                field: "Status",
                headerName: "Status",
                headerClassName: "grid-header",
                align: "center",
                flex: 0.3,
                height: "110px",
                renderCell: params => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 8,
                    }}
                  >
                    <a
                      // href={params.row.}
                      target="_blank"
                      rel="noreferrer"
                      // style={{ textDecoration: "underline" }}
                    >
                      {getStatus(
                        params.row.id,
                        params.row.order_show,
                        params.row.is_absent
                      )}
                    </a>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div></div>
      </div>

      <Modal
        open={showOTP.open}
        onClose={showOTP.onClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={{ ...modalStyles, textAlign: "center", marginBottom: "12px" }}>
          {showOTP.state === "take_otp" ? (
            <Box>
              <div
                style={{
                  ...modalHeader,
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                Start Session{" "}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  color: "#F5A536",
                  marginBottom: "32px",
                }}
              >
                {" "}
                To start session, enter the unique 6 digit code provided by
                student. Note: Ask student to check the activity page on mobile
                app to find this unique code.
              </div>
              <div>
                <span>
                  Enter the unique Code<span style={{ color: "red" }}>*</span>
                </span>
              </div>

              <OTPInput
                value={showOTP.otp}
                onChange={e => setShowOTP({ ...showOTP, otp: e })}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={props => <input {...props} />}
                inputStyle={{
                  borderWidth: "1px",
                  borderColor: Color.gainsboro200,
                  borderRadius: "8px",
                  // padding: "12px",
                  width: "48px",
                  height: "48px",
                  marginLeft: "10px",
                }}
                containerStyle={{
                  margin: "12px 0px",
                  justifyContent: "center",
                }}
              />
              <PrimaryButton
                variant="outlined"
                onClick={() => {
                  // setShowOTP({ ...showOTP, state: "confirmation" });
                  putjoinSession(showOTP.otp);
                }}
                // onClick={() => navigate("/FeedbackOverview")}
                // onClick={() => setShowOTP({ ...showOTP, open: true })}
                sx={{
                  textTransform: "none",
                  borderColor: Color.primary1,
                  background: Color.primary1,
                  color: Color.primary1,
                  borderRadius: "8px",
                  marginTop: "18px",
                  // marginLeft: "30px",
                  boxShadow: "none",
                  width: "100px",
                  "&:hover": {
                    background: Color.primary1,
                    borderColor: Color.primary1,
                    color: Color.primary1,
                  },
                }}
              >
                Submit
              </PrimaryButton>
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  ...modalHeader,
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                Ready to start?
              </div>
              <div
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                }}
              >
                Please check if you are talking to{" "}
                {sessionStudentData[0].student_name}.{" "}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                }}
              >
                Specialization :{sessionStudentData[0].sku_name}
                {/* {studentData?.spe_name} */}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                }}
              >
                Slot :{" "}
                {time.convertEpochToDate(sessionStudentData[0].start_time)} ,
                {time.convertEpochToTime(sessionStudentData[0].start_time)} -{" "}
                {time.convertEpochToTime(sessionStudentData[0].end_time)}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <PrimaryButton
                  variant="outlined"
                  // onClick={() => navigate("/FeedbackOverview")}
                  onClick={() =>
                    setShowOTP({ ...showOTP, open: false, state: "take_otp" })
                  }
                  style={{
                    color: Color.primary1,
                  }}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.primary1,
                    background: Color.white,
                    color: Color.primary1,
                    borderRadius: "8px",
                    marginTop: "32px",
                    // marginLeft: "30px",
                    boxShadow: "none",
                    width: "250px",
                  }}
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `/FeedbackOverview/offline?id=${sessionStudentData[0].order_id}`,
                      {
                        state: {
                          studentData: sessionStudentData[0],
                          process_id: process_id,
                          provideFeedback: false,
                        },
                      }
                    )
                  }
                  // onClick={() =>
                  //   setShowOTP({ ...showOTP, open: false, state: "take_otp" })
                  // }
                  sx={{
                    textTransform: "none",
                    borderColor: Color.primary1,
                    background: Color.primary1,
                    color: Color.primary1,
                    borderRadius: "8px",
                    marginTop: "32px",
                    // marginLeft: "30px",
                    boxShadow: "none",
                    width: "250px",
                    "&:hover": {
                      background: Color.primary1,
                      borderColor: Color.primary1,
                      color: Color.primary1,
                    },
                  }}
                >
                  Start Session
                </PrimaryButton>
              </div>
            </Box>
          )}
        </Box>
      </Modal>

      <Modal
        onClose={() => setError({ ...error, open: false })}
        open={error.open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={{ ...modalStyles, width: "300px", height: "200px" }}>
          <div
            style={{
              fontSize: "20px",
              color: "#F5A536",
              marginBottom: "32px",
            }}
          >
            Warning
          </div>
          <div>{error.heading}</div>
        </Box>
      </Modal>
    </div>
  );
}

export default SessionSchedule;
