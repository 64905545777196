import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
// import { convertBackendDateIntoUTC } from "../../../services/convertBackendDateIntoUTC";
import Tabs from "@mui/joy/Tabs";
import { Modal } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import debounce from "lodash.debounce";
// import { useAVToggle } from "@100mslive/react-sdk";
// import io from "socket.io-client";
// import Loader from "../../../components/Loader/Loader";
import TestYourDevice from "../../../components/Modal/TestYourDevice";
import { AppContext } from "../../../AppContext";
import UpcomingBookingCard from "../../ScheduleComponents/UpcomingBookingCard";
import ScrollButton from "../../ScrollButton/ScrollButton";
import SideNavBar from "../../SideNavBar/SideNavBar";
// import {
//   checkDate,
//   formatConvertermonthfirst,
// } from "../../../services/dateFunctions";
// import TimeService from "../../../services/epochTime";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import Service from "../../../services/httpService";
// import { sort_by } from "../../../services/sortJSONinArray";
// import { extractDateFromDateString } from "../../../services/specialCharReplace";
import { QUERY_PARAM_AUTH_TOKEN } from "../../../common/constants";
import "./UpcomingBooking.css";
const UpcomingBooking = () => {
  // const location = useLocation();
  // console.log("UpcomingBooking ~ userDetails:", userDetails);
  //loader
  // const [loading, setLoading] = useState(true);
  const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(false);
  const [isLoadingRecent, setIsLoadingRecent] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const { toggleVideo } = useAVToggle();
  // const [countingElement, setCountingElement] = useState(false);
  const { formInputs } = useContext(AppContext);
  // console.log("UpcomingBooking ~ formInputs:", formInputs, setFormInputs);
  const [settingType, setSettingType] = useState("upcoming");
  // const [shouldFetchRecent, setShouldFetchRecent] = useState(false);
  // const [upcomingBookingsTab, setUpcomingBookingsTab] = useState(true);
  // const [recentBookingsTab, setRecentBookingsTab] = useState(false);
  //objects
  const navigate = useNavigate();
  const services = new Service();
  // const timeService = new TimeService();
  // const socket = io("https://localhost:3001");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageRecent, setCurrentPageRecent] = useState(1);

  const [slotData, setSlotData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const today = new Date();
  // const today_day = today.toLocaleDateString("en-US", { weekday: "long" });
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  // const tomorrow_day = tomorrow.toLocaleDateString("en-US", {
  //   weekday: "long",
  // });
  const [showModal, setShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const handleUpcomingBookingsTabClick = async (type, page) => {
    if (currentPage === 1 && type === "upcoming") {
      setIsLoadingUpcoming(true);
    } else if (currentPageRecent === 1 && type === "recent") {
      setIsLoadingRecent(true);
    }
    setSettingType(type);
    try {
      const user_type = Cookies.get("user_type");
      const res = await services.get(
        `scheduling/bookedSlot?user_type=${user_type}&limit=4&page=${page}&type=${type}`
      );

      let tempData = res.data.map(booking => ({
        ...booking,
        features: JSON.parse(booking.features),
        mentor_materials: JSON.parse(booking.mentor_material),
        resume: JSON.parse(booking.resume) || { url: "", name: "" },
        other_docs: JSON.parse(booking.other_docs) || { files: [] },
      }));
      if (type === "upcoming") {
        if (currentPage === 1 || page !== currentPage) {
          setSlotData(prevData => {
            const updatedData = prevData.filter(existingItem => {
              const exists = tempData.some(
                newItem => newItem.id === existingItem.id
              );
              return exists ? false : true;
            });
            return [...updatedData, ...tempData];
          });
          setCurrentPage(page);
          if (res.data.length === 0) {
            setIsLoadingUpcoming(false);
          }
          // setIsLoadingUpcoming(false); // Update the currentPage for upcoming
        }
      } else if (type === "recent") {
        if (currentPageRecent === 1 || page !== currentPageRecent) {
          setRecentData(prevData => {
            const updatedData = prevData.filter(existingItem => {
              const exists = tempData.some(
                newItem => newItem.id === existingItem.id
              );
              return exists ? false : true;
            });
            return [...updatedData, ...tempData];
          });
          setCurrentPageRecent(page);
          if (res.data.length === 0) {
            setIsLoadingRecent(false);
          }
          // setIsLoadingRecent(false); // Update the currentPageRecent for recent
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (type === "upcoming" && slotData.length === 0) {
        setIsLoadingUpcoming(false);
      } else if (type === "recent" && recentData.length === 0) {
        setIsLoadingRecent(false);
      }
    }
  };
  // console.log("slot data", slotData);
  // console.log("recent data", recentData);
  const handleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (windowHeight + scrollTop + 10 >= documentHeight && !isFetching) {
      setIsFetching(true);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isFetching) {
      if (settingType === "upcoming") {
        handleUpcomingBookingsTabClick("upcoming", currentPage + 1);
      } else {
        handleUpcomingBookingsTabClick("recent", currentPageRecent + 1);
      }
      setIsFetching(false);
    }
  }, [isFetching]);

  useEffect(() => {
    sessionStorage.clear();

    handleUpcomingBookingsTabClick("upcoming", currentPage);
    // setFormInputs(userDetails);
  }, []);
  // console.log("setting of type", settingType);
  // useEffect(() => {
  //   if (shouldFetchRecent) {
  //     handleUpcomingBookingsTabClick("recent", currentPageRecent);
  //     setShouldFetchRecent(false);
  //   }
  // }, [shouldFetchRecent, currentPageRecent]);
  const handleTabChange = (event, newValue) => {
    if (
      (newValue === 0 && settingType !== "upcoming") ||
      (newValue === 1 && settingType !== "recent")
    ) {
      if (newValue === 0) {
        setSettingType("upcoming");
      } else {
        setSettingType("recent");
        handleUpcomingBookingsTabClick("recent", currentPageRecent);
      }
    }
  };

  // console.log("currentPageRecent", currentPageRecent);
  const closeModal = () => {
    setShowModal(false);
  };

  // console.log("page names", currentPage, settingType);

  const handleLogout = e => {
    // console.log("Logout loader!!1");
    Cookies.remove("token");
    Cookies.remove("user_type");
    // console.log("Logout loader!!2");
    navigate("/");
    // console.log("Logout loader!!3");
  };

  return (
    <div>
      <div className="upcomingBookingMain" style={{ marginTop: "0px" }}>
        <div>
          <SideNavBar formInputs={formInputs} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div style={{ right: "0px", textAlign: "right" }}>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <AccountCircleOutlinedIcon
                  style={{ height: "50px", width: "50px", color: "black" }}
                />
              </Button>

              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                sx={{ borderRadius: "16px" }}
                onClose={handleClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    borderRadius: "16px",
                  },
                }}
                MenuListProps={{
                  sx: {
                    width: "250px",
                    paddingBottom: "2rem",
                  },
                  "aria-labelledby": "basic-button",
                }}
              >
                <p className="firstNameAndLastNAme">
                  {formInputs?.first_name} {formInputs?.last_name}
                </p>
                <p className="firstNameAndLastNAme1">
                  {formInputs?.userType === "mentor" ? "Mentor" : "Student"}
                </p>
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <button className="logoutButton" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </Menu>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
            }}
          >
            <p className="upcomingBookingText">Your Bookings</p>
            <button
              className="Rephrasebutton"
              onClick={() => [setShowModal(true)]}
            >
              Test your Device
            </button>
          </div>
          <Tabs
            aria-label="tabs"
            value={settingType === "upcoming" ? 0 : 1} // Set value based on settingType
            sx={{
              bgcolor: "transparent",
              width: "100%",
              marginBottom: "1.5rem",
            }}
            onChange={handleTabChange}
          >
            <TabList
              disableUnderline
              sx={{
                p: 0.5,
                gap: 0.5,
                borderRadius: "xl",
                bgcolor: "background.level1",
                [`& .${tabClasses.root}[aria-selected="true"]`]: {
                  boxShadow: "sm",
                  bgcolor: "background.surface",
                },
              }}
            >
              <Tab sx={{ width: "50%" }} disableIndicator>
                Upcoming Bookings
              </Tab>
              <Tab sx={{ width: "50%" }} disableIndicator>
                Recent Bookings
              </Tab>
            </TabList>
            <TabPanel value={0}>
              {isLoadingUpcoming && (
                <>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                </>
              )}
              {!isLoadingUpcoming && slotData.length === 0 ? (
                <div className="noUpcomingBookings">
                  <div className="noUpcomingBookingsIcon">
                    <EventBusyIcon
                      sx={{
                        color: "#1C8EA8",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div className="noUpcomingBookingsTexts">
                    <h1 className="noUpcomingBookingsText">
                      No Upcoming Bookings
                    </h1>
                    <p
                      className="fontFamilyFixed"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Book an activity through the app <br />
                      to view your bookings here
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {slotData.map(slot => (
                    <div>
                      <UpcomingBookingCard
                        settingType={settingType}
                        slot={slot}
                        user_type="mentor"
                        isLoadingUpcoming={isLoadingUpcoming}
                        setIsLoadingUpcoming={setIsLoadingUpcoming}
                      />
                    </div>
                  ))}
                </>
              )}
            </TabPanel>
            <TabPanel value={1}>
              {isLoadingRecent && (
                <>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                </>
              )}
              {!isLoadingRecent && recentData.length === 0 ? (
                <div className="noUpcomingBookings">
                  <div className="noUpcomingBookingsIcon">
                    <EventBusyIcon
                      sx={{
                        color: "#1C8EA8",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div className="noUpcomingBookingsTexts">
                    <h1 className="noUpcomingBookingsText">
                      No Recent Bookings
                    </h1>
                    <p
                      className="fontFamilyFixed"
                      style={{ textAlign: "center" }}
                    >
                      Book an activity through the app <br />
                      to view your bookings here
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {recentData.map(slot => (
                    <div>
                      <UpcomingBookingCard
                        settingType={settingType}
                        slot={slot}
                        user_type="mentor"
                        isLoadingRecent={isLoadingRecent}
                        setIsLoadingRecent={setIsLoadingRecent}
                      />
                    </div>
                  ))}
                </>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{ zIndex: "1" }}
      >
        <TestYourDevice
          setShowModal={closeModal}
          token={useSearchParam(QUERY_PARAM_AUTH_TOKEN)}
        />
      </Modal>
      <ScrollButton />
    </div>
  );
};

export default UpcomingBooking;
