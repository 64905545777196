import React from "react";
// import SideNavBarStudent from "../../SideNavBar/SideNavBar";
import SideNavBarStudent from "../../SideNavBar/SideNavBarStudent";
// import MentorPastBookings from "../MentorPastBookings/MentorPastBookings";
const StudentDashboard = () => {
  return (
    <div
      className="OngoingEventsCard"
      style={{ marginTop: "2rem", marginLeft: "10rem" }}
    >
      <SideNavBarStudent />
      <div>
        <div className="topHeadings" style={{ display: "flex" }}>
          <p style={{ marginBottom: "1rem" }}>Dashboard</p>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
