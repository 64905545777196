import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const PlayerWrapper = styled.div`
  position: relative;
`;

const VideoPlayer = ({ url }) => {
  return (
    <PlayerWrapper>
      <ReactPlayer url={url} width="100%" height="100%" controls />
    </PlayerWrapper>
  );
};

export default VideoPlayer;
