import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { Circle } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  // Button,
  ButtonBase,
  Card,
  CardContent,
  // CircularProgress,
  Divider,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { VerticalDivider } from "@100mslive/react-ui";
import Alert from "../../../components/Alert/Alert";
import { ActivityLoader } from "../../../components/Loader/Loader";
import { Color, DivCenter } from "../../../../GlobalStyles";
import { SlotsContext } from "../../../AppContext";
import SideNavBar from "../../SideNavBar/SideNavBar";
import EditModal from "./EditModal";
import { hasBeenSevenDaysSinceSessionEnd } from "../../../services/dateFunctions";
import TimeService from "../../../services/epochTime";
import Service from "../../../services/httpService";
import {
  get_specialCharReplace,
  send_specialCharReplace,
} from "../../../services/specialCharReplace";
import "./FeedbackReviewPage.css";
const modalStyles = {
  position: "absolute",
  top: "20%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  transform: "translate(-50%, -20%)",
  padding: 2,
  backgroundColor: "white",
  boxShadow: 24,
  width: "700px",
  borderRadius: "20px",
};

const actionButtonStyles = {
  display: "none",
  position: "absolute",
  top: "50%",
  right: 8,
  transform: "translateY(-50%)",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
};
const FeedbackReview = () => {
  // const slot = JSON.parse(window.sessionStorage.getItem("slot"));
  // const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  console.log("queryParams", queryParams.get("order_id"));
  const { slotsContextData, setSlotsContextData } = useContext(SlotsContext);
  const [allFeedbackCount, setAllFeedbackCount] = useState(null);
  const [number_of_fields, setNumber_of_fields] = useState(null);
  const [rendering_data, setRendering_data] = useState({
    render_feedback: false,
    render_mcqs: false,
  });
  const [fieldsFeedback, setFieldsFeedback] = useState();
  const [feedbackTabs, setFeedbackTabs] = useState([]);
  const [allFeedbackTabs, setAllFeedbackTabs] = useState([]);
  const [filteredFeedback, setFilteredFeedback] = useState();
  const [mcqData, setMcqData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editField, setEditField] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [currentFilter, setCurrentFilter] = useState("All");
  const time = new TimeService();
  const [loading, setLoading] = useState({
    action_button: false,
    page_loader: false,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    bulletIndex: null,
    fieldName: null,
  });
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const [feedbackSubmitModal, setFeedbackSubmitModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentMcq, setCurrentMcq] = useState(null);

  const handleEditClick = mcq => {
    setCurrentMcq(mcq);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCurrentMcq(null);
  };

  const handleSubmitFeedbackAndMCQ = async updatedMcqData => {
    setLoading(prev => ({ ...prev, action_button: true }));
    let mcqData_body = JSON.parse(JSON.stringify(updatedMcqData));
    let body = {};
    body = {
      order_id: slotsContextData.id,
      mcq_feedback: {
        responses: mcqData_body.map(data => ({
          question: data.question,
          ans: data.ans,
          id: data.id,
        })),
      },
    };
    console.log("sending data:", body);
    try {
      window.location.pathname === "/FeedbackReview/offline"
        ? await services.put("/os/mentor/osMentorMcqFeedback", body)
        : await services.put(`/mentorModule/mentorMcqFeedback`, body);
      // if (res.status) {
      // setFeedbackSubmitModal(true);
      // }
      setMcqData(updatedMcqData);
      setLoading(prev => ({ ...prev, action_button: false }));
    } catch (err) {
      console.error("Error posting feedback and mcq data:", err);
      setLoading(prev => ({ ...prev, action_button: false }));
    }
  };

  const handleSave = updatedMcq => {
    const updatedMcqData = mcqData.map(mcq =>
      mcq.sequence === updatedMcq.sequence ? updatedMcq : mcq
    );
    console.log("updatedMcqData", updatedMcqData);
    setCurrentMcq(updatedMcqData);
    handleSubmitFeedbackAndMCQ(updatedMcqData);
  };

  console.log("fieldsFeedback", fieldsFeedback, mcqData);
  const handleEditCurrentIndex = (fieldName, index, currentDescription) => {
    setEditField(fieldName);
    setEditDescription(send_specialCharReplace(currentDescription));
    setEditIndex(index);
    setModalOpen(true);
  };
  console.log("hoveredIndex", hoveredIndex);

  const handleDeleteModalClick = (fieldName, index) => {
    setDeleteModal({
      isOpen: true,
      bulletIndex: index,
      fieldName: fieldName,
    });
  };

  const handleDeleteBulletModal = data => {
    if (data === "Yes" && allFeedbackCount >= number_of_fields) {
      console.log("not satiafies");
      handleDeleteCurrentIndex(deleteModal.bulletIndex, deleteModal.fieldName);
    } else {
      console.log("satiafies");

      setDeleteModal({
        isOpen: false,
        bulletIndex: null,
        fieldName: null,
      });
    }
  };

  const handleDeleteCurrentIndex = (index, fieldName) => {
    console.log("what is here,", fieldName, index);
    setFieldsFeedback(prevFeedback => {
      const updatedFeedback = { ...prevFeedback };
      updatedFeedback[fieldName]?.splice(index, 1);
      if (updatedFeedback[fieldName]?.length === 0) {
        delete updatedFeedback[fieldName];
      }
      let updating_count = getTotalFeedbackCount(updatedFeedback);
      setAllFeedbackCount(updating_count);
      handleSubmitFeedbackFields(updatedFeedback);
      return updatedFeedback;
    });
    setDeleteModal({
      isOpen: false,
      bulletIndex: null,
      fieldName: null,
    });
  };

  const handleSaveEditField = fieldName => {
    setFieldsFeedback(prevFeedback => {
      const updatedFeedback = { ...prevFeedback };

      if (editField === fieldName) {
        if (editIndex !== null) {
          updatedFeedback[fieldName][editIndex] =
            send_specialCharReplace(editDescription);
        } else {
          updatedFeedback[fieldName] = updatedFeedback[fieldName] || [];
          updatedFeedback[fieldName].push(
            send_specialCharReplace(editDescription)
          );
        }
      } else {
        if (editField && editIndex !== null) {
          updatedFeedback[editField].splice(editIndex, 1);
          if (updatedFeedback[editField].length === 0) {
            delete updatedFeedback[editField];
          }
        }
        updatedFeedback[fieldName] = updatedFeedback[fieldName] || [];
        updatedFeedback[fieldName].push(
          send_specialCharReplace(editDescription)
        );
      }
      handleSubmitFeedbackFields(updatedFeedback);
      let updating_count = getTotalFeedbackCount(updatedFeedback);
      setAllFeedbackCount(updating_count);
      return updatedFeedback;
    });
    setModalOpen(false);
    setEditField("");
    setEditDescription("");
    setEditIndex(null);
  };

  const handleAddNewFeedback = () => {
    setEditField("");
    setEditDescription("");
    setEditIndex(null);
    setModalOpen(true);
  };
  const services = new Service();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(prev => ({ ...prev, page_loader: true }));
      const response = await fetchGetRequest(queryParams);
      console.log("response", response);
      if (response && response.data) {
        const slotsGetter = response.data[0];
        if (window.location.pathname === "/FeedbackReview/offline") {
          setSlotsContextData({
            ...response.data[0],
          });
        } else {
          setSlotsContextData({
            ...slotsGetter,
            duration: response.sku_info.duration ?? 0,
            sku_name: response.sku_info.sku_name,
            mentor_first_name: response.mentor_info.first_name,
            mentor_last_name: response.mentor_info.last_name,
            student_first_name: response.student_info.first_name,
            student_last_name: response.student_info.last_name,
          });
        }
        await handleMainPFeedbackEffect(slotsGetter);
      }
      setLoading(prev => ({ ...prev, page_loader: false }));
    };
    fetchData();
  }, []);

  const groupFeedback = parsed => {
    if (!parsed || !Array.isArray(parsed.responses)) {
      console.error("Invalid parsed data:", parsed);
      return {};
    }

    console.log("parsed", parsed);
    console.log("currentFilter", currentFilter);

    return parsed.responses.reduce((acc, { field_name, description }) => {
      if (!acc[field_name]) {
        acc[field_name] = [];
      }
      description?.bullets?.forEach(bullet => {
        const modifiedBullet = get_specialCharReplace(bullet);
        acc[field_name].push(modifiedBullet);
      });
      return acc;
    }, {});
  };

  const fetchFeedbackFieldsCount = async slotsContextData => {
    try {
      const res = await services.get(
        `mentorModule/mentorFeedbackFields?act_type_id=${
          slotsContextData?.act_type_id ?? queryParams.get("order_id")
        }`
      );
      setAllFeedbackTabs(res.data);
      return res.no_of_fields;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };

  const fetchFeedbackFields = async slotsContextData => {
    try {
      const res =
        window.location.pathname === "/FeedbackReview/offline"
          ? await services.get(
              `/os/mentor/osMentorFeedback?order_id=${
                slotsContextData?.id ?? queryParams.get("order_id")
              }`
            )
          : await services.get(
              `mentorModule/mentorFeedback?order_id=${
                slotsContextData?.id ?? queryParams.get("order_id")
              }`
            );
      return res.data;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };
  const fetchFeedbackMCQ = async slotsContextData => {
    try {
      const res = await services.get(
        `mentorModule/mentorFeedbackMcq?act_type_id=${
          slotsContextData?.act_type_id ?? queryParams.get("order_id")
        }`
      );
      return res.data;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };

  const handleFilterChange = filter => {
    setCurrentFilter(filter);
    if (filter === "All") {
      setFilteredFeedback(fieldsFeedback);
      setRendering_data(prev => ({
        ...prev,
        render_feedback: true,
        render_mcqs: false,
      }));
    } else if (filter === "Review Ratings") {
      setRendering_data(prev => ({
        ...prev,
        render_mcqs: true,
        render_feedback: false,
      }));
      // setRendering_data(prev => ({ ...prev, render_feedback: false }));
    } else {
      setFilteredFeedback({ [filter]: fieldsFeedback[filter] });
      setRendering_data(prev => ({
        ...prev,
        render_feedback: true,
        render_mcqs: false,
      }));
    }
  };

  // const handleChange = (e, index, option) => {
  //   const { name } = e.target;
  //   const temp_feedback_data = [...mcqData];
  //   console.log("name", name);
  //   temp_feedback_data[index].ans = option;
  //   setMcqData(temp_feedback_data);
  // };

  const fetchGetRequest = async queryParams => {
    try {
      console.log("updating the session");
      let res =
        window.location.pathname === "/FeedbackReview/offline"
          ? await services.get(
              `/os/mentor/osOrderDetails?order_id=${queryParams.get(
                "order_id"
              )}`
            )
          : await services.get(
              `/superadmin/order?order_id=${queryParams.get("order_id")}`
            );
      window.sessionStorage.setItem("slot", JSON.stringify(res.data[0]));
      if (window.location.pathname === "/FeedbackReview/offline") {
        setSlotsContextData({
          ...res.data[0],
        });
      } else {
        const session_guidelines = res.session_guidelines;
        setSlotsContextData({
          ...res.data[0],
          session_guidelines: session_guidelines,
          duration: res.sku_info.duration ?? 0,
          sku_name: res.sku_info.sku_name,
          mentor_first_name: res.mentor_info.first_name,
          mentor_last_name: res.mentor_info.last_name,
          student_first_name: res.student_info.first_name,
          student_last_name: res.student_info.last_name,
        });
      }
      return res;
    } catch (err) {
      console.error(err.response.data.message);
    }
  };

  const handleMainPFeedbackEffect = async slotsContextData => {
    //[GET] fieldsFeedback fields
    if (slotsContextData) {
      let temp_feedback_fields = await fetchFeedbackFields(slotsContextData);
      let temp_feedback_mcq = await fetchFeedbackMCQ(slotsContextData);
      let temp_feedback_fields_count = await fetchFeedbackFieldsCount(
        slotsContextData
      );
      console.log("temp_feedback_fields", temp_feedback_fields);
      console.log("temp_feedback_mcq", temp_feedback_mcq);
      console.log("nmasbjv", temp_feedback_fields_count);
      setNumber_of_fields(temp_feedback_fields_count);
      if (
        temp_feedback_fields &&
        temp_feedback_fields[0]?.mcq_feedback !== null &&
        temp_feedback_fields[0]?.mcq_feedback !== "null"
      ) {
        let mcq_feedback_parse = JSON.parse(
          temp_feedback_fields[0]?.mcq_feedback
        ).responses;
        console.log("mcq_feedback_parse", mcq_feedback_parse);
        mcq_feedback_parse.forEach(obj => {
          const matching_id = temp_feedback_mcq.find(
            item => item.id === obj.id
          );
          if (matching_id) {
            obj.options = JSON.parse(matching_id.options).options;
            obj.sequence = matching_id.sequence;
          }
        });
        console.log("mcq_feedback_parse", mcq_feedback_parse);

        setMcqData(mcq_feedback_parse);
      } else {
        temp_feedback_mcq.forEach(
          data => (
            (data.options = JSON.parse(data.options).options), (data.ans = "")
          )
        );
        setMcqData(temp_feedback_mcq);
      }

      let parsed = groupFeedback(
        JSON.parse(temp_feedback_fields[0].fields_feedback)
      );
      console.log("parsed", parsed);
      setFieldsFeedback(parsed);
      setFilteredFeedback(parsed);
      setRendering_data(prev => ({ ...prev, render_feedback: true }));
      setFeedbackTabs(Object.keys(parsed));
      let count = getTotalFeedbackCount(parsed);
      setAllFeedbackCount(count);
      console.log(
        " temp_feedback_fields ",
        temp_feedback_fields,
        Object.keys(parsed)
      );
    }
    setLoading(prev => ({ ...prev, page_loader: false }));
  };
  const handleSubmitMCQ = () => {
    navigate("/");
  };
  const handleSubmitFeedbackModal = data => {
    if (data === "Yes") handleSubmitMCQ();
    else setFeedbackSubmitModal(false);
  };

  const handleSubmitFeedbackFields = async filteredFeedback => {
    setLoading(prev => ({ ...prev, action_button: true }));

    // let mcqData_body = JSON.parse(JSON.stringify(mcqData));
    console.log("filteredFeedback", filteredFeedback);
    setFilteredFeedback(filteredFeedback);
    setFeedbackTabs(Object.keys(filteredFeedback));
    let body = {};
    // if (tabData_body.length > 0) {
    body = {
      order_id: slotsContextData.id,
      fields_feedback: {
        responses: Object.entries(filteredFeedback).map(
          ([fieldName, descriptions], index) => ({
            field_name: fieldName,
            description: {
              bullets: descriptions.map(item => send_specialCharReplace(item)),
            },
          })
        ),
      },
    };

    console.log("sending data:", body);

    try {
      window.location.pathname === "/FeedbackReview/offline"
        ? await services.post(`/os/mentor/osMentorFieldsFeedback`, body)
        : await services.post(`/mentorModule/mentorFieldsFeedback`, body);
      setLoading(prev => ({ ...prev, action_button: false }));
    } catch (err) {
      console.error("Error posting feedback and mcq data:", err);
      setLoading(prev => ({ ...prev, action_button: false }));
    }
  };

  const getTotalFeedbackCount = fieldsFeedback => {
    let totalCount = 0;
    Object.values(fieldsFeedback)?.forEach(fieldFeedback => {
      totalCount += fieldFeedback?.length;
    });
    return totalCount;
  };

  console.log(
    "allFeedbackCount",
    allFeedbackCount,
    number_of_fields,
    allFeedbackCount < number_of_fields
  );

  return (
    <div>
      {loading.page_loader && <ActivityLoader show={loading.page_loader} />}
      <SideNavBar />
      <div className="feedbackReviewPage">
        {slotsContextData && fieldsFeedback && (
          <div>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
              <Typography sx={{ fontSize: "30px" }}>
                Feedback of {slotsContextData.student_first_name}{" "}
                {slotsContextData.student_last_name} (After Session)
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {slotsContextData.college_name},{" "}
                {slotsContextData.user_group_name},{" "}
                {slotsContextData.batch_type_name}
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {`${time.convertEpochToDate(
                  slotsContextData?.start_time
                )}, ${time.convertEpochToTime(slotsContextData?.start_time)}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "90%",
                flexWrap: "wrap",
                gap: "8px",
                marginTop: "6px",
              }}
            >
              <ButtonBase>
                <Box
                  onClick={() => handleFilterChange("All")}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    background:
                      currentFilter === "All" ? Color.primary1 : Color.white,
                    color:
                      currentFilter === "All" ? Color.white : Color.primary1,
                    border: `1px solid ${Color.primary1}`,
                    fontSize: "14px",
                    height: "32px",
                    width: "auto",
                    minWidth: "112px",
                    p: "10px",
                  }}
                >
                  All ({allFeedbackCount})
                </Box>
              </ButtonBase>
              {feedbackTabs.map((field, index) => (
                <ButtonBase key={index}>
                  <Box
                    onClick={() => handleFilterChange(field)}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      background:
                        currentFilter === field ? Color.primary1 : Color.white,
                      color:
                        currentFilter === field ? Color.white : Color.primary1,
                      border: `1px solid ${Color.primary1}`,
                      fontSize: "14px",
                      height: "32px",
                      width: "auto",
                      minWidth: "112px",
                      p: "10px",
                      gap: "16px",
                    }}
                  >
                    {field} ({fieldsFeedback[field]?.length})
                  </Box>
                </ButtonBase>
              ))}
              {/* review mcq  */}
              <VerticalDivider css={{ height: "auto" }} />
              <ButtonBase>
                <Box
                  onClick={() => handleFilterChange("Review Ratings")}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    background:
                      currentFilter === "Review Ratings"
                        ? Color.primary1
                        : Color.white,
                    color:
                      currentFilter === "Review Ratings"
                        ? Color.white
                        : Color.primary1,
                    border: `1px solid ${Color.primary1}`,
                    fontSize: "14px",
                    height: "32px",
                    width: "auto",
                    minWidth: "112px",
                    p: "10px",
                  }}
                >
                  Review Ratings
                </Box>
              </ButtonBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: Color.accent, fontSize: "14px" }}>
                (Hover on a specific fieldsFeedback to edit)
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", marginRight: 1 }}>
                  Add new Feedback
                </Typography>
                <ButtonBase>
                  <Box
                    onClick={handleAddNewFeedback}
                    sx={{
                      ...DivCenter,
                      borderRadius: "50%",
                      background: Color.primary1,
                      color: Color.white,
                      fontSize: "14px",
                      height: "32px",
                      width: "32px",
                      p: "10px",
                    }}
                  >
                    <AddIcon />
                  </Box>
                </ButtonBase>
              </Box>
            </Box>
            {/* {rendering_data.render_feedback && (
              <>
                {Object.entries(filteredFeedback).map(
                  ([fieldName, descriptions], index) => (
                    <div key={index}>
                      <Typography
                        sx={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          fontWeight: "medium",
                        }}
                        fontSize={20}
                      >
                        {fieldName}
                      </Typography>
                      {descriptions.map((description, idx) => (
                        <React.Fragment key={`${fieldName}-${idx}`}>
                          <Card
                            sx={{
                              boxShadow: "none",
                              cursor: "pointer",
                              position: "relative",
                              "&:hover": {
                                backgroundColor: Color.neutralLightGrey,
                              },
                              "&:hover .action-buttons": {
                                display: "flex",
                              },
                            }}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "6px",
                                paddingBottom: "15px !important",
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  width: "90%",
                                }}
                              >
                                <FiberManualRecordIcon
                                  fontSize="inherit"
                                  sx={{
                                    marginRight: 1,
                                    fontSize: "10px",
                                    marginTop: "8px",
                                  }}
                                />
                                <Typography sx={{ flex: 1 }}>
                                  {get_specialCharReplace(description)}
                                </Typography>
                              </Box>
                              {hasBeenSevenDaysSinceSessionEnd(
                                slotsContextData.end_time
                              ) < 7 && (
                                <Box
                                  className="action-buttons"
                                  sx={actionButtonStyles}
                                  style={{ display: "flex" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleEditCurrentIndex(
                                        fieldName,
                                        idx,
                                        description
                                      )
                                    }
                                    style={{
                                      ...DivCenter,
                                      borderRadius: "8px",
                                      color: Color.primary1,
                                      border: `1px solid ${Color.primary1}`,
                                      fontSize: "14px",
                                      height: "30px",
                                      width: "auto",
                                      minWidth: "70px",
                                      textTransform: "none",
                                      p: "10px",
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <VerticalDivider
                                    css={{
                                      mx: "$1",
                                      color: Color.neutralMidGrey,
                                    }}
                                  />
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() =>
                                      handleDeleteModalClick(fieldName, idx)
                                    }
                                    sx={{
                                      color: Color.meetRed,
                                      padding: "0px",
                                      cursor: "pointer",
                                      margin: "auto 0",
                                    }}
                                  />
                                </Box>
                              )}
                            </CardContent>
                          </Card>
                          {idx !== descriptions.length - 1 && (
                            <Divider sx={{ color: Color.neutralMidGrey }} />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )
                )}
              </>
            )} */}
            {rendering_data.render_feedback && (
              <>
                {Object.entries(filteredFeedback).map(
                  ([fieldName, descriptions], index) => (
                    <div key={index}>
                      <Typography
                        sx={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          fontWeight: "medium",
                        }}
                        fontSize={20}
                      >
                        {fieldName}
                      </Typography>
                      {descriptions.map((description, idx) => (
                        <React.Fragment key={`${fieldName}-${idx}`}>
                          <Card
                            sx={{
                              boxShadow: "none",
                              cursor: "pointer",
                              position: "relative",
                              "&:hover": {
                                backgroundColor: Color.neutralLightGrey,
                              },
                              "&:hover .action-buttons": {
                                opacity: 1,
                              },
                            }}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "6px",
                                paddingBottom: "15px !important",
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  width: "90%",
                                }}
                              >
                                <FiberManualRecordIcon
                                  fontSize="inherit"
                                  sx={{
                                    marginRight: 1,
                                    fontSize: "10px",
                                    marginTop: "8px",
                                  }}
                                />
                                <Typography sx={{ flex: 1 }}>
                                  {get_specialCharReplace(description)}
                                </Typography>
                              </Box>
                              {hasBeenSevenDaysSinceSessionEnd(
                                slotsContextData.end_time
                              ) < 7 && (
                                <Box
                                  className="action-buttons"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    opacity: 0,
                                    transition: "opacity 0.3s ease-in-out",
                                  }}
                                >
                                  <button
                                    onClick={() =>
                                      handleEditCurrentIndex(
                                        fieldName,
                                        idx,
                                        description
                                      )
                                    }
                                    style={{
                                      ...DivCenter,
                                      borderRadius: "8px",
                                      color: Color.primary1,
                                      border: `1px solid ${Color.primary1}`,
                                      fontSize: "14px",
                                      height: "30px",
                                      width: "auto",
                                      minWidth: "70px",
                                      textTransform: "none",
                                      padding: "10px",
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <VerticalDivider
                                    css={{
                                      mx: "$1",
                                      color: Color.neutralMidGrey,
                                    }}
                                  />
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() =>
                                      handleDeleteModalClick(fieldName, idx)
                                    }
                                    sx={{
                                      color: Color.meetRed,
                                      padding: "0px",
                                      cursor: "pointer",
                                      margin: "auto 0",
                                    }}
                                  />
                                </Box>
                              )}
                            </CardContent>
                          </Card>
                          {idx !== descriptions.length - 1 && (
                            <Divider sx={{ color: Color.neutralMidGrey }} />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )
                )}
              </>
            )}

            {rendering_data.render_mcqs && (
              <>
                <Typography fontSize={24}>Student Progress</Typography>
                {mcqData.map((mcq, index) => (
                  <React.Fragment key={index}>
                    <Card
                      sx={{
                        boxShadow: "none",
                        cursor: "pointer",
                        position: "relative",
                        background:
                          hoveredIndex === index
                            ? Color.neutralLightGrey
                            : "transparent",
                        transition: "background 0.3s ease-in-out",
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "6px",
                          paddingBottom: "15px !important",
                          position: "relative",
                          transition: "background 0.3s ease-in-out",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            width: "90%",
                          }}
                        >
                          <Typography sx={{ marginRight: ".5rem" }}>
                            {mcq.sequence}.{" "}
                          </Typography>
                          <Typography sx={{ flex: 1 }}>
                            {mcq.question}
                          </Typography>
                        </Box>
                        {hoveredIndex === index ? (
                          <Box
                            className="action-buttons"
                            sx={actionButtonStyles}
                            style={{ display: "flex" }}
                          >
                            <button
                              onClick={() => handleEditClick(mcq)}
                              style={{
                                ...DivCenter,
                                borderRadius: "8px",
                                color: Color.primary1,
                                border: `1px solid ${Color.primary1}`,
                                fontSize: "14px",
                                height: "30px",
                                width: "auto",
                                minWidth: "70px",
                                textTransform: "none",
                                padding: "10px",
                              }}
                            >
                              Edit
                            </button>
                          </Box>
                        ) : (
                          <Box
                            className="action-buttons"
                            sx={actionButtonStyles}
                            style={{ display: "flex" }}
                          >
                            {mcq.ans}
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </React.Fragment>
                ))}
              </>
            )}

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
              <Box sx={modalStyles}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "24px", fontWeight: "medium" }}>
                    {editField ? "Edit Feedback" : "New Feedback"}
                  </Typography>
                  <CloseIcon onClick={() => setModalOpen(false)} />
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${Color.blueBlack}`,
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingLeft: "10px",
                      fontSize: "14px",
                      fontWeight: "medium",
                    }}
                  >
                    {editField}
                  </Typography>
                  <TextField
                    multiline
                    placeholder="Click to type your fieldsFeedback"
                    variant="standard"
                    value={get_specialCharReplace(editDescription)}
                    onChange={e => setEditDescription(e.target.value)}
                    style={{
                      color:
                        window.location.path === "/FeedbackOverview"
                          ? Color.blueBlack
                          : "#fff",

                      width: "100%",
                      paddingLeft: "5px",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ alignItems: "flex-start", marginTop: "10px" }}
                        >
                          <Circle
                            sx={{
                              fontSize: "8px",
                              color: Color.neutralMidGrey,
                            }}
                          />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      style: {
                        color: Color.blueBlack,
                        alignItems: "flex-start",
                      },
                    }}
                  />
                </Box>
                {editDescription !== "" && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: Color.accent,
                      }}
                    >
                      Select the relevant tag to save the Feedback
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {allFeedbackTabs.map((field, index) => (
                        <ButtonBase key={index}>
                          <Box
                            onClick={() =>
                              handleSaveEditField(field.field_name)
                            }
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "8px",
                              background:
                                editField === field
                                  ? Color.white
                                  : Color.primary1,
                              color:
                                editField === field
                                  ? Color.primary1
                                  : Color.white,
                              border: `1px solid ${Color.primary1}`,
                              fontSize: "14px",
                              height: "32px",
                              width: "auto",
                              minWidth: "112px",
                              p: "10px",
                            }}
                          >
                            {field.field_name}
                          </Box>
                        </ButtonBase>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            </Modal>
            {/* <Box
              sx={{
                ...DivCenter,
                width: "100%",
                height: "10%",
                gap: "16px",
                marginBottom: "5rem",
              }}
            >
              <Button
                variant="contained"
                // disabled={mcqData.some(mcq => !mcq.ans)}
                onClick={handleSubmitFeedbackAndMCQ}
                sx={{
                  ...styles.button_styles.contained,
                  width: "200px",
                  marginBottom: "5rem",
                }}
              >
                {loading.action_button ? (
                  <CircularProgress size={24} sx={{ color: Color.white }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box> */}
          </div>
        )}
      </div>
      <Alert
        alert={feedbackSubmitModal}
        setAlert={setFeedbackSubmitModal}
        heading="Confirmation"
        body={<p>Feedback Submitted Successfully!</p>}
        responseAlert={handleSubmitFeedbackModal}
      />
      <Alert
        alert={deleteModal.isOpen}
        setAlert={isOpen =>
          setDeleteModal(prevState => ({ ...prevState, isOpen }))
        }
        origin="delete"
        container={
          allFeedbackCount <= number_of_fields ? "Got it" : "handleDelete"
        }
        image={
          allFeedbackCount <= number_of_fields
            ? "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Frame+2611108.png"
            : "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Frame+2611108t.png"
        }
        heading="Confirmation"
        body={
          allFeedbackCount <= number_of_fields ? (
            <p>
              To delete this feedback, there must be more than 15 feedback
              points. <br />
              Please add more points or edit the selected feedback instead.
            </p>
          ) : (
            <p>
              This is an irreversible step. We can't retrieve the data once
              deleted. <br />
              Do you still want to delete it?
            </p>
          )
        }
        responseAlert={handleDeleteBulletModal}
      />
      {currentMcq && (
        <EditModal
          open={openModal}
          handleClose={handleModalClose}
          mcq={currentMcq}
          handleSave={handleSave}
        />
      )}
    </div>
  );
};

export default FeedbackReview;
