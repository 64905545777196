import styles from "./ExpertConnectDIAGFinal.module.css";

const ExpertConnectDIAGFinal = () => {
  return (
    <div className={styles.groupParent}>
      <div className={styles.groupContainer}>
        <div className={styles.ellipseParent}>
          <img
            className={styles.groupChild}
            alt=""
            src="/ExpertConnectDIAGFinal/ellipse-134.svg"
          />
          <div className={styles.jdUnderstanding}>
            <p className={styles.jd}>JD</p>
            <p className={styles.jd}>Understanding</p>
          </div>
        </div>
        <div className={styles.ellipseGroup}>
          <img
            className={styles.groupChild}
            alt=""
            src="/ExpertConnectDIAGFinal/ellipse-134.svg"
          />
          <div className={styles.openEndedDiscussionsContainer}>
            <p className={styles.jd}>Open Ended</p>
            <p className={styles.jd}>Discussions</p>
          </div>
        </div>
        <div className={styles.ellipseContainer}>
          <img
            className={styles.groupInner}
            alt=""
            src="/ExpertConnectDIAGFinal/ellipse-1341.svg"
          />
          <div className={styles.industryExperts}>
            <p className={styles.jd}>Industry</p>
            <p className={styles.jd}>Experts</p>
          </div>
        </div>
        <div className={styles.groupDiv}>
          <img
            className={styles.groupChild}
            alt=""
            src="/ExpertConnectDIAGFinal/ellipse-134.svg"
          />
          <div className={styles.followUpSessionsContainer}>
            <p className={styles.jd}>Follow up</p>
            <p className={styles.jd}>Sessions</p>
          </div>
        </div>
        <div className={styles.ellipseParent1}>
          <img
            className={styles.groupChild}
            alt=""
            src="/ExpertConnectDIAGFinal/ellipse-134.svg"
          />
          <div className={styles.roleAndProfileContainer}>
            <p className={styles.jd}>Role and Profile</p>
            <p className={styles.jd}>Discussions</p>
          </div>
        </div>
        <div className={styles.ellipseParent2}>
          <img
            className={styles.groupChild}
            alt=""
            src="/ExpertConnectDIAGFinal/ellipse-134.svg"
          />
          <div className={styles.personalizedReview}>
            <p className={styles.jd}>Personalized</p>
            <p className={styles.jd}>Review</p>
          </div>
        </div>
        <img
          className={styles.vectorIcon}
          alt=""
          src="/ExpertConnectDIAGFinal/vector-20.svg"
        />
        <img
          className={styles.groupChild3}
          alt=""
          src="/ExpertConnectDIAGFinal/vector-21.svg"
        />
        <img
          className={styles.groupChild4}
          alt=""
          src="/ExpertConnectDIAGFinal/vector-23.svg"
        />
        <img
          className={styles.groupChild5}
          alt=""
          src="/ExpertConnectDIAGFinal/vector-22.svg"
        />
        <img
          className={styles.groupChild6}
          alt=""
          src="/ExpertConnectDIAGFinal/vector-24.svg"
        />
      </div>
    </div>
  );
};

export default ExpertConnectDIAGFinal;
