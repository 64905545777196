import * as React from "react";
// import { Color } from "../../../GlobalStyles";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
export function PlaceholderModal(props) {
  const style = {
    position: "absolute",
    top: "4%",
    left: "29%",
    // transform: "translate(-50%, -50%)",
    width: "20%",
    border: "1px solid #F4B537",
    borderRadius: "20px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
    p: 4,
    bgcolor: "#FFF4DD",
    maxWidth: "350px",
    // zIndex: 1, // Ensure it's above other content
  };
  const customBackdropStyles = {
    backdropFilter: "none",
    background: "transparent", // Remove blur effect
  };

  return (
    <div>
      <Modal
        open={props.open}
        BackdropProps={{
          sx: customBackdropStyles,
        }}
        disableAutoFocus // Prevents focusing on modal content when opened
        disableEnforceFocus
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                background: "#fff",
                borderRadius: "20px",
                padding: ".3rem",
                width: "80px",
              }}
            >
              <img
                style={{ marginRight: ".5rem", marginLeft: ".5rem" }}
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/idea+1.jpg"
                alt="Idea"
              />
              <Typography>Tips</Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: ".3rem" }}>{props.modalContent}</Box>
        </Box>
      </Modal>
    </div>
  );
}
