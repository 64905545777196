import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { init } from "zipyai";
import { store } from "./store/store";
import App from "./App";

if (process.env.REACT_APP_ZIPY_KEY) {
  init(process.env.REACT_APP_ZIPY_KEY);
}

const root = createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
