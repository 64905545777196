import React, { useEffect, useRef, useState } from "react";
import Forward10Icon from "@mui/icons-material/Forward10";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Replay10Icon from "@mui/icons-material/Replay10";
import { Box, IconButton, Slider, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
const formatTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
};

const AudioPlayer = ({ url }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const playAudio = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const pauseAudio = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const fastForwardAudio = () => {
    audioRef.current.currentTime += 10;
  };

  const backForwardAudio = () => {
    audioRef.current.currentTime -= 10;
  };

  const updateProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleSliderChange = (event, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography sx={{ width: "60%", textAlign: "left" }}>
        Session Recordings
      </Typography>
      <audio ref={audioRef} src={url} />
      <Box sx={{ display: "flex", alignItems: "center", width: "60%", mt: 2 }}>
        <Typography variant="body2">{formatTime(currentTime)}</Typography>
        <Slider
          value={currentTime}
          max={duration}
          onChange={handleSliderChange}
          sx={{ mx: 2, flex: 1, color: Color.neutralMidGrey }}
        />
        <Typography variant="body2">{formatTime(duration)}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", mt: 2, alignItems: "center" }}>
        {/* <IconButton onClick={backForwardAudio}> */}
        <Replay10Icon
          sx={{ fontSize: 40, cursor: "pointer" }}
          onClick={backForwardAudio}
        />
        {/* </IconButton> */}
        <IconButton onClick={isPlaying ? pauseAudio : playAudio}>
          {isPlaying ? (
            <PauseCircleIcon sx={{ fontSize: 75 }} />
          ) : (
            <PlayCircleIcon sx={{ fontSize: 75 }} />
          )}
        </IconButton>
        {/* <IconButton  > */}
        <Forward10Icon
          sx={{ fontSize: 40, cursor: "pointer" }}
          onClick={fastForwardAudio}
        />
        {/* </IconButton> */}
      </Box>
    </Box>
  );
};

export default AudioPlayer;
