import { Box } from "@100mslive/react-ui";
import React, { useContext } from "react";
import { Chat } from "../components/Chat/Chat";
import { SlotsContext } from "../AppContext";

const SidePane = () => {
  const { slotsContextData } = useContext(SlotsContext)
  return (
    slotsContextData?.isChatOpen &&
    <Box
      css={{
        position: "absolute",
        w: "$100",
        h: "90%",
        p: "$10",
        bg: "$surfaceDefault",
        // r: "$1",
        top: 0,
        right: "$0",
        zIndex: 10,
        boxShadow: "$md",
        "@lg": {
          w: "100%",
          right: 0,
          position: "fixed",
          bottom: 0,
          height: "unset",
        },
      }}
    >
      <Chat />
    </Box>
  );
};

export default SidePane;
