import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  CheckCircle,
  DeleteForeverOutlined,
  DescriptionOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  // Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie"; // Import Cookies if necessary
import {
  ActivityLoader,
  SmallPageLoader,
} from "../../components/Loader/Loader";
import Alert from "../../components/Modal/Alert";
// import ErrorIcon from "@mui/icons-material/Error";
import {
  Color,
  DivCenter,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "../../../GlobalStyles";
import ProfilePictureSelection from "../ImageCropperComponent/profilePictureSelection";
import SideNavBarStudent from "../SideNavBar/SideNavBarStudent";
import { myProfileData } from "./MyProfileJSON";
import { AboutYouValidations } from "./Validations";
// import { backendDataJSON } from "./MyProfileBackendDataJSON";
import { DialogueBox } from "../../services/DialogueBox";
import { ellipsisFunction } from "../../services/ellipsisFunction";
import { UnauthorizedLoginAgainModal } from "../../services/ErrorFiles";
import Service from "../../services/httpService";
import {
  get_specialCharReplace,
  send_specialCharReplace,
} from "../../services/specialCharReplace";
import "./MyProfile.css";
const MyProfile = props => {
  //mainData ~ Profile Data
  const service = new Service();
  const navigate = new useNavigate();
  const [loading, setLoading] = useState({
    page_loading: true,
    activity_loading: false,
  });
  const [error, setError] = useState({ unauthorized: false });
  const [mainData, setMainData] = useState([...myProfileData]);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [picture, setPicture] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );
  const [saveLoader, setSaveLoader] = useState(false);
  const [movePicture, setMovePicture] = useState();
  console.log({ picture, movePicture });
  const [imageErrorModal, setImageErrorModal] = useState(false);
  const [modalData, setModalData] = useState({ show: false, heading: "" });
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    onClose: () => setDeleteModal(prev => ({ ...prev, show: false })),
    deleteBodyData: {
      item_id: null,
      group_id: null,
      sec_type_id: null,
    },
  });
  const [verificationId, setPoVerificationId] = useState(null);
  const [newUploadedFiles, setNewUploadedFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [profileVerification, setProfileVerification] = useState(true);

  const [dialogue, setDialogue] = useState({
    open: false,
    content: "",
    actions: {
      label: "OK",
      handler: () => {},
      color: Color.neutralBlack,
      variant: "contained",
    },
  });
  const closeDialogue = () => {
    setDialogue(prev => ({ ...prev, open: false }));
  };

  const [activeIndexes, setActiveIndexes] = useState({
    sectionIndex: 0,
    ssIndex: 0,
    itemIndex: 0,
    fieldIndex: 0,
  });
  console.log("activeIndexes:", activeIndexes);

  const [currentFields, setCurrentFields] = useState([]);
  console.log("MyProfile ~ currentFields:", currentFields);
  const [userDetails, setUserDetails] = useState({
    user_id: null,
    full_name: "",
    roll_no: null,
    ug_id: null,
  });
  const [openedSections, setOpenedSections] = useState([0]);
  const [extraData, setExtraData] = useState({
    sectors: [{ id: null, value: "" }],
    majors: [{ id: null, value: "" }],
    minors: [{ id: null, value: "" }],
    userDetails: {
      user_id: null,
      roll_no: null,
      first_name: "",
      last_name: "",
      ug_id: null,
    },
  });

  //editable flag
  const [pageValidators, setPageValidators] = useState({
    editable: true,
    isVerifiedChange: true,
  });

  const getpoBatchMappingGetter = async ug_id => {
    const poBatchMapping = await service.get(
      `/po/ca/poBatchMapping?ug_id=${ug_id}`
    );

    return poBatchMapping.data;
  };

  useEffect(() => {
    setLoading(prev => ({ ...prev, page_loading: true }));
    let temp_mainData = [...mainData];
    setUserDetails(prev => ({
      ...prev,
      full_name:
        temp_mainData[0].subSections[0].items[0].fields[0].value ??
        extraData.userDetails.first_name +
          " " +
          ([null, "null"].includes(extraData.userDetails.last_name)
            ? ""
            : extraData.userDetails.last_name),
    }));
    setLoading(prev => ({ ...prev, page_loading: false }));
  }, [mainData[0].subSections[0].items[0].fields[0].value]);

  useEffect(() => {
    const handleMainEffect = async () => {
      setLoading(prev => ({ ...prev, page_loading: true }));
      const placeO_allowed = await getAllowPlaceO();
      if (!placeO_allowed) navigate("/");
      const { first_name, last_name, user_id } = await fetchUserDetails();

      const sector_data = await fetchSectors();
      const { major_data, minor_data } = await fetchMajorMinor(user_id);

      //fetch all data
      const main_data = await fetchAllStudentData(user_id);
      const remade_main_data = remakingAllMainData(main_data);

      const formatted_main_data = formattingAllMainData(
        remade_main_data,
        mainData,
        major_data,
        minor_data,
        sector_data
      );
      let temp_mainData = [...formatted_main_data.temp_main_data];
      let ug_id = formatted_main_data.ug_id;
      temp_mainData[0].subSections[0].items[0].fields[0].value =
        first_name +
        " " +
        ([null, "null"].includes(last_name) ? "" : last_name);

      console.log("122", temp_mainData);
      setMainData(temp_mainData);

      //update full name
      // let temp_mainData = [...mainData];
      // temp_mainData[0].subSections[0].items[0].fields[0].value =
      //   first_name + " " + last_name;
      // setMainData(temp_mainData);

      //check if profile is editable
      if (ug_id) {
        const profile_status_id = await getUserProfileStatus(ug_id, user_id);
        const batchMappingData = await getpoBatchMappingGetter(ug_id);
        const editable = isProfileEditable(
          profile_status_id,
          batchMappingData[0]
        );

        setProfileVerification(
          batchMappingData[0].profile_verification === 1 ? true : false
        );
        setPoVerificationId(profile_status_id);
        if (!editable) {
          setDialogue(prev => ({
            ...prev,
            open: true, //!HERE CHANGE THIS
            title: "Profile Not Editable",
            content: `Your Profile is not editable`,
            actions: { ...prev.actions, handler: () => closeDialogue() },
          }));
        }
        setPageValidators(prev => ({ ...prev, editable: editable }));
      }
      //!HERE CHANGE THIS
      setLoading(prev => ({ ...prev, page_loading: false }));
    };
    handleMainEffect();
  }, []);

  const getAllowPlaceO = async () => {
    try {
      const response = await service.get(`/po/ca/poAllowedCheck`);
      if (response) return response.po_allowed;
      else return false; //did this so that the page doesn't break or no annomaly happens
    } catch (error) {
      console.log("getAllowPlaceO ~ error:", error);
    }
  };

  const fetchSectors = async () => {
    try {
      const sectors = await service.get(`/po/ca/sector?section_type_id=8`);
      console.log("replaceSectorIdWithOptions ~ sectors:", sectors);
      const remade_sector = sectors.sectors.map(item => ({
        id: item.id,
        value: item.sector_name,
      }));
      setExtraData(prev => ({
        ...prev,
        sectors: remade_sector,
      }));
      return remade_sector;
    } catch (error) {
      console.error("Error with fetching sectors:", error);
    }
  };

  const fetchMajorMinor = async user_id => {
    try {
      const majorminorResponse = await service.get(
        `/po/ca/poMajorMinor?user_id=${user_id}`
      );

      const majors = majorminorResponse.data.map(item => ({
        id: item.id,
        value: item.major,
      }));
      const minors = majorminorResponse.data.map(item => ({
        id: item.id,
        value: item.minor,
      }));

      setExtraData(prev => ({ ...prev, majors, minors }));
      return { major_data: majors, minor_data: minors };
    } catch (error) {
      console.error("Error with fetching sectors:", error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const { userDetail } = await service.get(`/user/userDetail`);
      console.log("fetchUserDetails ~ userDetail:", userDetail);
      setExtraData(prev => ({
        ...prev,
        userDetails: {
          user_id: userDetail.user_id,
          roll_no: userDetail.roll_no,
          first_name: userDetail.first_name,
          last_name: [null, "null"].includes(userDetail.last_name)
            ? ""
            : userDetail.last_name,
        },
      }));
      return userDetail;
    } catch (error) {
      console.error("Error with fetching sectors:", error);
    }
  };

  const fetchAllStudentData = async user_id => {
    try {
      const response = await service.get(
        `/po/student/sTypeWiseGetAll?user_id=${user_id}`
      );
      return response.data;
    } catch (error) {
      setError(prev => ({ ...prev, unauthorized: true }));
      console.error("Error fetching all student data:", error);
    }
  };

  const remakingAllMainData = main_data => {
    let modifiedData = {};
    let initialData = {
      1: {
        1: [],
      },
      2: {
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        16: [],
      },
      3: {
        7: [],
        8: [],
        9: [],
        10: [],
      },
      4: {
        11: [],
        12: [],
      },
      5: {
        13: [],
        14: [],
        15: [],
      },
    };

    for (let key in initialData) {
      modifiedData[key] = {};

      if (main_data[key] && main_data[key].length) {
        console.log("maindata key:", main_data[key]);
        // Iterate through each sec_type_id in initialData[key]
        for (let secTypeId in initialData[key]) {
          if (parseInt(secTypeId) === 1) {
            modifiedData[key][secTypeId] = main_data[key];
          } else {
            // Check if the sec_type_id is present in main_data[key]
            const matchingObj = main_data[key].filter(
              obj => parseInt(obj.section_type_id) === parseInt(secTypeId)
            );

            // If present, push the data into modifiedData[key]
            modifiedData[key][secTypeId] = [...matchingObj];
          }
        }
      } else {
        modifiedData[key] = initialData[key];
      }
    }

    console.log("remakingAllMainData ~ modifiedData:", modifiedData);
    return modifiedData;
  };

  const formattingAllMainData = (
    remade_main_data,
    main_data,
    major_data,
    minor_data,
    sector_data,
    student_profile_verified
  ) => {
    let temp_remade_main_data = JSON.parse(JSON.stringify(remade_main_data));

    let this_ug_id;
    let temp_main_data = JSON.parse(JSON.stringify(main_data));
    temp_main_data.forEach((section, section_index) => {
      section.subSections.forEach((subsection, subsection_index) => {
        const fetched_data =
          temp_remade_main_data[subsection.group_id][subsection.sec_type_id];

        console.log("ANIMESHHJHJ", fetched_data, temp_remade_main_data);

        const lastItem = subsection.items[subsection.items.length - 1];
        if (fetched_data.length)
          for (let i = 0; i < fetched_data.length - 1; i++) {
            subsection.items.push(JSON.parse(JSON.stringify(lastItem)));
          }
        subsection.items.forEach((item, item_index) => {
          if (fetched_data.length) {
            item.item_id = fetched_data[item_index]?.id;

            // Check if mainItem.item_name exists
            if (item.item_name) {
              // Use regular expression to extract numeric part and replace it with itemIndex + 1
              item.item_name = item.item_name.replace(/\d+$/, item_index + 1);
              //handling is_verified -> item level (condition: if item_name is not available)
              item.is_verified =
                fetched_data[item_index].is_verified === 1
                  ? true
                  : fetched_data[item_index].is_verified === 0
                  ? false
                  : null; //for handling fieldwise is_verified (if it is undefined)
            } else {
              item.item_name = null;
              //handling is_verified -> subsection level (condition: if item_name is not available)
              // HERE ~ extra change
              item.is_verified =
                fetched_data[item_index].is_verified === 1
                  ? true
                  : fetched_data[item_index].is_verified === 0
                  ? false
                  : null; //for handling fieldwise is_verified (if it is undefined)
              subsection.is_verified =
                fetched_data[item_index].is_verified === 1
                  ? true
                  : fetched_data[item_index].is_verified === 0
                  ? false
                  : null; //for handling fieldwise is_verified (if it is undefined)
            }

            item.fields.forEach((field, field_index) => {
              if (subsection.group_id === 1 && subsection.sec_type_id === 1) {
                const json = fetched_data[item_index][field.backend_field_name];

                const groupOne_values = json
                  ? JSON.parse(
                      fetched_data[item_index][field.backend_field_name]
                    )
                  : { value: "", is_verified: false };

                field.value =
                  field.data_type === "document_url"
                    ? [null, "", undefined, "[]"].includes(json)
                      ? []
                      : Array.isArray(JSON.parse(json))
                      ? JSON.parse(json).map(item => item.url)
                      : [JSON.parse(json).value]
                    : get_specialCharReplace(groupOne_values?.value ?? "");
                field.is_verified = groupOne_values?.is_verified ?? false;

                if (field.is_mandatory === false && field.value === "") {
                  field.is_verified = null;
                }

                if (["on_sabbatical"].includes(field.backend_field_name)) {
                  if (![null, undefined].includes(groupOne_values.value)) {
                    // field.value =
                    //   parseInt(groupOne_values.value) === 0
                    //     ? "No"
                    //     : parseInt(groupOne_values.value) === 1
                    //     ? "Yes"
                    //     : "";

                    field.value = groupOne_values.value;
                  }
                }

                const this_user_id = fetched_data[0].user_id;
                const this_roll_no = fetched_data[0].roll_no;
                this_ug_id = fetched_data[0].ug_id;

                // setUg_id(fetched_data[0].ug_id);
                // const this_full_name =
                //   JSON.parse(fetched_data[0].full_name)?.value ?? "";
                setUserDetails(prev => ({
                  ...prev,
                  user_id: this_user_id,
                  roll_no: this_roll_no,
                  ug_id: this_ug_id,
                  // full_name: this_full_name ??,
                }));
              } else {
                const groupOther_values =
                  fetched_data[item_index][field.backend_field_name];
                // const groupOther_is_verified = fetched_data[item_index].is_verified;

                //condition for major and minor
                if (field.backend_field_name === "specialization_major") {
                  if (![null, undefined].includes(groupOther_values)) {
                    const majors_data = major_data.find(
                      major => major.id === groupOther_values
                    );
                    field.options = major_data; //will be initialized with default if no data
                    field.specialization_major_id = groupOther_values;
                    field.value = majors_data ? majors_data.value : "";
                  }
                } else if (
                  field.backend_field_name === "specialization_minor"
                ) {
                  if (![null, undefined].includes(groupOther_values)) {
                    const minors_data = minor_data.find(
                      minor => minor.id === groupOther_values
                    );
                    field.options = minor_data; //will be initialized with default if no data
                    field.specialization_minor_id = groupOther_values;
                    field.value = minors_data ? minors_data.value : "";
                  }
                } else if (
                  ["final_result_declared"].includes(field.backend_field_name)
                ) {
                  if (![null, undefined].includes(groupOther_values)) {
                    field.value =
                      parseInt(groupOther_values) === 0 ? "No" : "Yes";
                  }
                }
                //condition for sector_id
                else if (field.backend_field_name === "sector_id") {
                  console.log(
                    "item.fields.forEach ~ sector_data:",
                    sector_data
                  );
                  const sector = sector_data.find(
                    sector => sector.id === groupOther_values
                  );
                  field.options = sector_data; //will be initialized with default if no data
                  field.sector_id = groupOther_values;
                  field.value = sector ? sector.value : "";
                } else {
                  field.value =
                    field.data_type === "document_url"
                      ? [null, "", undefined, "[]"].includes(groupOther_values)
                        ? []
                        : JSON.parse(groupOther_values).map(item => item.url)
                      : get_specialCharReplace(groupOther_values);
                  // field.is_verified = groupOther_is_verified ? true : false;
                }
              }
            });
          } else {
            //filling options
            item.fields.forEach(field => {
              if (field.backend_field_name === "specialization_major") {
                field.options = major_data; //will be initialized with default if no data
              } else if (field.backend_field_name === "specialization_minor") {
                field.options = minor_data; //will be initialized with default if no data
              }
              //condition for sector_id
              else if (field.backend_field_name === "sector_id") {
                field.options = sector_data; //will be initialized with default if no data
              }
            });
          }

          //is_verified of subsection is handled -> if all items' is_verified is true then the subsections' is_verified becomes true
          const allItemsVerified = subsection.items.every(
            item => item.is_verified === true
          );
          if (allItemsVerified) {
            subsection.is_verified = true;
          } else {
            subsection.is_verified =
              subsection.is_verified !== null ? false : null;
          }
          // }
        });

        //for non-mandatory subsections, do not show Mark as Verified if there is no data filled
        if (
          subsection.items[0].is_mandatory === false &&
          subsection.items[0].item_id === null
        ) {
          subsection.is_verified = null;
        }
      }); //end for subsections map

      if (temp_remade_main_data[1][1].length) {
        const allSSVerified = calculateSectionVerification(
          section,
          temp_remade_main_data[1][1][0].po_profile_verfication_id === 3
        );
        section.is_verified = allSSVerified;
        console.log("allSSVerified ~ allSSVerified:", allSSVerified);
      }
    });
    console.log("ANKdkjhsfjkdskfhksdhf", temp_main_data);
    return { temp_main_data: temp_main_data, ug_id: this_ug_id };
  };

  //check first render section is_verifieds
  // const calculateSectionVerification = (section, student_profile_verified = true) => {
  //   let sectionVerification = true;

  //   section.subSections.forEach(subSection => {
  //     if (subSection.is_verified === false) {
  //       sectionVerification = false;
  //     } else if (subSection.is_verified === null && subSection.group_id !== 1) {
  //       if (student_profile_verified) sectionVerification = true;
  //       else sectionVerification = null;
  //     } else if (subSection.is_verified === null && subSection.group_id === 1) {
  //       subSection.items[0].fields.forEach(field => {
  //         if (field.is_verified === false) {
  //           sectionVerification = false;
  //         } else if (field.is_verified === null) {
  //           if (student_profile_verified) sectionVerification = true;
  //           else sectionVerification = null;
  //         }
  //       });
  //     }
  //   });

  //   return sectionVerification;
  // };

  const calculateSectionVerification = (
    section,
    student_profile_verified = true
  ) => {
    let sectionVerification = [];
    console.log("student_profile_verified:", student_profile_verified);

    section.subSections.forEach((subSection, subSection_index) => {
      if (subSection.is_verified === false)
        sectionVerification[subSection_index] = false;
      else if (subSection.is_verified === true)
        sectionVerification[subSection_index] = true;
      else if (subSection.is_verified === null && subSection.group_id !== 1) {
        //to handle projects and other similar subsections
        if (student_profile_verified)
          sectionVerification[subSection_index] = true;
        else {
          //to handle conflict b/w projects and other sections where there are non_mandatory and mandatory subsections combined
          if (section.subSections.length > 1) {
            sectionVerification[subSection_index] = null;
          } else sectionVerification[subSection_index] = false;
        }
      } else if (subSection.is_verified === null && subSection.group_id === 1) {
        // const field_isVerified = subSection.items[0].fields.map(
        //   field => field.is_verified
        // );
        const fields_no_doc_verify = subSection.items[0].fields.filter(
          field =>
            !["supported_docs", "work_ex_docs"].includes(
              field.backend_field_name
            )
        );
        const field_isVerified = fields_no_doc_verify.map(
          field => field.is_verified
        );
        if (field_isVerified.includes(false)) {
          sectionVerification[subSection_index] = false;
        } else if (field_isVerified.includes(true))
          sectionVerification[subSection_index] = true;
        else sectionVerification[subSection_index] = null;
      }
    });

    console.log(
      "sectionVerification:",
      sectionVerification,
      student_profile_verified
    );

    if (sectionVerification.includes(false)) return false;
    else if (sectionVerification.includes(true)) return true;
    else if (sectionVerification.every(ss => ss === null)) {
      if (student_profile_verified === true) return true;
      else return false;
    } else return null;
  };

  const getUserProfileStatus = async (batch_id, user_id) => {
    try {
      const response = await service.get(
        `/po/ca/allStudentsOfABatch?ug_id=${batch_id}&user_id=${user_id}&page=1&limit=100`
      );
      console.log("fetchStudentProfileStatus ~ response:", response);
      return response.data[0].po_profile_verfication_id;
    } catch (error) {
      console.error(
        "Error fetching student status and profile verification status:",
        error
      );
    }
  };

  const isProfileEditable = (profile_status_id, batchMappingData) => {
    if ([1, 3, 5].includes(profile_status_id)) return true;
    // else
    // if (batchMappingData.allow_editing === 1) return true;
    else return false;
  };

  const handleSideNavClick = index => {
    let temp_mainData = [...mainData];
    setActiveIndexes(prev => ({ ...prev, sectionIndex: index }));
    if (!openedSections.includes(index)) {
      setOpenedSections(prev => [...prev, index]);
    }
    //main
    temp_mainData.forEach(section => {
      if (section.is_selected === true) section.is_selected = false;
    });
    temp_mainData[index].is_selected = true;
    setMainData(temp_mainData);
  };

  const handleEditClick = (ss_index, item_index) => {
    // setActiveStepIndex(index);
    setActiveIndexes(prev => ({
      ...prev,
      ssIndex: ss_index,
      itemIndex: item_index,
    }));

    // Current Field which will be shown in the Modal
    const temp_field = componentFields(ss_index, item_index);
    setCurrentFields(temp_field);

    setModalData(prev => ({
      ...prev,
      show: true,
      heading: `${
        mainData[activeIndexes.sectionIndex].subSections[ss_index].items[
          item_index
        ].item_name ??
        mainData[activeIndexes.sectionIndex].subSections[ss_index].ss_name
      }`,
    }));
  };

  const handleDeleteItem = (
    ss_index,
    item_index,
    item_id,
    group_id,
    sec_type_id,
    item,
    item_is_mandatory
  ) => {
    setDeleteModal(prev => ({
      ...prev,
      show: true,
      deleteBodyData: {
        item_id,
        group_id,
        sec_type_id,
        ss_index,
        item_index,
        item_is_mandatory,
      },
    }));
    console.log(
      "ss_index, item_index, item_id, group_id, sec_type_id:",
      ss_index,
      item_index,
      item_id,
      group_id,
      sec_type_id,
      item
    );
  };

  const handleModalClose = () => {
    setModalData(prev => ({ ...prev, show: false }));
    // setActiveStepIndex(null);
  };

  const handleAddNew = ss_index => {
    // Current Field which will be shown in the Modal
    // const temp_field = componentFields(ss_index, 0);
    // temp_field.forEach(field => {
    //   field.value = "";
    // });
    // setCurrentFields(temp_field);
    let temp_mainData = JSON.parse(JSON.stringify(mainData));
    const sectionIndex = activeIndexes.sectionIndex;
    const ssIndex = ss_index;

    const lastItem =
      temp_mainData[sectionIndex].subSections[ssIndex].items.slice(-1)[0];
    console.log("handleAddNew ~ lastItem:", lastItem);
    if (lastItem.item_id) {
      // Use map to create a new array with modified field values
      const newFields = lastItem.fields.map(field => ({
        ...field,
        value: ["date", "year"].includes(field.data_type) ? null : "",
      }));

      const newItem = {
        ...lastItem,
        item_id: null,
        fields: newFields,
        item_name: lastItem.item_name?.replace(
          /\d+$/,
          temp_mainData[sectionIndex].subSections[ssIndex].items.length + 1
        ),
      };
      // console.log("handleAddNew ~ newItem:", newItem);

      temp_mainData[sectionIndex].subSections[ssIndex].items.push(newItem);
      setMainData(temp_mainData);
      setCurrentFields(newItem.fields);
      setActiveIndexes(prev => ({
        ...prev,
        ssIndex: ss_index,
        itemIndex:
          temp_mainData[sectionIndex].subSections[ssIndex].items.length - 1,
      }));
      setModalData(prev => ({
        ...prev,
        show: true,
        heading: `Add
      ${
        mainData[activeIndexes.sectionIndex].subSections[ss_index].items
          .at(-1)
          .item_name?.replace(
            /\d+$/,
            temp_mainData[sectionIndex].subSections[ssIndex].items.length
          ) ??
        mainData[activeIndexes.sectionIndex].subSections[
          ss_index
        ].ss_name.replace(
          /\d+$/,
          temp_mainData[sectionIndex].subSections[ssIndex].length
        )
      }`,
      }));
    } else {
      handleEditClick(ss_index, 0);
    }
  };

  const handleGoBack = () => {
    let temp_mainData = [...mainData];
    const sectionIndex = activeIndexes.sectionIndex;
    const ssIndex = activeIndexes.ssIndex;
    // const lastItemIndex =
    //   temp_mainData[sectionIndex].subSections[ssIndex].items.length - 1;
    const lastItemId =
      temp_mainData[sectionIndex].subSections[ssIndex].items[
        activeIndexes.itemIndex
      ].item_id;

    if (
      lastItemId === null &&
      temp_mainData[sectionIndex].subSections[ssIndex].items.length > 1
    ) {
      temp_mainData[sectionIndex].subSections[ssIndex].items.pop();
      // Optionally, you can perform additional operations with the deleted item if needed.
      setMainData(temp_mainData);
      handleModalClose();
    } else {
      // Handle the case where there are no items to delete.
      console.warn("No items to delete.");
      handleModalClose();
    }
  };

  const requestBodyMaker = (currentFields, mainData, current_subsection) => {
    const temp_currentFields = [...currentFields];

    const reqJSON = {
      Group1: {
        user_id: userDetails.user_id ?? extraData.userDetails.user_id,
        ...temp_currentFields.reduce((acc, field) => {
          if (
            ["supported_docs", "work_ex_docs"].includes(
              field.backend_field_name
            )
          ) {
            acc[field.backend_field_name] =
              Array.isArray(field.value) && field.value.length > 0
                ? field.value.map(item => {
                    return {
                      is_verified: true,
                      url: item,
                    };
                  })
                : [null, "", "[]"].includes(field.value) ||
                  field.value.length === 0
                ? []
                : [{ is_verified: true, url: field.value }];

            return acc;
          } else {
            let newValue = send_specialCharReplace(field.value);
            const newIsVerified = ["supported_docs", "work_ex_docs"].includes(
              field.backend_field_name
            )
              ? true
              : field.is_verified === null
              ? false
              : field.is_verified;

            // Add conditional logic here

            acc[field.backend_field_name] = {
              value: newValue,
              is_verified: newIsVerified,
            };
            return acc;
          }

          // Add the modified field to the accumulator
        }, {}),
      },
      Group2: {
        id: current_subsection.items[activeIndexes.itemIndex].item_id,
        ...temp_currentFields.reduce((acc, field) => {
          if (field.backend_field_name === "specialization_major") {
            const major = extraData.majors.find(
              major => major.value === field.value
            );
            acc[field.backend_field_name] = major ? major.id : null;
          } else if (field.backend_field_name === "specialization_minor") {
            const minor = extraData.majors.find(
              minor => minor.value === field.value
            );
            acc[field.backend_field_name] = minor ? minor.id : null;
          } else if (field.backend_field_name === "final_result_declared") {
            acc[field.backend_field_name] =
              field.value.toLowerCase() === "no" ? 0 : 1;
          } else if (field.data_type === "document_url") {
            acc[field.backend_field_name] =
              Array.isArray(field.value) && field.value.length > 0
                ? field.value.map(item => {
                    return {
                      is_verified: true,
                      url: item,
                    };
                  })
                : [null, "", "[]"].includes(field.value) ||
                  field.value.length === 0
                ? []
                : [{ is_verified: true, url: field.value }];
          } else {
            acc[field.backend_field_name] = send_specialCharReplace(
              field.value
            );
          }
          return acc;
        }, {}),
        is_verified: pageValidators.isVerifiedChange
          ? current_subsection.items[activeIndexes.itemIndex].is_verified
            ? 1
            : 0
          : undefined,
      },
      Group3: {
        id: current_subsection.items[activeIndexes.itemIndex].item_id,
        ...temp_currentFields.reduce((acc, field) => {
          if (field.backend_field_name === "sector_id") {
            const sector = extraData.sectors.find(
              sector => sector.value === field.value
            );
            acc[field.backend_field_name] = sector ? sector.id : null;
          } else if (field.data_type === "document_url") {
            acc[field.backend_field_name] =
              Array.isArray(field.value) && field.value.length > 0
                ? field.value.map(item => {
                    return {
                      is_verified: true,
                      url: item,
                    };
                  })
                : [null, "", "[]"].includes(field.value) ||
                  field.value.length === 0
                ? []
                : [{ is_verified: true, url: field.value }];
          } else
            acc[field.backend_field_name] = send_specialCharReplace(
              field.value
            );
          return acc;
        }, {}),
        is_verified: pageValidators.isVerifiedChange
          ? current_subsection.items[activeIndexes.itemIndex].is_verified
            ? 1
            : 0
          : undefined,
      },

      Group4: {
        id: current_subsection.items[activeIndexes.itemIndex].item_id,
        ...temp_currentFields.reduce((acc, field) => {
          if (field.data_type === "document_url") {
            acc[field.backend_field_name] =
              Array.isArray(field.value) && field.value.length > 0
                ? field.value.map(item => {
                    return {
                      is_verified: true,
                      url: item,
                    };
                  })
                : [null, "", "[]"].includes(field.value) ||
                  field.value.length === 0
                ? []
                : [{ is_verified: true, url: field.value }];
          } else
            acc[field.backend_field_name] = send_specialCharReplace(
              field.value
            );
          return acc;
        }, {}),
        is_verified: pageValidators.isVerifiedChange
          ? current_subsection.items[activeIndexes.itemIndex].is_verified
            ? 1
            : 0
          : undefined,
      },

      Group5: {
        id: current_subsection.items[activeIndexes.itemIndex].item_id,
        ...temp_currentFields.reduce((acc, field) => {
          if (field.data_type === "document_url") {
            acc[field.backend_field_name] =
              Array.isArray(field.value) && field.value.length > 0
                ? field.value.map(item => {
                    return {
                      is_verified: true,
                      url: item,
                    };
                  })
                : [null, "", "[]"].includes(field.value) ||
                  field.value.length === 0
                ? []
                : [{ is_verified: true, url: field.value }];
          } else
            acc[field.backend_field_name] = send_specialCharReplace(
              field.value
            );
          return acc;
        }, {}),
        is_verified: pageValidators.isVerifiedChange
          ? current_subsection.items[activeIndexes.itemIndex].is_verified
            ? 1
            : 0
          : undefined,
      },
    };

    // Determine the group_id from current_subsection
    const group_id = current_subsection.group_id;

    // Get the corresponding group object from reqJSON
    let groupObject = reqJSON[`Group${group_id}`];
    if (
      !current_subsection.items[activeIndexes.itemIndex].item_id &&
      group_id !== 1
    ) {
      delete groupObject.id;
      groupObject.user_id =
        userDetails.user_id ?? extraData.userDetails.user_id;
      groupObject.sec_type_id = current_subsection.sec_type_id;
    }

    // Create the body object based on the group
    const body = {
      group_id: group_id,
      sec_type_id: current_subsection.sec_type_id,
      body: groupObject || {}, // Use the groupObject, or an empty object if not found
    };
    console.log("requestBodyMaker ~ body:", body);
    return body;
  };

  const handleDocumentUpload = async (
    items,
    group_id,
    sec_type_id,
    temp_mainData
  ) => {
    let temp_last_item = items[items.length - 1];
    console.log("items inside documents123", newUploadedFiles, temp_last_item);

    let temp_currentField = JSON.parse(JSON.stringify(currentFields));

    // this is done to prevent error appending the name of document
    if (newUploadedFiles.length > 0) {
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      };

      const image_data = new FormData();
      newUploadedFiles.map(item => {
        let mimeType = "";
        switch (item.type) {
          case "application/pdf":
            mimeType = "application/pdf";
            break;
          case "image/jpg":
          case "image/jpeg":
            mimeType = "image/jpeg";
            break;
          case "image/png":
            mimeType = "image/png";
            break;
          default:
            throw new Error("Unsupported file type");
        }

        let pdfFile = new Blob([item], {
          type: mimeType,
        });
        image_data.append("poFile", pdfFile, item.name);
      });

      try {
        if (typeof temp_last_item.value === "object") {
          const imageRes = await axios.post(
            `${process.env.REACT_APP_API_URL}/po/student/poDocumentUpload?sec_type_id=${sec_type_id}&group_id=${group_id}`,
            image_data,
            { headers }
          );

          if (imageRes?.data?.document_url) {
            temp_currentField.forEach(field => {
              if (field.field_id === temp_last_item.field_id)
                field.value = Array.isArray(imageRes.data.document_url)
                  ? [...field.value, ...imageRes.data.document_url]
                  : [...field.value, imageRes.data.document_url];
            });

            setNewUploadedFiles([]);
            setCurrentFields(temp_currentField);

            return temp_currentField;
          }
        } else {
          const imageRes = await axios.post(
            `${process.env.REACT_APP_API_URL}/po/student/poDocumentUpload?sec_type_id=${sec_type_id}&group_id=${group_id}`,
            image_data,
            { headers }
          );

          if (imageRes?.data?.document_url) {
            temp_currentField.forEach(field => {
              if (field.field_id === temp_last_item.field_id)
                field.value = Array.isArray(imageRes.data.document_url)
                  ? [field.value, ...imageRes.data.document_url].filter(
                      x => x !== ""
                    )
                  : [field.value, imageRes.data.document_url].filter(
                      x => x !== ""
                    );
            });

            setNewUploadedFiles([]);
            setCurrentFields(temp_currentField);

            return temp_currentField;
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    } else {
      return temp_currentField;
    }
  };

  const handleSaveChanges = async () => {
    try {
      setSaveLoader(true);
      let temp_mainData = JSON.parse(JSON.stringify(mainData)); // Deep copy of mainData
      // const current_section = temp_mainData[activeIndexes.sectionIndex];
      const current_subsection =
        temp_mainData[activeIndexes.sectionIndex].subSections[
          activeIndexes.ssIndex
        ];

      // Await the handleDocumentUpload function to get the updated fields
      const temp_currentField = await handleDocumentUpload(
        currentFields,
        current_subsection.group_id,
        current_subsection.sec_type_id,
        temp_mainData
      );

      // Update temp_mainData immutably
      let temp_mainData1 = temp_mainData.map((section, section_index) => {
        if (section_index === activeIndexes.sectionIndex) {
          return {
            ...section,
            subSections: section.subSections.map((subSection, ssIndex) => {
              if (ssIndex === activeIndexes.ssIndex) {
                return {
                  ...subSection,
                  is_verified: false,
                  items: subSection.items.map((item, index) => {
                    if (index === activeIndexes.itemIndex) {
                      return {
                        ...item,
                        is_verified: false,
                        fields: temp_currentField,
                      };
                    }
                    return item;
                  }),
                };
              }
              return subSection;
            }),
          };
        }
        return section;
      });
      //backend call

      let formError = AboutYouValidations(temp_currentField);

      setFormErrors(formError);
      // setMainData(temp_mainData);
      const body = requestBodyMaker(
        temp_currentField,
        temp_mainData1,
        current_subsection
      );

      // let x = false;
      // if (Object.keys(formError).length === 0)
      if (Object.keys(formError).length === 0) {
        const put_response =
          current_subsection.items[activeIndexes.itemIndex].item_id !== null // handled by null because "About You" has undefined item_id
            ? await service.put(`po/student/sTypeWisePut`, body)
            : await service.post(`po/student/sTypeWisePost`, body);

        if (current_subsection.items[activeIndexes.itemIndex].item_id === null)
          temp_mainData1[activeIndexes.sectionIndex].subSections[
            activeIndexes.ssIndex
          ].items[activeIndexes.itemIndex].item_id = put_response.data.id;
        temp_mainData1[activeIndexes.sectionIndex].subSections[
          activeIndexes.ssIndex
        ].items[activeIndexes.itemIndex].fields = temp_currentField;
        const current_item =
          temp_mainData1[activeIndexes.sectionIndex].subSections[
            activeIndexes.ssIndex
          ].items[activeIndexes.itemIndex];

        const verificationBody = {
          po_profile_verfication_id: [1, 2].includes(verificationId) ? 1 : 3,
          ug_id: userDetails.ug_id,
          user_id: userDetails.user_id,
        };

        await service.put(`po/ca/updateProfileVerfication`, verificationBody);
        setPoVerificationId([1, 2].includes(verificationId) ? 1 : 3);
        if (current_subsection.group_id === 1) {
          current_item.fields.forEach(field => {
            if (field.is_verified === null && field.value !== "")
              field.is_verified = false;
            else if (field.is_mandatory === false && field.value === "")
              field.is_verified = null;
          });
        } else {
          if (
            current_item.id !== null &&
            current_subsection.is_verified === null
          ) {
            current_item.is_verified = false;
            current_subsection.is_verified = false;
          }
        }

        //if once gr_id = 1 is [POST], then the ids of all the gr_id = 1 items in the data would be equal
        temp_mainData1.forEach(section => {
          section.subSections.forEach(subSection => {
            if (subSection.group_id === 1)
              subSection.items[0].item_id =
                temp_mainData1[activeIndexes.sectionIndex].subSections[
                  activeIndexes.ssIndex
                ].items[activeIndexes.itemIndex].item_id;
          });
          //handle section if_verified
          // const allSSVerified = calculateSectionVerification(
          //   section,
          //   pageValidators.editable
          // );
          // section.is_verified = allSSVerified;
        });

        temp_mainData1 = temp_mainData1.map((item, index) => {
          if (item.is_selected) {
            return {
              ...item,
              is_verified: false,
            };
          } else {
            return item;
          }
        });

        setMainData(temp_mainData1);
        setModalData(prev => ({ ...prev, show: false }));
      }
    } catch (err) {
      console.error("Error on putting data:", err);
      window.alert(
        "Some data has been not filled properly, Error:",
        err?.message
      );
    } finally {
      setSaveLoader(false);
    }
  };

  // profile picture
  const getProfileImage = image => {
    setPicture(image);
  };

  const movePofilePicture = move => {
    // setPicture(image)
    // setLoader(false);
    setMovePicture(false);
  };

  //returns the concerned fields
  const componentFields = (ss_index, item_index) => {
    const temp_mainData = JSON.parse(JSON.stringify(mainData));

    const section_mapper = temp_mainData.filter(
      data => data.is_selected === true
    )[0].subSections;

    const ss_mapper = section_mapper[ss_index];
    const item_mapper = section_mapper[ss_index].items[item_index];

    if (ss_mapper.is_edit) {
      setActiveIndexes(prev => ({ ...prev, itemIndex: 0 }));
      // ss_mapper.items[0].fields.forEach(field => {
      //   //condition for major and minor
      //   if (field.backend_field_name === "specialization_major") {
      //     field.options = extraData.majors; //will be initialized with default if no data
      //   } else if (field.backend_field_name === "specialization_minor") {
      //     field.options = extraData.minors; //will be initialized with default if no data
      //   }
      //   //condition for sector_id
      //   else if (field.backend_field_name === "sector_id") {
      //     field.options = extraData.sectors; //will be initialized with default if no data
      //   }
      // });
      return ss_mapper.items[0].fields;
    } else if (item_mapper.is_edit) {
      console.log(
        "Check value of index:",
        section_mapper[ss_index].items[item_index]
      );
      setActiveIndexes(prev => ({
        ...prev,
        itemIndex: item_index,
      }));
      return item_mapper.fields;
    }
  };

  const endAdornmentMap = {
    Percentile: "%ile",
    Percentage: "%age",
    Score: "CGPA",
    Experience: "Months",
    Salary: "LPA",
    Duration: "Months",
    Startup: "Months",
  };

  const getEndAdornment = fieldName => {
    for (const key in endAdornmentMap) {
      if (fieldName.includes(key)) {
        return endAdornmentMap[key];
      }
    }
    return null;
  };
  //returns Edit Modal Component
  function EditModal() {
    //modalData ~ Component returning Functions and JSON
    const textField = field => {
      if (field.data_type === "string")
        return (
          <TextField
            sx={{
              width: field.width,
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
            InputProps={{
              style: { borderRadius: 0 },
            }}
            required={field.is_required ? true : false}
            disabled={field.disabled ?? false}
            name={field.field_name}
            label={field.field_name}
            value={field.value}
            onChange={e => {
              handleChangeModalFields(e, field.field_id);
            }}
            error={!!formErrors[field.field_name]}
            helperText={formErrors[field.field_name] || ""}
          />
        );
      else if (field.data_type === "number")
        return (
          // <TextField
          //   sx={{ width: field.width }}
          //   InputProps={{
          //     style: { borderRadius: 0 },
          //   }}
          //   type="number"
          //   name={field.field_name}
          //   label={field.field_name}
          //   value={field.value}
          //   onChange={e => {
          //     handleChangeModalFields(e, field.field_id);
          //   }}
          //   error={!!formErrors[field.field_name]}
          //   helperText={formErrors[field.field_name] || ""}
          // />
          <TextField
            sx={{
              width: field.width,
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }} // Adjusting width
            InputProps={{
              style: { borderRadius: 0, color: Color.neutralMidGrey },
              type: "number",
              inputProps: {
                style: { "-moz-appearance": "textfield" }, // Remove spinner arrows for Firefox
                onfocus:
                  "this.type='number';this.value = parseFloat(this.value);", // Ensure the value remains as a number
              },
              endAdornment: getEndAdornment(field.field_name), // Dynamically setting endAdornment based on field name
            }}
            required={field.is_required ? true : false}
            type="number"
            name={field.field_name}
            label={field.field_name}
            value={field.value}
            onChange={e => {
              handleChangeModalFields(e, field.field_id);
            }}
            error={!!formErrors[field.field_name]}
            helperText={formErrors[field.field_name] || ""}
          />
        );
      else if (field.data_type === "date")
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: field.width }}
              InputProps={{
                style: { borderRadius: 0 },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              name={field.field_name}
              label={field.field_name}
              // value={field.value}
              value={field.value ? dayjs(field.value) : field.value}
              views={["year", "month", "day"]}
              error={!!formErrors[field.field_name]}
              helperText={formErrors[field.field_name] || ""}
              onChange={newValue => {
                handleChangeModalFields__date(newValue, field.field_id);
              }}
            />
          </LocalizationProvider>
        );
      else if (field.data_type === "year") {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: field.width,
              }}
              InputProps={{
                style: { borderRadius: 0 },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              name={field.field_name}
              label={field.field_name}
              // value={field.value}
              value={field.value ? dayjs(field.value) : field.value}
              views={["year"]}
              onChange={newValue => {
                handleChangeModalFields__year(newValue, field.field_id);
              }}
              // renderInput={params => (
              //   <TextField
              //     {...params}
              //     error={!!formErrors[field.field_name]}
              //     helperText={formErrors[field.field_name] || ""}
              //     variant="outlined"
              //   />
              // )}
            />
          </LocalizationProvider>
        );
      }
    };

    const single_dropdown = field => {
      if (typeof field.options[0] === "object") {
        return (
          <TextField
            sx={{
              width: field.width,
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
            InputProps={{
              style: { borderRadius: 0 },
            }}
            required={field.is_required ? true : false}
            select
            name={field.field_name}
            label={field.field_name}
            value={field.value}
            onChange={e => {
              handleChangeModalFields_singledropdown_jsonOptions(
                e,
                field.field_id
              );
            }}
            error={!!formErrors[field.field_name]}
            helperText={formErrors[field.field_name] || ""}
          >
            {field.options.map(option => (
              <MenuItem value={option.value}>
                {typeof option === "object" && option !== null
                  ? option[Object.keys(option)[1]]
                  : option}
              </MenuItem>
            ))}
          </TextField>
        );
      } else {
        return (
          <TextField
            sx={{
              width: field.width,
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
            InputProps={{
              style: { borderRadius: 0 },
            }}
            required={field.is_required ? true : false}
            select
            name={field.field_name}
            label={field.field_name}
            value={field.value}
            onChange={e => {
              handleChangeModalFields(e, field.field_id);
            }}
            error={!!formErrors[field.field_name]}
            helperText={formErrors[field.field_name] || ""}
          >
            {field.options.map(option => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </TextField>
        );
      }
    };

    const multiSelectDropDown = field => {};

    const multiSelectNoDropDown = field => {
      return (
        <Autocomplete
          sx={{ width: field.width }}
          multiple
          // placeholder='Type Skills...'
          label={field.field_name}
          options={[]}
          freeSolo
          value={
            field.value
              ? typeof field.value === "string"
                ? JSON.parse(field.value)
                : field.value
              : []
          }
          filterOptions={(options, params) => {
            const filtered = options.filter(option =>
              option.toLowerCase().includes(params.inputValue.toLowerCase())
            );

            // Add the input value as an option if it doesn't exist
            if (
              params.inputValue !== "" &&
              !filtered.includes(params.inputValue)
            ) {
              filtered.push(params.inputValue);
            }

            return filtered;
          }}
          onChange={(e, value) =>
            handleChangeModalFields_multiselect_ndd(e, value, field.field_id)
          }
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                style={{
                  background: Color.white,
                  border: `1px solid ${Color.primary1}`,
                  color: Color.primary1,
                  borderRadius: 0,
                }}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              error={!!formErrors[field.field_name]}
              helperText={formErrors[field.field_name] || ""}
              label={field.field_name}
              sx={{
                "& .MuiFormLabel-asterisk": {
                  color: "red",
                },
              }}
              required={field.is_required ? true : false}
            />
          )}
        />

        // <Typography onClick={() => handleChangeModalFields_multiselect_ndd()}>
        //   {JSON.stringify(field.value)}
        // </Typography>
      );
    };

    const attach_document = field => {
      return (
        <Box
          sx={{
            width: "100%",
          }}
        >
          {Array.isArray(field.value) && field.value.length > 0 && (
            <Box>
              {field.value.map((item, index) => (
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <a href={item} target="_blank" rel="noreferrer">
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <DescriptionOutlined sx={{ color: Color.primary1 }} />
                      <Typography
                        sx={{ fontSize: "16px", color: Color.primary1 }}
                      >
                        {item.split("/").at(-1)}
                      </Typography>
                    </Box>
                  </a>
                  <DeleteForeverOutlined
                    sx={{
                      fontSize: "20px",
                      color: Color.red,
                      cursor: "pointer",
                    }}
                    onClick={e =>
                      handleDeleteAttachment(e, field.field_id, index)
                    }
                  />
                </Box>
              ))}
            </Box>
          )}
          {newUploadedFiles.map((item, index) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <DescriptionOutlined sx={{ color: Color.primary1 }} />
                <Typography sx={{ fontSize: "16px", color: Color.primary1 }}>
                  {item.name}
                </Typography>
              </Box>
              <DeleteForeverOutlined
                sx={{
                  fontSize: "20px",
                  color: Color.red,
                  cursor: "pointer",
                }}
                onClick={e =>
                  setNewUploadedFiles(prev =>
                    prev.filter((ele, i) => i !== index)
                  )
                }
              />
            </Box>
          ))}
          <Box>
            <label
              style={{
                border: "none",
                display: "flex",
                gap: "0.5rem",
                alignItems: "right",
                // width: "100%",
                justifyContent: "center",
                color: Color.primary1,
              }}
              for="files"
              class="btn"
            >
              + Attach Document
            </label>
            <TextField
              id="files"
              type="file"
              onChange={e =>
                handleChangeModalFields_document_upload(e, field.field_id)
              }
              style={{
                marginTop: "0",
                visibility: "hidden",
                width: "0%",
                justifyContent: "right",
              }}
            />
          </Box>
        </Box>
      );
    };

    const modalComponentJSON = {
      // string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year
      string: a => textField(a),
      number: a => textField(a),
      single_dropdown: a => single_dropdown(a),
      multiSelect_dd: a => multiSelectDropDown(a),
      multiSelect_ndd: a => multiSelectNoDropDown(a),
      date: a => textField(a),
      year: a => textField(a),
      document_url: a => attach_document(a),
    };

    //onChange functions
    const handleChangeModalFields = ({ target }, current_field_id) => {
      const { name, value } = target;

      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      if (name === "year_dropdown")
        temp_currentField.forEach(field => {
          if (field.field_id === current_field_id) {
            field.value = value.toString();
            if (
              mainData[activeIndexes.sectionIndex].subSections[
                activeIndexes.ssIndex
              ].group_id === 1
            ) {
              field.is_verified = false;
            } else {
              mainData[activeIndexes.sectionIndex].subSections[
                activeIndexes.ssIndex
              ].items[activeIndexes.itemIndex].is_verified = false;
            }
          }
        });
      else
        temp_currentField.forEach(field => {
          if (field.field_id === current_field_id) {
            field.value = value;
            if (
              mainData[activeIndexes.sectionIndex].subSections[
                activeIndexes.ssIndex
              ].group_id === 1
            ) {
              field.is_verified = false;
            } else {
              mainData[activeIndexes.sectionIndex].subSections[
                activeIndexes.ssIndex
              ].items[activeIndexes.itemIndex].is_verified = false;
            }
          }
        });
      setCurrentFields(temp_currentField);
    };

    //onChange functions
    const handleChangeModalFields__date = (event, current_field_id) => {
      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      temp_currentField.forEach(field => {
        if (field.field_id === current_field_id)
          field.value = event ? event.format("YYYY-MM-DD") : null;
      });
      setCurrentFields(temp_currentField);
    };

    //onChange functions
    const handleChangeModalFields__year = (event, current_field_id) => {
      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      temp_currentField.forEach(field => {
        if (field.field_id === current_field_id) {
          field.value = event ? event.format("YYYY") : null;
          //CHECK HERE
          mainData[activeIndexes.sectionIndex].subSections[
            activeIndexes.ssIndex
          ].items[activeIndexes.itemIndex].is_verified = false;
        }
      });
      console.log("EditModal ~ temp_currentField:", temp_currentField);
      setCurrentFields(temp_currentField);
    };

    const handleChangeModalFields_multiselect_ndd = (
      event,
      value,
      current_field_id
    ) => {
      // const { name, value } = target;
      console.log({ dropdown: value });
      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      temp_currentField.forEach(field => {
        if (field.field_id === current_field_id) {
          const skills = value.map(item => item.split(",")).flat();
          field.value = skills;
        }
      });
      setCurrentFields(temp_currentField);
    };

    const handleChangeModalFields_singledropdown_jsonOptions = (
      { target },
      current_field_id
    ) => {
      const { name, value } = target;
      console.log("object options:", { name, value });
      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      temp_currentField.forEach(field => {
        if (field.field_id === current_field_id) field.value = value.toString();
      });
      setCurrentFields(temp_currentField);
    };

    const handleChangeModalFields_document_upload = (e, current_field_id) => {
      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      // temp_currentField.forEach(field => {
      //   if (field.field_id === current_field_id)
      //     field.value = [...field.value, e.target.files[0]];
      // });
      if (
        ["image/png", "image/jpeg", "image/jpg", "application/pdf"].includes(
          e.target.files[0].type
        )
      ) {
        setNewUploadedFiles(prev => [...prev, e.target.files[0]]);
        setCurrentFields(temp_currentField);
      } else {
        toast.error("Only JPG/JPEG/PNG/PDF are supported");
      }
      // setSelectedImage(e.target.files[0]);
    };

    const handleDeleteAttachment = (e, current_field_id, index) => {
      let temp_currentField = JSON.parse(JSON.stringify(currentFields));
      console.log(
        "let's check what's happening",
        temp_currentField,
        current_field_id
      );
      temp_currentField.forEach(field => {
        if (field.field_id === current_field_id)
          field.value = field.value.filter((ele, i) => i !== index);
      });

      setCurrentFields(temp_currentField);
      // setSelectedImage(null);
    };

    return (
      <Modal open={modalData.show} onClose={handleGoBack}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            padding: "2rem",
            borderRadius: "40px",
            width: "65%",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "8px",
              paddingLeft: "16px",
            }}
          >
            {modalData.heading}
            {/* ${stepper[activeStepIndex]?.title} */}
          </Typography>
          <div
            style={{
              // marginTop: "10px",
              ...DivCenter,
              justifyContent: "space-between",
              // justifyContent: "flex-start",
              rowGap: "1.5rem",
              // overflowY: "auto",
              flexWrap: "wrap",
              padding: "16px",
              width: "100%",
            }}
          >
            {currentFields.length &&
              currentFields.map((field, field_index) =>
                modalComponentJSON[field.data_type](field)
              )}
          </div>

          <hr
            style={{
              color: Color.neutralMidGrey,
              width: "100%",
              marginTop: "32px",
              marginBottom: "16px",
            }}
          />
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              mt: "16px",
              gap: "10px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderColor: Color.neutralMidGrey,
                color: Color.neutralMidGrey,
                height: "36px",
                borderRadius: 0,
                boxShadow: "none",
                "&:hover": {
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                  boxShadow: "none",
                },
              }}
              onClick={() => handleGoBack()}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: Color.primary1,
                minWidth: "100px",
                height: "36px",
                borderRadius: 0,
                boxShadow: "none",
                "&:hover": {
                  background: Color.primary1,
                  boxShadow: "none",
                },
              }}
              onClick={handleSaveChanges}
            >
              {saveLoader ? (
                <CircularProgress size={16} sx={{ color: Color.white }} />
              ) : (
                "Save Changes"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  // DELETE Modal - Appears when delete item button is clicked
  function DeleteModal({ show, onClose, deleteBodyData }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };

    // DELETE MODAL FUNCTIONS
    const handleDeleteCancel = () => {
      onClose();
    };

    // bhoomika attach documents
    const handleDeleteYes = async () => {
      try {
        const body = {
          group_id: deleteBodyData.group_id,
          sec_type_id: deleteBodyData.sec_type_id,
          id: deleteBodyData.item_id,
        };
        console.log("handleDeleteYes ~ body:", { data: body });
        await service.delete(
          `/po/student/sTypeWiseDelete?group_id=${deleteBodyData.group_id}&id=${deleteBodyData.item_id}&sec_type_id=${deleteBodyData.sec_type_id}`
        );
        //update maindata
        let temp_mainData = [...mainData];
        const items =
          temp_mainData[activeIndexes.sectionIndex].subSections[
            deleteBodyData.ss_index
          ].items;
        if (items.length === 1) {
          const lastItem = items[items.length - 1];
          lastItem.fields.forEach(field => {
            field.value = "";
          });
          lastItem.item_id = null;
          temp_mainData[activeIndexes.sectionIndex].subSections[
            deleteBodyData.ss_index
          ].items.push(JSON.parse(JSON.stringify(lastItem)));

          temp_mainData[activeIndexes.sectionIndex].subSections[
            deleteBodyData.ss_index
          ].items = temp_mainData[activeIndexes.sectionIndex].subSections[
            deleteBodyData.ss_index
          ].items.filter((item, itemIndex) => itemIndex === 0);

          /* -------
            if one and only item of a non_mandatory subsection is deleted, 
            then we need to not show the subsection's is_verifed status,i.e., is_verified = null
          */
          console.log("deleting non_mandatory1", items[0].is_mandatory);
          if (items[0].is_mandatory === false) {
            console.log(
              "deleting non_mandatory2",
              temp_mainData[activeIndexes.sectionIndex].subSections[
                deleteBodyData.ss_index
              ]
            );
            temp_mainData[activeIndexes.sectionIndex].subSections[
              deleteBodyData.ss_index
            ].is_verified = null;
          }
          /*  ------  */
        } else {
          // Use filter to create a new array without the item to be deleted
          temp_mainData[activeIndexes.sectionIndex].subSections[
            deleteBodyData.ss_index
          ].items = temp_mainData[activeIndexes.sectionIndex].subSections[
            deleteBodyData.ss_index
          ].items.filter(item => item.item_id !== deleteBodyData.item_id);
        }
        // console.log(
        //   "check this:",
        //   temp_mainData[activeIndexes.sectionIndex].subSections[
        //     deleteBodyData.ss_index
        //   ]
        // );
        //appending the name of the item
        temp_mainData[activeIndexes.sectionIndex].subSections[
          deleteBodyData.ss_index
        ].items.forEach((item, item_index) => {
          item.item_name = item.item_name.replace(/\d+$/, item_index + 1);
        });
        setMainData(temp_mainData);
        onClose();
      } catch (error) {
        window.alert("Data not deleted. Please reload this page");
        console.error("Error while deleting:", error);
      }
    };

    return (
      <Modal open={show} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Delete item
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Are you sure you want to delete this?
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              gap: "10px",
            }}
          >
            <PrimaryButtonOutlined
              variant="outlined"
              onClick={() => handleDeleteCancel()}
              sx={{
                textTransform: "none",
                borderColor: Color.neutralMidGrey,
                color: Color.neutralMidGrey,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                },
              }}
            >
              Cancel
            </PrimaryButtonOutlined>
            <PrimaryButton
              variant="outlined"
              onClick={() => handleDeleteYes()}
              sx={{
                textTransform: "none",
                borderColor: Color.primary1,
                background: Color.primary1,
                color: Color.primary1,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  background: Color.primary1,
                  borderColor: Color.primary1,
                  color: Color.primary1,
                },
              }}
            >
              Yes
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      {loading.page_loading ? (
        <SmallPageLoader show={loading.page_loading} />
      ) : (
        <div>
          <SideNavBarStudent />
          <UnauthorizedLoginAgainModal show={error.unauthorized} />
          <ActivityLoader show={loading.activity_loading} />
          <ToastContainer />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            {/* Displayed Data - Left Navigation Part */}
            <Box
              sx={{
                alignItems: "flex-start",
                width: "30%",
                height: "100%",
                padding: "",
                paddingTop: "3rem",
                borderRight: `1px solid ${Color.neutralLightGrey}`,
                position: "fixed",
                // minHeight: "826px",
              }}
            >
              {/* PP */}
              <Box
                sx={{
                  ...DivCenter,
                  alignItems: "flex-start",
                  paddingLeft: "78.37px",
                  height: "20%",
                  marginBottom: "1rem",
                }}
              >
                <ProfilePictureSelection
                  marginLeft="23%"
                  width="max(50%,80vw)"
                  getProfileImage={getProfileImage}
                  pictureLoading={pictureLoading}
                  setPictureLoading={setPictureLoading}
                  show_user={1}
                  move_picture={movePofilePicture}
                  imageErrorModal={imageErrorModal}
                  setImageErrorModal={setImageErrorModal}
                  editable={pageValidators.editable}
                />
              </Box>

              {/* Names */}
              <Box
                sx={{
                  paddingLeft: "78.37px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  gap: ".5rem",
                  flexDirection: "column",
                  marginTop: "1.5rem",
                  height: "10%",
                }}
              >
                <span style={{ fontSize: "24px", fontWeight: 500 }}>
                  {extraData.userDetails.first_name +
                    " " +
                    (extraData.userDetails.last_name ?? "") ??
                    userDetails.full_name}
                </span>
                <span style={{ fontSize: "18px" }}>
                  {extraData.userDetails.roll_no ?? userDetails.roll_no}
                </span>
              </Box>
              {/* Sections */}
              <Box
                sx={{
                  marginTop: "1rem",
                  paddingLeft: "78.37px",
                  height: "60%",
                  overflow: "hidden",
                  overflowY: "auto",
                  mb: "16px",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for Chrome, Safari, Edge
                  },
                  scrollbarWidth: "none",
                }}
              >
                {mainData.map((step, step_index) => (
                  <Box
                    key={step_index}
                    onClick={() => handleSideNavClick(step_index)}
                    sx={{
                      ...DivCenter,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "7.5px 0",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      className="typography__stepperTitle"
                      sx={{
                        ...DivCenter,
                        width: "100%",
                        textAlign: "center",
                        padding: "7.5px 0",
                        fontSize: "20px",
                        cursor: "pointer",
                        fontWeight: 500,
                        color:
                          step.is_selected === true
                            ? Color.primary1
                            : Color.blueBlack,
                        background:
                          step.is_selected === true
                            ? "rgba(56, 193, 223, 0.10)"
                            : Color.white,
                      }}
                    >
                      <span style={{ width: "10%", textAlign: "left" }}></span>
                      <span style={{ width: "80%" }}>{step.section_name}</span>
                      {profileVerification ? (
                        <span style={{ width: "10%", textAlign: "left" }}>
                          {step.is_verified === true ? (
                            <CheckCircle
                              sx={{
                                height: "15px",
                                width: "15px",
                                color: Color.green,
                              }}
                            />
                          ) : step.is_verified === false ? (
                            <ErrorOutlineOutlined
                              sx={{
                                height: "15px",
                                width: "15px",
                                color: Color.red,
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </span>
                      ) : (
                        <span
                          style={{ width: "10%", textAlign: "left" }}
                        ></span>
                      )}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {/* Displayed Data - Right Data Part*/}
            <Box
              sx={{
                width: "70%",
                height: "100%",
                borderTop: `1px solid ${Color.neutralLightGrey}`,
                marginLeft: "30%",
                // borderLeft: `1px solid ${Color.neutralLightGrey}`,
              }}
            >
              {mainData?.length &&
                !error.unauthorized &&
                mainData
                  .filter(data => data.is_selected === true)[0]
                  .subSections.map((stepperItem, ss_index) => {
                    return (
                      <Box
                        key={stepperItem.ss_id}
                        sx={{
                          ...DivCenter,
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            ...DivCenter,
                            justifyContent: "flex-start",
                            width: "100%",
                            marginTop: "3%",
                            height: "20%",
                            padding: "12px 12px 12px 24px",
                            borderBottom: `1px solid ${Color.neutralMidGrey}`,
                          }}
                        >
                          <Box
                            sx={{
                              ...DivCenter,
                              gap: "1.5rem",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "87%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "16px",
                                  width: "38%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  {stepperItem.ss_name}
                                </Typography>
                                {stepperItem.is_edit === true &&
                                pageValidators.editable ? (
                                  <IconButton
                                    disabled={!pageValidators.editable}
                                    onClick={() => handleEditClick(ss_index, 0)}
                                    sx={{
                                      height: "32px",
                                      width: "32px",
                                      background: "rgba(56, 193, 223, 0.10)",
                                    }}
                                  >
                                    <EditOutlinedIcon
                                      sx={{
                                        fontSize: "14px",
                                        color: pageValidators.editable
                                          ? Color.primary1
                                          : Color.neutralMidGrey,
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}

                                {stepperItem.is_add_new &&
                                pageValidators.editable ? (
                                  <Box
                                    onClick={() => handleAddNew(ss_index)}
                                    sx={{
                                      ...DivCenter,
                                      width: "auto",
                                      color: Color.primary1,
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    + Add New
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Box>
                            {profileVerification ? (
                              <Box sx={{ width: "13%" }}>
                                {stepperItem.is_verified === false ? (
                                  <Box sx={{ ...DivCenter, gap: "4px" }}>
                                    <ErrorOutlineOutlined
                                      sx={{ color: Color.red }}
                                    />{" "}
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      Not Verified
                                    </span>
                                  </Box>
                                ) : stepperItem.is_verified === true ? (
                                  <Box sx={{ ...DivCenter, gap: "4px" }}>
                                    <CheckCircleIcon
                                      sx={{ color: Color.green }}
                                    />{" "}
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Verified
                                    </span>
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            ) : (
                              <Box sx={{ width: "13%" }} />
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            ...DivCenter,
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                            height: "80%",
                            padding: "12px 12px 12px 24px",
                            gap: "16px",
                          }}
                        >
                          {stepperItem.items.map((ss_item, ss_item_index) => (
                            <div
                              key={ss_item_index}
                              style={{
                                ...DivCenter,
                                flexDirection: "column",
                                width: "100%",
                                height: "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  borderBottom:
                                    ss_item.item_name !== null &&
                                    ss_item.is_mandatory !== false
                                      ? `1px solid ${Color.neutralLightGrey}`
                                      : "none",
                                }}
                              >
                                {ss_item.item_name !== null ? (
                                  ss_item.item_id === null &&
                                  ss_item.is_mandatory === false ? (
                                    <Typography
                                      style={{
                                        width: "100%",
                                        textAlign: "left",
                                        color: Color.neutralMidGrey,
                                        fontStyle: "italic",
                                        paddingTop: "8px",
                                      }}
                                    >
                                      Not added any data yet
                                    </Typography>
                                  ) : (
                                    <Box
                                      sx={{
                                        ...DivCenter,
                                        width: "100%",
                                        padding: "8px 0px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          ...DivCenter,
                                          width: "95%",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            ...DivCenter,
                                            justifyContent: "flex-start",
                                            width: "30%",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              color: Color.neutralMidGrey,
                                              fontSize: "18px",
                                              fontWeight: 550,
                                            }}
                                          >
                                            {ss_item.item_name}
                                          </Typography>
                                          {/* Item Edit */}
                                          {ss_item.is_edit &&
                                          pageValidators.editable ? (
                                            <IconButton
                                              disabled={
                                                !pageValidators.editable
                                              }
                                              onClick={() =>
                                                handleEditClick(
                                                  ss_index,
                                                  ss_item_index
                                                )
                                              }
                                              sx={{
                                                height: "32px",
                                                weight: "32px",
                                                background:
                                                  "rgba(56, 193, 223, 0.10)",
                                                ml: "16px",
                                              }}
                                            >
                                              <EditOutlinedIcon
                                                sx={{
                                                  fontSize: "14px",
                                                  color: pageValidators.editable
                                                    ? Color.primary1
                                                    : Color.neutralMidGrey,
                                                }}
                                              />
                                            </IconButton>
                                          ) : (
                                            <></>
                                          )}
                                          {ss_item.is_delete &&
                                          pageValidators.editable ? (
                                            <Tooltip
                                              title={
                                                stepperItem.items.length > 1 ||
                                                ss_item.is_mandatory === false
                                                  ? "Delete"
                                                  : "Atleast one entry is required"
                                              }
                                              placement="top"
                                            >
                                              <div>
                                                <IconButton
                                                  disabled={
                                                    stepperItem.items.length >
                                                      1 ||
                                                    ss_item.is_mandatory ===
                                                      false
                                                      ? false
                                                      : true
                                                  }
                                                  onClick={() =>
                                                    handleDeleteItem(
                                                      ss_index,
                                                      ss_item_index,
                                                      ss_item.item_id,
                                                      stepperItem.group_id,
                                                      stepperItem.sec_type_id,
                                                      stepperItem,
                                                      ss_item.is_mandatory
                                                    )
                                                  }
                                                  sx={{
                                                    height: "32px",
                                                    weight: "32px",
                                                    ml: "8px",
                                                  }}
                                                >
                                                  <DeleteForeverOutlined
                                                    sx={{
                                                      fontSize: "14px",
                                                      color:
                                                        stepperItem.items
                                                          .length > 1 ||
                                                        ss_item.is_mandatory ===
                                                          false
                                                          ? Color.red
                                                          : Color.neutralMidGrey,
                                                    }}
                                                  />
                                                </IconButton>
                                              </div>
                                            </Tooltip>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Box>
                                      {profileVerification ? (
                                        <Box sx={{ width: "5%" }}>
                                          {ss_item.is_verified === false ? (
                                            <ErrorOutlineOutlined
                                              sx={{ color: Color.red }}
                                            />
                                          ) : ss_item.is_verified === true ? (
                                            <CheckCircleIcon
                                              sx={{ color: Color.green }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      ) : (
                                        <Box sx={{ width: "5%" }} />
                                      )}
                                    </Box>
                                  )
                                ) : (
                                  ss_item.is_mandatory === false &&
                                  ss_item.item_id === null && (
                                    <Typography
                                      style={{
                                        width: "100%",
                                        textAlign: "left",
                                        color: Color.neutralMidGrey,
                                        fontStyle: "italic",
                                        paddingTop: "8px",
                                      }}
                                    >
                                      Not added any data yet
                                    </Typography>
                                  )
                                )}
                              </Box>

                              {(ss_item.item_id || ss_item.is_mandatory) && (
                                <>
                                  {ss_item.fields.map((field, fieldIndex) =>
                                    field.field_name !== "Attach Documents" ? (
                                      <div
                                        key={fieldIndex}
                                        style={{
                                          ...DivCenter,
                                          flexDirection: "column",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            gap: "5rem",
                                            textAlign: "left",
                                            height: "auto",
                                            // minHeight: "57px",
                                            paddingTop: ".5rem",
                                            justifyContent: "space-between",
                                            alignItems: "left",
                                            paddingBottom: ".5rem",
                                            borderBottom: `1px solid ${Color.neutralLightGrey}`,
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              width: "30%",
                                              color: Color.neutralMidGrey,
                                            }}
                                          >
                                            {field.field_name}
                                          </Typography>
                                          {field.field_name ===
                                          "Attach Documents" ? (
                                            <Typography
                                              sx={{
                                                width: "65%",
                                                color: Color.primary1,
                                                cursor:
                                                  field.value !== "{}" ||
                                                  field.value !== ""
                                                    ? "pointer"
                                                    : "none",
                                              }}
                                              disabled={
                                                field.value !== "{}" ||
                                                field.value !== ""
                                                  ? false
                                                  : true
                                              }
                                            >
                                              <button
                                                className="textTagsHere"
                                                disabled={
                                                  field.value === "{}" ||
                                                  field.value === ""
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  border: "none",
                                                  color: "#1C8EA8",
                                                  background: "transparent",
                                                  textAlign: "left",
                                                  outline: "none",
                                                }}
                                                onClick={event => {
                                                  event.stopPropagation();
                                                  const mentorLinkedInLink =
                                                    field.value;
                                                  window.open(
                                                    mentorLinkedInLink
                                                  );
                                                }}
                                              >
                                                {field.value === "{}" ||
                                                field.value === ""
                                                  ? "No Attached Documents"
                                                  : ellipsisFunction(
                                                      field.value?.substring(
                                                        field.value.lastIndexOf(
                                                          "/"
                                                        ) + 1
                                                      )
                                                    )}
                                              </button>
                                            </Typography>
                                          ) : (
                                            <Typography sx={{ width: "65%" }}>
                                              {["string", "number"].includes(
                                                typeof field.value
                                              )
                                                ? field.value.toString()
                                                : (field.value || []).map(
                                                    (data, index) =>
                                                      index !==
                                                      field.value.length - 1
                                                        ? data + ", "
                                                        : data
                                                  ) ?? "N/A"}
                                            </Typography>
                                          )}

                                          {profileVerification ? (
                                            <Box sx={{ width: "5%" }}>
                                              {field.is_verified === false ? (
                                                <ErrorOutlineOutlined
                                                  sx={{ color: Color.red }}
                                                />
                                              ) : field.is_verified === true ? (
                                                <CheckCircleIcon
                                                  sx={{ color: Color.green }}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </Box>
                                          ) : (
                                            <Box sx={{ width: "5%" }} />
                                          )}
                                        </Box>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          width: "100%",
                                          paddingBlock: "10px",
                                          display: "flex",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            width: "30%",
                                            color: Color.neutralMidGrey,
                                          }}
                                        >
                                          {field.field_name}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            width: "65%",
                                            color: Color.primary1,
                                            alignItems: "center",
                                            marginLeft: "30px",
                                            cursor:
                                              field.value !== "{}" ||
                                              field.value !== ""
                                                ? "pointer"
                                                : "none",
                                          }}
                                          disabled={
                                            field.value !== "{}" ||
                                            field.value !== ""
                                              ? false
                                              : true
                                          }
                                        >
                                          {field.value === "{}" ||
                                          field.value === "" ||
                                          field.value === "[]" ||
                                          field.value === null ? (
                                            ""
                                          ) : typeof field.value ===
                                            "string" ? (
                                            <a
                                              href={field.value}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {field.value.split("/").at(-1)}
                                            </a>
                                          ) : (
                                            field.value.map(item => (
                                              <a
                                                href={item}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <Typography>
                                                  {item.split("/").at(-1)}
                                                </Typography>
                                              </a>
                                            ))
                                          )}
                                        </Typography>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </Box>
                        {/* <Box sx={{ width: "100%", height: "20%" }} /> */}
                      </Box>
                    );
                  })}
            </Box>

            {/* Modals */}
            {EditModal()}
            <DeleteModal
              show={deleteModal.show}
              onClose={deleteModal.onClose}
              deleteBodyData={deleteModal.deleteBodyData}
            />
            <DialogueBox
              open={dialogue.open}
              onClose={dialogue.actions.handler}
              title={dialogue.title}
              actions={dialogue.actions}
              content={
                <>
                  <p>{dialogue.content}</p>
                </>
              }
            />
            <Alert
              show={imageErrorModal}
              title="Caution!"
              body={
                <>
                  Image size exceeds 10 MB
                  <br />
                  <br />
                </>
              }
              alertResponse={() => setImageErrorModal(false)}
              onHide={() => setImageErrorModal(false)}
              cancelResponse={() => setImageErrorModal(false)}
            />
          </Box>
          )
        </div>
      )}
    </>
  );
};

export default MyProfile;
