import React from "react";
import { Box } from "@mui/material";
import { VerticalDivider } from "@100mslive/react-ui";
// import UserInformationComponent from "../../DocumentsAndFeedback/UserInformationComponent";
import { Color, DivCenter } from "../../../../GlobalStyles";
import PdfViewer from "../../DocumentsAndFeedback/PdfViewer";
import "./OfflineSessions.css";
function OSDocumentFeedback() {
  //   const [showPdfUrl, setShowPdfUrl] = useState();
  return (
    <div style={{ marginleft: "120px" }}>
      <Box
        sx={{ backgroundColor: "#1b1a1a", color: Color.white }}
        className="OS-header OS-header-sb"
      >
        <Box className="OS-header" style={{ padding: "4px 0px" }}>
          <div style={{ padding: "10px 16px", fontSize: 12, fontWeight: 500 }}>
            Mock Interview of Manish Jha
          </div>
          <VerticalDivider css={{ color: Color.neutralMidGrey }} />

          <div style={{ padding: "10px 16px", fontSize: 12 }}>
            IIM Udaipur, PGP, 2nd Year
          </div>
          <VerticalDivider css={{ color: Color.neutralMidGrey }} />

          <div style={{ padding: "10px 16px", fontSize: 12 }}>
            Wednesday, 28 Mar 2024, 9:30 AM
          </div>
        </Box>
        <Box className="OS-header">
          <div> chat </div>
          <div>Time left : 30:00</div>
        </Box>
      </Box>
      <Box
        sx={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "50vh",
          overflowY: "auto", // Changed to 'auto' to allow scrolling
          background: "#fff",
          zIndex: 2,
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for Chrome, Safari, Edge
          },
          scrollbarWidth: "none",
          marginBottom: ".5rem",
          boxShadow: "none",
          borderBottomRightRadius: "6px",
          borderBottomLeftRadius: "6px",
        }}
      >
        <PdfViewer url="https://profile-picture-and-logo.s3.amazonaws.com/Out-of-Pocket-Expense/2026_Resume-2.pdf" />
      </Box>
    </div>
  );
}

export default OSDocumentFeedback;
