import React from "react";
// import Loader from "../../components/Loader/Loader";
import MyProfile from "./MyProfile.js";
// import { backendDataJSON } from "./MyProfileBackendDataJSON";
// import { myProfileData } from "./MyProfileJSON";
// import MyProfileRequests from "./MyProfileRequests";
// import Service from "../../services/httpService";

const MyProfileMain = () => {
  // const [mainData, setMainData] = useState([...myProfileData]);
  // const service = new Service();
  // const [loading, setLoading] = useState({
  //   page_loading: false,
  //   activity_loading: false,
  // });
  // const selectedSectionBackendData = backendDataJSON.find(
  //   section => section.is_selected === true
  // );
  // console.log("selectedSectionBackendData:", selectedSectionBackendData);

  // useEffect(() => {
  //   const handleEffect = async () => {
  //     const value = await MyProfileRequests(mainData, setMainData);
  //     console.log("value:", value);
  //     let temp_data = [...mainData];
  //     temp_data.forEach(data => {
  //       if (data.is_selected === true) {
  //         data.subSections = value;
  //       }
  //     });
  //     setMainData(temp_data);
  //   };
  //   handleEffect();
  // }, []);

  // const MyProfileRequests = async () => {
  //   setLoading(prev => ({ ...prev, page_loading: true }));
  //   try {
  //     const promises = selectedSectionBackendData.subSections.map(
  //       async item => {
  //         const response = await service.get(item.get_url);
  //         return { ...item, data: response.data[0] };
  //       }
  //     );

  //     const updatedItems = await Promise.all(promises);
  //     console.log("updatedItems:", updatedItems);
  //     const remade_mainData = remakingMainData(updatedItems);
  //     console.log("remade_mainData:", remade_mainData);
  //     setLoading(prev => ({ ...prev, page_loading: false }));
  //     return remade_mainData;
  //   } catch (error) {
  //     setLoading(prev => ({ ...prev, page_loading: false }));
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const remakingMainData = fetchedData => {
  //   const temp_mainData = mainData.find(
  //     data => data.is_selected === true
  //   ).subSections;
  //   let temp_backendData = [...fetchedData];
  //   console.log("temp_backendData ~ remakingMainData:", temp_backendData);

  //   // Update temp_backendData based on temp_data
  //   temp_backendData = updateBackendDataItems(temp_backendData);
  //   console.log("updateBackendDataItems ~ temp_backendData:", temp_backendData);

  //   // Copy values within subSections and items
  //   temp_mainData.forEach((mainSubSection, subIndex) => {
  //     const backendSubSection = temp_backendData[subIndex];

  //     mainSubSection.items.forEach((mainItem, itemIndex) => {
  //       const backendItem = backendSubSection.items[itemIndex];

  //       mainItem.fields.forEach((mainField, fieldIndex) => {
  //         const backendField = backendItem.fields[fieldIndex];
  //         mainField.value = backendField.value;
  //         mainField.is_verified = backendField.is_verified;
  //       });
  //     });
  //   });
  //   // });

  //   console.log("temp_mainData after copying values:", temp_mainData);
  //   return temp_mainData;
  // };

  // // Function to update values in temp_backendData based on temp_data
  // function updateBackendDataItems(temp_backendData) {
  //   // console.log("temp_backendData, temp_data:", temp_backendData);
  //   temp_backendData.forEach(subSection => {
  //     subSection.items.forEach(item => {
  //       item.fields.forEach(field => {
  //         const matchingKey = JSON.parse(subSection.data[field.field_name]);
  //         const matchingKeyValue = matchingKey ? matchingKey.value : null;
  //         const matchingKeyVerified = matchingKey
  //           ? matchingKey.is_verified
  //           : null;
  //         if (matchingKey) {
  //           field.value = matchingKeyValue;
  //           field.is_verified = matchingKeyVerified;
  //         }
  //       });
  //     });
  //   });
  //   // });
  //   return temp_backendData;
  // }

  return (
    <div>
      <MyProfile />
    </div>
  );
};

export default MyProfileMain;
