import styles from "./FrameComponent.module.css";

const FrameComponent = () => {
  return (
    <div className={styles.groupParent}>
      <div className={styles.groupContainer}>
        <img className={styles.groupChild} alt="" src="/group-200.svg" />
        <div className={styles.eyeingAPpo}>Eyeing a PPO or PPI?</div>
        <img className={styles.groupItem} alt="" src="/group-200.svg" />
        <div className={styles.dumpedWithLead}>
          Dumped with lead generation?
        </div>
        <img className={styles.groupInner} alt="" src="/group-200.svg" />
        <div className={styles.workedUpAbout}>Worked up about final ppt?</div>
        <img className={styles.groupIcon} alt="" src="/group-200.svg" />
        <div className={styles.confusedWhichTools}>
          Confused which tools to use?
        </div>
        <img className={styles.groupChild1} alt="" src="/group-2007.svg" />
        <div className={styles.strugglingWithOnline}>
          Struggling with online mode?
        </div>
        <img className={styles.groupChild2} alt="" src="/group-2007.svg" />
        <div className={styles.worriedAboutReport}>
          Worried about report structure?
        </div>
        <img className={styles.groupChild3} alt="" src="/group-2007.svg" />
        <div className={styles.wantToStrengthen}>
          Want to strengthen your project?
        </div>
        <img className={styles.groupChild4} alt="" src="/group-2007.svg" />
        <div className={styles.notYourMentorsContainer}>
          <p className={styles.notYourMentors}>{`Not your mentor’s `}</p>
          <p className={styles.notYourMentors}>priority?</p>
        </div>
        <div className={styles.lineDiv} />
        <img className={styles.ellipseIcon} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild5} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild6} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild7} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild8} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild9} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild10} alt="" src="/ellipse-121.svg" />
        <img className={styles.groupChild11} alt="" src="/ellipse-121.svg" />
      </div>
    </div>
  );
};

export default FrameComponent;
