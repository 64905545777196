// import AsyncStorage from "@react-native-async-storage/async-storage";
// import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
  },
});
let jwt_token = "Bearer " + Cookies.get("token");
// window.location.pathname === "/UpcomingBooking"
// ?
// "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjc0NiwiaWF0IjoxNjc5MzAyODExNjIxLCJleHAiOjE2NzkzMDM2NzU2MjF9.T2kpaelmy68r3cFbn0GKnGhsT_yopM-ShU60xvYEAYwuYtRwBBA880ERdWIq0d8o2EXUMrBpfWcYrkd-e_TnBs0t9_MBH0cLb46h0FMKphw11SvXa5Pnr9AF8dgveXJ7Plsb2-YviEDVG9JZBsE9GYvPAU7_Qy2U7OiTWmQIxDNYMTF62nmXMMZyLWiYgAkrT6AJoob2GhdfYXLuAQELCenZ-LmN4Gw-jWKfqc4Lxwzo3_ivC43_6OUS6xpBQqP3oXTOh7dtkX141b3B4bB2E0xXftuq3EtakBdUYAMZM79ubzS4gJqtC6zo90-KaD53rh2zRZD-DfAzwiByig7C7nezJRUtPdhfjAMd5eM48Zrx9-JkxicN0f911xooLAcWFB-WlKc4NKEMz4UnxPa_YLAByB9FmTT96PqI4nSay5L92AAAb9qhh6ldGi8-nIgYiJ_Qlwhlnvx7mc0UdXpCs_vuptL2L2qwuJVYXeP99K5g3BPPd5P07s90M-fohtTVq6K_Jjbz7VO64k9v_m3KoVB16UlYG-llOsTERYY0cG2PGYOz8HUyJsvP9JNM9prJbnV98RENtV2-M1GmL78eYrxXBFQw35SfQrpLf04e7MpySl9yfGeqtEvCLw8Rcu1QA9GG9rHSlnCdTpxaQT70C_1uHahSm3hkeDZIrGKMjFw";
// : "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY4MDA2NjkxOTY1MiwiZXhwIjoxNjgwMDY3NzgzNjUyfQ.A5n91Kr0QnWHshBSCSdZpWxIdHDyaAESv_0Np1AMTfTQArjkrsSkCxtyIoUwqKAvG34DofaDsXGRg5UH73QCO6eRe3dPDioKGCyFqzoX2aDUTmlqnBoyvPLZOs8qk8I_BiwMze-z3LjRXhmmpYwi5d-kyguHqo_w9Bh3w1YF07HSOhIo-_OzFbQDrQVs4A3mithxmJWpRzxFgc69dYe9JG8xwIHKmmcvRWgVCHTSYvL2Qi2tW4z8GNTwZn45LI5X3oFDh6NSuptazhzdhF0Jww_IuQBOX1CKeaz-pXHuyymCfZQ132f2mRk5fiCpz9KsjLA6pr7n69Z5m--IhAgqW6wOeSlrO_6M1uKdMDrls3d0wJbVvfpDOxrKp-xRgdAXMTofEmc3fExwld-nWKqao84WwlXZG7O6XUu9GcjKMquvmBui5EUXjH6I2TVizi1fNACZ2vAdA0VoaYC2sT9ZLAkYYGkJeTSN2eHEPliW-2IdWS7vfMWlNB7T3fhcf-rnZ_uy7nhyYi5s6EXA4wP0mMs69isULzTrtuboLmdUISbP2Jq_pWYHR1EkNsZKHLnjUfmWEraUXRIzuNnSev8sbTv8_yJaDEOhex5h9AG2XfoSR80MHqUJ5F1WNcY1Ey5UjYKBsM6t9cr0CUD8cImOJ3ePxj_dcTKQfiC8akkonOQ";
// let jwt_token =
//   "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImlhdCI6MTY4MDA2NjkxOTY1MiwiZXhwIjoxNjgwMDY3NzgzNjUyfQ.A5n91Kr0QnWHshBSCSdZpWxIdHDyaAESv_0Np1AMTfTQArjkrsSkCxtyIoUwqKAvG34DofaDsXGRg5UH73QCO6eRe3dPDioKGCyFqzoX2aDUTmlqnBoyvPLZOs8qk8I_BiwMze-z3LjRXhmmpYwi5d-kyguHqo_w9Bh3w1YF07HSOhIo-_OzFbQDrQVs4A3mithxmJWpRzxFgc69dYe9JG8xwIHKmmcvRWgVCHTSYvL2Qi2tW4z8GNTwZn45LI5X3oFDh6NSuptazhzdhF0Jww_IuQBOX1CKeaz-pXHuyymCfZQ132f2mRk5fiCpz9KsjLA6pr7n69Z5m--IhAgqW6wOeSlrO_6M1uKdMDrls3d0wJbVvfpDOxrKp-xRgdAXMTofEmc3fExwld-nWKqao84WwlXZG7O6XUu9GcjKMquvmBui5EUXjH6I2TVizi1fNACZ2vAdA0VoaYC2sT9ZLAkYYGkJeTSN2eHEPliW-2IdWS7vfMWlNB7T3fhcf-rnZ_uy7nhyYi5s6EXA4wP0mMs69isULzTrtuboLmdUISbP2Jq_pWYHR1EkNsZKHLnjUfmWEraUXRIzuNnSev8sbTv8_yJaDEOhex5h9AG2XfoSR80MHqUJ5F1WNcY1Ey5UjYKBsM6t9cr0CUD8cImOJ3ePxj_dcTKQfiC8akkonOQ";

class Service {
  post = async (endpoint, data) => {
    jwt_token = "Bearer " + Cookies.get("token");
    api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
    let res = await api.post(endpoint, data);
    return res;
  };

  get = async endpoint => {
    // console.log("getreq", Cookies.get("token") ? "user" : "ytfkuv");
    jwt_token = "Bearer " + Cookies.get("token");
    api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
    let res = await api.get(endpoint);
    return res.data;
  };

  // getFull = async endpoint => {
  //   api.defaults.headers.common["Authorization"] =
  //     "Bearer " + (await AsyncStorage.getItem("token"));
  //   let res = await api.get(endpoint);
  //   return res;
  // };

  // patch = async (endpoint, data) => {
  //   api.defaults.headers.common["Authorization"] =
  //     "Bearer " + (await AsyncStorage.getItem("token"));
  //   let res = await api.patch(endpoint, data);
  //   return res.data;
  // };

  delete = async endpoint => {
    jwt_token = "Bearer " + Cookies.get("token");
    api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
    let res = await api.delete(endpoint);
    return res.data;
  };
  put = async (endpoint, data) => {
    jwt_token = "Bearer " + Cookies.get("token");
    api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
    let res = await api.put(endpoint, data);
    return res.data;
  };
}

export default Service;
