import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
// import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Color } from "../../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Service from "../../services/httpService";
import "react-phone-input-2/lib/material.css";
const GetYourFreeAccount = () => {
  const navigate = useNavigate();
  const services = new Service();
  const errRef = useRef();
  const Mobile = useMediaQuery("(max-width:800px)");
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [phone, setPhone] = useState("");
  console.log("phone", phone);
  console.log("phone", phone.length);
  const [college_name, setCollege_name] = useState();
  const [college_id, setCollege_id] = useState();
  const [errorPhone, setErrorPhone] = useState("");
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: null,
    email: "",
    linkedin: "",
    college_id: null,
  });
  //   college data get
  const [collegeData, setCollegeData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  console.log("isSubmit", formErrors);
  const [errMsg, setErrMsg] = useState("");
  console.log("isSubmit", errMsg);

  //   college get request
  const handleCollegeGetter = async () => {
    try {
      const collegeDataGet = await services.get(`/superAdmin/mentor_college`);

      console.log("college getter", collegeDataGet);
      setCollegeData([...collegeDataGet.data]);
      return collegeDataGet.data;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const handleEffect = async () => {
      let collegeDetails = await handleCollegeGetter();
      console.log("is college data available?", collegeDetails);

      data_manipulation_collegeDetails(collegeDetails);
    };
    handleEffect();
  }, []);
  console.log("setCollegeData", collegeData);

  const data_manipulation_collegeDetails = collegeDetails => {
    let temp_college = collegeDetails;
    console.log("tem data", temp_college);
    temp_college = temp_college.map(college => {
      college.key = college.college_name;
      college.id = college.id;
      return college;
    });
    console.log("temp data", temp_college);
    setCollege_name(temp_college);
    console.log("college nameee", college_name);
    console.log("college data", collegeData);
  };

  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormInputs({ ...formInputs, [name]: value });
    if (value === "") {
      setFormInputs({ ...formInputs, [name]: null });
    } else if (value === "+91") {
      setFormInputs({ ...formInputs, [name]: null });
    } else if (name === "college_id") {
      setCollege_id(value);
      setFormInputs({ ...formInputs, college_id: value });
    }

    setFormErrors({});
    setErrMsg("");
    console.log("first name", formInputs.first_name);
    console.log("last name", formInputs.last_name);
    console.log("email", formInputs.email);
    console.log("phone no", formInputs.phone);
  };

  //for validation
  const validation = (values, phone) => {
    console.log("values", values);

    const errors = {};

    if (values.first_name === "") {
      errors.first_name = "Enter a valid name";
    }
    if (values.email === "" || values.email === null) {
      errors.email = "Email is Invalid";
    }
    if (phone?.length <= 7) {
      errors.phone = "Enter Correct Phone Number";
      setErrorPhone("Enter Correct Phone Number");
      console.log("Phone Number cannot be blank");
    }
    if (phone?.length >= 7) {
      setErrorPhone("");
    }
    if (values.linkedin === "" || values.linkedin === null) {
      errors.linkedin = "LInkedIn can not be empty";
    }
    if (values.college_id === null) {
      errors.college_id = "College can not be empty";
    }
    // if(values.phone.trim())
    return errors;
  };

  const handleSubmit = async () => {
    let formError = { ...formErrors };
    formError = validation(formInputs, phone);
    setFormErrors(formError);
    console.log("Form errors length:", formError);
    console.log({ formInputs });

    console.log();
    if (Object.keys(formError).length === 0 && errorPhone === "") {
      try {
        const mentorInterestDetails = await services.post(
          `/superAdmin/mentor`,
          {
            first_name: formInputs.first_name,
            last_name:
              formInputs.last_name === "" ? null : formInputs.last_name,
            email: formInputs.email,
            ph_no: "+" + phone,
            linkedin: formInputs.linkedin,
            college_name: formInputs.college_id,
          }
        );
        if (mentorInterestDetails.status === 200) {
          setErrMsg(
            <p style={{ color: Color.green }}>
              We have received your request. We will get back to you shortly via
              mail.
            </p>
          );
        }
        console.log(mentorInterestDetails);
      } catch (err) {
        console.log(err);
        console.log(err?.response.data);
        setErrMsg(
          err?.response.data.message
            ? err?.response.data.message
            : err?.response.data.errMess
        );
      }
    }
  };

  console.log("college_id", college_id, errMsg);

  return (
    <div
      className="login-Container"
      style={{
        width: Mobile ? "100%" : "max(100%,1200px)",
        ...(Mobile && {
          overflow: "hidden",
          width: "100%",
          maxWidth: "100%",
          overflowY: "auto",
        }),
      }}
    >
      {!Mobile && (
        <div className="login-Resumes_Image">
          <img
            className="imageFlexible"
            src="backgroundImage.png"
            alt="background"
            style={{
              backgroundRepeat: "none",
              float: "left",
              margin: "0",
              width: "100%",
              height: "100%",
              position: "static",
            }}
          />
          <img
            className="imageFlexible"
            src="logo_welcome.png"
            alt="background"
            style={{
              position: "absolute",
              height: "150px",
              width: "150px",
              top: "max(30%,200px)",
              left: "20%",
            }}
          />
          <img
            src="CareerCarve.png"
            alt="background"
            style={{
              position: "absolute",
              top: "max(52%,350px)",
              left: "13%",
            }}
          />
          <p
            style={{
              position: "absolute",
              top: "max(62%, 430px)",
              left: "13.5%",
              fontSize: "20px",
            }}
          >
            {" "}
            Placement | Preparedness | Perfected
          </p>
        </div>
      )}

      <div
        className="login-Main-content"
        style={{ width: Mobile ? "100%" : "50%" }}
      >
        {Mobile && (
          <img
            src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/career+carve+logo+vector+small.png"
            style={{ margin: "0 auto", width: "200px", marginTop: "1rem" }}
            alt="Career Carve Logo"
          />
        )}

        <p
          className="login-name"
          style={{
            whiteSpace: "nowrap",
            ...(Mobile && {
              paddingLeft: "1rem",
              fontSize: "28px",
              textAlign: "left",
              marginTop: "1rem",
            }),
          }}
        >
          Want to become a Mentor?
        </p>
        <p
          className="mentor_interest_details"
          style={{
            ...(Mobile && {
              marginTop: ".5rem",
              textAlign: "left",
              paddingLeft: "1rem",
            }),
          }}
        >
          Enter your details and we shall get back to you
        </p>
        <div className="login-l-form">
          <form
            action=""
            class="login-form"
            style={{
              ...(Mobile && {
                paddingTop: "1rem",
                paddingRight: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
                width: "100%",
              }),
            }}
          >
            <div
              className="outlined-basic"
              style={{ width: Mobile ? "100%" : "400px" }}
            >
              <Box sx={{}}>
                <div className="firstNameAndLastName">
                  <TextField
                    onChange={handleChange}
                    value={formInputs.first_name}
                    name="first_name"
                    id="outlined-basic"
                    label="First Name"
                    required
                    variant="outlined"
                    sx={{ mr: 2.5 }}
                    InputProps={{ sx: { height: 50.4 } }}
                    InputLabelProps={{
                      required: false, // Disable automatic rendering of the asterisk
                      sx: {
                        "&.Mui-focused": {
                          // Target label when the field is focused
                          color: "primary.main", // Maintain primary color on focus
                        },
                        "&::after": {
                          // Use the ::after pseudo-element to add the asterisk after the label
                          content: '"*"', // Content of the pseudo-element is the asterisk
                          color: "red", // Color of the asterisk
                          marginLeft: "2px", // Space between the label text and the asterisk
                        },
                      },
                    }}
                  />
                  <TextField
                    onChange={handleChange}
                    value={formInputs.last_name}
                    name="last_name"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    //   sx={{ mr: 3 }}
                    InputProps={{ sx: { height: 50.4 } }}
                  />
                </div>
                <p className="alert-errMsg-input">{formErrors.first_name}</p>
              </Box>
              <Box sx={{ mb: Mobile ? 4 : 3 }}>
                <TextField
                  className=""
                  onChange={handleChange}
                  value={formInputs.email}
                  name="email"
                  required
                  id="outlined-basic"
                  label="Email "
                  variant="outlined"
                  sx={{ width: Mobile ? "100%" : 400 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                  InputLabelProps={{
                    required: false, // Disable automatic rendering of the asterisk
                    sx: {
                      "&.Mui-focused": {
                        // Target label when the field is focused
                        color: "primary.main", // Maintain primary color on focus
                      },
                      "&::after": {
                        // Use the ::after pseudo-element to add the asterisk after the label
                        content: '"*"', // Content of the pseudo-element is the asterisk
                        color: "red", // Color of the asterisk
                        marginLeft: "2px", // Space between the label text and the asterisk
                      },
                    },
                  }}
                />
                <p className="alert-errMsg-input">{formErrors.email}</p>
              </Box>
              <Box className="signup-form__div" sx={{ mb: Mobile ? 4 : 3 }}>
                <PhoneInput
                  className="signup-div__input"
                  onEnterKeyPress={handleSubmit}
                  countryCodeEditable={false}
                  specialLabel={
                    <>
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={phone}
                  placeholder=" "
                  required
                  country="in"
                  name="phoneno"
                  countrySelectProps={{ unicodeFlags: true }}
                  onChange={val => setPhone(val)}
                />
                <p className="alert-errMsg-input">{errorPhone}</p>
              </Box>
              <Box sx={{ mb: Mobile ? 4 : 3 }}>
                <TextField
                  className=""
                  onChange={handleChange}
                  value={formInputs.linkedin}
                  required
                  name="linkedin"
                  id="outlined-basic"
                  label="LinkedIn URL"
                  variant="outlined"
                  sx={{ width: Mobile ? "100%" : 400 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  style={{
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                  InputLabelProps={{
                    required: false, // Disable automatic rendering of the asterisk
                    sx: {
                      "&.Mui-focused": {
                        // Target label when the field is focused
                        color: "primary.main", // Maintain primary color on focus
                      },
                      "&::after": {
                        // Use the ::after pseudo-element to add the asterisk after the label
                        content: '"*"', // Content of the pseudo-element is the asterisk
                        color: "red", // Color of the asterisk
                        marginLeft: "2px", // Space between the label text and the asterisk
                      },
                    },
                  }}
                />
                <p className="alert-errMsg-input">{formErrors.linkedin}</p>
              </Box>
              <Box sx={{ mb: Mobile ? 4 : 3 }}>
                <FormControl
                  sx={{ minWidth: 120, height: 50.4, maxHeight: 50.4 }}
                  // size="small"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    MBA College
                    <span style={{ color: "red" }}>*</span>{" "}
                    {/* Required asterisk */}
                  </InputLabel>
                  <Select
                    name="college_id"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formInputs.college_id}
                    label="MBA College"
                    style={{ height: 50.4, textAlign: "left" }}
                    onChange={handleChange}
                  >
                    {console.log("college namee", college_name)}
                    {college_name?.map(college => (
                      <MenuItem key={college.id} value={college.key}>
                        {college.key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <p className="alert-errMsg-input">{formErrors.college_id}</p>
              </Box>
              <p
                className="mentor_interest_details"
                style={{
                  marginTop: "5px",
                  ...(Mobile && {
                    textAlign: "left",
                    marginTop: "1rem",
                    marginBottom: ".7rem",
                  }),
                }}
              >
                Part time or certification programmes from the institutes listed
                below are not considered
              </p>
              {/* <div className="signup-form__div"> */}
              <p
                style={{
                  whiteSpace: "nowrap",
                  color: Color.neutralMidGrey,
                  margin: 0, // Remove default margin
                  padding: 0, // Remove default padding
                  marginLeft: "-20px",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "1rem",
                }}
              >
                <Checkbox
                  checked={privacyPolicyChecked}
                  style={{ textAlign: "left", marginRight: "5px", padding: 0 }} // Adjust margin and padding for Checkbox
                  onChange={e => setPrivacyPolicyChecked(e.target.checked)}
                />
                I agree to the{" "}
                <a
                  href="/TermsOfUse"
                  style={{ color: Color.primary1, cursor: "pointer" }}
                  target="_blank"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="/PrivacyPolicy"
                  style={{ color: Color.primary1, cursor: "pointer" }}
                  target="_blank"
                >
                  Privacy Statement
                </a>
              </p>

              {/* </div> */}
              <button
                type="button"
                className="Login-button"
                style={{
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  width: Mobile ? "100%" : "400px",
                  marginTop: "1.5rem",
                }}
                disabled={!privacyPolicyChecked}
                onClick={e => handleSubmit(e)}
              >
                Become a mentor now
              </button>
              <div
                style={{
                  width: "100%",
                  ...(Mobile && { textAlign: "center" }),
                }}
              >
                <p
                  ref={errRef}
                  className="alert-errMsg-button"
                  aria-live="assertive"
                  style={{
                    ...(Mobile && { width: "100%", textAlign: "center" }),
                  }}
                >
                  {errMsg}
                </p>
              </div>
            </div>
          </form>
        </div>

        <div
          style={{
            bottom: 0,
            display: "flex",
            alignItems: "center",
            marginBottom: "2rem",
            // marginTop: "2rem",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>Already have an account?</p>
          <button
            className="LoginNowBUtton"
            onClick={() => navigate("/")}
            style={{
              margin: 0,
              marginLeft: "1rem",
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetYourFreeAccount;
