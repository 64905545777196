import { Box, Modal } from "@mui/material";

export const UnauthorizedLoginAgainModal = ({ show }) => {
  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    height: 150,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(5px)",
  };
  return (
    <Modal
      open={show}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box sx={{ ...modalStyles }}>
        Uh Oh! Something went wrong! Please login again
      </Box>
    </Modal>
  );
};
