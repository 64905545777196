// EndSessionContext.js
import React, { createContext, useState } from "react";

export const EndSessionContext = createContext();

export const EndSessionProvider = ({ children }) => {
  const [endSessionCalled, setEndSessionCalled] = useState(false);

  return (
    <EndSessionContext.Provider
      value={{ endSessionCalled, setEndSessionCalled }}
    >
      {children}
    </EndSessionContext.Provider>
  );
};
