import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import "../../Students/SessionNotStarted/SessionNotStarted.css";

const EndSession = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_type = location.state?.user_type;
  console.log("user type", user_type);
  const handleNavigation = () => {
    if (location.state?.navigate && location.state?.navigate === "offline") {
      console.log("ok here in mentor nav");
      // navigate("/SessionSchedule?process_id=" + location.state?.process_id);
      navigate("/OfflineSession");
    } else {
      navigate("/", { state: { user_type: user_type } });
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Container maxWidth="sm">
          <p className="sessionNotStartedPTag">
            Your Session has ended. Thank you.
          </p>
          <button
            onClick={() => handleNavigation()}
            className="Rephrasebutton"
            style={{ width: "100%", height: "45px" }}
          >
            Go Back to All Activities
          </button>
        </Container>
      </Grid>
    </Grid>
  );
};

export default EndSession;
