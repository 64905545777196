import * as React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
import ImageScroll from "../ImagesInfiniteScroll/ImagesInfiniteScroll";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CollegeTieUps() {
  const Mobile = useMediaQuery("(min-width:800px)");

  return (
    <div style={{ marginTop: "141px", marginBottom: "141px" }}>
      <Typography
        fontSize="24px"
        align="center"
        sx={{
          position: "relative",
          "&::after": {
            content: "''",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "-10%",
            width: "64px",
            borderBottom: "2px solid #1C8EA8",
          },
          paddingBottom: "9px",
        }}
      >
        College Tie-Ups
      </Typography>

      <Container
        className="landing-container"
        sx={{
          width: Mobile ? "max(100%, 800px)" : "80%",
          flexDirection: Mobile ? "row" : "column",
          display: "flex",
          justifyContent: "center", // center horizontally
          alignItems: "center", // center vertically
        }}
      >
        <Container
          sx={{
            // width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography fontSize="57px" fontWeight="400px" align="left">
            NIRF Top Rank
          </Typography>
          <Typography
            fontSize="57px"
            fontWeight="400px"
            align="left"
            color={Color.primary1}
          >
            Business Schools
          </Typography>
          <Typography align="left" fontSize="16px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu{" "}
            <br />
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus{" "}
            <br />
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            <br />
            tellus elit sed risus. Maecenas eget condimentum velit,
          </Typography>
          <Button
            variant="contained"
            // color="warning"
            size="large"
            sx={{
              float: "left",
              marginTop: "40px",
              backgroundColor: Color.primary1,
            }}
          >
            Start Now
          </Button>
        </Container>
        {/* <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        > */}
        <ImageScroll />
        {/* </Container> */}
      </Container>
    </div>
  );
}
