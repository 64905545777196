import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CCAppBar from "../../components/AppBar/AppBar";
import ServiceCM from "../../services/cartmoduleServices";
// import Service from "../../services/httpService";
import "../Pricing/Buy1x1/Buy1x1.css";
import "../Pricing/OurPackages/OurPackages.css";
const Pricing = () => {
  const navigate = useNavigate();
  // const service = new Service();
  const serviceCM = new ServiceCM();
  const [skuInfo, setSkuInfo] = useState([]);
  const [bundleInfo, setBundleInfo] = useState([]);
  const [skuIds, setSkuIds] = useState([]);
  // const [tempQuantity, setTempQuantity] = useState({});

  const [temp_quantity, setTemp_quantity] = useState([
    { search_tbl: "", id: null, quantity: 0, price: 0, total_price: 0 },
  ]);
  // handle sku quality increment and decrement
  const handleSkuIncrement = (key, index, price) => {
    let temp_sku_info = JSON.parse(JSON.stringify(skuInfo));
    temp_sku_info[index].quantity = temp_sku_info[index].quantity + 1;
    setSkuInfo(temp_sku_info);
    setTemp_quantity(
      temp_quantity?.map(item => {
        if (item.id === key && item.search_tbl === "sku") {
          return {
            ...item,
            quantity: temp_sku_info[index].quantity,
            total_price: price * temp_sku_info[index].quantity,
          };
        }
        return item;
      })
    );
    console.log("key and index", { key }, { index });
    console.log("asdfghjkxcvbn", temp_sku_info);
  };

  const handleSkuDecrement = (key, index, price) => {
    let temp_sku_info = JSON.parse(JSON.stringify(skuInfo));
    temp_sku_info[index].quantity = temp_sku_info[index].quantity - 1;
    setSkuInfo(temp_sku_info);
    setTemp_quantity(
      temp_quantity?.map(item => {
        if (item.id === key && item.search_tbl === "sku") {
          return {
            ...item,
            quantity: temp_sku_info[index].quantity,
            total_price: price * temp_sku_info[index].quantity,
          };
        }
        return item;
      })
    );
    console.log("key and index", { key }, { index });
  };

  // handle bundle increment and decrement
  const handleBundleIncrement = (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(bundleInfo));
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity + 1;
    setBundleInfo(temp_bundle_info);
    setTemp_quantity(
      temp_quantity?.map(item => {
        if (item.id === key && item.search_tbl === "bundle") {
          return {
            ...item,
            quantity: temp_bundle_info[index].quantity,
            total_price: price * temp_bundle_info[index].quantity,
          };
        }
        return item;
      })
    );
  };

  const handleBundleDecrement = (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(bundleInfo));
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity - 1;
    setBundleInfo(temp_bundle_info);
    setTemp_quantity(
      temp_quantity?.map(item => {
        if (item.id === key && item.search_tbl === "bundle") {
          return {
            ...item,
            quantity: temp_bundle_info[index].quantity,
            total_price: price * temp_bundle_info[index].quantity,
          };
        }
        return item;
      })
    );
  };
  // bundle buy now and add to cart click
  const handleBundleBuyNowClick = async (key, index, price) => {
    let bundle_temp_quantity = {
      search_tbl: "bundle",
      id: key,
      quantity: 1,
      total_price: price * 1,
    };
    let updatedTempQuantity = [...temp_quantity, bundle_temp_quantity];
    setTemp_quantity(updatedTempQuantity);
    localStorage.setItem("temp_quantity", JSON.stringify(updatedTempQuantity));
    navigate("/CartScreen", { state: { temp_quantity: bundle_temp_quantity } });

    console.log("buy now key id clicked", key);
    console.log("inside temp quatity", bundle_temp_quantity);
  };

  const handleBundleAddToCartClick = async (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(bundleInfo));
    temp_bundle_info[index].addToCart = true;
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity + 1;
    setBundleInfo(temp_bundle_info);
    console.log("add to cart key id clicked", key);
    console.log("temp quantity clicked", temp_bundle_info[index].quantity);
    let bundle_temp_quantity = {
      search_tbl: "bundle",
      id: key,
      quantity: temp_bundle_info[index].quantity,
      total_price: price * temp_bundle_info[index].quantity,
    };
    let updatedTempQuantity = [...temp_quantity, bundle_temp_quantity];
    setTemp_quantity(updatedTempQuantity);
    localStorage.setItem("temp_quantity", JSON.stringify(updatedTempQuantity));
  };
  // skus buy and add to cart click
  const handleSkuBuyNowClick = async (key, index, price) => {
    let bundle_temp_quantity = {
      search_tbl: "sku",
      id: key,
      quantity: 1,
      total_price: price * 1,
    };
    let updatedTempQuantity = [...temp_quantity, bundle_temp_quantity];
    setTemp_quantity(updatedTempQuantity);
    localStorage.setItem("temp_quantity", JSON.stringify(updatedTempQuantity));
    navigate("/CartScreen", { state: { temp_quantity: bundle_temp_quantity } });

    console.log("key id for sku buyNow", key);
  };

  const handleSkuAddToCartClick = async (key, index, price) => {
    let temp_sku_info = JSON.parse(JSON.stringify(skuInfo));
    temp_sku_info[index].addToCart = true;
    temp_sku_info[index].quantity = temp_sku_info[index].quantity + 1;
    setSkuInfo(temp_sku_info);
    console.log("id for add to cart", key);
    let bundle_temp_quantity = {
      search_tbl: "sku",
      id: key,
      quantity: temp_sku_info[index].quantity,
      total_price: price * temp_sku_info[index].quantity,
    };
    let updatedTempQuantity = [...temp_quantity, bundle_temp_quantity];
    setTemp_quantity(updatedTempQuantity);
    localStorage.setItem("temp_quantity", JSON.stringify(updatedTempQuantity));
    // setSkusGroupButton(true);
    console.log("key id for sku add to cart", { key }, { index });
  };

  // get request for bundle
  const bundleGetter = async () => {
    try {
      const res = await serviceCM.get("/bundle");
      console.log("bundle RESPONSE:", res.data);
      // setBundleInfo([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const data_manupulator_bundle = bundle => {
    console.log("bundle data:", bundle);
    let temp_rowsData = JSON.parse(JSON.stringify(bundle));

    console.log("temp rows:", temp_rowsData);
    const temp_skuIds = [];
    console.log("bundleInfo available", skuIds);
    temp_rowsData.map(cell => {
      const skusObj = JSON.parse(cell.skus);
      console.log("skus obj", skusObj);
      const ids = skusObj.skus.map(sku => sku.id);
      console.log("skus ids", ids);
      temp_skuIds.push(...ids);
    });
    console.log("temp sku ids", temp_skuIds);
    setSkuIds(temp_skuIds);
  };
  console.log("bundle info", bundleInfo);
  console.log("sku only ids", skuIds);

  //   get request of skus
  const SKUGetter = async () => {
    try {
      const res = await serviceCM.get("/sku");
      console.log("Duration RESPONSE:", res.data);
      // setSkuInfo([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  console.log("skuInfo", skuInfo);

  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      const storedTempQuantity = JSON.parse(
        localStorage.getItem("temp_quantity")
      );
      console.log("localstorage data", storedTempQuantity);
      if (storedTempQuantity) {
        setTemp_quantity(storedTempQuantity);
      }
      let sku = await SKUGetter();

      let bundle = await bundleGetter();
      if (storedTempQuantity && storedTempQuantity.length > 0) {
        storedTempQuantity?.forEach(item => {
          bundle
            .filter(skuItem => skuItem.id === item.id)
            ?.forEach(
              element => (
                (element.quantity = item.quantity),
                (element.id = element.id),
                (element.search_tbl = "bundle"),
                (element.addToCart = true),
                (element.price = element.actual_price)
              )
            );
        });
        storedTempQuantity?.forEach(item => {
          sku
            .filter(skuItem => skuItem.id === item.id)
            ?.forEach(
              element => (
                (element.quantity = item.quantity ? item.quantity : 0),
                (element.id = element.id),
                (element.search_tbl = "sku"),
                (element.addToCart = item.quantity > 0 ? true : false),
                (element.price = element.price)
              )
            );
        });
      } else {
        // Set default values for bundle items
        bundle?.forEach(
          element => (
            (element.quantity = 0),
            (element.id = element.id),
            (element.search_tbl = "bundle"),
            (element.addToCart = false),
            (element.price = element.actual_price)
          )
        );
        sku?.forEach(
          element => (
            (element.quantity = 0),
            (element.id = element.id),
            (element.search_tbl = "sku"),
            (element.addToCart = false),
            (element.price = element.price)
          )
        );
      }
      setSkuInfo(sku);
      setBundleInfo(bundle);

      // storedTempQuantity.forEach(item => {
      //   bundle.forEach(
      //     element => (
      //       (element.quantity = item.quantity ? item.quantity : 0),
      //       (element.id = element.id),
      //       (element.search_tbl = "bundle"),
      //       (element.addToCart = item.quantity > 0 ? true : false),
      //       (element.price = element.actual_price)
      //     )
      //   );
      // });

      // setBundleInfo(bundle);

      // const bundleSkus = await bundleSkusIds();
      data_manupulator_sku(sku);
      data_manupulator_bundle(bundle);

      console.log("odonil", { sku }, { bundle });
    };

    handleEffect();
  }, []);
  const data_manupulator_sku = sku => {
    console.log("SKU data:", sku);
    let temp_rowsData = JSON.parse(JSON.stringify(sku));
    console.log("sku data", temp_rowsData);
  };

  // const displayCounter = counter > 0;

  return (
    <div>
      {console.log("localstorage state", temp_quantity)}
      <CCAppBar />
      <div style={{ marginTop: "100px", marginBottom: "none" }}>
        <p className="pricingCareerCrave1">Invest in your career goals</p>
        <p className="pricingCareerCrave"> with CareerCarve </p>
        <p className="pricingOurPackage">Our Packages</p>
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Table
            sx={{
              maxWidth: "65%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className="pricingtablerow">
                <TableCell />
                {bundleInfo.map(heading => (
                  <TableCell align="center">{heading.name}</TableCell>
                ))}
              </TableRow>
              <TableRow className="pricingtablerow">
                <TableCell align="left">Price</TableCell>
                {bundleInfo.map(heading => (
                  <TableCell
                    align="center"
                    // style={{
                    //   textDecoration: heading.actual_price
                    //     ? "line-through"
                    //     : "none",
                    // }}
                  >
                    &#x20b9; {heading.actual_price} <br />
                    &#x20b9; {heading.discounted_price}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {skuInfo.map(row => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.name} ({row.duration})
                  </TableCell>
                  {console.log("bundle info beforemap", bundleInfo)}
                  {console.log("asdfghj", bundleInfo)}

                  {bundleInfo.map((cell, i) => {
                    let bundleSkus = JSON.parse(cell.skus)?.skus?.some(
                      element => element?.id === row?.id
                    );

                    return bundleSkus ? (
                      <>
                        {console.log("asdfghj", JSON.parse(cell.skus).skus)}

                        <TableCell align="center">
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        {console.log(
                          "asdfghj",
                          JSON.parse(cell.skus).skus.find(
                            element => element.id === row.id
                          )
                        )}
                        {console.log("asdfghj", JSON.parse(cell.skus).skus)}
                        {console.log("row?.id", row?.id)}

                        <TableCell align="center">-</TableCell>
                      </>
                    );
                  })}
                </TableRow>
              ))}
              <TableRow className="pricingtablerow">
                <TableCell />
                {bundleInfo.map((heading, i) => (
                  <TableCell key={heading.id} align="center">
                    <button
                      onClick={() => {
                        handleBundleBuyNowClick(
                          heading?.id,
                          i,
                          heading.actual_price
                        );
                      }}
                      style={{
                        width: "100px",
                        marginRight: 0,
                        marginBottom: ".5rem",
                        height: "35px",
                      }}
                      className="pricingSingleACtivityButton"
                    >
                      Buy Now
                    </button>
                    <br />
                    {!heading?.addToCart && (
                      <button
                        onClick={() => {
                          handleBundleAddToCartClick(
                            heading?.id,
                            i,
                            heading.actual_price
                          );
                        }}
                        style={{ width: "100px", height: "35px" }}
                        className="pricingSingleACtivityButton1"
                      >
                        Add to Cart
                      </button>
                    )}
                    {heading.addToCart && (
                      <ButtonGroup
                        // style={{ alignItems: "left" }}
                        className="pricingSingleACtivityOrder"
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            handleBundleIncrement(heading?.id, i, heading.price)
                          }
                        >
                          +
                        </Button>

                        <Button disabled>{heading?.quantity}</Button>

                        <Button
                          onClick={() =>
                            handleBundleDecrement(heading?.id, i, heading.price)
                          }
                        >
                          -
                        </Button>
                      </ButtonGroup>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="buy1x1EntireDiv">
        <p className="PricingBuyText">Buy 1X1 Separately</p>
        <p className="PricingPackageText">
          Can’t find what you’re looking for in our packages? Make your own
          package!
        </p>
        {skuInfo.map((description, i) => (
          <div className="pricingActivityInfos">
            <div className="pricingSingleActivityInfo">
              <p className="skuNames">{description.name}</p>
              <p className="skuNames" style={{ fontSize: "16px" }}>
                &#x20b9; {description.price}
              </p>
            </div>
            <div className="pricingSingleActivityInfo1">
              <p className="pricingSingleACtivityDescription">
                {description.description}
              </p>
              <button
                key={description?.id}
                onClick={() =>
                  handleSkuBuyNowClick(description?.id, i, description.price)
                }
                className="pricingSingleACtivityButton"
              >
                Buy Now
              </button>
              {!description.addToCart && (
                <button
                  key={description.id}
                  onClick={() =>
                    handleSkuAddToCartClick(
                      description?.id,
                      i,
                      description.price
                    )
                  }
                  className="pricingSingleACtivityButton1"
                >
                  Add to Cart
                </button>
              )}
              {description.addToCart && (
                <ButtonGroup
                  style={{ justifyContent: "center" }}
                  className="pricingSingleACtivityOrder"
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    onClick={() =>
                      handleSkuIncrement(description?.id, i, description?.price)
                    }
                  >
                    +
                  </Button>

                  <Button disabled>{description.quantity}</Button>

                  <Button
                    onClick={() =>
                      handleSkuDecrement(description?.id, i, description.price)
                    }
                  >
                    -
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
