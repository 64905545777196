import React from "react";
import "../PrivacyPolicy/PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacyPolicymainConatiner">
      <div className="privacyPolicymainConatiner1">
        <div>
          <p className="privacyPolicyHeader">Privacy Policy</p>
          <p className="normalprivacyPolicy">
            CareerCarve [Parivartana Consulting Private Limited], including its
            subsidiaries, controlled entities, affiliates and related parties
            (hereinafter referred to as the “CareerCarve” or “careercarve.com” /
            “careercarve.in” / “resumes.digital”), a company incorporated under
            the provisions of the Companies Act, 2013 had created and owns the
            domain name, “CareerCarve.com”, “careercarve.in” and
            “resumes.digital” (hereinafter referred to as the “Website” or
            “Site”). CareerCarve provides the services through the 'Website” or
            “Site” and the mobile application called “[CareerCarve]” App
            (hereinafter referred to as “Application”). At CareerCarve, we are
            highly committed to the privacy of your data and information and in
            providing excellent service to all our customers and visitors of the
            Website and Application. We have prepared this Data Protection and
            Privacy Policy ("Policy") to inform you of the privacy principles
            that govern this Website and Application. For the purposes of this
            Policy, wherever the context so requires, ‘You’ or ‘Your’ or ‘User’
            shall mean any natural or legal person who uses the services
            available in the Website or the Application. The terms “we” “us”
            shall mean CareerCarve or its authorized representatives. This
            Policy shall govern the information collection, including all your
            personal information collected by CareerCarve, storage and
            dissemination through the Website and Application. This Privacy
            Policy is integral part of the Terms of Use of the Website available
            at [Please provide the link for Terms of Use] and the Application
            and this Policy, read along with the Terms of Use, shall govern your
            use of this Website and the services of CareerCarve. You are advised
            to read this Policy carefully. By accessing the services provided by
            careercarve.com/ careercarve.in / resumes.digital you agree to the
            collection and use of your data by careercarve.com/ careercarve.in /
            resumes.digital and certain authorized third-party service providers
            in the manner provided in this Policy. If you do not agree with this
            Policy, please do not use the Website.
          </p>
          <p className="privacyPolicySubHeading">
            What is covered in this Policy?
          </p>
          <p className="normalprivacyPolicy">
            This Policy covers how CareerCarve treats personal information that
            CareerCarve collects and receives, including information related to
            your past use of the Website, products and services available in the
            Website or the Application. When you register for our service, we
            would require your personal information that is personally
            identifiable like your name, address, email address, LinkedIn ID or
            phone number, and other information that is not otherwise publicly
            available and is essentially required for registering and receiving
            services from us. CareerCarve shall not be, in any manner,
            responsible for any acts or omissions, deeds or things done or
            committed by any person not being directly employed or authorized
            specifically by CareerCarve. In addition, certain CareerCarve’s
            associated/partner companies have their own privacy statements which
            can be viewed by clicking on to their respective links.
          </p>
          <p className="privacyPolicySubHeading">Privacy - Our Commitment</p>
          <p className="normalprivacyPolicy">
            At CareerCarve, we are extremely committed to protect your privacy.
            The data provided by the User shall not be used by CareerCarve,
            except in the manner provided in this Policy. CareerCarve undertakes
            not to rent and sell your personal information, provided by you
            during the use of the Website or Application to any third parties.
            You acknowledge that, CareerCarve is authorized to use the data
            provided by you, in order to improve the services in the Website or
            Application, by providing it to our partners. Your comfort, trust
            and confidence are of paramount importance to us.
          </p>
          <p className="privacyPolicySubHeading">
            What Information is collected from you.
          </p>
          <p className="privacyPolicySubHeading" style={{ marginTop: "0px" }}>
            Profile Information
          </p>
          <p className="normalprivacyPolicy">
            CareerCarve collects the details provided by you on registration
            which includes but not limited to:
          </p>
          <br />
          a. Personal information like name, phone number, address, email and
          other contact details and all other personal information that is
          provided with your google account.
          <br />
          b. Specific requirements for you to avail the services offered by us
          including the details regarding your background information and such
          other information that would be required for performing the services
          efficiently;
          <br />
          c. Your interests, search patterns in the Website or the App,
          including the interests in curated course plans/ specific training on
          your usage of the Website or the Application together with information
          we learn about you from your use of our service and your visits to our
          Site;
          <br />
          d. Any other information that is required by us to efficiently provide
          the services and for protecting the business interest of CareerCarve.
          <br />
          <p className="normalprivacyPolicy">
            Any transaction or banking information including details of credit /
            debit card number, cardholder name, expiration date, CVV or any
            other information as required for online transactions are not
            collected or stored by CareerCarve. However, for the purpose of
            providing better services and understanding the user patterns,
            CareerCarve might collect information that are available in public
            domain including payment methods and type of cards used. CareerCarve
            may also collect certain information automatically, including, but
            not limited to, the type of mobile device you use, your mobile
            devices unique device ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browsers you
            use, and information about the way you use the Website or the
            Application. Please note that, CareerCarve is entitled to collect
            information regarding the pattern of your use of the Website or the
            Application or your previous orders and transactions including the
            product and pricing details, the date of purchase, transaction
            information, payment history etc. We may use the information
            provided to us by you for proper working of the Applications. We may
            collect additional information in connection with your participation
            in any promotions or quizzes offered by us and information you
            provide when giving us feedback or completing profile forms. We also
            monitor and store information relating to customer traffic patterns,
            session log information on Website which includes your navigation
            within the site, source of traffic, IP address (from which we can
            approximately determine user location), browser type, browser
            language, operating system, date and time of request etc. and
            Website use, which enables us to improve the service we provide. We
            will collect only such information as is necessary and relevant to
            us to provide you with the services available on the Site.
          </p>
          <p className="privacyPolicySubHeading">Data Retention Policy</p>
          <p className="normalprivacyPolicy">
            You hereby understand and acknowledge that, you can terminate your
            user account in the Website at any time. Further, you understand
            that we will retain user information provided to us may be required
            for the Website or the Application to function properly and for
            statutory and legal purposes. You hereby agree that the information
            provided by you during your use of the Website or the Application
            may be archived on our servers even after the deletion or the
            termination of your account.
          </p>
          <p className="privacyPolicySubHeading">Anonymous Information</p>
          <p className="normalprivacyPolicy">
            In addition to the information that you explicitly provide during
            your interactions on the Site, we will automatically receive and
            collect certain anonymous information in standard usage logs through
            our Web server, including computer-identification information
            obtained from "cookies" sent to your browser from: <br /> 1. web
            server cookie stored on your hard drive <br /> 2. an IP address,
            assigned to the computer which you use <br /> 3. the domain server
            through which you access our service <br /> 4. the type of computer
            you're using <br /> 5. the type of web browser you're using A
            "cookie" is a small piece of information stored by a Web server on a
            Web browser so it can be later read back from that browser. Cookies
            are useful for enabling the browser to remember information specific
            to a given user. CareerCarve places both permanent and temporary
            cookies in your computer's/ mobile’s hard drive. You can set your
            browser to notify you when you are sent a cookie, and you can decide
            whether or not to accept it.
          </p>
          <p className="privacyPolicySubHeading">
            Who collects the information?
          </p>
          <p className="normalprivacyPolicy">
            The information collected in the use of the Website or the
            Application is entirely collected, stored and owned by CareerCarve.
            However, the User understands that, in case the User connects to any
            other website through the links, including the promotions or
            advertisements, the User shifts to the server of such advertiser and
            hence, any collection or storing of information in the third- party
            websites cannot be controlled by CareerCarve. CareerCarve is not
            responsible for privacy practices or the contents of those linked
            websites. We collect personal information about you as part of the
            registration process, which is voluntary. Other means of collecting
            personal information is through some contests, online events,
            surveys, third party sites etc.
            <br /> We collect anonymous information like traffic information and
            hardware information when you visit our Site. <br />
            <br /> Our advertisers and partners may collect their own anonymous
            information through their own cookies for which we are not
            responsible for. Use of the Website or the Application of the
            CareerCarve is available only to persons who can form a legally
            binding contract under Indian Contract Act, 1872. CareerCarve
            understands the importance of protecting children's privacy,
            especially in an online environment. Our Sites are not intended to
            be used by or service for children 18 years of age or younger. It is
            our policy never to knowingly collect information about anyone under
            the age of 18.
          </p>
          <p className="privacyPolicySubHeading">Information Usage</p>
          <p className="normalprivacyPolicy">
            We use your personal information for the purpose of processing your
            registration, to process any orders that you may make for any
            products or services displayed on the Website and Application,
            providing you with improved services, contact you when it is needed
            by telephone and e-mail, and to advise you of products and services
            which may be of interest to you, inviting you to be a participant or
            a respondent to an online quiz that is hosted on CareerCarve.
            Further, the relevant information is used by CareerCarve to (i)
            provide you with statements of your account; (ii) to communicate
            with you on any matter relating to the conduct of your account; and
            (iii) to communicate the details of any orders / processing of any
            orders placed by you relating to products displayed on the Website.
            <br />
            User and customer information will be sharable with our current and
            future subsidiaries, affiliates and partners which are also involved
            in delivering the service to you. We will also need to share some or
            all of your information as governed in the case of acquisitions by
            or mergers with other business entities. CareerCarve may also use
            aggregate information and statistics for the purposes of monitoring
            Website usage in order to help us develop the Website, our products
            and services and may provide such Aggregate information to third
            parties on an aggregate basis with the intention to enhance the
            quality of services provided through the Website or the Application.
            These statistics will not include information that can be used to
            identify any individual user of the Website. <br /> CareerCarve may
            organize quizzes and surveys and the information collected during
            these events may be used by CareerCarve to improve your overall
            customer experience. The information will only be shared with third
            parties on an aggregate basis. [For the purposes of this Policy,
            “Aggregate information” shall mean and include any information that
            is recorded about users and collected into groups, including the
            information relating to pattern of use of the Website and the nature
            of the services / products acquired from the Website, so that it no
            longer reflects or references an individually identifiable user.
            Such information does not identify you individually.] Personal data
            collected by the Website may be transferred to other sites of
            CareerCarve where it is necessary to meet the purpose for which you
            have submitted the information. By submitting data on this Website,
            you are providing explicit consent to transmission of data collected
            on the Website.
            <br /> We use anonymous information like traffic and other data to
            provide us with information to recognize the access privileges to
            our Site, track your participation in any of the events, providing
          </p>
          <br />
          <p className="normalprivacyPolicy">
            you with better content and advertisements, help diagnose the
            problems with our Site and for the purposes detailed in the Policy.
            Cookies would be used to provide a customized experience to our
            users such as identifying recently viewed items (of interest to the
            user) as well as implementing wish list and cart features.
            <br /> We use session log and traffic flow information to understand
            how users are interacting with our Site and to make improvements.
            This will also be used by us to understand and analyse your usage
            pattern which enables us to provide empirical data at an aggregate
            level. We use IP address, traffic sources, user navigation across
            the site, browser and Operating System information etc. to measure
            overall site performance in terms of number of users, user
            engagement, time spent by users on the site etc. <br />
            We will need to use user or customer information in case required in
            aspects such as legal disputes or other legal processes; comply with
            any statutory or regulatory requirement; troubleshoot problems;
            detect and protect against error, fraud and other criminal or
            illegal activity; collect fees owed; enforce our terms and
            conditions; prevent abuse of our services; prevent violation of the
            rights of third parties, other users or the general public.
          </p>
          <p className="privacyPolicySubHeading">
            Information Sharing and Disclosures
          </p>
          <p className="privacyPolicySubHeading" style={{ marginTop: "0px" }}>
            Profile Information
          </p>
          <p className="normalprivacyPolicy">
            CareerCarve does not indulge in the transfer of information for
            commercial purposes. All or any information provided by you during
            the use of the Site or the App or when contracting with us by
            placing any Order shall be solely used for the purpose of providing
            better services to you or facilitating the services undertaken to be
            provided by CareerCarve. In any case, you hereby authorize
            CareerCarve to share such personal information provided by you or
            data collected by us through your usage patterns:
            <br /> a. to any third-party vendors for the purpose of enhancing
            your user experience in the Site or the Application or in order to
            facilitate the services offered to you through the Site or the
            Application;
            <br /> b. to any potential investors, in order to showcase the
            Company outreach and business portfolio or third-party entities, in
            case of any merger or acquisition of CareerCarve;
            <br /> c. to any law enforcement agency for the purpose of
            compliance with any laws for the time being in force.
            <br /> In such cases of sharing or transfer of information,
            CareerCarve would require that these parties agree to process such
            information based on our instructions and in compliance with this
            Policy and any other appropriate confidentiality and security
            measures would be in place in order to protect the security of the
            data provided in the Site or the Application.
          </p>
          <p className="privacyPolicySubHeading">Information Security</p>
          <p className="normalprivacyPolicy">
            We endeavour to take appropriate security measures to protect
            against unauthorized access to or unauthorized alteration,
            disclosure or destruction of data that are provided by you, while
            using of the Website or the Application. These include periodical
            internal reviews of our data collection, storage and processing
            practices and security measures, as well as physical security
            measures
          </p>
          <br />
          <p className="normalprivacyPolicy">
            {" "}
            to guard against unauthorized access to systems where we store
            personal data of the users. We restrict access to personal
            information and such information would be divulged even to the
            authorized representative of CareerCarve including employees,
            contractors and agents only on need to know basis. CareerCarve does
            not divulge the confidential information of the users to any third
            parties or its representatives without imposing confidentiality
            obligations on them. <br />
            Although we endeavour to safeguard the confidentiality of your
            personally identifiable information, transmissions made by means of
            the Internet cannot be made absolutely secure. By using this Site,
            you agree that we will have no liability for disclosure of your
            information due to errors in transmission or unauthorized acts of
            third parties or because of acts that are beyond the control of
            CareerCarve.
            <br /> You further agree that you are solely responsible and liable
            for, and shall indemnify CareerCarve against any and all costs,
            expenses, damages, fees, etc. that CareerCarve may incur or suffer
            due to any personal information or other materials that you post,
            upload, submit, and otherwise make available on the Website and
            Application, including areas of the Website and Application that are
            available to the public. We have no control over and cannot protect
            personal information that you disclose in public areas of the
            Website. If you disclose your personal information in public areas,
            it may be collected and used by third parties, without our or your
            knowledge. You should also understand that by displaying your
            information or photographs on the Website and the internet, for the
            intention of showing the information / those photographs to your
            friends, family, acquaintances, clients, business partners, and
            others, that you directly intended to see the photographs, you are
            relinquishing certain traditional privacy rights, wherein anyone
            with access to the internet has the potential ability to view your
            information / photographs. If you do not wish to relinquish these
            traditional privacy rights, do not share your information /
            photographs.
          </p>
          <p className="privacyPolicySubHeading">Policy Compliance</p>
          <p className="normalprivacyPolicy">
            CareerCarve regularly reviews its compliance with this Policy.
            Please feel free to direct any questions or concerns regarding this
            Policy or CareerCarve's treatment of personal information by
            contacting us through this Website or by e-mailing to us at schedule
            [@] careercarve [.] com. When we receive complaints at this address,
            it is CareerCarve's policy to contact the complaining user regarding
            their concerns. We will cooperate with the appropriate regulatory
            authorities to resolve any complaints regarding the transfer of
            personal data that cannot be resolved between CareerCarve and an
            individual.
          </p>
          <p className="privacyPolicySubHeading">Disputes</p>
          <p className="normalprivacyPolicy">
            Any dispute, controversy or claim directly or indirectly caused by,
            arising out of or relating to this Policy will be governed by the
            laws of India and will be referred to confidential, mandatory and
            binding arbitration in Bengaluru, India. The arbitration will be
            conducted on an expedited basis before a single arbitrator appointed
            by both the Parties to the dispute and the arbitration shall be
            conducted according to the Indian Arbitration and Conciliation Act,
            1996 (as amended). The arbitrator's award shall be substantiated in
            writing and will be final and binding on you and CareerCarve.
            Subject to the above, you agree to submit yourself to the exclusive
            jurisdiction of the Courts in Bengaluru, India.
          </p>
          <p className="privacyPolicySubHeading">Your Choices</p>
          <p className="normalprivacyPolicy" style={{ paddingBottom: "2rem" }}>
            By submitting your information, you consent to the use of that
            information as set out in this Policy. We welcome your views on this
            Website, Application and the Policy. However, submitting personally
            identifiable information is entirely voluntary. You are not required
            to register with us in order to browse our Site. Please note that we
            offer curated services only to users who do register. At any point
            in time, you can correct and make changes to your personal
            information by accessing your information in My Account section of
            the Website or the Application. You may change your interests at any
            time and may opt-in or opt-out of any marketing / promotional /
            newsletters mailings. CareerCarve reserves the right to send you
            certain service related communication, considered to be a part of
            your CareerCarve account without offering you the facility to
            opt-out. You may update your information and change your account
            settings at any time. If we plan to use your personally identifiable
            information for any commercial purposes, we will notify you at the
            time we collect that information and allow you to opt-out of having
            your information used for those purposes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
