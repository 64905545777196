import React from "react";
import { Color } from "../../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useNavigate } from "react-router-dom";
import "../PrivacyPolicy/PrivacyPolicy.css";
import "../Login/Login.css";
const DeleteAccountNotice = () => {
  const Mobile = useMediaQuery("(max-width:800px)");

  // const navigate = new useNavigate();
  return (
    <div className="privacyPolicymainConatiner">
      <div className="privacyPolicymainConatiner1" style={{}}>
        <div>
          <p
            className="privacyPolicyHeader"
            style={{
              marginBottom: "1rem",
              fontSize: Mobile ? "28px" : "32px",
              // whiteSpace: "nowrap",
            }}
          >
            Delete my CareerCarve Account
          </p>
          <p className="normalprivacyPolicy" style={{ marginBottom: "1rem" }}>
            You can request to delete your account permanently from the
            CareerCarve app or the web.
          </p>
          <p style={{ marginBottom: "1rem" }}>
            <span
              className="normalprivacyPolicy"
              style={{ fontWeight: "bold", marginRight: ".1rem" }}
            >
              Note:
            </span>
            <span
              className="normalprivacyPolicy"
              style={{ marginBottom: "1rem" }}
            >
              If you have a 'Resumes.Digital' account using the same
              credentials, it will also be deleted when your request is
              complete.
            </span>
          </p>
          <p className="normalprivacyPolicy" style={{ marginBottom: "1rem" }}>
            Before you can request to delete your CareerCarve account, we'll ask
            you to prove it's really you by asking for your account email ID.
          </p>
          <p
            className="privacyPolicySubHeading"
            style={{ fontSize: "24px", marginBottom: ".5rem" }}
          >
            Delete your account
          </p>
          <p className="normalprivacyPolicy" style={{ marginBottom: "1rem" }}>
            Open the link below and enter your email ID. After verification, the
            request to delete your account will be successfully sent.
          </p>
          <a
            href="/DeleteAccount"
            style={{ color: Color.primary1, marginBottom: ".5rem" }}
          >
            <button
              // onClick={() => navigate("/DeleteAccount")}
              type="link"
              style={{ color: Color.primary1, marginBottom: "1rem" }}
            >
              <u>Delete my CareerCarve account</u>
            </button>
          </a>
          <p
            className="privacyPolicySubHeading"
            style={{
              marginBottom: ".5rem",
              fontSize: "24px",
              marginTop: "1rem",
            }}
          >
            Delete your account from the CareerCarve app
          </p>

          <p className="normalprivacyPolicy" style={{ marginBottom: "1rem" }}>
            1. Go to <b>Profile</b> <br />
            2. Select <b>Delete Account</b> <br />
            3. Click{" "}
            <span>
              <a href="/DeleteAccount" style={{ color: Color.blueBlack }}>
                <u>here</u>
              </a>
            </span>{" "}
            and verify your email ID to request deletion
          </p>
          <p className="normalprivacyPolicy" style={{ marginBottom: "1rem" }}>
            After completing the process above, your account will immediately go
            offline and be permanently deleted after 30 days. Any unused
            activities, promotions, purchases and personal information including
            name, email and phone number will be permanently deleted after 30
            days.
          </p>
          <p className="normalprivacyPolicy" style={{ marginBottom: "1rem" }}>
            If you change your mind and sign back into your account during this
            period, your account will be restored.
          </p>
          <p className="normalprivacyPolicy" style={{ paddingBottom: "3rem" }}>
            As per our Privacy Policy, please note that we may retain certain
            information as required by law, for the Website or the Application
            to function properly and for statutory and legal purposes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountNotice;
